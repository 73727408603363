'use client'
import dayjs from "dayjs";
import "./style.css";
import React, { useCallback, useEffect, useState } from 'react'
import LaptopCalendarView from './LaptopCalendarView'
import MobileCalendarView from './MobileCalendarView/page'
import Helper from '../../util/helper';
import { useDispatch } from 'react-redux';
import { multicenterActions, formatMsg, calendarActions, packageActions, accountPackageActions } from 'sharedresources';
import { getItem, removeItem } from "../../libs/encryptedStorage";
const { getAllCentersList } = multicenterActions;
const { fetchPackage } = packageActions;
const { getPackageList } = accountPackageActions;
const { getScheduleDetail, sagaSuccess } = calendarActions;


const page = (props) => {


	const dispatch = useDispatch();

	const { firebase, calendarReducer, packageReducer, accountPackageReducer, multicenterReducer } = Helper.getSelectorState(["calendarReducer", "packageReducer", "accountPackageReducer", "multicenterReducer"]);


	// Parent component common things 
	const [packageList, setPackageList] = useState([]);
	const [packageFilter, setpackageFilter] = useState(undefined);
	const [packageMap, setPackageMap] = useState(new Map());
	const [listWithColors, setListWithColors] = useState([]);
	const [filteredCalendars, setFilteredCalendars] = useState([]);
	const [instructorFilter, setInstructorFilter] = useState(undefined);
	const [teacherMap, setTeacherMap] = useState(new Map());
	const [packageColorMap, setPackageColorMap] = useState(new Map());
	const [toggle, setToggle] = useState(1);
	const [centerFilter, setCenterFilter] = useState(undefined);
	const [centerMap, setCenterMap] = useState(new Map());
	const [search, setSearch] = useState<any>();
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const [selectedSchedule, setSelectedSchedule] = useState<any>(JSON.parse(getItem('selectedSchedule')) || null);


	const handlePackageFilterChange = useCallback((packageFilterVal) => {

		const isAllSelected: boolean = packageFilterVal?.some((val) => val === 'all packages') || false;

		if (packageList && isAllSelected) {
			packageFilterVal = packageList.map((item) => item.id);
		}

		let stringifiedData = packageFilterVal ? JSON.stringify(packageFilterVal) : '';

		setpackageFilter(packageFilterVal);
		Helper.setUrlParam("packageFilter", stringifiedData);

	}, [setpackageFilter, packageList]);


	const handleCenterFilterChange = useCallback((centerFilterVal) => {

		const isAllSelected: boolean = centerFilterVal?.some((val) => val === 'all centers') || false;

		if (multicenterReducer.allCentersList && isAllSelected) {
			centerFilterVal = multicenterReducer.allCentersList.map((item) => item.id);
		}

		let stringifiedData = centerFilterVal ? JSON.stringify(centerFilterVal) : '';

		setCenterFilter(centerFilterVal);
		Helper.setUrlParam("centerFilter", stringifiedData);

	}, [setCenterFilter, multicenterReducer.allCentersList]);


	const handleInstructorFilterChange = useCallback((instructorFilterVal) => {

		const isAllSelected: boolean = instructorFilterVal?.some((val) => val === 'allInstructors') || false;

		if (isAllSelected) {
			instructorFilterVal = Helper.getTeacherList().map((item) => item.id);
		}

		let stringifiedData = instructorFilterVal ? JSON.stringify(instructorFilterVal) : '';

		setInstructorFilter(instructorFilterVal);
		Helper.setUrlParam("instructorFilter", stringifiedData);

	}, [setInstructorFilter]);


	function getUrlParam() {

		let search = Helper.getUrlParam('calendarSearch'); 
		search = Helper.checkUrlParam(search, undefined);
		if (search) setSearch(search); 

		let packageFilterJSONString = Helper.getUrlParam("packageFilter");
		let packageFilterParsedJSON = packageFilterJSONString ? JSON.parse(packageFilterJSONString) : undefined;
		if (packageFilterParsedJSON) setpackageFilter(packageFilterParsedJSON);

		let centerFilterJSONString = Helper.getUrlParam("centerFilter");
		let centerFilterParsedJSON = centerFilterJSONString ? JSON.parse(centerFilterJSONString) : undefined;
		if (centerFilterParsedJSON) setCenterFilter(centerFilterParsedJSON);

		let instructorFilterJSONString = Helper.getUrlParam("instructorFilter");
		let instructorFilterParsedJSON = instructorFilterJSONString ? JSON.parse(instructorFilterJSONString) : undefined;
		if (instructorFilterParsedJSON) setInstructorFilter(instructorFilterParsedJSON);
	}


	useEffect(() => {

		getUrlParam();

		if (Helper.getUrlParam("toggle"))
			setToggle(Helper.getUrlParam("toggle") ? Number(Helper.getUrlParam("toggle")) : 1)

		!Helper.isAccount(firebase) && dispatch(getAllCentersList({ firebase: firebase }));

		if (Helper.isAccount(firebase)) {
			dispatch(getPackageList({ loggedInData: firebase }));
		} else {
			dispatch(fetchPackage({ firebase: firebase }));
		}
	}, [])

	useEffect(() => {
		if (packageReducer.packages.length) {
			setPackageList(packageReducer.packages);
		}
		if (accountPackageReducer.packageList.length) {
			setPackageList(accountPackageReducer.packageList);
		}
	}, [packageReducer.packages, accountPackageReducer.packageList])


	useEffect(() => {
		let packageMap = new Map();
		let packageColorMap = new Map();
		let index = 0;

		calendarReducer.calendars?.forEach((item) => {
			if (!packageMap.has(item.packageId)) {
				packageMap.set(item.packageId, index++);
			}
		});

		calendarReducer.calendars?.forEach((item) => {
			if (!packageColorMap.has(item.packageId)) {
				packageColorMap.set(item.packageId, Helper.assignPackageColors(packageMap.get(item.packageId)));
			}
		});

		let listWithColors = calendarReducer.calendars?.map((item) => {
			return {
				...item,
				color: { darkColor: Helper.assignPackageColors(packageMap.get(item.packageId)) }
			}
		});
		setListWithColors(listWithColors);
		setPackageColorMap(packageColorMap);
	}, [calendarReducer.calendars])


	useEffect(() => {
		let newPackageMap = new Map();
		packageList?.forEach(item => {
			newPackageMap.set(item.id, item)
		})
		setPackageMap(newPackageMap);
	}, [packageList])

	useEffect(() => {
		let newCenterMap = new Map();
		multicenterReducer.allCentersList?.forEach(item => {
			newCenterMap.set(item.id, item)
		});
		setCenterMap(newCenterMap);
	}, [multicenterReducer.allCentersList]);

	useEffect(() => {
		let newTeacherMap = new Map();
		Helper.getTeacherList()?.forEach(item => {
			newTeacherMap.set(item.id, item)
		});
		setTeacherMap(newTeacherMap);
	}, []);


	useEffect(() => {
		let filteredListWithColors = listWithColors || [];

		if (instructorFilter?.length) {
			const selectedInstructorsSet = new Set(instructorFilter);
			filteredListWithColors = filteredListWithColors.filter(cal => {
				let packageObj = packageMap?.get(cal.packageId);
				return (
					packageObj?.staffIds?.some((staffId) => selectedInstructorsSet.has(staffId))
				);
			});
		}

		if (packageFilter?.length) {
			const selectedPackagesSet = new Set(packageFilter);
			filteredListWithColors = [...filteredListWithColors].filter(cal => selectedPackagesSet.has(cal.packageId));
		}

		if (centerFilter?.length) {
			const selectedCentersSet = new Set(centerFilter);
			filteredListWithColors = filteredListWithColors.filter((item) => {
				let packageObj = packageMap?.get(item.packageId);
				return packageObj?.centers.some((ele) => selectedCentersSet.has(ele.id));
			});
		}

		if (debouncedSearch) {
			filteredListWithColors = filteredListWithColors.filter((item) => {
				let packageObj = packageList?.find((ele) => ele.id === item.packageId);
				return (
					packageObj?.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
					item.batchName.toLowerCase().includes(debouncedSearch.toLowerCase())
				);
			});
		} else {
			Helper.setUrlParam("package", "");
		}
		
		setFilteredCalendars(Array.from([...filteredListWithColors]));
	
	}, [packageFilter, listWithColors, instructorFilter, centerFilter, debouncedSearch]);


	useEffect(() => {
		const id = setTimeout(() => {
			setDebouncedSearch(search);
		}, 1000); // Adjust debounce time as needed
		return () => (clearInterval(id))
	}, [search]);

	function getEntityDetail() {
		let entityType = Helper.getUrlParam("entityType");
		let id = Helper.getUrlParam("id");
		if (Helper.isAccount(firebase)) {
			if (entityType === "booking") {
				return {
					id: id,
					entityType: "account"
				}
			} else {
				return {
					id: firebase.user.id,
					entityType: "account"
				}
			}
		} else {
			if (entityType === "account") {
				return {
					id: Helper.isAccount(firebase) ? firebase.user.id : id,
					entityType: entityType
				}
			} else if (entityType === "user") {
				return {
					id: id,
					entityType: entityType
				}
			}
		}
	}

	useEffect(() => {
		if (selectedSchedule) {
			dispatch(getScheduleDetail({
				firebase, packageId: selectedSchedule.packageId, date: selectedSchedule.date, slotRandomId: selectedSchedule.slotRandomId, batchId: selectedSchedule.batchId,
				entityType: getEntityDetail()?.entityType,
				entityId: getEntityDetail()?.id
			}))
		}
	}, [selectedSchedule])

	useEffect(() => {
		return () => {
			dispatch(sagaSuccess({ calendars: [] }))
		}
	}, [])

	const handleSearch = (e) => {
		setSearch(e.target.value); 
		Helper.setUrlParam('calendarSearch', e.target.value ? e.target.value : ''); 
	}

	return (
		<div>
			<div
				className='laptop-calendar-view'
			>
				<LaptopCalendarView
					search={search}
					handleSearch={handleSearch}
					packageList={packageList}
					packageFilter={packageFilter}
					handlePackageFilterChange={handlePackageFilterChange}
					instructorFilter={instructorFilter}
					handleInstructorFilterChange={handleInstructorFilterChange}
					teacherMap={teacherMap}
					allCentersList={multicenterReducer.allCentersList}
					centerFilter={centerFilter}
					handleCenterFilterChange={handleCenterFilterChange}
					centerMap={centerMap}
					toggle={toggle}
					setToggle={setToggle}
					packageMap={packageMap}
					filteredCalendars={filteredCalendars}
					selectedSchedule={selectedSchedule}
					setSelectedSchedule={setSelectedSchedule}
					mode={props.mode}
					calendarReducer={calendarReducer}
					firebase={firebase}
				/>
			</div>
			<div
				className='mobile-calendar-view'
			>
				<MobileCalendarView
					search={search}
					handleSearch={handleSearch}
					packageList={packageList}
					packageFilter={packageFilter}
					handlePackageFilterChange={handlePackageFilterChange}
					instructorFilter={instructorFilter}
					handleInstructorFilterChange={handleInstructorFilterChange}
					allCentersList={multicenterReducer.allCentersList}
					centerFilter={centerFilter}
					handleCenterFilterChange={handleCenterFilterChange}
					filteredCalendars={filteredCalendars}
					selectedSchedule={selectedSchedule}
					setSelectedSchedule={setSelectedSchedule}
					calendarReducer={calendarReducer}
					firebase={firebase}
				/>
			</div>
		</div>
	)
}

export default page