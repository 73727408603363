export default {
    newUser: "newUser",
    schools: "schools",
    discount: "/discount",
    bookingActivity: "/activity",
    tax: "/tax",
    registrationFee: "/registrationFee",
    additionalCharge: "/additionalCharge",
    packages: "/packages",
    pricingPacks: "/pricingPacks",
    parentRef: "/parents",
    packageTypeRef: "/packageType",
    ageRangeRef: "/ageRange",
    skillRef: "/skill",
    parents: "/parents",
    student: "/students",
    lastUpdateNotification: "/lastUpdate/notifications",
    lastUpdateTeacher: "/lastUpdate/teacher",
    lastUpdateStudent: "/lastUpdate/student",
    reportRequests: "/reportRequests",
    accounts: "/accounts",
    users: "/users",
    lastUpdateUsers: "/lastUpdate/user", 
    lastUpdateAccounts: "/lastUpdate/account" 
}