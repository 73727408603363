import styled from 'styled-components';
import { Form } from 'antd';


const StyledFormItem = styled(Form.Item)`
    .ant-form-item-label > label {
        width: 100%;
        margin-bottom: 5px;
    }
`;


export {
    StyledFormItem
}