import React from 'react';
import dayjs from 'dayjs';
import Helper from '../../util/helper';
import formatMsg from '../../util/formatMsg';


const transactStatusColorMap = Helper.getTranscatStatusColorMap();

const TransactionMobileCard = (props: any) => {

    const {
        transactionObj,
        firebase,
        packageIdToObjMap
    } = props;

    function getTransactPaymentModeIcon() {

        let src = '/images/purpleDollarCoin.png';
        let className = 'transact-dollar-icon-img';

        const lowercasedPaymentMode = transactionObj?.paymentMode?.toLowerCase()

        if (lowercasedPaymentMode.includes('cash')) {
            src = '/images/purpleDollarCoin.png';
        }
        else if (lowercasedPaymentMode.includes('bank')) {
            src = '/images/purpleBankTransfer.png';
        }
        else if (lowercasedPaymentMode.includes('credit')) {
            src = '/images/purpleCredits.png';
        }
        else if (lowercasedPaymentMode.includes('card')) {
            src = '/images/purpleCard.png';
            className = 'transact-card-icon-img';
        }

        return (
            <img
                src={src}
                className={className}
            />
        )
    }

    return (
        <div className='transact-list-card'>
            <div className='transact-card-1st-row'>
                <div className='transact-basic-detail-container'>
                    <div className='transact-pkg-name'>
                        {packageIdToObjMap && packageIdToObjMap.get(transactionObj.packageId)?.name}
                    </div>
                    <div className='transact-id-container'>
                        <div className='mobile-transact-inv-date'>
                            {transactionObj.invoiceNumber}
                        </div>
                        {
                            transactionObj.transactionId &&
                            <>
                                <div className='mobile-transact-vertical-divider' />
                                <div className='transact-id'>
                                    {transactionObj.transactionId}
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className='transact-data'>
                    <div className='mobile-transact-amount'>
                        {Helper.getCurrency(firebase)}
                        {" "}
                        {transactionObj.amount}
                    </div>
                    <div
                        className='mobile-transact-status'
                        style={{ color: transactStatusColorMap[transactionObj.status] }}
                    >
                        {formatMsg(transactionObj.status || 'hi')}
                    </div>
                </div>
            </div>
            <div className='mobile-transact-horizontal-divider' />
            <div className='transact-card-2nd-row'>
                <div className='transact-2nd-row-1st-col'>
                    <div className='mobile-transact-raise-date'>
                        <div className='transact-calendar-img-container'>
                            <img
                                src='/images/miniCalendar.png'
                                className='transact-calendar-img'
                            />
                        </div>
                        {dayjs.tz(transactionObj.invoiceRaiseDate).format("DD MMM YYYY")}
                    </div>
                </div>
                {
                    transactionObj.paymentMode &&
                    <div className='transact-payment-mode-container'>
                        <div className='transact-payment-mode-text'>
                            {transactionObj.paymentMode}
                        </div>
                        <div className='transact-payment-mode-img-container'>
                            {getTransactPaymentModeIcon()}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default TransactionMobileCard