"use client"
import React, { useEffect, useRef, useState, memo, useMemo } from 'react';
import dayjs from 'dayjs';
import SpinWrapper from "./../SpinWrapper/page"
import { Box, PageHeaderWrapper } from "../../components/wrapperComponents/page"
import { Col, DatePicker, Input, Modal, Popover, Row, Select } from 'antd';
import Helper from "../../util/helper";
import Toggle from '../buttons/toggle';
import colors from '../../libs/colorFactory';
import CalendarListView from './scheduleCalender/calendarListView';
import { useDispatch } from "react-redux";
import ViewScheduleDrawer from './ViewScheduleDrawer';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { SearchOutlined } from "@ant-design/icons";
import { PrefixIconButton, calendarActions, formatMsg } from 'sharedresources';
import { removeItem } from '../../libs/encryptedStorage';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
const { getCalendar, getEntitySchedule } = calendarActions;
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; // Import Ant Design icons

const LaptopCalendarView: React.FC<any> = (props: any) => {
	const calendarRef = useRef<FullCalendar | null>(null);
	const {
		search,
		handleSearch,
		packageList,
		packageFilter,
		handlePackageFilterChange,
		instructorFilter,
		handleInstructorFilterChange,
		teacherMap,
		allCentersList,
		centerFilter,
		handleCenterFilterChange,
		centerMap,
		toggle,
		setToggle,
		packageMap,
		filteredCalendars,
		selectedSchedule,
		setSelectedSchedule,
		calendarReducer,
		firebase,
		mode
	} = props;

	const dispatch = useDispatch();
	let scrollRef = useRef<any>();

	// Laptop view specific things 
	const [currentMonth, setCurrentMonth] = useState(dayjs.tz());
	const [weekValue, setWeekValue] = useState(dayjs.tz());
	const [viewMode, setViewMode] = useState("dayGridMonth");
	const [viewType, setViewType] = useState("grid");
	const [popoverData, setPopoverData] = useState(null);
	const popoverRef = useRef(null);

	const scrollDown = () => {
		setTimeout(() => {
			scrollRef.current.scrollIntoView({
				block: 'end',
			});
		}, 100);
	};
	let entityType = Helper.getUrlParam("entityType");
	const formattedEvents = entityType ? useMemo(() => {
		return filteredCalendars.map((event) => ({
			id: event.batchId,
			title: event.packageName,
			start: dayjs.tz(event.startTime).tz("UTC").format(),
			end: dayjs.tz(event.endTime).tz("UTC").format(),
			allDay: false,
			backgroundColor: event.color.darkColor,
			extendedProps: {
				...event
			},
		}));
	}, [filteredCalendars, packageFilter]) :
		useMemo(() => {
			return filteredCalendars.map((event) => ({
				id: event.batchId,
				title: event.packageName,
				start: dayjs.tz(event.startTime).tz("UTC").format(),
				end: dayjs.tz(event.endTime).tz("UTC").format(),
				allDay: false,
				backgroundColor: event.color.darkColor,
				extendedProps: { ...event },
			}));
		}, [filteredCalendars, viewMode])

	useEffect(() => {
		let reqObj = {
			startDate: undefined,
			endDate: undefined,
		}

		switch (viewMode) {
			case "timeGridWeek":
				reqObj.startDate = dayjs.tz(weekValue).startOf("week").valueOf();
				reqObj.endDate = dayjs.tz(weekValue).endOf("week").valueOf();
				break;
			case "dayGridMonth":
				reqObj.startDate = dayjs.tz(weekValue).startOf("month").valueOf();
				reqObj.endDate = dayjs.tz(weekValue).endOf("month").valueOf();
				break;
			case "timeGridDay":
				reqObj.startDate = dayjs.tz(weekValue).startOf("day").valueOf();
				reqObj.endDate = dayjs.tz(weekValue).endOf("day").valueOf();
				break;
		}
		if (viewType != "gridList") {
			let id = Helper.getUrlParam("id");
			let entityType = Helper.getUrlParam("entityType");
			!entityType && !Helper.isAccount(firebase) && dispatch(getCalendar({ firebase, startDate: reqObj.startDate, endDate: reqObj.endDate, packageId: id ? id : null }))
		}
	}, [weekValue, viewMode]);

	useEffect(() => {
		let entityType = Helper.getUrlParam("entityType");
		let id = Helper.getUrlParam("id");
		if (Helper.isAccount(firebase)) {
			if (entityType === "booking") {
				dispatch(getEntitySchedule({ firebase, entityId: id, entityType: "booking" }));
			} else {
				dispatch(getEntitySchedule({ firebase, entityId: firebase.user.id, entityType: "account" }));
			}
		} else {
			if (entityType === "account") {
				dispatch(getEntitySchedule({ firebase, entityId: Helper.isAccount(firebase) ? firebase.user.id : id, entityType: "account" }));
			} else if (entityType === "user") {
				dispatch(getEntitySchedule({ firebase, entityId: id, entityType: "user" }));
			}
		}
	}, [])

	useEffect(() => {
		setViewType(toggle === 1 ? "grid" : "list")
	}, [toggle])

	function hideHeader() {
		let entityType = Helper.getUrlParam("entityType");
		return Helper.isAccount(firebase) || entityType === "account" || entityType === "user"
	}

	const handleViewChange = (view) => {
		setViewMode(view);
	};

	const handleNavigation = (action) => {
		if (viewMode !== "list" && calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			if (action === "prev") calendarApi.prev();
			else if (action === "next") calendarApi.next();
			else if (action === "today") calendarApi.today();
			setWeekValue(dayjs.tz(calendarApi.getDate())); // Update selected date
		} else {
			let currDate = dayjs.tz(weekValue);
			if (viewMode === "dayGridMonth") {
				if (action === "prev") currDate = currDate.subtract(1, "month");
				else if (action === "next") currDate = currDate.add(1, "month");
			} else if (viewMode === "timeGridWeek") {
				if (action === "prev") currDate = currDate.subtract(1, "week");
				else if (action === "next") currDate = currDate.add(1, "week");
			} else if (viewMode === "timeGridDay") {
				if (action === "prev") currDate = currDate.subtract(1, "day");
				else if (action === "next") currDate = currDate.add(1, "day");
			}
			setWeekValue(currDate);
		}
	};

	useEffect(() => {
		if (viewMode !== "list" && calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			if (calendarApi) {
				calendarApi.changeView(viewMode); // Change view
				calendarApi.gotoDate(Helper.convertToDate(weekValue, firebase)); // Change date
			}
		}

	}, [viewMode, weekValue]);

	function getDatelabelForHeader() {
		if (viewMode === "dayGridMonth") {
			return dayjs.tz(weekValue).format("MMMM, YYYY")
		}
		else if (viewMode === "timeGridWeek") {
			const startOfWeek = dayjs.tz(weekValue).startOf("week");
			const endOfWeek = dayjs.tz(weekValue).endOf("week");

			return `${startOfWeek.format("MMM DD, YYYY")} - ${endOfWeek.format("MMM DD, YYYY")}`;
		} else {
			return dayjs.tz(weekValue).format("DD MMMM, YYYY")
		}
	}

	const handleMoreClick = (info) => {
		info.jsEvent.preventDefault(); // Stop FullCalendar's default behavior
		info.jsEvent.stopPropagation(); // Stop event bubbling
		setPopoverData({
			date: info.date,
			events: info.allSegs.map((seg) => seg.event),
			// position: { x: info.jsEvent.pageX, y: info.jsEvent.pageY }
		});
		return false;
	};

	const closePopover = () => {
		setPopoverData(null);
	};

	useEffect(() => {
		if (calendarRef.current) {
			let calendarApi = calendarRef.current.getApi();
			calendarApi.removeAllEvents(); // Clear old events
			calendarApi.addEventSource(formattedEvents); // Add updated events
		}
	}, [formattedEvents]);


	return (
		<SpinWrapper spinning={calendarReducer.isLoading}>
			<Box style={{ paddingBottom: 0 }}>
				{!hideHeader() && <PageHeaderWrapper>
					<p className='global-page-header'>{formatMsg("sidebar.calendar")}
					</p>
				</PageHeaderWrapper>}
				<Row
					typeof='flex'
					justify={"space-between"}
					gutter={[30, 30]}
				>
					<Col
						md={8}
						lg={7}
						xl={6}
						xxl={5}
						style={{
							backgroundColor: "#F5F7FB",
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",
							overflowX: "scroll"
						}}
					>
						<div className='custom-picker-container' style={{ borderBottom: "1px solid #D0D0D0", paddingBottom: "12px" }}>
							{viewMode === "timeGridDay" ?
								<div className='custom-day-picker'>
									<DayPicker
										timeZone={firebase.schoolConfig.timezone}
										onSelect={days => {
											setWeekValue(dayjs.tz(days))
										}}
										numberOfMonths={1}
										pagedNavigation
										showOutsideDays
										selected={Helper.convertToDate(weekValue, firebase)}
										month={Helper.convertToDate(currentMonth, firebase)}
										onMonthChange={(month) => setCurrentMonth(dayjs.tz(month))}
										modifiers={{
											selected: Helper.convertToDate(weekValue, firebase),
										}}
										weekStartsOn={1}
										mode='single'
									// disabled={disabled}
									/>
								</div>
								:
								viewMode === "timeGridWeek" ?
									<div className="custom-week-picker">
										<DatePicker.WeekPicker
											style={{ width: "100%" }}
											value={dayjs.tz(weekValue)}
											allowClear={false}
											onChange={(value) => {
												setViewType(prev => prev === "gridList" ? "grid" : prev)
												setWeekValue(dayjs.tz(value));
											}}
											open={true}
											format={Helper.customWeekStartEndFormat}
											getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
										/>
									</div>
									:
									<div className="custom-month-picker">
										<DatePicker.MonthPicker
											allowClear={false}
											value={weekValue}
											// style={{ width: "60%" }}
											format={"MMM-YYYY"}
											onChange={(value) => {
												setViewType(prev => prev === "gridList" ? "grid" : prev)
												setWeekValue(dayjs.tz(value));
											}}
											open={true}
											getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
											size='small'
										/>
									</div>
							}
						</div>
						<div style={{ paddingLeft: "12px" }}>
							{!hideHeader() && <div style={{ marginTop: 14 }}>
								<div className='labelstyle'>{formatMsg("centers")}</div>
								<Select
									style={{ width: '90%', marginTop: "6px" }}
									popupMatchSelectWidth={false}
									mode='multiple'
									onChange={handleCenterFilterChange}
									maxTagCount={0}
									maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('center', centerFilter, centerMap)}
									value={centerFilter}
									allowClear
									placeholder={formatMsg('placeholder.selectCenters')}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
								>
									<Select.Option key={"all"} value={"all centers"}>{formatMsg("allCenters")}</Select.Option>
									{
										allCentersList?.map((item) => {
											return (
												<Select.Option key={item.id} value={item.id}>
													{item.name}
												</Select.Option>
											)
										})
									}
								</Select>
							</div>}
							<div style={{ marginTop: 14 }}>
								<div className='labelstyle'>{formatMsg("sidebar.packages")}</div>
								<Select
									mode='multiple'
									onChange={handlePackageFilterChange}
									maxTagCount={0}
									maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('package', packageFilter, packageMap)}
									value={packageFilter}
									style={{ width: "90%", marginTop: "6px" }}
									allowClear
									placeholder={formatMsg("placeholder.selectPackages")}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
								>
									<Select.Option key={"all"} value={"all packages"}>{formatMsg("caps.allPackages")}</Select.Option>
									{packageList?.map(pack =>
										<Select.Option key={pack.id} value={pack.id}>
											{pack.name}
										</Select.Option>
									)}
								</Select>
							</div>
							{!hideHeader() && <div style={{ marginTop: 14 }}>
								<div className='labelstyle'>{formatMsg("instructors")}</div>
								<Select
									mode='multiple'
									onChange={handleInstructorFilterChange}
									maxTagCount={0}
									maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('instructor', instructorFilter, teacherMap)}
									value={instructorFilter}
									style={{ width: "90%", marginTop: "6px" }}
									allowClear
									placeholder={formatMsg("placeholder.instructors")}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
								>
									<Select.Option value="allInstructors">{formatMsg("allInstructors")}</Select.Option>
									{Helper.getTeacherList().map((item) => (
										<Select.Option value={item.id}>{item.name}</Select.Option>
									))}
								</Select>
							</div>}

						</div>
					</Col>
					<Col
						md={16}
						lg={17}
						xl={18}
						xxl={19}
					>
						<div className={`${mode == "packageDetailView" ? "" : "fc-top-header"} pt-20`}>
							<div style={{
								marginBottom: 20,
								display: "flex",
								gap: 5,
								justifyContent: "space-between",
								alignItems: "center"
							}}>
								<div style={{
									display: "flex",
									alignItems: "center",
									gap: 15,
									marginLeft: 8
								}}>
									<div className="cl-nav-buttons">
										<LeftOutlined
											onClick={() => handleNavigation("prev")}
											style={{
												color: colors.v2_Cerulean,
												fontSize: "12px",
												marginRight: 6
											}}
										/>

										<div className="selected-date">
											<strong>{getDatelabelForHeader()}</strong>
										</div>
										<RightOutlined onClick={() => handleNavigation("next")} style={{
											color: colors.v2_Cerulean,
											fontSize: "12px",
											marginLeft: 8
										}} />
									</div>

									<div>
										<Input
											className='fc-search'
											placeholder={formatMsg("search")}
											value={search}
											onChange={(e) => handleSearch(e)}
											suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										/>
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={styles.colStyle}>
										{["timeGridDay", "timeGridWeek", "dayGridMonth"].map((item) => (
											<PrefixIconButton
												title={formatMsg(item)}
												backgroundColor={viewMode === item ? colors.v2_Cerulean : "white"}
												fontColor={viewMode === item ? colors.color_white : colors.black}
												onClick={() => handleViewChange(item)}
												borderRadius={10}
												fontWeight={400}
											/>
										))}
									</div>
									<div style={{ ...styles.colStyle, display: "flex", justifyContent: "flex-end" }}>
										<Toggle
											buttonOneTitle={<img src='/images/cal-active.png' style={{ height: 13, width: 13, margin: "auto 16px" }} />}
											buttonTwoTitle={<img src='/images/list-inactive.png' style={{ height: 13, width: 13, margin: "auto 16px" }} />}
											onToggle={(e) => {
												Helper.setUrlParam("toggle", e)
												setToggle(e)
											}}
											activeBackground={colors.color_white}
											toggle={toggle}
											selectedTextColor={"#7413BD"}
											textColor={"#5B5B5B"}
											width="fit-content"
										/>
									</div>
								</div>
							</div>
							<div className='custom-fc' ref={scrollRef} style={{ boxShadow: "0px 4px 25px 0px #0000000F", borderRadius: 10, border: "0.2px solid #D0D0D0", overflow: "auto" }}>
								{(viewType === "grid" || viewType === "gridList") &&
									<FullCalendar
										key={formattedEvents.map(event => event.id + event.start + event.title).join("-")}
										ref={calendarRef}
										plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
										initialView={viewMode}
										events={formattedEvents}
										initialDate={Helper.convertToDate(weekValue, firebase)}
										slotEventOverlap={false}
										eventContent={
											// viewMode === "dayGridMonth" ?
											({ event }) => {
												return (
													<div
														style={{
															backgroundColor: Helper.hexToLightColor(event.backgroundColor), // Custom color
															color: event.backgroundColor, // Text color
															padding: "10px",
															borderRadius: "5px",
															fontSize: "var(--font-size-13)",
															border: "0.5px solid #D0D0D0",
															borderLeftColor: event.backgroundColor,
															borderLeft: "5px solid",
															fontWeight: 500,
															overflowX: "hidden",
															whiteSpace: viewMode !== "dayGridMonth" && "nowrap",
															minHeight: "100%"
														}}
														className='cl-custom-event'
													>
														<div>
															<div className='fc-event-time'><span>{dayjs.tz(event.start).format("hh:mm A")}</span> - <span>{event.end ? dayjs.tz(event.end).format("hh:mm A") : null}</span></div>
															<div className='event-title fc-event-time'>{event.title}</div>
															<div><div>{event.extendedProps.batchName}</div></div>
														</div>
														{/* <br /> */}
													</div>
												)

											}
											// : null
										}

										headerToolbar={false}
										dayMaxEvents={2}
										eventMaxStack={viewMode === "timeGridWeek" ? 2 : 6}
										dayMaxEventRows={3}
										moreLinkClick={(info) => {
											handleMoreClick(info);
											// return false;
											// return "popover"; // Prevent default modal
										}}
										eventClick={(info) => {
											setSelectedSchedule({
												...info.event.extendedProps,
											})
										}}
										eventDidMount={(info) => {
											if (info.view.type === "timeGridWeek" || info.view.type === "timeGridDay") {
												info.el.style.backgroundColor = "transparent";
												info.el.style.border = "none";
												info.el.style.color = "#000"; // Ensure text is visible
											}

										}}
										views={{
											dayGridMonth: {
												dayHeaderContent: (info) => {
													return (
														<div>
															<div className='fc-header-day'>{dayjs.tz(info.date).format('ddd')}</div>
														</div>
													)
												}
											},
											timeGridWeek: {
												dayHeaderContent: (info) => {
													const isToday = dayjs.tz(info.date).isSame(dayjs.tz(), 'day');
													return (
														<div>
															<div className='fc-header-day'>{dayjs.tz(info.date).format('ddd')}</div>

															<div className={isToday ? "fc-header-date-today" : 'fc-header-date'}>{dayjs.tz(info.date).format('D')}</div>
														</div>
													)
												}
											},
											timeGridDay: {
												dayHeaderContent: (info) => {
													const isToday = dayjs.tz(info.date).isSame(dayjs.tz(), 'day');
													return (
														<div>
															<div className='fc-header-day'>{dayjs.tz(info.date).format('ddd')}</div>

															<div className={isToday ? "fc-header-date-today" : 'fc-header-date'}>{dayjs.tz(info.date).format('D')}</div>
														</div>
													)
												}
											}
										}}
										allDaySlot={false}
									/>
								}
								{(viewType === "list" || viewType === "gridList") &&
									<CalendarListView
										appointments={filteredCalendars}
										viewMode={viewType === "gridList" ? "day" : viewMode}
										weekValue={weekValue}
										packageMap={packageMap}
										setSelectedSchedule={setSelectedSchedule}
										firebase={firebase}
									/>
								}
							</div>
						</div>
						<ViewScheduleDrawer
							selectedSchedule={selectedSchedule}
							drawerOnClose={() => {
								setSelectedSchedule(undefined)
								removeItem('selectedSchedule');
							}}
							isLoading={calendarReducer.isLoading}
							scheduleDetail={calendarReducer.scheduleDetail}
							packageMap={packageMap}
							firebase={firebase}
						/>

						{popoverData && (
							<Modal
								open={popoverData}
								onCancel={closePopover}
								footer={null}
								className="popover"
								width={350}
							>
								<div className='fc-popup-displayFlex '>
									<div className='fc-popup-day'>{dayjs.tz(popoverData.date).format("ddd").toUpperCase()}</div>
									<div className='fc-popup-date'> {dayjs.tz(popoverData.date).format("DD")}</div>
								</div>
								{popoverData.events.map((event) => (
									<div key={event.id} style={{ backgroundColor: Helper.hexToLightColor(event.backgroundColor), marginBottom: "12px", padding: "8px", display: 'flex', alignItems: "center", justifyContent: "flex-start", borderRadius: 8, cursor: "pointer" }}
										onClick={() => {
											setSelectedSchedule({
												...event.extendedProps,
											})
										}}
									>
										<div style={{ backgroundColor: event.backgroundColor, marginRight: "12px" }} className='event-circle'></div>	<div>{dayjs.tz(event.start).format("hh:mm A")}{" "}{event.title}</div>
									</div>
								))}
							</Modal>
						)}
					</Col>
				</Row>
			</Box>
		</SpinWrapper>
	);
};

export default LaptopCalendarView;

const styles: any = {
	colStyle: {
		display: "flex",
		alignItems: "center",
		flexWrap: "nowrap",
		whiteSpace: "nowrap",
		marginRight: 12
	}
}