import { loginApi } from "../firebase-api/login";
import { endPointUrl } from "../libs/fbConfig";
import Helper from "./helper";
import authHelper from "../okta/page"
const superagent = require("superagent");





async function callApi(firebase: any, method: any, callUrl: any, requestPayload: any, unauthorized?: any, endPoint?: any, queryParameter?: any) {
    var endpoint = endPoint ? endPoint : endPointUrl;
    if (firebase) {
        callUrl = callUrl + (queryParameter ? "&" : "?") + "centerId=" + (firebase.sbDbName || firebase.centerId || Helper.getUrlParam("centerId"))
        requestPayload = Helper.getSuperagentBody(requestPayload, firebase);
    }
    let promise;
    let authToken: any

    if (!unauthorized) {
        authToken = await authHelper.getAuthToken()
    }
    switch (method.toLowerCase()) {
        case "post":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .post(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set({
                        "Authorization": "Bearer " + authToken,
                        "x-platform": "booking"
                    }).send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;

        case "get":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .get(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set({
                        Authorization: "Bearer " + authToken,
                        "x-platform": "booking"
                    })
                    .send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;

        case "delete":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .delete(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set({
                        Authorization: "Bearer " + authToken,
                        "x-platform": "booking"
                    })
                    .send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;
    }
}
export { callApi };
