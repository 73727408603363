import "firebase/compat/storage";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { loginApi } from "../../firebase-api/login";
import actions from "../actionType";
import { callApi } from "../../util/accountSuperAgentUtil";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import bugsnagClient from "@bugsnag/js";
import Helper from "../../util/helper";
import { getItem } from "../../libs/encryptedStorage";
import { oktaRedirectUrl } from "../../libs/fbConfig";
import dayjs from "dayjs";
function* loginAsGuest(request) {
  try {
    const { payload } = request;
    let alreadyLoggedIn = payload.alreadyLoggedIn || null;
    let currentUser = payload.user;
    let user: any = {
      email: currentUser.email || null,
      countryCode: currentUser.countryCode || null,
      phoneNumber: currentUser.phoneNumber || (!currentUser.email ? currentUser.name : null) || null,
      relation: currentUser.relation || null,
      id: currentUser.id || null
    }
    let centerId =
      getItem("centerId");
    let endpoint = `bookingoApi/signup`
    endpoint += "/" + (user.email || user.phoneNumber);

    let response;
    // if (alreadyLoggedIn) {
    //   endpoint += "/" + (user.email || user.phoneNumber) + "/profileDetails";
    //   let studentId = getItem("guestStudentId");
    //   studentId = Helper.checkUrlParam(studentId, null);
    //   if (studentId) {
    //     endpoint += "?guestStudentId=" + studentId;
    //   }
    //   response = yield call(callApi, { user, centerId }, "get", endpoint, user, null, null, studentId || null);
    // }
    // else {
    delete user.id;
    response = yield call(callApi, { user, centerId }, "get", endpoint, {});
    // }
    if (response?.status == 200) {
      user = response.body.data.guest;
      let schoolConfig = response.body.data.schoolConfig;
      let studentId = user?.student?.id;
      let studentList = response.body.data.studentList || [];
      let guestList = response.body.data.guestList || [user];
      let isEnrolled = response.body.data.isEnrolled || false;
      if (schoolConfig.timezone) {
        dayjs.tz.setDefault(schoolConfig.timezone);
      }
      loginApi.initBugSnag(user, centerId)
      let profileComplete = response.body.data.profileComplete;
      loginApi.login({ centerId, guestUser: user, guestStudentId: studentId });
      let secondaryApp = yield call(loginApi.initSecondaryDb, schoolConfig, centerId);
      if (!alreadyLoggedIn) {
        yield put({
          type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
          payload: {
            operationType: "LOGGED_IN_AS_GUEST",
            isLoading: false,
            loggedInData: {
              user: user,
              loggedIn: true,
              centerId: centerId,
              profileComplete: profileComplete,
              studentList: studentList,
              schoolConfig: schoolConfig,
              secondaryApp: secondaryApp,
              // secondaryDb: secondaryApp.database(),
              secondaryStorage: secondaryApp.storage(),
              sbp: schoolConfig.branchPath,
              sbDbName: schoolConfig.centerId,
              guestList: guestList,
              isEnrolled
            }
          }
        });
      }
      else {
        yield put({
          type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
          payload: {
            operationType: "ALREADY_LOGGED_IN_AS_GUEST",
            isLoading: false,
            loggedInData: {
              user: user,
              loggedIn: true,
              centerId: centerId,
              profileComplete: profileComplete,
              studentList: studentList,
              schoolConfig: schoolConfig,
              secondaryApp: secondaryApp,
              // secondaryDb: secondaryApp.database(),
              secondaryStorage: secondaryApp.storage(),
              sbp: schoolConfig.branchPath,
              sbDbName: schoolConfig.centerId,
              guestList: guestList,
              isEnrolled
            }
          }
        });
      }
    }
    else if (response.status == 206) {
      if (response.body.data?.isParent) {
        notification("warning", formatMsg("alreadyParentNotif"));
        // Determine the URL based on the environment
        const redirectUrl = process.env.NODE_ENV === "production"
          ? "https://admin.mybookingo.com"
          : "https://dev.mybookingo.com";

        // Redirect to the appropriate URL
        window.location.href = redirectUrl;
      }
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { operationType: "LOGGED_IN_AS_GUEST_FAILED", isLoading: false, }
      });
    }
    else {
      notification("error", formatMsg("failedToLogin"))
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { operationType: "LOGGED_IN_AS_GUEST_FAILED", isLoading: false, }
      });
    }
  } catch (err) {
    console.log("error in loginAsGuest  ", err);
    if (err?.response?.body?.message) notification("error", err?.response?.body?.message)
    yield logout()
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
      payload: { operationType: "LOGGED_IN_AS_GUEST_FAILED", isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function* createStudentProfile(request) {
  try {
    const { payload } = request;
    const { loggedInData, requestPayload } = payload;
    let storagePath = loggedInData.sbp + "/media/images/";
    if (requestPayload.student.profileImageUrl && typeof requestPayload.student.profileImageUrl !== 'string') {
      let photourl = yield call(Helper.getAttachedMediaPath, storagePath, requestPayload.student.profileImageUrl, loggedInData);
      if (photourl) {
        requestPayload.student.profileImageUrl = photourl[0].path
      }
    }
    requestPayload.id = loggedInData.user.id
    let endpoint = "bookingoApi/guest/" + loggedInData.user.id
    let response = yield call(callApi, loggedInData, "post", endpoint, requestPayload);
    if (response?.statusCode == 200) {
      let data = response.body.data;
      let studentList = response.body.data.studentList || [];
      if (data.profileComplete) {
        notification("success", formatMsg("profileCreatedSuccessfully"))
      }
      loginApi.login({ centerId: loggedInData.centerId, guestUser: { ...data.guest, }, guestStudentId: data.guest.student.id });
      yield put({
        type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
        payload: {
          operationType: "CREATE_PROFILE",
          loggedInData: { ...loggedInData, profileComplete: data.profileComplete, user: { ...data.guest, }, studentList: studentList },
          isLoading: false,
        }
      });
    }
    else if (response?.statusCode == 206) {
      notification("error", response.body.data.message)
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { isLoading: false, operationType: "CREATE_PROFILE_FAILED", }
      });
    }
    else {
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { isLoading: false, operationType: "CREATE_PROFILE_FAILED", }
      });
    }
  } catch (err) {
    console.log("error in createProfile  ", err);
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}



export function* logout() {
  try {
    loginApi.logout();
    yield put({
      type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
      payload: {
        operationType: "LOGOUT",
        isLoading: false,
        loggedInData: undefined
      }
    });
  } catch (err) {
    console.log("error in logout  ", err);
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function* addSibling(request) {
  try {
    const { payload } = request;
    const { loggedInData, studentObj } = payload;
    let endpoint = "bookingoApi/guest/" + loggedInData.user.id + "/addSibling";
    let response = yield call(callApi, loggedInData, "post", endpoint, studentObj);
    if (response?.status == 200) {
      let studentList = response.body.data || [];
      yield put({
        type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
        payload: {
          operationType: "SIBLING_ADDED",
          isLoading: false,
          loggedInData: {
            ...loggedInData,
            studentList: studentList
          }
        }
      });
    }
    else {
      notification("error", formatMsg("failedToAddSibling"))
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    notification("error", formatMsg("failedToAddSibling"))
    console.log("error in add sibling  ", err);
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function* createParent(request) {
  try {
    const { payload } = request;
    const { loggedInData, reqObj } = payload;
    let storagePath = loggedInData.sbp + "/media/images/";
    if (reqObj.profileImageUrl && typeof reqObj.profileImageUrl !== 'string') {
      let photourl = yield call(Helper.getAttachedMediaPath, storagePath, reqObj.profileImageUrl, loggedInData);
      if (photourl) {
        reqObj.profileImageUrl = photourl[0].path
      }
    }
    let endpoint = "bookingoApi/guest";
    if (reqObj.id) {
      endpoint = "bookingoApi/guest/" + reqObj.id;
    }
    let response = yield call(callApi, loggedInData, "post", endpoint, reqObj);
    if (response?.status == 200) {
      let studentList = response.body.data || [];
      notification("success", formatMsg("addParentSuccess"));
      yield put({
        type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
        payload: {
          operationType: "PARENT_ADDED",
          isLoading: false,
          loggedInData: {
            ...loggedInData,
            studentList: studentList
          }
        }
      });
    }
    else {
      notification("error", formatMsg("failedToAddParent"))
      yield put({
        type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    notification("error", formatMsg("failedToAddParent"))
    console.log("error in add parent  ", err);
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function getLoggedInData(loggedInData) {
  // not logged in case
  if (!loggedInData || !loggedInData.centerId || !loggedInData.user || !loggedInData.user.id) {
    let centerId = getItem("centerId") || Helper.getUrlParam("centerId");
    return { centerId: centerId, user: { name: "" } };
  }
  return loggedInData;
}


function* getCustomFields(request) {
  try {
    let { firebase, fieldType } = request.payload;
    firebase = getLoggedInData(firebase);
    let endpoint = firebase?.user?.id ? `bookingoApi/settings/studentCustomField/${fieldType}` : `bookingoApi/guest/settings/studentCustomField/${fieldType}`
    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true, null);
    if (response.status == 200) {
      const { customFields } = response.body.data;
      yield put({
        type: actions.ACCOUNT_LOGIN_SLICE_OPERATION,
        payload: { isLoading: false, customFields, [fieldType + "Fields"]: response.body.data }
      });
    }
    else {
      yield put({ type: actions.ACCOUNT_LOGIN_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.ACCOUNT_LOGIN_SAGA_FAILED
    });
    console.log("failed to fetch custom fields", err);
  }
}



export default function* rootSaga() {
  yield takeLatest(actions.LOGIN_AS_GUEST, loginAsGuest);
  yield takeLatest(actions.LOGOUT, logout);
  yield takeLatest(actions.CREATE_STUDENT_PROFILE, createStudentProfile);
  yield takeLatest(actions.Add_SIBLING, addSibling);
  yield takeLatest(actions.CREATE_PARENT, createParent)
  yield takeEvery(actions.GET_CUSTOMFIELDS, getCustomFields)
}

