import React, { useEffect, useState } from 'react';
import { Popover, List, Form } from 'antd';
import "./style.css"
import LinkWrapper from '../linkWrapper/page';
const CustomPopoverOptions: any = (props) => {
    const { open, setOpen, recordId, options, imgWidth, imgHeight, imgSrc } = props;

    const getOptions = () => {
        return options?.map((item) => {
            return (<div onClick={(e) => {
                e.preventDefault(); // Prevent default action
            }}>
                <List className='cpos-listitem-popOptions' >
                    {
                        item.redirect ?
                            <LinkWrapper href={item?.path}>
                                <List.Item onClick={() => item.action && item.action()} >
                                    <img src={item.image} className='cpos-popOption-image' alt="Edit" />
                                    <p>{item.title}</p>
                                </List.Item>
                            </LinkWrapper> :
                            <List.Item onClick={() => item.action && item.action()} >
                                <img src={item.image} className='cpos-popOption-image' alt="Edit" />
                                <p>{item.title}</p>
                            </List.Item>
                    }

                </List></div>)
        })
    }
    return (
        <Popover
            content={getOptions()}
            open={open}
            onOpenChange={(v) => v ? setOpen(recordId) : setOpen(undefined)}
            trigger={"click"}
            placement='bottom'
        >
            <div className='cpos-imageBack' onClick={(e) => {
                e.preventDefault(); // Prevent default action
            }}>
                <img style={{ height: imgHeight ? imgHeight : 16, width: imgWidth ? imgWidth : 16 }} src={imgSrc ? imgSrc : '/images/Menu Vertical.png'} alt="Options" />
            </div>
        </Popover>
    )
}
export default CustomPopoverOptions;