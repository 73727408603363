'use client';
import { Row } from "antd";
import dayjs from "../../../libs/day";
import React, { useEffect, useState } from "react";
import formatMsg from "../../../util/formatMsg";
import CalendarListDay from "./calendarListDay";
import "./style.css";
import { setItem } from "../../../libs/encryptedStorage";

const CalendarListView = (props) => {
  const [appointmentMap, setAppointmentMap] = useState<any>(new Map());
  const [dateRange, setDateRange] = useState([dayjs.tz(), dayjs.tz()]);

  useEffect(() => {
    if (props.viewMode) {
      if (props.viewMode === "dayGridMonth") {
        const startOfMonth = dayjs.tz(props.weekValue).startOf("month");
        const endOfMonth = dayjs.tz(props.weekValue).endOf("month");
        setDateRange([startOfMonth, endOfMonth]);
      } else if (props.viewMode === "timeGridWeek" && props.weekValue) {
        const startOfWeek = dayjs.tz(props.weekValue).startOf("week");
        const endOfWeek = dayjs.tz(props.weekValue).endOf("week");
        setDateRange([startOfWeek, endOfWeek]);
      } else if (props.viewMode === "timeGridDay" && props.weekValue) {
        const startOfDay = dayjs.tz(props.weekValue).startOf("day");
        const endOfDay = dayjs.tz(props.weekValue).endOf("day");
        setDateRange([startOfDay, endOfDay]);
      }
    }
  }, [props.viewMode, props.weekValue]);

  useEffect(() => {
    const { appointments } = props;
    if (appointments?.length) {
      const newMap = new Map();
      appointments.forEach((item) => {
        const date = dayjs.tz(item.date).format("D MMM YYYY");
        if (newMap.has(date)) {
          const arr = newMap.get(date);
          arr.push(item);
          newMap.set(date, arr);
        } else newMap.set(date, [item]);
      });
      setAppointmentMap(newMap);
    }
  }, [props.appointments]);

  const getDaysBetweeenDateRange = () => {
    let cards = [];
    if (dateRange && dateRange.length === 2 && props.viewMode) {
      let start = dayjs.tz(dateRange[0]);
      let end = dayjs.tz(dateRange[1]);
      while (start.isSameOrBefore(end, 'day')) {
        let key = start.format("D MMM YYYY");
        let temp = start;
        if (appointmentMap.has(key)) {
          let dayData = appointmentMap.get(key);
          cards.push(
            <CalendarListDay
              key={key}
              dayData={dayData}
              day={temp}
              onTourClick={(tour) => {
                props.setSelectedSchedule(tour)
                setItem('selectedSchedule', tour);
              }}
              packageMap={props.packageMap}
              firebase={props.firebase}
            />
          );
        }
        start = start.add(1, "day");
      }
    }
    else {
      for (let [key, val] of appointmentMap) {
        cards.push(
          <CalendarListDay
            key={key}
            dayData={val}
            day={dayjs.tz(key, "D MMM YYYY")}
            onTourClick={(tour) => {
              props.setSelectedSchedule(tour)
              setItem('selectedSchedule', tour);
            }}
            packageMap={props.packageMap}
            firebase={props.firebase}
          />
        );
      }
    }
    return cards;
  };

  return <Row>
    {props.appointments && props.appointments.length > 0 ?
      <div style={{ width: "100%" }}>{getDaysBetweeenDateRange()}</div> :
      <div style={{ textAlign: "center", padding: "30px", border: "0.5px solid #D0D0D0", backgroundColor: "white", borderRadius: "10px", width: "100%" }}>
        <img style={styles.noCalendarImage} src={"/images/Calendar Colored.png"} />
        <p style={{ color: "#5B5B5B", fontSize: "var(--font-size-18)", fontWeight: "bold", marginTop: "20px" }}>{formatMsg("noScheduleFound")}</p>
      </div>
    }
  </Row>;
};

export default CalendarListView;

const styles: any = {
  noCalendarImage: {
    height: "54px",
    width: "54px",
    objectFit: "contain",
  }
}
