'use client';
import './style.css';
import colors from '../../libs/colorFactory';
interface propTypes {
    title?: any,
    titleFontSize?: string | number,
    subTitle?: any,
    subTitleFontSize?: string | number,
    isActive?: boolean,
    titleColor?: string,
    subTitleColor?: string,
    verticalAlignment?: boolean,
    activeBackGround?: string,
    name?: any,
    onClick?: any,
    style?: any
}

const defaultProps: propTypes = {
    titleFontSize: "var(--font-size-36)",
    subTitleFontSize: "var(--font-size-18)",
    isActive: false,
    titleColor: colors.color_darkerGrey,
    subTitleColor: colors.color_darkerGrey,
    verticalAlignment: false,
    activeBackGround: colors.color_activeBackground
};

const Sticker: React.FC<propTypes> = (props) => {
    const {
        title,
        name,
        titleFontSize,
        subTitle,
        subTitleFontSize,
        titleColor,
        subTitleColor,
        isActive,
        verticalAlignment,
        activeBackGround,
        onClick,
        style
    } = { ...defaultProps, ...props };
    return (
        <div
            onClick={() => {
                onClick(name)
            }}
            className='sticker-main-container'
            style={{
                flexDirection: verticalAlignment ? "column" : "row",
                background: isActive ? activeBackGround : null,
                ...style
            }}
        >
            <div
                style={{
                    fontSize: titleFontSize,
                    color: titleColor
                }}
            >
                {title}
            </div>
            <div
                className='subTitle-container'
                style={{
                    fontSize: subTitleFontSize,
                    color: subTitleColor,
                    fontWeight: "lighter"
                }}
            >
                {subTitle}
            </div>
        </div>
    )
}
export default Sticker;