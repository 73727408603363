'use client'

import React from 'react'
import styles from './style.module.css'


const MobileFilterButton = (props: any) => {

    const {
        setShowFiltersBottomSheet,
        appliedFiltersCount
    } = props;


    function makeFiltersBottomSheetVisible() {
        setShowFiltersBottomSheet(true);
    }

    return (
        <div
            className={styles.btnContainer}
            onClick={makeFiltersBottomSheetVisible}
        >
            <div className={styles.imgContainer}>
                <img
                    src='/images/mobileFilter.png'
                    className={styles.mobFilterImg}
                />
            </div>
            <div className={styles.verticalDivider} />
            <div className={styles.appliedFilterCount}>
                {appliedFiltersCount}
            </div>
        </div>
    )
}

export default MobileFilterButton