'use client'

import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import formatMsg from '../../util/formatMsg';
import { Checkbox, Radio } from 'antd';
import PrefixIconButton from '../buttons/prefixIconButton';
import colors from '../../libs/colorFactory';
import Helper from '../../util/helper';


// In classnames fbs stands for 'filter-bottom-sheet' 

/* 
  structure of "filterNameToFilterMetaDataObjMap" = map('filterName', filterMetaDataObj) = [
    ['filterName'],  
    [
      optionsArr: [], 
      selectedVal: selectedFilterVal, 
      setSelectedVal: setSelectedFilterVal 
    ]
  ] 
*/


const FiltersBottomSheet = (props) => {

    const {
        filterNameToFilterMetaDataObjMap,
        showFiltersBottomSheet,
        setShowFiltersBottomSheet,
        setAppliedFiltersCount
    } = props;

    const overlayContainerRef = useRef(null);
    const bottomSheetRef = useRef(null);

    const handleFBSCloseOnOutsideClick = (e) => {
        if (
            overlayContainerRef.current &&
            overlayContainerRef.current.contains(e.target) &&
            bottomSheetRef.current &&
            !bottomSheetRef.current.contains(e.target)
        ) {
            closeBottomSheet();
        }
    }

    const [selectedFilterName, setSelectedFilterName] = useState(filterNameToFilterMetaDataObjMap?.size > 0 ? [...filterNameToFilterMetaDataObjMap][0][0] : null);

    useEffect(() => {

        let appliedFiltersCount = 0;

        filterNameToFilterMetaDataObjMap && [...filterNameToFilterMetaDataObjMap].map(([filterName, filterMetaDataObj]) => {
            if (isFilterType('singleSelect', filterMetaDataObj) && filterMetaDataObj.selectedVal) {
                appliedFiltersCount++;
            }
            else if (isFilterType('multiSelect', filterMetaDataObj) && filterMetaDataObj.selectedVal?.length) {
                appliedFiltersCount++;
            }
        });

        setAppliedFiltersCount(appliedFiltersCount);

    }, [filterNameToFilterMetaDataObjMap]);


    function selectFilterName(filterName) {
        if (filterName !== selectedFilterName) {
            setSelectedFilterName(filterName); 
        }
    }

    function closeBottomSheet() {
        setShowFiltersBottomSheet(false);
    }

    const selectedFilterMetaDataObj = filterNameToFilterMetaDataObjMap.get(selectedFilterName);

    const handleRadioDeselection = (key: string) => {
        if (key === selectedFilterMetaDataObj.selectedVal) {
            selectedFilterMetaDataObj.setSelectedVal(undefined);
        }
    }

    function getAllOptionValue() {
        switch (selectedFilterName) {
            case 'filterLabel.packages':
                return "all packages";
            case 'filterLabel.centers':
                return "all centers";
            case 'filterLabel.instructors':
                return "allInstructors";
            case 'filterLabel.participants':
                return "all participants";
            case 'filterLabel.accounts':
                return "all accounts";
            case 'filterLabel.ageRange':
                return "all";
            case 'filterLabel.status':
                return "all status";
        }
    }

    function getAllOptionLabel() {
        switch (selectedFilterName) {
            case 'filterLabel.packages':
                return formatMsg("caps.allPackages");
            case 'filterLabel.centers':
                return formatMsg("allCenters");
            case 'filterLabel.instructors':
                return formatMsg("caps.allInstructors");
            case 'filterLabel.participants':
                return formatMsg("allParticipants");
            case 'filterLabel.accounts':
                return formatMsg('caps.allAccounts');
            case 'filterLabel.ageRange':
                return formatMsg("All");
            case 'filterLabel.status':
                return formatMsg("caps.AllStatus");
        }
    }

    function getOptionValue(option) {
        switch (selectedFilterName) {
            case 'filterLabel.packages':
            case 'filterLabel.centers':
            case 'filterLabel.instructors':
            case 'filterLabel.participants':
            case 'filterLabel.ageRange':
            case 'filterLabel.accounts':
                return option.id;

            case 'filterLabel.status':
                return option;
        }
    }

    function getOptionLabel(option) {
        switch (selectedFilterName) {
            case 'filterLabel.packages':
            case 'filterLabel.centers':
            case 'filterLabel.instructors':
            case 'filterLabel.participants':
            case 'filterLabel.accounts': 
                return option.name;

            case 'filterLabel.ageRange':
                return option.value ? formatMsg(option.value) : Helper.getAgeRangeFormat(option);
            case 'filterLabel.status':
                return formatMsg(option);
        }
    }

    function clearAllFilters() {
        [...filterNameToFilterMetaDataObjMap]?.map(([filterName, filterMetaDataObj]) => {
            filterMetaDataObj.setSelectedVal(undefined);
        })
        closeBottomSheet();
    }

    function isFilterType(
        toBeFilterType: string,
        filterMetaDataObj: Record<string, any>
    ) {
        return (filterMetaDataObj.filterType === toBeFilterType);
    }

    return (
        <div
            className={`${showFiltersBottomSheet ? 'fbs-visible' : 'fbs-invisible'} fbs-overlay`}
            ref={overlayContainerRef}
            onClick={handleFBSCloseOnOutsideClick}
        >
            <div
                className='fbs'
                ref={bottomSheetRef}
            >
                <div
                    className='fbs-heading-container'
                >
                    <div className='fbs-heading'>
                        {formatMsg('filters')}
                    </div>
                    <div
                        className='close-fbs-icon-container'
                        onClick={closeBottomSheet}
                    >
                        <img
                            className='close-fbs-icon'
                            src='/images/closeGray.png'
                        />
                    </div>
                </div>

                <div
                    className='filters-body-container'
                >
                    <div className='filter-lists'>
                        {
                            filterNameToFilterMetaDataObjMap &&
                            [...filterNameToFilterMetaDataObjMap]?.map(([filterName, filterMetaDataObj]) => {
                                return (
                                    <div
                                        className={`filter-name ${(selectedFilterName === filterName) ? 'filter-name-selected' : ''}`}
                                        onClick={() => selectFilterName(filterName)}
                                    >
                                        <div>
                                            {formatMsg(filterName)}
                                        </div>
                                        <div className='fbs-filter-count'>
                                            {
                                                isFilterType('singleSelect', filterMetaDataObj) &&
                                                    filterMetaDataObj.selectedVal ? "1" : ""
                                            }
                                            {
                                                isFilterType('multiSelect', filterMetaDataObj) &&
                                                    filterMetaDataObj.selectedVal?.length ? filterMetaDataObj.selectedVal.length : ""
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        Boolean(selectedFilterName && selectedFilterMetaDataObj && filterNameToFilterMetaDataObjMap) &&
                        <div
                            className='selected-filter-name-options'
                        >
                            {
                                isFilterType('singleSelect', selectedFilterMetaDataObj) &&
                                <Radio.Group
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value) selectedFilterMetaDataObj.setSelectedVal(value);
                                    }}
                                    value={selectedFilterMetaDataObj.selectedVal}
                                    className='custom-fbs-radio-group'
                                >
                                    {
                                        selectedFilterMetaDataObj.showAllOption &&
                                        <Radio
                                            value={getAllOptionValue()}
                                            className='custom-fbs-radio'
                                            onClick={() => handleRadioDeselection(getAllOptionValue())}
                                        >
                                            {getAllOptionLabel()}
                                        </Radio>
                                    }
                                    {
                                        selectedFilterMetaDataObj &&
                                        selectedFilterMetaDataObj.optionsArr?.map((option) => {
                                            return (
                                                <Radio
                                                    className='custom-fbs-radio'
                                                    value={getOptionValue(option)}
                                                    onClick={() => handleRadioDeselection(getOptionValue(option))}
                                                >
                                                    {getOptionLabel(option)}
                                                </Radio>
                                            )
                                        })
                                    }
                                </Radio.Group>
                            }
                            {
                                isFilterType('multiSelect', selectedFilterMetaDataObj) &&
                                <Checkbox.Group
                                    value={selectedFilterMetaDataObj.selectedVal}
                                    onChange={(value) => {
                                        if (value) selectedFilterMetaDataObj.setSelectedVal(value);
                                    }}
                                >
                                    {
                                        selectedFilterMetaDataObj.showAllOption &&
                                        <Checkbox
                                            value={getAllOptionValue()}
                                            className='custom-fbs-checkbox'
                                        >
                                            {getAllOptionLabel()}
                                        </Checkbox>
                                    }
                                    {
                                        selectedFilterMetaDataObj &&
                                        selectedFilterMetaDataObj.optionsArr?.map((option) => {
                                            return (
                                                <Checkbox
                                                    className='custom-fbs-checkbox'
                                                    value={getOptionValue(option)}
                                                >
                                                    {getOptionLabel(option)}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            }
                        </div>
                    }
                </div>

                <div
                    className='fbs-footer-container'
                >
                    <PrefixIconButton
                        title={formatMsg("clearAll")}
                        border={true}
                        borderColor={colors.color_purple}
                        fontColor={colors.color_purple}
                        padding='5px 20px'
                        borderRadius='8px'
                        onClick={clearAllFilters}
                    />
                </div>
            </div>
        </div>
    )
}

export default FiltersBottomSheet; 