"use client"
import React, { useState, useEffect } from "react";
import { Popover, Skeleton, Segmented, List } from "antd";
import { useDispatch } from "react-redux";
import colors from "../../../libs/colorFactory";
import formatMsg from "../../../util/formatMsg";
import Helper from "../../../util/helper";
import ImageWrapper from "../../image/imageWrapper";
import dayjs from "dayjs";
import Link from "next/link";
import "./style.css";
import { notificationActions } from "sharedresources";
const { getLastUpdateNotification, getNotifications, markReadAllNotifications, markReadNotification, sagaSuccess } = notificationActions
const TopbarNotification = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [allNotifications, setAllNotifications] = useState([]);
  const { firebase, notificationReducer } = Helper.getSelectorState(["notificationReducer"])

  useEffect(() => {
    dispatch(getLastUpdateNotification({ firebase }))
  }, [])


  useEffect(() => {
    return () => {
      notificationReducer.updateNotificationChan?.close()
      dispatch(sagaSuccess({ notifications: [] }))
    }
  }, [notificationReducer.updateNotificationChan])

  useEffect(() => {
    if (notificationReducer.lastUpdateNotification) {
      dispatch(getNotifications({ firebase }))
    }
  }, [notificationReducer.lastUpdateNotification])

  useEffect(() => {
    let tempUnread = 0;
    setAllNotifications(notificationReducer.notifications)
    notificationReducer.notifications.forEach(noti => {
      if (!noti.read) tempUnread++;
    })
    setUnreadCount(tempUnread)
  }, [notificationReducer.notifications])

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const onUnreadChange = (e) => {
    if (e === "unread") {
      const alerts = notificationReducer.notifications.filter((a) => !a.read);
      setAllNotifications(alerts);
    } else {
      setAllNotifications(notificationReducer.notifications);
    }
  };

  const getParentRedirectURL = (activityName, nodeId) => {
    let redirectURL = "";
    switch (activityName) {
      case "WAITLIST":
      case "BOOKING":
        redirectURL = "/dashboard/bookingDetailView?id=" + nodeId
        break;
      case "PAYMENT":
      case "INVOICE":
        redirectURL = "/dashboard/payments"
        break;
      case "EXPLORE":
        redirectURL = "/dashboard/packageDetailView?id=" + nodeId
        break;
      case "PROFILE":
        redirectURL = "/dashboard/accountDetailView"
        break;
      case "CLASSES":
        redirectURL = "/dashboard/calendar"
        break;
    }
    return redirectURL
  }

  const getTeacherRedirectURL = (activityName, nodeId) => {
    let redirectURL = "";
    switch (activityName) {
      case "BOOKING":
        redirectURL = "/dashboard/booking"
        break;
      case "PAYMENT":
      case "INVOICE":
        redirectURL = "/dashboard/invoices/invoiceDetail?id=" + nodeId
        break;
    }
    return redirectURL
  }

  const content = (
    <div className="topbarNotification">
      {!notificationReducer.isLoading ? <>
        <div className="isoDropdownHeader global-card-header">
          {formatMsg("notifications")}
        </div>
        <div className="isoDropdownBody" style={{ marginBottom: 1 }}>
          <div className="isoDropdownListItem" style={styles.dropdownHeader}>
            <Segmented
              style={{ fontSize: "var(--font-size-14)" }}
              onChange={e => onUnreadChange(e)}
              options={[
                { label: formatMsg("all"), value: 'all' },
                { label: formatMsg("unread") + " (" + (unreadCount > 99 ? "99+" : unreadCount) + ")", value: 'unread' },
              ]}
            />
            {unreadCount ?
              <div
                style={styles.markAllRead}
                onClick={() => dispatch(markReadAllNotifications({ firebase }))}
              >
                {formatMsg("markAllRead")}
              </div>
              : <></>
            }
          </div>
          {allNotifications.length > 0 ?
            <List
              dataSource={allNotifications}
              renderItem={(item) =>
                <List.Item onClick={() => {
                  setVisible(false)
                  if (!item.read) dispatch(markReadNotification({ firebase, referenceId: item.id, notifications: notificationReducer.notifications }))
                }}>
                  <Link prefetch={true} style={{ width: "100%" }} href={Helper.isAccount(firebase) ? getParentRedirectURL(item.activityName, item.nodeId) : getTeacherRedirectURL(item.activityName, item.nodeId)}>
                    <div className="notification-main">
                      <div className="notification-subMain">
                        <img src="/images/confirm-notification.png" style={{ height: 32, width: 32 }} />
                        <div>
                          <p className="notification-body">{item.body}</p>
                          <p className="notification-date">{dayjs.tz(-item.inverseTime).format(Helper.dateFormat(firebase))}</p>
                        </div>
                      </div>
                      {!item.read && <img src="/images/green-dot.png" style={{ height: 6, width: 6 }} />}
                    </div>
                  </Link>
                </List.Item>
              }
            />
            : (
              <div style={styles.noNotification}>
                <img src="/images/no-notification.png" style={{ height: 40, width: 40 }} />
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "var(--font-size-16)", fontWeight: 600 }}>{formatMsg("noNotificationFound")}</p>
                  <p style={{ fontSize: "var(--font-size-14)", fontWeight: 400, color: colors.color_mediumGrey, marginTop: 8 }}>{formatMsg("description.noNotificationFound")}</p>
                </div>
              </div>
            )}
        </div>
      </> :
        <div className="notification-loading">
          {Array.from({ length: 3 }).map(e =>
            <Skeleton loading={true} active avatar={{ size: "small", style: { marginTop: 10 } }} paragraph={{ rows: 1 }}></Skeleton>
          )}
        </div>
      }
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement={typeof window !== "undefined" && window.innerWidth < 600 ? "bottom" : "bottomLeft"}
      rootClassName="topbar-notification-popover"
    >
      <div className="isoIconWrapper">
        <div style={styles.iconDiv}>
          {Helper.isAccount(firebase) ?
            <ImageWrapper
              style={{
                ...styles.iconImage,
                filter: Helper.getThemeColors(firebase?.schoolConfig?.themeColor).filter
              }}
              src="/images/bellWhite.png"
              collapsed={true}
            />
            :
            <img style={styles.iconImage} src="/images/bellIcon.png" />
          }
          {unreadCount ? (
            <p style={styles.alertCount}>
              {unreadCount > 0 && unreadCount < 100 ? unreadCount : unreadCount > 99 ? "99+" : ""}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </Popover>
  );
};

export default TopbarNotification;

const styles: any = {
  iconDiv: {
    height: 40,
    width: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  dropdownHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "8px 0",
  },
  markAllRead: {
    width: "fit-content",
    color: colors.v2_Cerulean,
    cursor: "pointer",
    fontSize: "var(--font-size-14)",
    textDecoration: "underline"
  },
  noNotification: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "100px",
    gap: 24,
    width: '100%',
    height: "100%"
  },
  iconImage: {
    height: 22,
    width: "auto",
    objectFit: "contain",
    marginTop: 2,
    cursor: "pointer",
    marginLeft: 4,
  },
  alertCount: {
    margin: 0,
    position: "absolute",
    top: 0,
    right: -1,
    backgroundColor: colors.v2_fuzzy_brown,
    borderRadius: "50%",
    color: colors.color_white,
    border: "1px solid",
    borderColor: colors.v2_fuzzy_brown,
    height: 17,
    minWidth: 17,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "var(--font-size-11)",
  },
};
