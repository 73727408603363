"use client";
import { Auth0Client } from '@auth0/auth0-spa-js';
import { oktaRedirectUrl, auth0ClientConfig } from '../libs/fbConfig';
import { notification } from '../util/notification';
import formatMsg from '../util/formatMsg';
import { loginApi } from '../firebase-api/login';
function getauth0Instance(loginType: "sms" | null) {
    let authorizationParams: any = {
        redirect_uri: oktaRedirectUrl,
        audience: "illumine/auth",
    }
    const workspaceName = process.env.NEXT_PUBLIC_WORKSPACE_NAME;
    if (loginType === "sms") {
        authorizationParams.connection = "sms"
    }
    else if (workspaceName === "users") {
        authorizationParams.connection = "email"
    }
    const auth0 = new Auth0Client({ ...auth0ClientConfig, authorizationParams });

    return auth0
}

async function getAuthToken() {
    try {
        let authorizationParams: any = {
            redirect_uri: oktaRedirectUrl,
            audience: "illumine/auth",
        }
        const auth0 = new Auth0Client({ ...auth0ClientConfig, authorizationParams });
        let token = await auth0.getTokenSilently();
        return token;
    } catch (err) {
        loginApi.logout();
        console.log("Failure in login flow", err);
        notification("error", formatMsg("sessionExp"));

    }
}


export default { getauth0Instance, getAuthToken }