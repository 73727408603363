import React, { useState, useEffect } from "react";
import { Box, ButtonHolder, PageHeaderWrapper } from "../wrapperComponents/page";
import { Drawer } from "antd";
import PrefixIconButton from "../buttons/prefixIconButton";
import colors from "../../libs/colorFactory";
import styles from './style.module.css';


const CustomPdfViewer: React.FC<any> = (props) => {
  let height = typeof window !== "undefined" ? window.innerHeight : 1000;

  const showErr = (err, url) => {
    const { downloadAllowed } = props;
    return (
      <div style={{
        marginTop: height / 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <h3
          style={{
            fontWeight: "bolder",
            color: colors.color_lightGrey,
          }}
        >
          Unsupported format !!!
        </h3>

        {!downloadAllowed || downloadAllowed === true ? (
          <div style={{ marginTop: 20 }}>
            <a
              href={url}
              style={{
                color: colors.v2_Cerulean,
              }}
            >
              Click here to open document in browser
            </a>
          </div>
        ) : null}
      </div>
    );
  };

  const { downloadAllowed, invoiceUrl, shareEnabled, onClose, visible, onShare } = props;

  return (
    <Drawer
      width={640}
      title={null}
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
    >
      <Box>
        <PageHeaderWrapper>
          {!downloadAllowed || downloadAllowed === true || downloadAllowed === "true" ? (
            <ButtonHolder style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%"
            }}>
              {shareEnabled ? (
                <div>
                  <PrefixIconButton
                    title="Share"
                    onClick={() => onShare()}
                    backgroundColor={colors.color_white}
                    fontColor={colors.v2_Cerulean}
                    imgSrc={"/images/PDF.png"}
                    border={true}
                    borderColor={colors.v2_Cerulean}
                  />
                </div>
              ) : null}

              {!shareEnabled ? (
                <div className={styles.pdfViewerActions}>
                  <a href={invoiceUrl} target="_blank">
                    <PrefixIconButton
                      title="Download"
                      onClick={() => null}
                      backgroundColor={colors.color_white}
                      fontColor={colors.v2_Cerulean}
                      imgSrc={"/images/PDF.png"}
                      border={true}
                      className="prefixicon-inline-block"
                      borderColor={colors.v2_Cerulean}
                    />
                  </a>
                  <div
                    onClick={onClose}
                    className={styles.pdfDrawerCrossCont}
                  >
                    <img 
                      className={styles.pdfDrawerCross}
                      src="/images/black-cross.png" 
                    />
                  </div>
                </div>
              ) : null}
            </ButtonHolder>
          ) : null}
        </PageHeaderWrapper>

        <embed
          src={invoiceUrl + "#toolbar=0"}
          type="application/pdf"
          style={{
            minWidth: "100%",
            height: height - 145,
            border: "none",
            backgroundColor: "white",
          }}
          onError={(err) => showErr(err, invoiceUrl)}
        // onload={(e) => stopSpinner()}
        />
      </Box>
    </Drawer>
  );
};

export default CustomPdfViewer;

