'use client'

import React, { useEffect } from 'react'
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { PageHeaderWrapper } from '../../../wrapperComponents/page';
import formatMsg from '../../../../util/formatMsg';
import styles from './style.module.css'


const MobileCalendarSingleScheduleView = (props: any) => {

    const {
        selectedSchedule,
        drawerOnClose,
        isLoading,
        scheduleDetail,
        firebase
    } = props;

    useEffect(() => {
        return () => {
            drawerOnClose();
        }
    }, []);

    return (
        <Spin spinning={isLoading}>
            <PageHeaderWrapper>
                <div className="global-page-header">
                    {formatMsg('sessionDetails')}
                </div>
            </PageHeaderWrapper>

            <div className={styles.viewAlign}>

                <div className={styles.packageDetails}>
                    <div
                        className={styles.packageCircle} 
                        style={{ backgroundColor: selectedSchedule?.color?.darkColor || '' }}
                    />
                    <div className={styles.packageName}>
                        {selectedSchedule?.packageName}
                    </div>
                </div>

                <div className={styles.basicDetails}>
                    <div className={styles.displayFlex}>
                        <img 
                            src='/images/schedule.png'  
                            className={styles.scheduleImg}
                        />
                        <p 
                            className={styles.drawerScheduleDetail}
                        >
                            {dayjs.tz(selectedSchedule?.date).format('dddd, DD MMMM YYYY')}
                        </p>
                    </div>
                    <div className={styles.displayFlex}>
                        <img 
                            src='/images/clock.png' 
                            className={styles.clockImg}
                        />
                        <p 
                            className={styles.drawerScheduleDetail}
                        >
                            {dayjs.tz(selectedSchedule?.startTime).format('hh:mma') + " - " + dayjs.tz(selectedSchedule?.endTime).format('hh:mma')}
                        </p>
                    </div>
                    <div className={styles.displayFlex}>
                        <img src='/images/batchIcon.png' style={{ height: 15, width: 15, marginRight: 12 }} />
                        <p 
                            className={styles.drawerScheduleDetail}
                        >
                            {selectedSchedule?.batchName}
                        </p>
                    </div>
                    {
                        (selectedSchedule?.instructors?.length > 0) &&
                        <div className={styles.instructorsContainer}>
                            <img src='/images/board.png' style={{ height: 15, width: 16, marginRight: 12 }} />
                            <p 
                                className={styles.drawerScheduleDetail}
                            >
                                {selectedSchedule?.instructors?.map(s => s.name).join(", ")}
                            </p>
                        </div>
                    }
                </div>

                <div className={styles.horizontalDivider} />

                <div className={styles.bookedForUsersContainer}>
                    {scheduleDetail?.students?.filter(s => s.status.toLowerCase() === "active").map(student =>
                        <div className={styles.bookedForUserWrapper}>
                            <div className={styles.bookedForUserContainer}>
                                <div className={styles.flexImgContainer}>
                                    <img
                                        src={student.profileImageUrl || "/images/NoPhoto.png"} 
                                        className={styles.studentProfileImg}
                                    />
                                </div>
                                <div>
                                    <div className='global-body-text'>{student.name}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.horizontalDivider} />

                <div className={styles.scheduleDataContainer}>
                    <div className={styles.generalFlexRow}>
                        <div className={styles.general1stCol}>
                            <div className={styles.flexImgContainer}>
                                <img src='/images/confirm.png' style={{ height: 16, width: 16 }} />
                            </div>
                            <div>
                                {formatMsg("small.totalBookings")}:
                            </div>
                        </div>
                        <div>
                            {(scheduleDetail?.totalBookings?.length || 0) + " " + "seats"}
                        </div>
                    </div>
                    <div className={styles.generalFlexRow}>
                        <div className={styles.general1stCol}>
                            <div className={styles.flexImgContainer}>
                                <img src='/images/waitlisted.png' style={{ height: 15, width: 15 }} />
                            </div>
                            <div>
                                {formatMsg("waitlists")}:
                            </div>
                        </div>
                        <div>
                            {scheduleDetail?.waitlists?.length || "-"}
                        </div>
                    </div>
                    <div className={styles.generalFlexRow}>
                        <div className={styles.general1stCol}>
                            <div className={styles.flexImgContainer}>
                                <img src='/images/miniAccount.png' style={{ height: 16, width: 14 }} />
                            </div>
                            <div>
                                {formatMsg("accountHolder")}:
                            </div>
                        </div>
                        <div>
                            {firebase && firebase?.user?.name || "-"}
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default MobileCalendarSingleScheduleView