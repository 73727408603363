import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'accountLogin',
  initialState: {
    isLoading: false,
    loggedIn: false,
    loggedInData: null,
    centerId: undefined,
    operationType: undefined,
    attachmentProgress: undefined,
    allFilesUploaded: false,
    customFields: undefined,
    userFields: undefined,
    accountFields: undefined,
  },
  reducers: {
    loginAsGuest: (state, action) => {
      state.isLoading = true
    },
    createStudentProfile: (state, action) => {
      state.isLoading = true
    },
    logout: (state, action) => {
      state.isLoading = true
    },
    sliceOperation: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false
    },
    addSibling: (state, action) => {
      state.isLoading = true
    },
    resetOperationType: (state, action) => {
      state.operationType = undefined
    },
    attachmentUploadProgress: (state, action: any) => {
      state.allFilesUploaded = action.allFilesUploaded,
        state.attachmentProgress = state.attachmentProgress && action.moreThanOne ? state.attachmentProgress + action.attachmentProgress : action.attachmentProgress
    },
    createParent: (state, action) => {
      state.isLoading = true
    },
    getCustomFields: (state, action) => {
      state.isLoading = true
    },
    setSchoolConfig: (state, action) => {
      state.loggedInData = { ...state.loggedInData, schoolConfig: { ...(state.loggedInData?.schoolConfig || {}), ...action.payload.schoolConfig } }
    }
  }
})

export const { loginAsGuest, sliceOperation, resetOperationType, sagaFailed, createStudentProfile, logout, addSibling, attachmentUploadProgress, createParent, getCustomFields } = loginSlice.actions

export default loginSlice.reducer