'use client';
import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Upload, Select, Radio, DatePicker, Checkbox, TimePicker, Switch, Button, Tooltip, InputNumber, Modal, Divider } from "antd";
import { QuestionCircleOutlined, UploadOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import formatMsg from "../../util/formatMsg";
import PhoneInput from "react-phone-input-2";
import colors from "../../libs/colorFactory";
import "./style.css";
import 'react-phone-input-2/lib/style.css'
import Helper from "../../util/helper";
import {
    GetCountries,
    GetState
} from "react-country-state-city";
import { notification } from "sharedresources";
import { useDispatch } from 'react-redux';
import { packageActions, dayjs } from 'sharedresources';
import Cropper, { ReactCropperElement } from "react-cropper";
import { UploadRef } from "antd/es/upload/Upload";
import "react-quill/dist/quill.snow.css";
import dynamic from "next/dynamic";
import { StyledFormItem } from "./styledFormItem";
const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });

const stripHtmlTags = (html: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
};

const FormWrapper = ({
    formFields, values, setValues,
    countrySelectorVisible, stateSelectorVisible,
    form, setPreviewObj, firebase,
    editableRecords, setEditableRecords
}: FormWrapperProps) => {

    const { enhanceWithAi, sagaSuccess } = packageActions;
    const dispatch = useDispatch();
    const { packageReducer } = Helper.getSelectorState(["packageReducer"])
    /*
        {
            modal?.open && <AddEditCenterModal
                modal={modal}
                setModal={setModal}
                firebase={firebase}
                multicenterReducer={multicenterReducer}
            />
        }
    */

    const [countryName, setCountryName] = useState();
    const [stateName, setStateName] = useState();

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const countryNameToIndexMap = useRef(new Map());
    const stateNameToIndexMap = useRef(new Map());

    const [selectedRadioVal, setSelectedRadioValue] = useState(null);
    const [aIFieldName, setAIFieldName] = useState();
    const [fileList, setFileList] = useState<any[]>([]);
    const [isCropModalVisible, setIsCropModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const cropperRef = useRef<ReactCropperElement>(null);
    const uploadRef = useRef<UploadRef>(null);
    const [currentFile, setCurrentFile] = useState<any>(null);
    const [htmlText, setHtmlText] = useState("");
    const [charCount, setCharCount] = useState(0);

    // to stop scroll number change issue 
    useEffect(() => {
        const disableScroll = () => {
            const el = document?.activeElement;

            if (!(el instanceof HTMLInputElement) || el?.type !== 'number') return;

            el.blur();
            setTimeout(() => el.focus({ preventScroll: true }), 0);
        };

        document.addEventListener('wheel', disableScroll);
        return () => document.removeEventListener('wheel', disableScroll);
    }, []);

    const handleRadioChange = (fieldName: string, newSelectionVal: string, onRadioDeselect?: Function) => {

        if (selectedRadioVal === newSelectionVal) {
            setSelectedRadioValue(null);
            form.setFieldsValue({ [fieldName]: null });
            if (onRadioDeselect) onRadioDeselect();
            if (setPreviewObj) {
                setPreviewObj({
                    ...form.getFieldsValue(),
                    [fieldName]: null
                });
            }
        }
        else {
            setSelectedRadioValue(newSelectionVal);
            form.setFieldsValue({ [fieldName]: newSelectionVal });
        }
    };

    useEffect(() => {
        if (countrySelectorVisible) {
            GetCountries().then((countriesList) => {

                if (countriesList) {
                    countriesList.forEach((countryObj) => {
                        countryNameToIndexMap.current.set(countryObj.name, countryObj.id);
                    });
                }

                setCountryList(countriesList ? countriesList : []);
                const currentCountryNameInEditableRecord = form.getFieldValue('country');

                if (currentCountryNameInEditableRecord) {

                    const countryIndex = countryNameToIndexMap?.current?.get(currentCountryNameInEditableRecord);

                    GetState(countryIndex).then((statesList) => {
                        if (statesList) {
                            statesList.forEach((stateObj) => {
                                stateNameToIndexMap?.current?.set(stateObj.name, stateObj.id);
                            });
                        }
                        setStateList(statesList ? statesList : []);
                    });
                }
            });
        }

        return () => {
            setStateList([]);
        }
    }, []);

    useEffect(() => {
        if (aIFieldName && packageReducer.enchancedAIText != undefined) {
            form.setFieldsValue({ [aIFieldName]: packageReducer.enchancedAIText });
        }
        setAIFieldName(undefined)
        dispatch(sagaSuccess({ enchancedAIText: undefined }))
    }, [packageReducer.enchancedAIText])

    const handleEnhance = (name) => {
        const text = form.getFieldValue(name);
        if (!text) {
            notification("error", "Please enter text before enhancing!");
            return;
        }
        setAIFieldName(name);
        dispatch(enhanceWithAi({ firebase: firebase, text }));
    };

    const onCountryChange = (index) => {

        const country = countryList[index];
        setCountryName(country?.name);
        form.setFieldValue('country', country?.name);

        if (stateSelectorVisible) {

            setStateName(undefined);
            form.resetFields(['state']);
            setStateList([]);

            if (country) {
                GetState(country.id).then((statesList) => {
                    if (statesList) {
                        statesList.forEach((stateObj) => {
                            stateNameToIndexMap.current.set(stateObj.name, stateObj.id);
                        });
                    }
                    setStateList(statesList ? statesList : []);
                });
            }
        }
    }

    const onStateChange = (index) => {
        const state = stateList[index];
        setStateName(state?.name);
        form.setFieldValue('state', state?.name);
    }

    const { Dragger } = Upload;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;

    const handleValueChange = (fieldName, value) => {
        if (values && setValues) {
            let obj: any = { ...values };
            obj[fieldName] = value;
            setValues(obj);
        }
    }

    const getMediaProfiles = (field) => {
        if (editableRecords) {
            if (!field?.multiple) {
                return editableRecords[field.name] && <div className="form-imgContainer">
                    <img src={editableRecords[field.name]} className="form-imgStyle"></img>
                    <img src="/images/delete.png" className="iconStyle" onClick={() => handleImageChange(field)}></img>
                </div>
            }
            return editableRecords && editableRecords[field.name]?.map((item, index) => {
                return (
                    <div className="form-imgContainer">
                        <img src={item.path} className="form-imgStyle"></img>
                        <img src="/images/delete.png" className="iconStyle" onClick={() => handleImageChange(field, index)}></img>
                    </div>
                )
            })
        }
    }

    const handleImageChange = (field, index?) => {
        let newObj = { ...editableRecords };
        if (!field.multiple) {
            newObj[field.name] = undefined;
        } else {
            newObj[field.name] = newObj[field.name].filter((item, i) => i !== index);
        }
        setEditableRecords(newObj);
        form.setFieldsValue({ ...newObj });
    }

    const handleSelectAll = (value, options) => {
        if (value && value.length && value.includes("all")) {
            if (value.length === options.length + 1) {
                return [];
            }
            let optionsId = options?.map((item) => item.id).filter((item) => item !== "all");
            return [...optionsId];
        }
        return value;
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.stopPropagation(); // Prevent event from propagating to other elements
            e.preventDefault();  // Optional: Prevent default behavior if needed
        }
    };


    const renderFormFields = () => {
        return formFields?.map((fieldCollection: FormFieldsType) => {
            let span = 24 / fieldCollection.length;
            let fields = [];
            fieldCollection?.map((field: FormFields) => {
                if (field.header) {
                    fields.push(
                        <Col span={24} className="subheader">{field.header}</Col>
                    )
                }
                if (span === 24) {
                    fields.push(
                        <Col lg={span} md={24} sm={24} xs={24}>
                            {getFields(field)}
                        </Col>
                    )
                } else {
                    fields.push(
                        <Col lg={Math.round(span - 1)} md={24} sm={24} xs={24}>
                            {getFields(field)}
                        </Col>
                    )
                }
            });
            return <Row typeof="flex" justify="space-between" align="bottom" className="form-wrapper-main-row">{fields}</Row>
        });
    }

    const dataURLtoFile = (dataUrl: string, filename: string): File => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const getCroppedMediaField = (field) => {
        if (editableRecords && editableRecords[field.name]) {
            return getMediaProfiles(field);
        } else {
            return <>
                <Form.Item
                    label={
                        <div className="global-display-flex-space-between-important" style={{ width: 336, marginBottom: 5 }}>
                            <div>{field.label}</div>
                            {fileList.map((file) => (
                                <div
                                    style={{ color: "var(--primary-color)", cursor: "pointer" }}
                                    onClick={() => {
                                        setFileList([]);
                                        form.setFieldValue(field.name, []);
                                        uploadRef.current.upload
                                    }}
                                >
                                    <EditOutlined style={{ marginRight: 6 }} />
                                    {formatMsg("editPhoto")}
                                </div>
                            ))}
                        </div>
                    }
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    className="upload-list-inline"
                    getValueFromEvent={(e: any) => e.fileList}
                    valuePropName="fileList"
                >
                    <Upload
                        maxCount={1}
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={handleBeforeUpload}
                        className="cpkg-upload"
                        showUploadList={false}
                        ref={uploadRef}
                    >
                        {fileList.length >= 1 ? null : (
                            <div className="upload-style">
                                <div className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </div>
                                <div className="upload-text">Click to attach photo</div>
                            </div>
                        )}
                    </Upload>

                    {/* Custom Preview */}
                    {fileList.map((file) => (
                        <div key={file.uid} className="custom-preview">
                            <img
                                style={{ borderRadius: 8, height: 189, width: 336 }}
                                src={file.url}
                                alt={file.name}
                                className="custom-preview-image"
                            />

                        </div>
                    ))}
                </Form.Item>
                <Modal
                    open={isCropModalVisible}
                    maskClosable={false}
                    onCancel={() => {
                        setFileList([]);
                        form.setFieldValue(field.name, []);
                        setIsCropModalVisible(false)
                    }}
                    footer={[
                        <Button key="cancel" onClick={() => {
                            setFileList([]);
                            form.setFieldValue(field.name, []);
                            setIsCropModalVisible(false)
                        }}>
                            Cancel
                        </Button>,
                        <Button key="crop" type="primary" onClick={() => handleCrop(field.name)}>
                            Crop
                        </Button>,
                    ]}
                >
                    {selectedImage && (
                        <Cropper
                            src={selectedImage}
                            style={{ height: 400, width: "100%" }}
                            aspectRatio={16 / 9}
                            guides={false}
                            ref={cropperRef}
                            viewMode={1}
                            background={false}
                            autoCropArea={1}
                            responsive={true}
                        />
                    )}
                </Modal>
            </>
        }
    }

    const handleBeforeUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result as string);
            setCurrentFile(file);
            setIsCropModalVisible(true);
        };
        reader.readAsDataURL(file);
        return false;
    };

    const handleCrop = (name) => {
        if (cropperRef.current) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            const croppedImage = croppedCanvas.toDataURL("image/png");

            const croppedFile = dataURLtoFile(croppedImage, currentFile.name);

            const newFileList = [
                ...fileList,
                {
                    uid: currentFile.uid,
                    name: currentFile.name,
                    status: "done",
                    url: croppedImage, // Thumbnail preview
                    originFileObj: croppedFile, // Attach cropped file for upload
                },
            ];

            setFileList(newFileList);
            form.setFieldValue(name, newFileList);
            setSelectedImage(null);
            setIsCropModalVisible(false);
        }
    };

    const getMediaField = (field) => {

        const fieldLabel = field.label;

        if (field.multiple) {
            return <>
                <Form.Item
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    tooltip={field.toolTip ? field.toolTip.title : null}
                >
                    <Dragger
                        name="file"
                        multiple={field.multiple}
                        listType={field.inputType}
                        maxCount={!field.multiple ? 1 : null}
                        className="upload-list-inline"
                    >
                        <div className='upload-style'>
                            <div className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </div>
                            <div className="upload-text">Click or drag to attach photo</div>
                        </div>
                    </Dragger>
                </Form.Item>
                {getMediaProfiles(field)}
            </>
        } else {
            if (editableRecords && editableRecords[field.name]) {
                return getMediaProfiles(field);
            } else {
                return <Form.Item
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <Dragger
                        name="file"
                        multiple={field.multiple}
                        listType={field.inputType}
                        maxCount={!field.multiple ? 1 : null}
                        className="upload-list-inline"
                    >
                        <div className='upload-style'>
                            <div className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </div>
                            <div className="upload-text">Click or drag to attach photo</div>
                        </div>
                    </Dragger>
                </Form.Item>
            }

        }
    }

    const getFields = (field) => {

        let fieldLabel = field.label ? field.label : null;

        fieldLabel = (fieldLabel)
            ? (<div
                style={
                    (!field.renderCustomComponentInDropdown && field.CustomComponent) ?
                        (field.customComponentContainerStyle) ?
                            field.customComponentContainerStyle
                            :
                            styles.labelWithCustomComponent
                        :
                        {}
                }
            >
                <div>
                    {fieldLabel}
                </div>
                {
                    (!field.renderCustomComponentInDropdown && field.CustomComponent) ?
                        <div style={styles.customComponent}>
                            {
                                field.CustomComponent()
                            }
                        </div>
                        :
                        null
                }
            </div>)
            : null;

        switch (field.type?.toLowerCase()) {
            case "input":
                return <>
                    <Form.Item
                        label={fieldLabel}
                        name={field.name}
                        // rules={[{ required: field.required, message: field.warningMessage }]}
                        rules={[
                            { required: field.required, message: field.warningMessage, whitespace: field.inputType === "string" && field.required },
                            field.inputType === "email" ? { type: 'email', message: formatMsg("error.validEmail") } : {}
                        ]}
                        initialValue={field.initialValue || undefined}
                        tooltip={field.toolTip ? field.toolTip.title : null}
                        className={field.formItemCss ? field.formItemCss : ''}
                    >
                        <Input
                            prefix={field.inputPrefixIcon ? field.inputPrefixIcon : undefined}
                            maxLength={field.maxLength}
                            type={field.inputType}
                            placeholder={field.placeHolder}
                            disabled={field.disabled}
                        />
                    </Form.Item>
                </>

            case "inputnumber":
                return <StyledFormItem<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    initialValue={field.initialValue || undefined}
                    tooltip={field.toolTip ? field.toolTip.title : null}
                    className={field.formItemCss ? field.formItemCss : ''}
                >
                    <InputNumber
                        prefix={field.inputPrefixIcon ? field.inputPrefixIcon : undefined}
                        onKeyDown={handleKeyDown}
                        className={field.inputNumberStyleClass ? field.inputNumberStyleClass : field.inputPrefixIcon ? 'input-num-w-100p-with-prefix-icon' : 'input-num-w-100percent'}
                        placeholder={field.placeHolder}
                        disabled={field.disabled}
                    />
                </StyledFormItem>

            case "phoneinput":
                return <Form.Item
                    label={<div className={field.required ? "global-required" : null} style={{ display: 'flex' }}>{fieldLabel}</div>}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <div className="isoInputWrapper ">
                        <PhoneInput
                            onChange={field.onPhoneChange}
                            preferredCountries={["in", "au", "sa"]}
                            placeholder={formatMsg("page.phoneNumber")}
                            inputStyle={{
                                width: "100%",
                                borderColor: colors.color_lightGrey,
                                color: "black"
                            }}
                            disabled={field.disabled}
                            country={Helper.getCountryCode()}
                            localization={Helper.getDefaultLanguageKey()}
                            value={field.phoneValue ? field.phoneValue : ""}
                        />
                    </div>
                </Form.Item>

            case "media":
                return getMediaField(field);

            case "croppedmedia":
                return getCroppedMediaField(field);

            case "textarea":
                return <div
                    className={field.styleClass ? field.styleClass : ''}
                    style={{ paddingBottom: (field.padding || field.padding === 0) ? field.padding : 28 }}
                >
                    <Form.Item
                        label={fieldLabel}
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        className={field.formItemCss ? field.formItemCss : ''}
                    >
                        <TextArea
                            placeholder={field.placeHolder}
                            rows={field.rows || 5}
                            disabled={field.disabled}
                            showCount={field.showCount}
                            maxLength={field.maxLength}
                        />
                    </Form.Item>
                    {/* <Button className="text-area-button" onClick={() => handleEnhance(field.name)}>
                        <img src="/images/Magic.png" style={{ height: 17, width: 17, marginRight: 7 }} />Enhance with AI
                    </Button> */}
                </div>

            case "html":
                return <div style={{ paddingBottom: 20 }}>
                    <Form.Item
                        label={fieldLabel}
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        className="html-editor"
                    >
                        <ReactQuill
                            theme="snow"
                            value={form.getFieldValue(field.name)}
                            onChange={(value) => {
                                const plainText = stripHtmlTags(value);
                                // if (plainText.length <= 500) {
                                setCharCount(plainText.length);
                                form.setFieldValue(field.name, value);
                                // }
                            }}
                            placeholder={field.placeHolder}
                            readOnly={field.disabled}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                    { 'indent': '-1' }, { 'indent': '+1' }, { 'direction': 'rtl' }, 'link'], ['clean']
                                ],
                            }}
                        />
                    </Form.Item>
                    <div className="text-area-button-div">
                        <Button className="text-area-button" onClick={() => handleEnhance(field.name)}>
                            <img src="/images/Magic.png" style={{ height: 17, width: 17, marginRight: 7 }} />
                            Enhance with AI
                        </Button>
                        <span style={{ fontSize: '14px', color: charCount > 500 ? 'red' : 'inherit' }}>
                            {`${charCount} characters`}
                        </span>
                    </div>
                </div>

            case "select":
                if (field?.extraContent) {
                    return <> <Form.Item
                        label={fieldLabel}
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                        getValueFromEvent={(values) => handleSelectAll(values, field.options)}
                        tooltip={field.toolTip ? field.toolTip.title : null}
                        className={field.formItemCss ? field.formItemCss : ''}
                    >
                        <Select
                            placeholder={field.placeHolder}
                            mode={field.mode}
                            disabled={field.disabled}
                            showSearch
                            onChange={(val) => handleValueChange(field.name, val)}
                            allowClear={field.mode == "multiple" || !field.required ? true : false}
                            maxTagCount={field.showMaxCount ? field.maxCount || 0 : undefined}
                        >
                            {
                                field?.options?.map((item) => {
                                    let val = field.optionKey ? item[field.optionKey] : item;
                                    let label = field.optionLabel ? item[field.optionLabel] : item;
                                    return (
                                        <Select.Option value={val}>{label}</Select.Option>
                                    )
                                }
                                )
                            }
                        </Select>
                    </Form.Item>
                        {field?.extraContent}
                    </>
                }
                else {
                    return <StyledFormItem
                        label={
                            <div
                                style={
                                    (!field.renderCustomComponentInDropdown && field.CustomComponent) ?
                                        styles.labelWithCustomComponent
                                        :
                                        {}
                                }
                            >
                                <div>
                                    {fieldLabel}
                                </div>
                                {
                                    (!field.renderCustomComponentInDropdown && field.CustomComponent) ?
                                        <div style={styles.customComponent}>
                                            {

                                                field.CustomComponent()
                                            }
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        }
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                        getValueFromEvent={(values) => handleSelectAll(values, field.options)}
                        tooltip={field.toolTip ? field.toolTip.title : null}
                        className={field.formItemCss ? field.formItemCss : ''}
                    >
                        <Select
                            placeholder={field.placeHolder}
                            mode={field.mode}
                            disabled={field.disabled}
                            showSearch
                            onChange={(val) => handleValueChange(field.name, val)}
                            allowClear={field.mode == "multiple" || !field.required ? true : false}
                            filterOption={(input, option) =>
                                (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
                            }
                            maxTagCount={field.showMaxCount ? field.maxCount || 0 : undefined}
                            maxTagPlaceholder={field.maxTagPlaceholder || ""}
                            dropdownRender={field.renderCustomComponentInDropdown && field.CustomComponent ? menu => {
                                return <>
                                    {field.CustomComponent()}
                                    <Divider style={{ margin: '0 0 8px 0' }} />
                                    {menu}
                                </>
                            } : undefined}
                        >
                            {
                                field?.options?.map((item) => {
                                    let val = field.optionKey ? item[field.optionKey] : item;
                                    let label = field.optionLabel ? item[field.optionLabel] : item;
                                    return (
                                        <Select.Option value={val} disabled={field.optionDisabled ? field.optionDisabled(item) : false}>{field.skipFormat || !label ? label : formatMsg(label)}</Select.Option>
                                    )
                                }
                                )
                            }
                        </Select>
                    </StyledFormItem>
                }

            case "radio":
                return <Form.Item
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    tooltip={field.toolTip ? field.toolTip.title : null}
                    className={field.formItemCss ? field.formItemCss : ''}
                    initialValue={field.initialValue}
                >
                    <Radio.Group
                        disabled={field.disabled}
                        value={selectedRadioVal}
                    >
                        {
                            field?.options?.map((item) => (
                                <Radio
                                    value={item.key}
                                    onClick={() => handleRadioChange(field.name, item.key, field.onRadioDeselect)}
                                >
                                    <div style={{ textTransform: "capitalize", marginTop: field.noMarTop ? undefined : "5px", display: 'flex' }}>
                                        {item.formattedLabel || formatMsg(item.label)}
                                        {
                                            item.toolTip &&
                                            (
                                                <div
                                                    className='flex-center'
                                                >
                                                    <Tooltip
                                                        title={item.toolTip.title ? item.toolTip.title : 'No title given'}
                                                        placement={item.toolTip.placement ? item.toolTip.placement : 'top'}
                                                    >
                                                        <img
                                                            src={item.toolTip.iconSrc ? item.toolTip.iconSrc : ''}
                                                            className={item.toolTip.iconStyleClass ? item.toolTip.iconStyleClass : ''}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Radio>
                            ))
                        }
                    </Radio.Group>
                </Form.Item>

            case "date":
                return <Form.Item
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    initialValue={field.initialValue || undefined}
                    tooltip={field.toolTip ? field.toolTip.title : null}
                >
                    <DatePicker
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        disabledDate={field.disabledDate}
                        allowClear={field.allowClear ?? true}
                        placeholder={field.placeHolder ? field.placeHolder : formatMsg('selectDate')}
                    />
                </Form.Item>

            case "daterange":
                return <Form.Item
                    // initialValue={dayjs.tz()}
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <RangePicker
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        onChange={(val) => field.onChange ? field.onChange(val) : handleValueChange(field.name, val)}
                        disabledDate={field.disabledDate}
                    />
                </Form.Item>

            case "time":
                return <Form.Item
                    // initialValue={dayjs.tz()}
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <TimePicker disabled={field.disabled}
                        // format="HH:mm"
                        style={{ width: '100%' }}
                        needConfirm={false} />

                </Form.Item>

            case "timerange":
                return <Form.Item
                    // initialValue={dayjs.tz()}
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <TimePicker.RangePicker
                        needConfirm={false}
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        onChange={(val) => handleValueChange(field.name, val)}
                    />
                </Form.Item>

            case "checkbox":
                return <Form.Item
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    valuePropName="checked"
                    initialValue={field.initialValue || undefined}
                >
                    <Checkbox disabled={field.disabled}>{field.options}{
                        field.toolTip ? <Tooltip
                            placement='right'
                            title={field.toolTip.title}
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: "#00000073" }} />
                        </Tooltip> : null}
                    </Checkbox>
                </Form.Item>

            case "legend":
                return <Row style={{ marginBottom: 14, color: colors.v2_Cerulean }}>
                    {field.value}
                </Row>

            case "normallabel":
                return (<Row
                    style={{
                        marginBottom: field.marginBottom ? field.marginBottom : 7,
                        fontSize: field.labelFontSize ? field.labelFontSize : 'var(--font-size-15)',
                        cursor: 'default'
                    }}
                >
                    {fieldLabel}
                </Row>)

            case "horizontal custom styled input":
                return <>
                    <Col style={styles.horizontalCustomInput.container} >
                        <Col style={styles.horizontalCustomInput.label} >
                            {fieldLabel}
                        </Col>
                        <Col style={styles.flexCenter} >
                            {
                                field.secondaryText &&
                                <p style={styles.horizontalCustomInput.secondaryText} >
                                    {field.secondaryText}
                                </p>
                            }
                            <Form.Item
                                name={field.name}
                                rules={[{ required: field.required, message: field.warningMessage }]}
                                initialValue={field.initialValue || undefined}
                                style={styles.horizontalCustomInput.formItem}
                            >
                                <InputNumber
                                    style={field.inputStyle ? field.inputStyle : undefined}
                                    onKeyDown={handleKeyDown}
                                    maxLength={field.maxLength}
                                    placeholder={field.placeHolder}
                                    disabled={field.disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                    <div style={styles.greyDivider}></div>
                </>

            case "country selector":
                return (countrySelectorVisible) ? (
                    <Form.Item
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                    >
                        <Select
                            value={countryName}
                            onChange={onCountryChange}
                            disabled={countryList.length === 0}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (
                                option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase().trim()) >= 0
                            )}
                            placeholder={field.placeHolder}
                        >
                            {countryList.map((item, index) => (
                                <Select.Option key={index} value={item.index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>)
                    :
                    (<></>)

            case "state selector":
                return (stateSelectorVisible) ? (
                    <Form.Item
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                    >
                        <Select
                            value={stateName}
                            onChange={onStateChange}
                            disabled={stateList.length === 0}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (
                                option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase().trim()) >= 0
                            )}
                            placeholder={field.placeHolder}
                        >
                            {stateList.map((item, index) => (
                                <Select.Option key={index} value={item.index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>)
                    :
                    (<></>)

            case "switch":
                return (
                    <div style={field.switchContainerStyle ? field.switchContainerStyle : styles.switchContainer}>
                        <Form.Item
                            name={field.name}
                            rules={[{ required: field.required, message: field.warningMessage }]}
                            valuePropName="checked"
                            initialValue={field.initialValue || false}
                            className={field.formItemCss ? field.formItemCss : ''}
                        >
                            <Switch
                                size={field.size}
                            />
                        </Form.Item>
                        <p style={field.switchTextStyle ? field.switchTextStyle : styles.switchLabel}>
                            {fieldLabel} {
                                field.toolTip ? <Tooltip
                                    placement='right'
                                    title={field.toolTip.title}
                                >
                                    <QuestionCircleOutlined style={{ marginLeft: 5, color: "#00000073" }} />
                                </Tooltip> : null}
                        </p>
                    </div>
                )

            case "prefixiconbutton":
                return (
                    <div style={field.containerDivStyle ? field.containerDivStyle : {}}>
                        {field.prefixIconButton ? field.prefixIconButton : null}
                    </div>
                )
        }
    }

    return (
        <>
            {renderFormFields()}
        </>

    )
}


export default FormWrapper;


const styles = {
    horizontalCustomInput: {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            height: '40px',
            alignItems: 'center'
        },
        label: {
            padding: 0,
            margin: '0px 10px',
            color: colors.blackish_back,
            fontSize: 'var(--font-size-16)'
        },
        secondaryText: {
            padding: 0,
            margin: '0px 10px',
            color: colors.color_mediumGrey,
            fontSize: 'var(--font-size-15)',
            fontWeight: 300
        },
        formItem: {
            marginTop: '26px',
            height: '40px'
        }
    },
    greyDivider: {
        height: '0.5px',
        backgroundColor: colors.color_lightGrey,
        margin: '20px 0px'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
    },
    switchLabel: {
        padding: 0,
        margin: '0px 0px 0px 10px',
        height: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    switchContainer: {
        display: 'flex',
        marginTop: '-13px',
        marginLeft: '9px'
    },
    labelWithCustomComponent: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    customComponent: {
        marginRight: '-20px'
    }
}