import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import Helper from "../../util/helper";
import { setItem } from "../../libs/encryptedStorage";

function* getBookings(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/dashboard/bookings?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          bookings: response.body.data
        }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to fetch dashboard stats", err);
  }
}

function* getRevenue(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/dashboard/revenue?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: { isLoading: false, revenue: response.body.data }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to fetch dashboard stats", err);
  }
}

function* getStudentStaff(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/dashboard/studentStaff?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: { isLoading: false, studentStaff: response.body.data }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to fetch dashboard stats", err);
  }
}

function* getPackages(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/dashboard/packages?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: { isLoading: false, packages: response.body.data }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to fetch dashboard stats", err);
  }
}

function* getUpcomingClasses(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/dashboard/upcomingClasses?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: { isLoading: false, upcomingClasses: response.body.data }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to fetch dashboard stats", err);
  }
}
function* updateOnboardingStatus(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingoApi/dashboard/updateOnboardingStatus/${firebase.schoolConfig.schoolId}`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {

      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          ...reqObj,
        }
      };
      setItem("schoolConfig", newFirebase.schoolConfig);
      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });
      yield put({
        type: actions.DASHBOARD_SAGA_SUCCESS,
        payload: { operationType: "ONBOARDING_STATUS_UPDATED", isLoading: false }
      });
    }
    else {
      yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.DASHBOARD_SAGA_FAILED });
    console.log("failed to update onboarding status", err);
  }
}
export default function* rootSaga() {
  yield takeLatest(actions.GET_DASH_BOOKINGS, getBookings);
  yield takeLatest(actions.GET_DASH_REVENUE, getRevenue);
  yield takeLatest(actions.GET_DASH_STUDENT_STAFF, getStudentStaff);
  yield takeLatest(actions.GET_DASH_PACKAGES, getPackages);
  yield takeLatest(actions.GET_DASH_UPCOMINGCLASSES, getUpcomingClasses);
  yield takeLatest(actions.UPDATE_ONBOARDING_STATUS, updateOnboardingStatus);
}