const prodConfig = {
    apiKey: "AIzaSyAC7azjLKOSjvAOIy4S_ccDB_O8age4KMo",
    authDomain: "masterbooking-f8b6e.firebaseapp.com",
    projectId: "masterbooking-f8b6e",
    storageBucket: "masterbooking-f8b6e.appspot.com",
    messagingSenderId: "635424051725",
    appId: "1:635424051725:web:e37afd337802cabda83b44",
    measurementId: "G-46V2LM255Q"
};
const devConfig = {
    apiKey: "AIzaSyD8PWEqWcq2Z6IHRYfThZely1T5T3_PnL8",
    authDomain: "masterbookingdev.firebaseapp.com",
    projectId: "masterbookingdev",
    storageBucket: "masterbookingdev.appspot.com",
    messagingSenderId: "177926895397",
    appId: "1:177926895397:web:953d4c67c5c900c96e8ccc",
    measurementId: "G-NSEHLXRGY0"
};
const stagingConfig = {
    apiKey: "AIzaSyABN-XL3n8vRvG6WAIKoGf3AsWBtkpn1m0",
    authDomain: "masterbookingstaging.firebaseapp.com",
    databaseURL: "https://masterbookingstaging-default-rtdb.firebaseio.com",
    projectId: "masterbookingstaging",
    storageBucket: "masterbookingstaging.firebasestorage.app",
    messagingSenderId: "860142424680",
    appId: "1:860142424680:web:ca56de3635edff805461dd",
    measurementId: "G-B22Z8D020T"
};

export const localDevPointUrl = "http://127.0.0.1:5001/masterbookingdev/us-central1/";
export const localMasterEndPointUrl = "http://127.0.0.1:5001/masterbooking-f8b6e/us-central1/";
export const localStagingEndPointUrl = "http://127.0.0.1:5001/masterbookingstaging/us-central1/";
export const devEndPointUrl = "https://us-central1-masterbookingdev.cloudfunctions.net/";
export const prodEndPointUrl = "https://us-central1-masterbooking-f8b6e.cloudfunctions.net/";
export const StagingEndPointUrl = "https://us-central1-masterbookingstaging.cloudfunctions.net/";


export let endPoint;

switch (process.env.NODE_ENV) {
    case "production":
        endPoint = "production";
        if (process.env.NEXT_PUBLIC_ENDPOINT && process.env.NEXT_PUBLIC_ENDPOINT !== "production") {
            endPoint = process.env.NEXT_PUBLIC_ENDPOINT;
        }
        break;
    case "development":
        endPoint = process.env.NEXT_PUBLIC_ENDPOINT;
        break;

    case "test":
        endPoint = "test";
        break;

    default:
        endPoint = "development";
}


export const listingEndPointUrl =
    endPoint === "production" ? "https://explore.mybookingo.com/" :
        endPoint === "staging" ?
            "https://staging-listing-mybookingo.web.app/" :
            "https://dev-listing-mybookingo.web.app/";


const endPointMap: Record<string, string> = {
    production: prodEndPointUrl,
    staging: StagingEndPointUrl,
    localStaging: localStagingEndPointUrl,
    localDev: localDevPointUrl,
    localProd: localMasterEndPointUrl,
};

export const endPointUrl = endPointMap[endPoint] || devEndPointUrl;

const firebaseConfigMap: Record<string, typeof prodConfig> = {
    production: prodConfig,
    staging: stagingConfig,
    localStaging: stagingConfig,
    localProd: prodConfig,
};

export const firebaseConfig = firebaseConfigMap[endPoint] || devConfig;


const workspaceName = process.env.NEXT_PUBLIC_WORKSPACE_NAME;

const oktaRedirectMap: Record<string, string> = {
    production: workspaceName === "admin"
        ? "https://admin.mybookingo.com"
        : "https://explore.mybookingo.com",
    localDev: "http://localhost:3000",
    localProd: "http://localhost:3000",
    localStaging: "http://localhost:3000",
    staging: workspaceName === "admin"
        ? "https://masterbookingstaging.web.app"
        : "https://staging-listing-mybookingo.web.app",
    development: workspaceName === "admin"
        ? "https://dev.mybookingo.com"
        : "https://dev-listing-mybookingo.web.app",
};

export const oktaRedirectUrl = oktaRedirectMap[endPoint] || "http://localhost:3000";

export const authorizationParamsConfig: any = {
    redirect_uri: oktaRedirectUrl,
    audience: "illumine/auth",
}


export const xPlatformHeaderValue = (() => {
    switch (endPoint) {
        case "production":
        case "localProd":
            return "booking"
        case "development":
        case "localDev":
            return "booking-dev"
    }
})();


export const auth0ClientConfig: any = (() => {
    switch (endPoint) {
        case "production":
        case "localProd":
            return {
                domain: 'login.illumine.app',
                clientId: workspaceName === "admin" ?
                    'FCC4OCNY6h3iyxqMkp6W0TEgByMQ0pjJ'
                    : 'aWlvIZINmMH1JIMYlv7wMpAZslFdEW0I',
                useRefreshTokens: true,
                cacheLocation: 'localstorage',
                useRefreshTokensFallback: true
            }
        case "development":
        case "localDev":
            return {
                domain: 'dev-ohmvp3r38mq6cfsg.us.auth0.com',
                clientId:
                    workspaceName === "admin" ?
                        'OMcal91vIOUlTjieRYF9Rx6AoQ39OKT8'
                        : 'nXpmNAWZUB4z2KkjfAxJpD1UXN8FbhXw',
                useRefreshTokens: true,
                cacheLocation: 'localstorage',
                useRefreshTokensFallback: true
            }
        case "staging":
        case "localStaging":
            return {
                domain: 'dev-kh2czo44lzpni5a3.us.auth0.com',
                clientId:
                    workspaceName === "admin" ?
                        'uyDnbkiEXvXh8XnYeoeOBgiMor36oMDm'
                        : '72ASREZtUaLDFBkuinuMrCkPgD7vbmJQ',
                useRefreshTokens: true,
                cacheLocation: 'localstorage',
                useRefreshTokensFallback: true
            }
    }
})();