import { createSlice } from '@reduxjs/toolkit'
export const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        isLoading: false,
        operationType: "",
        accountList: [],
        accountDetails: undefined,
        dateRangedAccountsList: [],
        accountUsersList: [], 
        accountUserIdsMap: new Map(), 
        lastCreatedAccount: null 
    },
    reducers: {
        sagaFailed: (state, action) => {
            state.isLoading = false;
            state.operationType = ""
        },
        sagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        getAccountDetails: (state, action) => {
            state.isLoading = true;
        },
        createAccount: (state, action) => {
            state.isLoading = true;
        },
        resetOperationType: (state, action) => {
            state.operationType = ""
        },
        linkAccount: (state, action) => {
            state.isLoading = true;
        },
        deleteAccount: (state, action) => {
            state.isLoading = true;
        },
        getAccountsListByDateRange: (state, action) => {
            state.isLoading = true;
        },
        delinkAccount: (state, action) => {
            state.isLoading = true
        },
        deleteLinkedAccount: (state, action) => {
            state.isLoading = true
        },
        getUsersOfAccount: (state, action) => {
            state.isLoading = true
        },
        saveBookingReview: (state, action) => {
            state.isLoading = true
        },
        getAccountUserIdsMap: (state, action) => {
            state.isLoading = true
        },
    }
})

export const {
    getAccountsListByDateRange,
    sagaFailed,
    sagaSuccess,
    getAccountDetails,
    createAccount,
    resetOperationType,
    linkAccount,
    deleteAccount,
    delinkAccount,
    deleteLinkedAccount,
    getUsersOfAccount, 
    saveBookingReview, 
    getAccountUserIdsMap 
} = accountsSlice.actions

export default accountsSlice.reducer