"use client"

import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./style.css"
import Sticker from '../sticker/page'
import formatMsg from '../../util/formatMsg'
import { TableWrapper } from '../wrapperComponents/page'
import { Col, Input, Row, Select, Spin, Tag } from 'antd'
import Helper from '../../util/helper'
import { SearchOutlined } from '@ant-design/icons';
import { FilterDateSelector, FiltersBottomSheet, MobileFilterButton, multicenterActions, packageActions } from 'sharedresources'
import { useDispatch } from 'react-redux'
import { InvoicePopoverOptions } from 'sharedresources'
import dayjs from '../../libs/day'
import { ColumnsType } from 'antd/es/table'
import InvoiceMobileCard from './InvoiceMobileCard'
import CustomPdfViewer from '../pdfDrawer/page'
import Link from 'next/link'

const { getAllCentersList } = multicenterActions;
const { getPackageListDropdown } = packageActions;


const page = (props) => {

    const dispatch = useDispatch();

    const { accountReducer, multicenterReducer, packageReducer, invoiceReducer, firebase } = Helper.getSelectorState(["accountReducer", "multicenterReducer", "packageReducer", "invoiceReducer"]);
    const { allCentersList } = multicenterReducer;
    const { packageList } = packageReducer;
    const { invoiceLists } = props;
    const [invoiceList, setInvoiceList] = useState([]);

    const [oustandingAmount, setOustandingAmount] = useState(0);
    const [paidInvoices, setPaidInvoices] = useState({
        amount: 0,
        count: 0
    });
    const [overdueInvoices, setOverdueInvoices] = useState({
        amount: 0,
        count: 0
    });

    const [pdfViewerSelectedObj, setPdfViewerSelectedObj] = useState(null);

    const [search, setSearch] = useState(undefined);
    const [packageFilter, setPackageFilter] = useState(undefined);
    const [centerFilter, setCenterFilter] = useState(undefined);
    const [accountFilter, setAccountFilter] = useState(undefined);
    const [statusFilter, setStatusFilter] = useState(undefined);

    const [accountsMap, setAccountsMap] = useState(new Map());
    const [centersMap, setCentersMap] = useState(new Map());
    const [packagesMap, setPackagesMap] = useState(new Map());

    const [showFiltersBottomSheet, setShowFiltersBottomSheet] = useState(false);
    const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handleAccountFilterChange = useCallback((acountFilterVal) => {

        const isAllSelected: boolean = acountFilterVal?.some((val) => val === 'all accounts') || false;

        if (Helper.getAccountList() && isAllSelected) {
            acountFilterVal = Helper.getAccountList().map((item) => item.id);
        }

        let stringifiedData = acountFilterVal ? JSON.stringify(acountFilterVal) : '';

        setAccountFilter(acountFilterVal);
        Helper.setUrlParam("invAccountFilter", stringifiedData);

    }, [setAccountFilter, accountReducer.accountList]);

    const handleCenterFilterChange = useCallback((centerFilterVal) => {

        const isAllSelected: boolean = centerFilterVal?.some((val) => val === 'all centers') || false;

        if (allCentersList && isAllSelected) {
            centerFilterVal = allCentersList.map((item) => item.id);
        }

        let stringifiedData = centerFilterVal ? JSON.stringify(centerFilterVal) : '';

        setCenterFilter(centerFilterVal);
        Helper.setUrlParam('invCenterFilter', stringifiedData);

    }, [setCenterFilter, allCentersList]);

    const handlePackageFilterChange = useCallback((packageFilterVal) => {

        const isAllSelected: boolean = packageFilterVal?.some((val) => val === 'all packages') || false;

        if (packageList && isAllSelected) {
            packageFilterVal = packageList.map((item) => item.id);
        }

        let stringifiedData = packageFilterVal ? JSON.stringify(packageFilterVal) : '';

        setPackageFilter(packageFilterVal);
        Helper.setUrlParam('invPackageFilter', stringifiedData);

    }, [setPackageFilter, packageList]);


    const handleStatusFilterChange = useCallback((statusFilterVal) => {

        const isAllSelected: boolean = statusFilterVal?.some((val) => val === 'all status') || false;

        if (invoiceStatusesList.current && isAllSelected) {
            statusFilterVal = invoiceStatusesList.current.map((item) => item);
        }

        let stringifiedData = statusFilterVal ? JSON.stringify(statusFilterVal) : '';

        setStatusFilter(statusFilterVal);
        Helper.setUrlParam('invStatusFilter', stringifiedData);

    }, [setStatusFilter]);


    const handleSearchChange = useCallback((val) => {
        setSearch(val);
        Helper.setUrlParam('invSearch', val ? val : '');
    }, [setSearch]);

    const handlePageChange = useCallback((newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
        Helper.setUrlParam('pageNo', newPageNo ? newPageNo : 1);
        Helper.setUrlParam('pageSize', newPageSize ? newPageSize : 10);
    }, [setPageNo, setPageSize]);

    useEffect(() => {
        if (Helper.getAccountList()) {
            const newAccountsMap = new Map();
            Helper.getAccountList().forEach((item) => newAccountsMap.set(item.id, item));
            setAccountsMap(newAccountsMap);
        }
    }, [accountReducer.accountList]);

    useEffect(() => {
        if (allCentersList) {
            const newCentersMap = new Map();
            allCentersList.forEach((item) => newCentersMap.set(item.id, item));
            setCentersMap(newCentersMap);
        }
    }, [allCentersList]);

    useEffect(() => {
        if (packageList) {
            const newPackagesMap = new Map();
            packageList.forEach((item) => newPackagesMap.set(item.id, item));
            setPackagesMap(newPackagesMap);
        }
    }, [packageList]);

    const invoiceStatusesList = useRef([
        "paid",
        "cancelled",
        "pending",
        "overdue",
        "due",
        "partiallyOverdue",
        "partiallyPaid"
    ]);

    const isTeacherLoggedIn = Helper.getLoggedInUserType(firebase).toLowerCase() === "teacher";

    const renderSearchBar = isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);
    const renderAccountFilter = isTeacherLoggedIn && isView(['adminPaymentsView', 'adminPackageDetail']);
    const renderCenterFilter = isTeacherLoggedIn && isView(['adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);
    const renderPackageFilter = isView(['userPaymentsView', 'adminPaymentsView', 'adminAccountDetail']);
    const renderStatusFilter = isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);

    const [filterNameToFilterMetaDataObjMap, setFilterNameToFilterMetaDataObjMap] = useState(
        getFilterNameToFilterMetaDataObjMapForView()
    );

    function getFilterNameToFilterMetaDataObjMapForView() {

        const newFilterNameToFilterMetaDataObjMap = new Map();

        if (renderAccountFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.accounts', {
                optionsArr: Helper.getAccountList() || [],
                selectedVal: accountFilter,
                setSelectedVal: handleAccountFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderCenterFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.centers', {
                optionsArr: allCentersList || [],
                selectedVal: centerFilter,
                setSelectedVal: handleCenterFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderPackageFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.packages', {
                optionsArr: packageList || [],
                selectedVal: packageFilter,
                setSelectedVal: handlePackageFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderStatusFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.status', {
                optionsArr: invoiceStatusesList.current || [],
                selectedVal: statusFilter,
                setSelectedVal: handleStatusFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }

        return newFilterNameToFilterMetaDataObjMap;
    }

    useEffect(() => {
        setFilterNameToFilterMetaDataObjMap(
            getFilterNameToFilterMetaDataObjMapForView()
        );
    }, [accountReducer.accountList, accountFilter, handleAccountFilterChange, allCentersList, centerFilter, handleCenterFilterChange, packageList, packageFilter, handlePackageFilterChange, statusFilter, handleStatusFilterChange]);


    const currency = Helper.getCurrency(firebase);

    useEffect(() => {
        getOustandingAmount();
        getPaidInvoicesSummary();
        getOverdueInvoicesSummary();
    }, [invoiceLists])

    useEffect(() => {
        filterList();
    }, [invoiceLists, search, packageFilter, centerFilter, accountFilter, statusFilter]);


    const getFilterUrlParam = () => {

        let search = Helper.getUrlParam("invSearch");
        search = Helper.checkUrlParam(search, undefined);
        if (search) setSearch(search);

        let accountFilterJSONString = Helper.getUrlParam("invAccountFilter");
        let accountFilterParsedJSON = accountFilterJSONString ? JSON.parse(accountFilterJSONString) : undefined;
        if (accountFilterParsedJSON) setAccountFilter(accountFilterParsedJSON);

        let centerFilterJSONString = Helper.getUrlParam("invCenterFilter");
        let centerFilterParsedJSON = centerFilterJSONString ? JSON.parse(centerFilterJSONString) : undefined;
        if (centerFilterParsedJSON) setCenterFilter(centerFilterParsedJSON);

        let packageFilterJSONString = Helper.getUrlParam("invPackageFilter");
        let packageFilterParsedJSON = packageFilterJSONString ? JSON.parse(packageFilterJSONString) : undefined;
        if (packageFilterParsedJSON) setPackageFilter(packageFilterParsedJSON);

        let statusFilterJSONString = Helper.getUrlParam("invStatusFilter");
        let statusFilterParsedJSON = statusFilterJSONString ? JSON.parse(statusFilterJSONString) : undefined;
        if (statusFilterParsedJSON) setStatusFilter(statusFilterParsedJSON);

        let pageNo: any = Helper.getUrlParam("pageNo");
        pageNo = Helper.checkUrlParam(pageNo, 1, "number");
        if (pageNo) setPageNo(pageNo);

        let pageSize: any = Helper.getUrlParam("pageSize");
        pageSize = Helper.checkUrlParam(pageSize, 10, "number");
        if (pageSize) setPageSize(pageSize);
    }

    useEffect(() => {
        getFilterUrlParam();
        if (isTeacherLoggedIn) {
            dispatch(getAllCentersList({ firebase }));
        }
        dispatch(getPackageListDropdown({ firebase }));
    }, [])

    const getOustandingAmount = () => {
        const totalOutstanding = invoiceLists.reduce((sum, invoice) => {
            const amount = Number(invoice?.pending);
            return sum + (isNaN(amount) ? 0 : amount);
        }, 0);

        setOustandingAmount(totalOutstanding);
    };

    const getPaidInvoicesSummary = () => {
        const summary = invoiceLists.reduce(
            (acc, invoice) => {
                if (invoice?.invoiceStatus === "paid") {
                    const amount = Number(invoice.paid);
                    if (!isNaN(amount)) {
                        acc.amount += amount;
                        acc.count += 1;
                    }
                }
                return acc;
            },
            { amount: 0, count: 0 }
        );

        setPaidInvoices(summary);
    };

    const getOverdueInvoicesSummary = () => {
        const summary = invoiceLists.reduce(
            (acc, invoice) => {
                if (invoice?.invoiceStatus === "overdue") {
                    const amount = Number(invoice.pending);
                    if (!isNaN(amount)) {
                        acc.amount += amount;
                        acc.count += 1;
                    }
                }
                return acc;
            },
            { amount: 0, count: 0 }
        );

        setOverdueInvoices(summary);
    };


    const filterList = () => {

        let selectedAccountsSet = new Set();
        if (accountFilter?.length) selectedAccountsSet = new Set(accountFilter);

        let selectedCentersSet = new Set();
        if (centerFilter?.length) selectedCentersSet = new Set(centerFilter);

        let selectedPackagesSet = new Set();
        if (packageFilter?.length) selectedPackagesSet = new Set(packageFilter);

        let selectedStatusesSet = new Set();
        if (statusFilter?.length) {
            statusFilter.forEach((status) => {
                selectedStatusesSet.add(status.toLowerCase());
            });
        }

        let filteredData = invoiceLists.filter((invoice) => {

            const searchMatch = !search || search.trim() == "" || (invoice.invoiceNumber && invoice.invoiceNumber.toLowerCase().includes(search.toLowerCase())) || (invoice.accountName && invoice.accountName.toLowerCase().includes(search.toLowerCase())) || (invoice.profileName && invoice.profileName.toLowerCase().includes(search.toLowerCase())) || (invoice.packageName && invoice.packageName.toLowerCase().includes(search.toLowerCase()));

            const packageMatch = !packageFilter?.length || selectedPackagesSet.has(invoice.packageId);

            const centerMatch = !centerFilter?.length || selectedCentersSet.has(invoice.centerId);

            const accountMatch = !accountFilter?.length || (invoice.accountId && selectedAccountsSet.has(invoice.accountId));

            const statusMatch = !statusFilter?.length || selectedStatusesSet.has(invoice.invoiceStatus.toLowerCase());

            return searchMatch && packageMatch && centerMatch && accountMatch && statusMatch;
        });

        setInvoiceList(filteredData);
    };

    const renderWithLink = (isLinked, record, content) =>
        isLinked ? <Link prefetch={true} className="invoice-link" href={`/dashboard/invoices/invoiceDetail?id=${record.id}`}>{content}</Link> : content;



    let columns: ColumnsType<any> = [
        {
            key: 'invoiceNumber',
            dataIndex: 'invoiceNumber',
            title: <div>
                <div className="inv-main-heading">{formatMsg("invoice.invoiceNumber")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "invoiceNumber"),
            render: (text, record) => renderWithLink(isTeacherLoggedIn, record, <div className="inv-main-body">{record.invoiceNumber || " - "}</div>)
        },
        ...(isTeacherLoggedIn ? [{
            key: 'customerAccounts',
            dataIndex: 'accountName',
            title: <div>
                <div className="inv-main-heading">{formatMsg("customerAccounts")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "accountName"),
            render: (text, record) => {
                const { accountName, profileName } = record;
                return renderWithLink(isTeacherLoggedIn, record, (
                    <div>
                        <div className="inv-main-body" style={{ marginBottom: "10px" }}>{accountName}</div>
                        {profileName && (
                            <Tag color="#DDDDDD" style={{ borderRadius: "12px", color: "black" }}>
                                {profileName}
                            </Tag>
                        )}
                    </div>
                ));
            }
        }] : []),
        {
            key: 'packageName',
            dataIndex: 'packageName',
            title: <div>
                <div className="inv-main-heading">{formatMsg("invoice.packageName")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "packageName"),
            render: (text, record) => renderWithLink(isTeacherLoggedIn, record, <div className="inv-main-body">{record.packageName}</div>)
        },
        {
            key: 'invoiceDate',
            dataIndex: 'invoiceRaiseDate',
            title: <div>
                <div className="inv-main-heading">{formatMsg("invoiceDate")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "invoiceRaiseDate"),
            render: (text, record) => {
                const { invoiceRaiseDate, dueDate } = record;
                return renderWithLink(isTeacherLoggedIn, record, (
                    <div className="invoice-date">
                        <div className="inv-main-body">{dayjs.tz(invoiceRaiseDate).format("DD MMM YYYY")}</div>
                        <div style={{ color: "#8D8D8D", whiteSpace: "nowrap" }}>
                            Due date: {dayjs.tz(dueDate).format("DD MMM YYYY")}
                        </div>
                    </div>
                ))
            }
        },

        {
            key: 'amount',
            dataIndex: 'total',
            title: <div>
                <div className="inv-main-heading">{formatMsg("amount")}</div>
            </div>,
            sorter: (a, b) => Helper.sortIntegerValue(a, b, "total"),
            render: (text, record) => {
                const { currencySymbol, paid, total } = record;
                return renderWithLink(isTeacherLoggedIn, record, (
                    <div>
                        <div className="inv-main-body">Paid: {currencySymbol}{paid}</div>
                        <div style={{ color: "#8D8D8D", whiteSpace: "nowrap" }}>Billed: {currencySymbol}{total}</div>
                    </div>
                ));
            }
        },

        {
            key: 'status',
            dataIndex: 'invoiceStatus',
            title: <div>
                <div className="inv-main-heading">{formatMsg("status")}</div>
            </div>,
            render: (text, record) => renderWithLink(isTeacherLoggedIn, record, (
                <div>
                    <div className="inv-main-body">{Helper.getAmountStatusTag(text)}</div>
                </div>
            ))
        },
        {
            key: 'options',
            dataIndex: 'options',
            title: "",
            render: (text, record) =>
                record?.id && (
                    <div style={{ display: "flex", justifyContent: "center", cursor: "pointer", width: "auto" }}>
                        <InvoicePopoverOptions 
                            record={record} 
                            setPdfViewerSelectedObj={setPdfViewerSelectedObj} 
                        />
                    </div>
                )
        }
    ];

    function isView(inputArr) {
        return inputArr.some((viewVal) => viewVal === props.view);
    }

    const isLoading = accountReducer.isLoading || multicenterReducer.isLoading || packageReducer.isLoading || invoiceReducer.isLoading;

    return (
        <>

            <Row align={"middle"} justify={"center"}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className='invoice-container'>
                        {isTeacherLoggedIn &&
                            isView(['adminPaymentsView', 'adminPackageDetail']) &&
                            <>
                                <div className="stickers-container">
                                    <Sticker
                                        name={"OUSTANDING_AMOUNT"}
                                        style={styles.stickerStyle}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>

                                                <div className="sticker-left">
                                                    <img src="/images/oustanding.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("totalOutstandingAmount")}</div>
                                                </div>
                                                <div className="sticker-value">{currency} {Helper.roundNumberToTwo(oustandingAmount)}</div>
                                            </div>

                                        }
                                        onClick={() => {
                                            return null;
                                        }}
                                    />

                                    <Sticker
                                        name={"PAID_INVOICES"}
                                        style={{ ...styles.stickerStyle, justifyContent: "space-between" }}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="sticker-left">
                                                    <img src="/images/paid.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("totalPaidInvoices")}</div>
                                                </div>
                                                <div className="sticker-value">{currency} {Helper.roundNumberToTwo(paidInvoices.amount)}</div>

                                            </div>
                                        }
                                        subTitle={
                                            <div className="sticker-badge">{paidInvoices.count}</div>
                                        }
                                        onClick={() => {
                                            return null;
                                        }}
                                    />
                                    <Sticker
                                        name={"OVERDUE_INVOICES"}
                                        style={{ ...styles.stickerStyle, justifyContent: "space-between" }}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="sticker-left">
                                                    <img src="/images/overdue.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("totalOverdueInvoices")}</div>
                                                </div>
                                                <div className="sticker-value">{currency} {Helper.roundNumberToTwo(overdueInvoices.amount)}</div>

                                            </div>
                                        }
                                        subTitle={
                                            <div className="sticker-badge overdue">{overdueInvoices.count}</div>
                                        }
                                        onClick={() => {
                                            return null;
                                        }}
                                    />
                                </div>
                                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <PrimaryButtonWrapper onClick={() => router.push("/dashboard/invoices/createInvoice")}>
                                            <PlusOutlined /> {formatMsg("createInvoice")}
                                        </PrimaryButtonWrapper>
                                    </div> */}
                            </>
                        }

                        <Row
                            justify="space-between"
                            typeof="flex"
                            gutter={[15, 12]}
                            className='laptop-payment-filters-row'
                        >
                            {
                                renderSearchBar &&
                                <Col xxl={8} xl={8} lg={24} md={24} sm={24} xs={24} >
                                    <Row typeof="flex" gutter={[15, 12]}>
                                        <Col
                                            xxl={20}
                                            xl={20}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                        >
                                            <Input
                                                placeholder={formatMsg("invoice.search")}
                                                style={{ width: "100%" }}
                                                value={search}
                                                onChange={(e) => handleSearchChange(e.target.value)}
                                                suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24} >
                                <Row typeof="flex" justify="end" gutter={[15, 12]}>
                                    {
                                        isView(['adminPackageDetail', 'adminAccountDetail']) &&
                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                                            <FilterDateSelector
                                                dateRange={props.dateRange}
                                                setDateRange={props.setDateRange}
                                            />
                                        </Col>
                                    }
                                    {
                                        renderAccountFilter &&
                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                            <Select
                                                mode='multiple'
                                                onChange={handleAccountFilterChange}
                                                maxTagCount={0}
                                                maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('account', accountFilter, accountsMap)}
                                                placeholder={formatMsg("placeholder.selectCustomerAccounts")}
                                                style={{ width: "100%" }}
                                                value={accountFilter}
                                                allowClear
                                                popupMatchSelectWidth={false}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                            >
                                                <Select.Option
                                                    key={'all accounts'}
                                                    value={'all accounts'}
                                                >
                                                    {formatMsg('caps.allAccounts')}
                                                </Select.Option>
                                                {Helper.getAccountList()?.map((account, index) => (
                                                    <Select.Option key={account.id} value={account.id}>
                                                        {account.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    }
                                    {
                                        renderCenterFilter &&
                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                            <Select
                                                mode='multiple'
                                                onChange={handleCenterFilterChange}
                                                maxTagCount={0}
                                                maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('center', centerFilter, centersMap)}
                                                placeholder={formatMsg("placeholder.selectCenters")}
                                                style={{ width: "100%" }}
                                                value={centerFilter}
                                                allowClear
                                                popupMatchSelectWidth={false}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                            >
                                                <Select.Option
                                                    key={"all"}
                                                    value={"all centers"}
                                                >
                                                    {formatMsg("allCenters")}
                                                </Select.Option>
                                                {allCentersList.map((center, index) => (
                                                    <Select.Option
                                                        key={center.id}
                                                        value={center.id}
                                                    >
                                                        {center.name}
                                                    </Select.Option>
                                                ))}

                                            </Select>
                                        </Col>
                                    }
                                    {
                                        renderPackageFilter &&
                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                            <Select
                                                mode='multiple'
                                                onChange={handlePackageFilterChange}
                                                maxTagCount={0}
                                                maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('package', packageFilter, packagesMap)}
                                                placeholder={formatMsg("placeholder.selectPackages")}
                                                style={{ width: "100%" }}
                                                value={packageFilter}
                                                allowClear
                                                popupMatchSelectWidth={false}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                            >
                                                <Select.Option
                                                    key={"all"}
                                                    value={"all packages"}
                                                >
                                                    {formatMsg("caps.allPackages")}
                                                </Select.Option>
                                                {packageList.map((item, index) => (
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    }

                                    {
                                        renderStatusFilter &&
                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                            <Select
                                                mode='multiple'
                                                onChange={handleStatusFilterChange}
                                                maxTagCount={0}
                                                maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('status', statusFilter)}
                                                placeholder={formatMsg("selectStatus")}
                                                style={{ width: "100%" }}
                                                value={statusFilter}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                            >
                                                {
                                                    ["all status", ...invoiceStatusesList.current].map((status) => {
                                                        return (
                                                            <Select.Option
                                                                value={status}
                                                            >
                                                                {formatMsg(status)}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>

                        {
                            isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']) &&
                            <div className='mobile-payment-filters-row'>
                                <div className='mob-payments-search-filter-container'>
                                    <Input
                                        width={"100%"}
                                        value={search}
                                        onChange={e => handleSearchChange(e.target.value)}
                                        placeholder={formatMsg("search")}
                                        suffix={<SearchOutlined />}
                                    />
                                </div>

                                <div className='mob-date-range-selector-row'>
                                    {
                                        (props.dateRange && props.setDateRange) &&
                                        <div className='mobile-date-range-filter'>
                                            <FilterDateSelector
                                                dateRange={props.dateRange}
                                                setDateRange={props.setDateRange}
                                            />
                                        </div>
                                    }

                                    <MobileFilterButton
                                        setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                                        appliedFiltersCount={appliedFiltersCount}
                                    />
                                </div>
                            </div>
                        }

                        <div className='payments-table-view'>
                            <TableWrapper
                                columns={columns}
                                dataSource={invoiceList}
                                pagination={{
                                    current: pageNo,
                                    pageSize: pageSize,
                                    onChange: handlePageChange,
                                    showSizeChanger: true,
                                    hideOnSinglePage: false
                                }}
                                loading={isLoading}
                            />
                        </div>

                        <Spin spinning={isLoading}>
                            <div className='payments-list-view'>
                                {invoiceList.map((invoiceObj) => (
                                    <InvoiceMobileCard
                                        invoiceObj={invoiceObj}
                                        firebase={firebase}
                                        setPdfViewerSelectedObj={setPdfViewerSelectedObj}
                                    />
                                ))}
                            </div>
                        </Spin>
                    </div>
                </Col>
            </Row>
            <FiltersBottomSheet
                filterNameToFilterMetaDataObjMap={filterNameToFilterMetaDataObjMap}
                showFiltersBottomSheet={showFiltersBottomSheet}
                setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                setAppliedFiltersCount={setAppliedFiltersCount}
            />
            <CustomPdfViewer
                visible={Boolean(pdfViewerSelectedObj)}
                onClose={() => setPdfViewerSelectedObj(null)}
                downloadAllowed={true}
                invoiceUrl={pdfViewerSelectedObj?.pdfUrl}
            />
        </>
    )
}

export default page

const styles = {
    colStyle: {
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: "nowrap"
    },
    stickerStyle: {
        minWidth: "320px",
        maxWidth: "400px",
        flex: "1 1 fit-content",
        boxShadow: "0px 4px 25px 0px #00000026",
        // border: "1px solid #D0D0D0",
        borderRadius: "12px"
    }
}