"use client"
import React, { useEffect, useState } from 'react';
import { Popover, List, Form } from 'antd';
import { ModalWrapper, PrimaryButtonWrapper } from '../wrapperComponents/page';
import FormWrapper from '../formWrapper/page';
import formatMsg from '../../util/formatMsg';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import Helper from '../../util/helper';
import { notification } from '../../util/notification';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { invoiceActions, parentActions } from 'sharedresources';
const { addPayment, addRefund, getInvoiceById, invoicePDF, sagaSuccess, sendFeeReminder } = invoiceActions
const { payNow } = parentActions

import './style.css';

const InvoicePopoverOptions: any = ({ record, setPdfViewerSelectedObj, renderActionButton }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [showAddPaymentModal, setshowAddPaymentModal] = useState(false);
  const [showAddRefundModal, setshowAddRefundModal] = useState(false);
  const [openedOption, setOpenedOption] = useState("");
  const [receiptModal, setShowReceiptModal] = useState(false);
  const { firebase, invoiceReducer, parentReducer } = Helper.getSelectorState(["invoiceReducer", "parentReducer"]);
  const router = useRouter();
  const status = record.invoiceStatus;

  useEffect(() => {
    if (parentReducer.operationType && parentReducer.operationType === "UPAYMENT_REDIRECT_LINK")
      if (parentReducer.redirectLink) router.push(parentReducer.redirectLink);
  }, [parentReducer.operationType])

  const handleAddPaymentModalOk = async () => {
    try {
      const values = await form.validateFields();
      const amt = Number(values.amountPaid)
      if (!amt || amt < 1 || amt > record.pending) {
        notification("error", formatMsg("error.amountGreaterOrLessThan"))
        return
      }
      const requestPayload = {
        paymentRecords: [{
          amount: amt,
          mode: values.paymentMode,
          referenceNumber: values.transactionNumber,
          updatedOn: dayjs.tz().valueOf(),
          timestamp: values.paymentDate.valueOf(),
        }],
        sendEmail: values.sendEmailToParents
      }
      form.resetFields()
      dispatch(addPayment({ firebase, requestPayload, invoiceId: record.id }))
      setshowAddPaymentModal(false);
    } catch (errorInfo) {
    }
  };

  const handleAddRefundModalOk = async () => {
    try {
      const values = await form.validateFields();
      const amt = Number(values.refundAmount)
      if (!amt || amt < 1 || amt < (record.paid - (record.totalRefund || 0))) {
        notification("error", formatMsg("error.refundGreaterOrLessThan"))
        return
      }
      const requestPayload = {
        values: {
          amount: amt,
          mode: values.paymentMode,
          referenceNumber: values.referenceNumber,
          paymentDate: values.refundDate.valueOf(),
          userId: firebase.user.id
        },
      }
      form.resetFields()
      dispatch(addRefund({ firebase, requestPayload, invoiceId: record.id }))
      setshowAddRefundModal(false);
    } catch (errorInfo) {
    }
  };

  const addRefundFormFields = [
    [{
      label: formatMsg("refundAmount"),
      type: "input",
      name: "refundAmount",
      placeHolder: formatMsg("placeholder.refundAmount"),
      warningMessage: "Please input refund amount!",
      inputType: "number",
      required: true,
      initialValue: (record?.paid - (record?.totalRefund || 0))?.toString()
    }],
    [{
      label: formatMsg("paymentMode"),
      type: "select",
      name: "paymentMode",
      placeHolder: formatMsg("placeholder.paymentMode"),
      warningMessage: "Please select payment mode!",
      inputType: "select",
      required: true,
      multiple: false,
      options: ["Cheque", "Cash", "UPI", "Bank transfer"],
      initialValue: "Cash"
    }],
    [{
      label: formatMsg("refundDate"),
      type: "date",
      name: "refundDate",
      placeHolder: formatMsg("placeholder.refundDate"),
      warningMessage: "Please select refund date!",
      inputType: "string",
      required: true,
      initialValue: dayjs.tz()
    }],
    [{
      label: formatMsg("referenceNumber"),
      type: "input",
      name: "referenceNumber",
      placeHolder: formatMsg("placeholder.referenceNumber"),
      warningMessage: "Please input reference number!",
      inputType: "string",
    }],
  ]
  const addPaymentFormFields = [
    [{
      label: formatMsg("amountPaid"),
      type: "input",
      name: "amountPaid",
      placeHolder: formatMsg("placeholder.amountPaid"),
      warningMessage: "Please input paid amount!",
      inputType: "number",
      required: true,
      initialValue: record?.pending?.toString()
    }],
    [{
      label: formatMsg("paymentMode"),
      type: "select",
      name: "paymentMode",
      placeHolder: formatMsg("placeholder.paymentMode"),
      warningMessage: "Please select payment mode!",
      inputType: "select",
      required: true,
      multiple: false,
      options: ["Cheque", "Cash", "UPI", "Bank transfer"],
      initialValue: "Cash"
    }],
    [{
      label: formatMsg("paymentDate"),
      type: "date",
      name: "paymentDate",
      placeHolder: formatMsg("placeholder.paymentDate"),
      warningMessage: "Please select payment date!",
      inputType: "string",
      required: true,
      initialValue: dayjs.tz()
    }],
    [{
      label: formatMsg("transactionNumber"),
      type: "input",
      name: "transactionNumber",
      placeHolder: formatMsg("placeholder.transactionNumber"),
      warningMessage: "Please input transaction number!",
      inputType: "string",
    }],
    [{
      label: "",
      type: "checkBox",
      name: "sendEmailToParents",
      inputType: "checkBox",
      options: formatMsg("sendEmailToParents"),
      initialValue: true
    }],
  ]

  const getInvoiceOptions = () => {
    return (
      <List split={false} className='invoicePopOptions'>
        {!Helper.isAccount(firebase) &&
          <div 
            onClick={() => {
              if (record.paymentRecords?.length) notification("error", formatMsg("deletePaymentToEdit"))
              else router.push("/dashboard/invoices/createInvoice?invoiceId=" + record.id)
            }}
          >
            <List.Item>
              <img src='/images/editV2Icon.png' className='inv-pop-icon' />
              <p style={{ cursor: "pointer" }}>{formatMsg("edit")}</p>
            </List.Item>
          </div>
        }
        {record.status !== "CANCELLED" && <List.Item onClick={() => {
          setOpenedOption("");
          if (record.pdfUrl) {
            setPdfViewerSelectedObj(record);
          }
          else dispatch(invoicePDF({ firebase, requestPayload: { id: record.id } }) as any)
        }}>
          <img 
            src={`/images/${record.pdfUrl ? 'previewInvoicePdf' : 'createInvoiePdf'}.png`} 
            className='inv-pop-icon' 
          /> 
          <p style={{ cursor: "pointer" }}>{formatMsg(record.pdfUrl ? "previewInvoicePdf" : "createInvoiePdf"
          )}</p>
        </List.Item>
        }
        {!Helper.isAccount(firebase) && record.pdfUrl && record.status !== "CANCELLED" &&
          <List.Item onClick={() => {
            setOpenedOption("");
            dispatch(invoicePDF({ firebase, requestPayload: { id: record.id } }) as any)
          }}>
            <img src='/images/refreshInvoicePdf.png' className='inv-pop-icon' />
            <p style={{ cursor: "pointer" }}>{formatMsg('refreshInvoicePdf')}</p>
          </List.Item>
        }
        {record.receiptList && record.status !== "CANCELLED" &&
          <List.Item onClick={() => {
            setOpenedOption("");
            setShowReceiptModal(true)
          }}>
            <img src='/images/viewReceiptPdf.png' className='inv-pop-icon' />
            <p style={{ cursor: "pointer" }}>{formatMsg('viewReceiptPdf')}</p>
          </List.Item>
        }
        {!Helper.isAccount(firebase) && record.pending > 0 && record.status !== "CANCELLED" && (
          <List.Item onClick={() => {
            setOpenedOption("");
            dispatch(sendFeeReminder({ firebase, requestPayload: { invoiceIds: [record.id] } }))
          }}>
            <img src='/images/sendReminder.png' className='inv-pop-icon' />
            <p style={{ cursor: "pointer" }}>{formatMsg("sendReminder")}</p>
          </List.Item>
        )}

        {record.pending > 0 && (
          !Helper.isAccount(firebase) && record.status !== "CANCELLED" ?
            <List.Item onClick={() => {
              setOpenedOption("");
              setshowAddPaymentModal(true);
              setshowAddRefundModal(false);
            }}>
              <img src='/images/recordPayment.png' className='inv-pop-icon' />
              <p style={{ cursor: "pointer" }}>{formatMsg("c.recordPayment")}</p>
            </List.Item>
            : (firebase.schoolConfig.paymentAccount && (
              <List.Item>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatch(payNow({ firebase, invoiceId: record.id }))}
                >
                  <img src='/images/payNow.png' className='inv-pop-icon' />
                  {formatMsg("payNow")}
                </div>
              </List.Item>))
        )}

        {record.paid > 0 && !Helper.isAccount(firebase) && (
          <List.Item onClick={() => {
            setOpenedOption("");
            setshowAddPaymentModal(false);
            setshowAddRefundModal(true);
          }}>
            <img src='/images/addRefund.png' className='inv-pop-icon' />
            <p style={{ cursor: "pointer" }}>{formatMsg("addRefund")}</p>
          </List.Item>
        )}
      </List >
    );
  };
  return (
    <>
      <Popover
        content={getInvoiceOptions()}
        open={openedOption === record.id}
        onOpenChange={(v) => setOpenedOption(v ? record.id : "")}
        trigger={"click"}
        placement='bottom'
      >
        {renderActionButton ?
          <PrimaryButtonWrapper>{formatMsg("action")}</PrimaryButtonWrapper> :
          <div className='invoiceOptions' style={{ padding: 12 }}>
            {/* <img style={{ height: 16, width: 16 }} src='/images/Menu Vertical.png' alt="Options" /> */}
            <img style={{ height: 20, width: 5, cursor: "pointer" }} src='/images/dot.png' alt="Options" />
          </div>
        }
      </Popover>
      <ModalWrapper
        title={showAddRefundModal ? "Add Refund" : "Add Payment"}
        open={showAddPaymentModal || showAddRefundModal}
        onOk={showAddRefundModal ? handleAddRefundModalOk : handleAddPaymentModalOk}
        onCancel={() => {
          setshowAddPaymentModal(false)
          setshowAddRefundModal(false)
          form.resetFields()
        }}
        okText="Save"
        maskClosable={false}
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
        >
          <FormWrapper formFields={showAddRefundModal ? addRefundFormFields : addPaymentFormFields} />
        </Form>
      </ModalWrapper>
      <ModalWrapper
        open={receiptModal}
        title={formatMsg("viewReceipt")}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setShowReceiptModal(false)}
      >
        <List
          dataSource={record.receiptList}
          split={false}
          renderItem={(item: any) => (
            <List.Item key={item.receiptNumber} style={{ cursor: "pointer", color: "var(--primary-color)", textDecoration: "underline" }} onClick={() => setPdfViewerSelectedObj({ pdfUrl: item.pdfUrl })}>
              {item.receiptNumber}
            </List.Item>
          )}
        />
      </ModalWrapper>
    </>
  );
};

export default InvoicePopoverOptions;
