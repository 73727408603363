export enum ReportEndpointsSuffix {
    TRANSACTION_REPORT = '/reports/transaction' 
}
  
export enum NewBookingStatuses {
    CONFIRMED = "CONFIRMED",
    CONFIRMATION_PENDING = "CONFIRMATION_PENDING",
    ACTIVE = "ACTIVE", 
    WAITLISTED = "WAITLISTED", 
    CANCELLED = "CANCELLED", 
    EXPIRED = "EXPIRED" 
}

export enum NewMembershipStatuses {
    CONFIRMED = "CONFIRMED",
    CONFIRMATION_PENDING = "CONFIRMATION_PENDING",
    ACTIVE = "ACTIVE", 
    WAITLISTED = "WAITLISTED", 
    CANCELLED = "CANCELLED", 
    EXPIRED = "EXPIRED",
    ENDED = "ENDED" 
}