'use client'


import React from 'react'
import styles from './style.module.css';
import MobileAccountUserMetaDataCard from '../MobileAccountUserMetaDataCard/page';
import formatMsg from '../../../../util/formatMsg';
import Helper from '../../../../util/helper';


const LinkedAccountsMobileCard = (props: any) => {

    const {
        title,
        getAccountName,
        getAccountEmail,
        isPrimaryAccount,
        getAccountPhoneNumber,
        getLinkStatus,
        showPopoverActions,
        getPopContent,
        getEmptyState, 
        dataSource
    } = props;

    const getFieldsList = (record) => {

        const fieldsList = [
            {
                key: 'accountName',
                heading: formatMsg('accountName'),
                value: getAccountName(record),
                subText: isPrimaryAccount(record),
                prefixImg: Helper.getProfileImageUrl(record) || '-'
            },
            {
                key: 'linkStatus',
                heading: formatMsg('status'),
                value: getLinkStatus(record) || '-'
            },
            {
                key: 'email',
                heading: formatMsg('email'),
                value: getAccountEmail(record) || '-'
            },
            {
                key: 'phoneNumber',
                heading: formatMsg('phoneNumber'),
                value: getAccountPhoneNumber(record) || '-'
            }
        ];

        return fieldsList;
    }

    return (
        <div className={styles.mainCard}>
            <div
                className={styles.cardHeader}
            >
                <div className="global-card-header">{title}</div>
            </div>
            {
                (dataSource?.length) ?
                    <div>
                        {
                            dataSource.map((record, index) => {
                                return (
                                    <MobileAccountUserMetaDataCard
                                        fieldsList={getFieldsList(record)}
                                        showThreeDots={showPopoverActions}
                                        getThreeDotsPopContent={() => getPopContent(record)}
                                        showBorderBottom={index !== (dataSource.length - 1)}
                                    />
                                )
                            })
                        }
                    </div>
                    :
                    getEmptyState && getEmptyState()
            }
        </div>
    )
}

export default LinkedAccountsMobileCard