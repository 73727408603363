'use client'

import React from 'react'
import styles from './style.module.css'
import { Row } from 'antd'
import PrefixIconButton from '../../buttons/prefixIconButton'
import formatMsg from '../../../util/formatMsg'
import colors from '../../../libs/colorFactory'
import { PlusOutlined } from "@ant-design/icons"
import MobileUserCard from '../../mobileUserCard/page'


const AccountUsersMobileView = (props: any) => {

  const {
    firebase,
    accountDetails,
    editAction,
    addNewUserForAccount,
    linkedAccountsList,
    currentDetailedViewOfAccountId,
    getPopContent,
    getHeaderRedirectUrl,
    canEditUserInCurrentAccount,
    deleteUserConfirmation,
    getUserCardLabel,
    getUserCardValue,
    getAccountName,
    getAccountEmail,
    getAccountPhoneNumber,
    getAccountLinkStatus,
    showPopoverActions,
    isPrimaryAccount
  } = props;

  const getEmptyState = () => {
    return (
      <div
        className={styles.noLinkedUsersContainer}
      >
        <img
          src='/images/noUsersFound.png'
          className={styles.noLinkedUsers}
        />
      </div>
    )
  }

  return (
    <div>
      <Row typeof="flex" justify={"end"}>
        <PrefixIconButton
          title={formatMsg("new")}
          backgroundColor={colors.v2_Cerulean}
          fontColor={colors.color_primarytext}
          width="100px"
          height="32px"
          prefixIcon={<PlusOutlined />}
          onClick={addNewUserForAccount}
          marginBottom={15}
        />
      </Row>

      {
        accountDetails?.users && accountDetails.users.length !== 0 ?
          accountDetails.users.map((user, i) => {
            return <MobileUserCard
              currentDetailedViewOfAccountId={currentDetailedViewOfAccountId}
              firebase={firebase}
              user={user}
              accounts={linkedAccountsList}
              action={(user) => editAction(user)}
              getPopContent={getPopContent}
              getHeaderRedirectUrl={getHeaderRedirectUrl}
              canEditUserInCurrentAccount={canEditUserInCurrentAccount}
              deleteUserConfirmation={deleteUserConfirmation}
              getUserCardLabel={getUserCardLabel}
              getUserCardValue={getUserCardValue}
              getAccountName={getAccountName}
              getAccountEmail={getAccountEmail}
              getAccountPhoneNumber={getAccountPhoneNumber}
              getAccountLinkStatus={getAccountLinkStatus}
              showPopoverActions={showPopoverActions}
              isPrimaryAccount={isPrimaryAccount}
            />
          })
          :
          <div>
            {getEmptyState()}
          </div>
      }
    </div>
  )
}

export default AccountUsersMobileView