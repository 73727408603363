'use client'

import React from 'react'
import PrefixIconButton from '../../buttons/prefixIconButton';
import { Row } from 'antd';
import formatMsg from '../../../util/formatMsg';
import colors from '../../../libs/colorFactory';
import { PlusOutlined } from "@ant-design/icons";
import Helper from '../../../util/helper';
import UserCard from '../../userCard/page';
import EmptyPage from '../../EmptyPage/page';

import styles from './style.module.css'


const AccountUsersLaptopView = (props: any) => {

  const {
    firebase,
    accountDetails,
    editAction,
    addNewUserForAccount,
    linkedAccountsList,
    currentDetailedViewOfAccountId,
    getPopContent,
    getHeaderRedirectUrl,
    canEditUserInCurrentAccount,
    deleteUserConfirmation,
    getUserCardLabel,
    getUserCardValue,
    getAccountName,
    getAccountEmail,
    getAccountPhoneNumber,
    getAccountLinkStatus,
    showPopoverActions,
    isPrimaryAccount
  } = props;

  return (
    <div>
      <Row typeof="flex" justify={"end"}>
        <PrefixIconButton
          title={formatMsg("new")}
          type={"primary"}
          width="100px"
          height="32px"
          prefixIcon={<PlusOutlined />}
          onClick={addNewUserForAccount}
          marginBottom={15}
        />
      </Row>
      {
        accountDetails?.users && accountDetails.users.length !== 0 ?
          accountDetails.users.map((user, i) => {
            return <UserCard
              currentDetailedViewOfAccountId={currentDetailedViewOfAccountId}
              firebase={firebase}
              user={user}
              index={i}
              accounts={linkedAccountsList}
              action={(user) => editAction(user)}
              getPopContent={getPopContent}
              getHeaderRedirectUrl={getHeaderRedirectUrl}
              canEditUserInCurrentAccount={canEditUserInCurrentAccount}
              deleteUserConfirmation={deleteUserConfirmation}
              getUserCardLabel={getUserCardLabel}
              getUserCardValue={getUserCardValue}
              getAccountName={getAccountName}
              getAccountEmail={getAccountEmail}
              getAccountPhoneNumber={getAccountPhoneNumber}
              getAccountLinkStatus={getAccountLinkStatus}
              showPopoverActions={showPopoverActions}
              isPrimaryAccount={isPrimaryAccount}
            />
          })
          :
          <EmptyPage
            mainHeading={Helper.getSelectedUserTypeName(firebase, formatMsg("noUsersFound"), 'No')}
          />
      }
    </div>
  )
}

export default AccountUsersLaptopView