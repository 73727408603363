// import { useIntl } from "react-intl";

// function formatMsg(key: string) {
//     const intl = useIntl();
//     return intl.formatMessage({ id: key })
// };
// export default formatMsg;

import { getCurrentLocale } from "./localeHelper";
import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();
let localeHelper = getCurrentLocale();
const intl = createIntl(
    {
        locale: localeHelper.locale,
        messages: localeHelper.message,
        onError: (err) => {
            if (err.code === 'MISSING_TRANSLATION') {
                console.log("missing translation", err.descriptor.id)
            } else {
                console.error(err);
            }
        }
    },
    cache
);

const formatMsg = (key: string, opt?) => {
    return intl.formatMessage({ id: key, defaultMessage: key }, opt);
};

export default formatMsg;
