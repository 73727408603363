'use client'

import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react'
import MobileCalendarListViewCard from '../MobileCalendarListViewCard/page';
import { DatePicker } from 'antd';
import styles from './style.module.css'
import formatMsg from '../../../../util/formatMsg';
import LinkWrapper from '../../../linkWrapper/page';
import { getItem } from '../../../../libs/encryptedStorage';
import Helper from '../../../../util/helper';


const MobileCalendarListView = (props: any) => {

    const {
        calendarSelectedDate,
        dateToFilteredSlotsArrMap,
        monthInDisplay,
        setMonthInDisplay,
        setCalendarSelectedDate,
        setSelectedSchedule,
        setShowMobileSingleScheduleView,
    } = props;

    function onMonthYearChange(dateDayjsObj) {
        setMonthInDisplay(dayjs.tz(dateDayjsObj));
    }

    function onDayPickerDateSelection(day) {
        setCalendarSelectedDate(dayjs.tz(day));
        onMonthYearChange(dayjs.tz(day));
    }

    function changeMonth(type) {
        let newMonth = null;
        switch (type) {
            case 'next':
                newMonth = monthInDisplay.add(1, 'month');
                break;
            case 'prev':
                newMonth = monthInDisplay.subtract(1, 'month');
                break;
        }
        onMonthYearChange(newMonth);
    }

    const currSelectedDateStr = calendarSelectedDate?.format('DD-MM-YYYY') || '';

    const selectedMonthStartDayJs = monthInDisplay.startOf('month');
    const selectedMonthEndDayJs = monthInDisplay.endOf('month');

    const selectedDateRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (selectedDateRef && selectedDateRef.current) {
                selectedDateRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 1000);
    }, [dateToFilteredSlotsArrMap]);

    const atLeastOneEventPresentInMonth = dateToFilteredSlotsArrMap &&
        [...dateToFilteredSlotsArrMap].some(([currProcessingDateStr, slotsObjArr]) => isDateInSelectedMonth(currProcessingDateStr) && slotsObjArr?.length);

    function isDateInSelectedMonth(currProcessingDateStr: string) {

        const currDateDayjs = dayjs.tz(dayjs(currProcessingDateStr, 'DD-MM-YYYY'));

        return !(
            currDateDayjs.isAfter(selectedMonthEndDayJs, 'day') ||
            currDateDayjs.isBefore(selectedMonthStartDayJs, 'day')
        );
    }

    return (
        <div>
            <div className='month-date-picking'>
                <DatePicker
                    className='calendar-custom-date-picker'
                    format="MMMM, YYYY"
                    value={dayjs.tz(monthInDisplay)}
                    onChange={onMonthYearChange}
                    picker="month"
                    allowClear={false}
                    suffixIcon={
                        <img
                            src='/images/downArrow.png'
                            className='calendar-custom-down-arrow'
                        />
                    }
                />

                <div className={styles.mobCalendarMonthNav}>
                    <div
                        className={styles.monthNavPrev}
                        onClick={() => changeMonth('prev')}
                    >
                        <svg width="24" height="24">
                            <polygon
                                className={styles.monthNavColor}
                                points="16 18.112 9.81111111 12 16 5.87733333 14.0888889 4 6 12 14.0888889 20"
                            />
                        </svg>
                    </div>
                    <div
                        className={styles.monthNavNext}
                        onClick={() => changeMonth('next')}
                    >
                        <svg width="24" height="24">
                            <polygon
                                className={styles.monthNavColor}
                                points="8 18.612 14.1888889 12.5 8 6.37733333 9.91111111 4.5 18 12.5 9.91111111 20.5"
                            />
                        </svg>
                    </div>
                </div>
            </div>

            <div className={styles.scheduleListView}>
                {
                    atLeastOneEventPresentInMonth
                        ? [...dateToFilteredSlotsArrMap].map(([currProcessingDateStr, slotsObjArr]) => {

                            if (!isDateInSelectedMonth(currProcessingDateStr)) return null;

                            const currDateDayjs = dayjs.tz(dayjs(currProcessingDateStr, 'DD-MM-YYYY'));
                            const isThisDateSelected = (currSelectedDateStr === currProcessingDateStr);

                            return (
                                <div
                                    className={styles.scheduleDateDiv}
                                >
                                    <div
                                        ref={isThisDateSelected ? selectedDateRef : null}
                                        className={styles.listViewDate}
                                        onClick={() => onDayPickerDateSelection(Helper.convertToDate(currDateDayjs, props.firebase))}
                                    >
                                        <div
                                            className={`${styles.listViewDay} ${(isThisDateSelected) ? styles.listViewDateSelected : ''}`}
                                        >
                                            {currDateDayjs.format("ddd").toUpperCase()}
                                        </div>
                                        <div
                                            className={`${styles.listViewDateNum} ${(isThisDateSelected) ? styles.listViewDateSelected : ''}`}
                                        >
                                            {currDateDayjs.format("D")}
                                        </div>
                                    </div>
                                    <div className={styles.listViewDateSchedules}>
                                        {slotsObjArr.map((slotsObj) => {
                                            return (
                                                <MobileCalendarListViewCard
                                                    slotsObj={slotsObj}
                                                    setSelectedSchedule={setSelectedSchedule}
                                                    setShowMobileSingleScheduleView={setShowMobileSingleScheduleView}
                                                />
                                            )
                                        })}
                                        <div className={styles.horizontalDivider} />
                                    </div>
                                </div>
                            )
                        })
                        : (
                            <div
                                className={styles.noCalendarEventsContainer}
                            >
                                <img
                                    src='/images/noCalendarEvents.png'
                                    className={styles.noCalendarEvents}
                                />
                                <div className={styles.noCalendarEventsText}>{formatMsg('noCalendarEvents')}</div>
                                <LinkWrapper
                                    href={"/dashboard?centerId=" + (getItem("centerId") || Helper.getUrlParam("centerId"))}
                                >
                                    <div
                                        className={styles.explorePackagesText}
                                    >
                                        {formatMsg('explorePackages')}
                                    </div>
                                    <img
                                        src='/images/explorePurpleArrow.png'
                                        className={styles.explorePurpleArrow}
                                    />
                                </LinkWrapper>
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default MobileCalendarListView