'use client'

import React from 'react'
import { DatePicker } from 'antd'
import { DayPicker } from 'react-day-picker';
import dayjs from 'dayjs';
import { useRouter } from 'next/navigation';
import { Helper } from "sharedResources";

/*

# How to use: 

- Intialize these 2 states in parent component and pass all 4 them down this component as props 

    const [monthInDisplay, setMonthInDisplay] = useState(dayjs.tz().startOf('day')); 
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(dayjs.tz().startOf('day')); 

*/


const MobileCalendar = (props: any) => {

    const router = useRouter();

    const {
        monthInDisplay,
        setMonthInDisplay,
        calendarSelectedDate,
        setCalendarSelectedDate,
        dateToFilteredSlotsArrMap,
        setShowDailyView
    } = props;

    function onMonthYearChange(dateDayjsObj) {
        setMonthInDisplay(dayjs.tz(dateDayjsObj));
    }

    function onDayPickerDateSelection(day) {
        if (day) {
            setCalendarSelectedDate(dayjs.tz(day));
            onMonthYearChange(dayjs.tz(day));
        }
        setShowDailyView(true);
        router.push('#list-view-open');
    }

    function getEventCircles(currDateDayjs) {

        const formattedDate = currDateDayjs.format('DD-MM-YYYY');
        let daySlotsArr: any = [];

        if (dateToFilteredSlotsArrMap?.size) {
            daySlotsArr = dateToFilteredSlotsArrMap?.get(formattedDate) || [];
        }

        return (
            <>
                {
                    daySlotsArr.map((slotsObj, index) => {
                        return (index <= 2) ? (
                            <div
                                style={{ backgroundColor: slotsObj?.color?.darkColor || '' }}
                                className='event-circle'
                            />
                        )
                            : null
                    })
                }
            </>
        )
    }

    function customDayRendering(dayProps) {

        const { day, ...buttonProps } = dayProps;
        const currDateDayjs = dayjs.tz(day.date);

        return (
            <button
                {...buttonProps}
                className={`${buttonProps.className} calender-date-container`}
            >
                <div className='mobile-calendar-date'>
                    {currDateDayjs.format('D')}
                </div>
                <div className='calender-date-inline-metadata'>
                    {getEventCircles(currDateDayjs)}
                </div>
            </button>
        );
    }

    return (
        <div>
            <div className='month-date-picking'>
                <DatePicker
                    className='calendar-custom-date-picker'
                    format="MMMM, YYYY"
                    value={dayjs.tz(monthInDisplay)}
                    onChange={onMonthYearChange}
                    picker="month"
                    allowClear={false}
                    suffixIcon={
                        <img
                            src='/images/downArrow.png'
                            className='calendar-custom-down-arrow'
                        />
                    }
                    inputReadOnly={true}
                />
            </div>

            <div>
                <DayPicker
                    timeZone={props.firebase.schoolConfig.timezone}
                    mode="single"
                    onSelect={onDayPickerDateSelection}
                    onMonthChange={(monthYearObj) => onMonthYearChange(dayjs.tz(monthYearObj))}
                    month={Helper.convertToDate(monthInDisplay, props.firebase)}
                    selected={Helper.convertToDate(calendarSelectedDate, props.firebase)}
                    className='custom-mobile-calendar'
                    components={{
                        DayButton: customDayRendering
                    }}
                />
            </div>
        </div>
    )
}

export default MobileCalendar;