'use client';
import { Row, Col } from "antd";
import "./style.css";
import formatMsg from "../../util/formatMsg";


const AdditionalFieldCard: React.FC<any> = (props) => {

    const { title, fields, action, deleteAction, firebase, entity, view } = props;

    function isEntity(entityOptionsArr: string[]) {
        return entityOptionsArr.some((entityOpt) => entityOpt.toLowerCase() === entity.toLowerCase());
    }

    function isView(viewOptionsArr: string[]) {
        return viewOptionsArr.some((viewOpt) => viewOpt.toLowerCase() === view.toLowerCase());
    }

    return (
        <div className="main-card-acc">
            <div
                className="card-header-acc"
                style={{
                    position: 'relative',
                    borderBottom: isView(['mobileCard']) && isEntity(['accountOrUser'])
                        ? "0.5px solid #E0E0E0"
                        : undefined,
                    paddingBottom: isView(['mobileCard']) && isEntity(['accountOrUser'])
                        ? '10px'
                        : undefined
                }}
            >
                <div className="global-card-header">{title}</div>

                <div className="actionStyle">
                    {
                        action &&
                        <img 
                            src="/images/pencil.png" 
                            className="editIconStyleAbsolute"
                            onClick={() => action()}
                        />
                    }
                    {
                        deleteAction &&
                        <img
                            src="/images/delete.png"
                            className="deleteIconStyle"
                            onClick={() => deleteAction()}
                        />
                    }
                </div>
            </div>

            <Row
                style={{ padding: 20 }}
                gutter={
                    isView(['mobileCard']) && isEntity(['accountOrUser'])
                        ? [15, 15]
                        : [0, 0]
                }
            >
                {
                    fields?.map((item) => {
                        item.value = Array.isArray(item.value) ? item.value.join(', ') : item.value;
                        return (
                            <Col lg={5} md={12} sm={12} xs={24} className="field-style-acc">
                                <div className="card-label-style-acc">{formatMsg(item.key)}</div>
                                <div className="card-value-style-acc">{item.value || "-"}</div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default AdditionalFieldCard;