"use client";

import "./style.css";
import formatMsg from "../../util/formatMsg";
import { Form } from "antd";
import { ModalWrapper } from "../wrapperComponents/page";
import FormWrapper from "../formWrapper/page";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { notification } from "../../util/notification";
import { createUser, resetOperationType, deleteUser, linkNewUserToLinkedAccounts, deleteAccountUserLink, delinkAccountFromUser, linkAccountToUser } from '../../redux/users/reducer';
import Helper from "../../util/helper";
import LinkUserToOtherLinkedAccounts from "./LinkUserToOtherLinkedAccounts";
import { useForm } from "antd/es/form/Form";
import AccountUsersLaptopView from "./AccountUsersLaptopView/page";
import AccountUsersMobileView from "./AccountUsersMobileView/page";
import { getItem } from "../../libs/encryptedStorage";
import colors from "../../libs/colorFactory";


const AccountUsers: React.FC<any> = (props) => {

    const { accountDetails, firebase } = props;
    const [addNewVisible, setAddnewVisible] = useState<any>(false);
    const [editableRecords, setEditableRecords] = useState<any>();
    const [currAccountObj, setCurrAccountObj] = useState<any>();
    const [isLinkUserModalVisible, setIsLinkUserModalVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [form2] = useForm();
    const dispatch = useDispatch();

    const { userReducer, settingReducer } = Helper.getSelectorState(["userReducer", "settingReducer"]);

    useEffect(() => {
        accountDetails && setCurrAccountObj({
            id: accountDetails.id,
            name: accountDetails.name,
            email: accountDetails.email,
            phoneNumber: accountDetails.phoneNumber,
            profileImageUrl: accountDetails.profileImageUrl
        })
    }, [accountDetails])

    function handleCancel() {
        form.resetFields();
        setAddnewVisible(undefined);
        setEditableRecords(undefined);
    }

    useEffect(() => {
        const { operationType } = userReducer;
        if (operationType) {
            switch (operationType) {
                case "USER_CREATED":
                    handleCancel();
                    if (
                        accountDetails &&
                        accountDetails.linkedAccounts &&
                        accountDetails.linkedAccounts.length > 0
                    ) {
                        setIsLinkUserModalVisible(true);
                    }
                    dispatch(resetOperationType(null));
                    break;
                case "USER_UPDATED":
                    handleCancel();
                    dispatch(resetOperationType(null));
                    break;
            }
        }
    }, [userReducer.operationType])

    function handleOk() {

        form.validateFields()
            .then((values) => {
                values.status = "ACTIVE";
                let acountId = Helper.getUrlParam("id");
                values.accountId = acountId;
                values.childConfig = settingReducer.customFields ?
                    settingReducer.customFields.map((additionalFieldObj) => {
                        const tempAdditionalFieldObj = { ...additionalFieldObj };
                        tempAdditionalFieldObj.value = values[additionalFieldObj.name] ? values[additionalFieldObj.name] : null;
                        delete values[additionalFieldObj.name];
                        return tempAdditionalFieldObj;
                    })
                    :
                    null;

                values.id = editableRecords && editableRecords.id;
                if (!values.profileImageUrl && editableRecords) {
                    values.profileImageUrl = editableRecords.profileImageUrl
                }
                dispatch(createUser({ reqBody: values, firebase: firebase }));
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }


    const userFields = [
        [{
            label: formatMsg("addProfilePhoto"),
            type: "media",
            name: "profileImageUrl",
            placeHolder: formatMsg("addProfilePhoto"),
            warningMessage: "Please select profile photo!",
            inputType: "picture",
            values: form.getFieldValue("profileImageUrl")
        }],
        [{
            label: formatMsg("name"),
            type: "input",
            name: "name",
            placeHolder: formatMsg("name"),
            required: true,
            warningMessage: "Please input name!",
            inputType: "string",
        }]
    ];

    function handleLinkUserOk() {

        form2.validateFields()
            .then((values) => {
                values.userId = userReducer.lastCreatedUser.id;
                values.introducedByAccountId = Helper.getUrlParam('id') ? Helper.getUrlParam('id') : '';

                dispatch(linkNewUserToLinkedAccounts({ firebase, reqObj: { ...values } }));

                form2.resetFields();
                setIsLinkUserModalVisible(false);
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }

    function handleLinkUserCancel() {
        form2.resetFields();
        setIsLinkUserModalVisible(false);
    }

    const linkedAccountsList = [
        ...(accountDetails?.linkedAccounts || []),
        { ...(currAccountObj || {}) }
    ];

    const currentDetailedViewOfAccountId = accountDetails?.id;

    function addNewUserForAccount() {
        setAddnewVisible(true);
    }

    function editAction(user) {
        setAddnewVisible(true);
        let editFormObj = Helper.getChildConfigTransformedForForm(user);
        form.setFieldsValue({ ...editFormObj });
        setEditableRecords({ ...user })
    }

    function deleteAction(user) {
        dispatch(deleteUser({ firebase, id: user.id }))
    }


    function getPopContent(accountObj: Record<string, any>, user: any, linkStatus: string): ReactElement {

        const options: any[] = [];

        /*
        options.push({
            optionIconSrc: '/images/newDeleteIcon.png',
            iconClassName: 'delete-img-style',
            iconContainerClassName: 'select-item-style',
            optionName: 'delete',
            optionAction: () => {
                const id = Helper.getUrlParam('id');
                if (id) {
                    dispatch(deleteAccountUserLink({
                        firebase,
                        reqObj: {
                            userId: user.id,
                            accountId: accountObj.id
                        }
                    })); 
                }
            }
        });
        */

        const lowercasedLinkStatus = linkStatus?.toLowerCase();

        if (lowercasedLinkStatus === 'linked') {
            options.push({
                optionIconSrc: '/images/unlink.png',
                iconClassName: 'link-img-style',
                iconContainerClassName: 'select-item-style',
                optionName: 'delink',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {

                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        confirmAlertObject.yes = formatMsg("confirm");
                        confirmAlertObject.no = formatMsg("cancel");

                        confirmAlertObject.title = formatMsg('delinkAccountFromUser');
                        confirmAlertObject.message = (
                            <div className='flex-wrap'>
                                {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delink') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('from') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
                            </div>
                        )

                        confirmAlertObject.yesOnClick = () => {
                            dispatch(delinkAccountFromUser({
                                firebase,
                                reqObj: {
                                    userId: user.id,
                                    accountId: accountObj.id
                                }
                            }));
                        };

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }
        else if (lowercasedLinkStatus === 'delinked') {
            options.push({
                optionIconSrc: '/images/link.png',
                iconClassName: 'unlink-img-style',
                iconContainerClassName: 'select-item-style',
                optionName: 'link',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {

                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        confirmAlertObject.yes = formatMsg("confirm");
                        confirmAlertObject.no = formatMsg("cancel");

                        confirmAlertObject.title = formatMsg('linkAccountWithUser');
                        confirmAlertObject.message = (
                            <div className="flex-wrapp">
                                {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.link') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('small.with') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
                            </div>
                        )

                        confirmAlertObject.yesOnClick = () => {
                            dispatch(linkAccountToUser({
                                firebase,
                                reqObj: {
                                    userId: user.id,
                                    accountId: accountObj.id
                                }
                            }));
                        };

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }

        return (
            <div>
                {
                    options.map((optionObj, index) => {
                        return (
                            <div
                                onClick={optionObj.optionAction}
                                className={`options-list ${(index !== (options.length - 1)) ? 'border-bottom-gray' : ''}`}
                            >
                                <div
                                    className={optionObj.iconContainerClassName}
                                >
                                    <img
                                        className={optionObj.iconClassName}
                                        src={optionObj.optionIconSrc}
                                    />
                                </div>
                                <div className='global-regular-font'>
                                    {formatMsg(optionObj.optionName)}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }


    function getHeaderRedirectUrl(user): string {

        const loggedInUserType: string = Helper.getLoggedInUserType(firebase);
        let redirectUrl: string = '/dashboard';

        switch (loggedInUserType) {
            case 'TEACHER':
                redirectUrl += "/users/userDetailView?";
                break;
            case 'ACCOUNT':
                redirectUrl += "/userDetailView?centerId=" + (getItem("centerId") || Helper.getUrlParam("centerId")) + "&";
                break;
        }

        redirectUrl += ("id=" + user.id + "&entityType=user");

        return redirectUrl;
    }

    const canEditUserInCurrentAccount = (user) => {
        const hasLinkedAccounts = user?.linkedAccountsIdAndStatusObjArr?.length > 0;

        const hasPrimaryAccountAccess = hasLinkedAccounts &&
            user.linkedAccountsIdAndStatusObjArr.some(
                (obj) =>
                    obj.accountType?.toLowerCase() === 'primary_account' &&
                    obj.accountId === currentDetailedViewOfAccountId
            );

        return hasPrimaryAccountAccess;
    };

    const deleteUserConfirmation = (user) => {

        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject();

        confirmAlertObject.yes = formatMsg("confirm");
        confirmAlertObject.no = formatMsg("cancel");

        confirmAlertObject.title = formatMsg('deleteUser');
        confirmAlertObject.message = (
            <div className="flex-wrapp">
                {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delete') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
            </div>
        );

        confirmAlertObject.yesOnClick = () => {
            deleteAction(user)
        };

        Helper.confirmAlert(confirmAlertObject);
    }

    const getUserCardLabel = (item, additionalFieldMap) => {
        return (
            additionalFieldMap && additionalFieldMap.has(item)
                ? additionalFieldMap.get(item).name
                : formatMsg(item)
        );
    }

    const getUserCardValue = (item, user, additionFieldMap) => {
        return (
            (item === "status")
                ? formatMsg(user[item])
                : additionFieldMap && additionFieldMap.has(item)
                    ? additionFieldMap.get(item).value || '-'
                    : user[item] || "-"
        )
    }

    const getAccountName = (accountObj) => accountObj.name;
    const getAccountEmail = (accountObj) => accountObj.email;
    const getAccountPhoneNumber = (accountObj) => accountObj.phoneNumber;
    const getAccountLinkStatus = (linkStatus) => linkStatus && formatMsg(linkStatus);
    const showPopoverActions = (accountType) => accountType?.toLowerCase() !== 'primary_account';

    const isPrimaryAccount = (accountType) => {
        return accountType ?
            <div
                className="global-subheader-regular"
                style={{ color: colors.color_purple, fontSize: 'var(--font-size-14)' }}
            >
                {formatMsg(accountType)}
            </div>
            :
            null
    }

    if (accountDetails && Object.keys(accountDetails).length) {
        return (
            <>
                <div className='laptop-view'>
                    <AccountUsersLaptopView
                        firebase={firebase}
                        accountDetails={accountDetails}
                        editAction={editAction}
                        deleteAction={deleteAction}
                        addNewUserForAccount={addNewUserForAccount}
                        linkedAccountsList={linkedAccountsList}
                        currentDetailedViewOfAccountId={currentDetailedViewOfAccountId}
                        getPopContent={getPopContent}
                        getHeaderRedirectUrl={getHeaderRedirectUrl}
                        canEditUserInCurrentAccount={canEditUserInCurrentAccount}
                        deleteUserConfirmation={deleteUserConfirmation}
                        getUserCardLabel={getUserCardLabel}
                        getUserCardValue={getUserCardValue}
                        getAccountName={getAccountName}
                        getAccountEmail={getAccountEmail}
                        getAccountPhoneNumber={getAccountPhoneNumber}
                        getAccountLinkStatus={getAccountLinkStatus}
                        showPopoverActions={showPopoverActions}
                        isPrimaryAccount={isPrimaryAccount}
                    />
                </div>

                <div className='mobile-view'>
                    <AccountUsersMobileView
                        firebase={firebase}
                        accountDetails={accountDetails}
                        editAction={editAction}
                        deleteAction={deleteAction}
                        addNewUserForAccount={addNewUserForAccount}
                        linkedAccountsList={linkedAccountsList}
                        currentDetailedViewOfAccountId={currentDetailedViewOfAccountId}
                        getPopContent={getPopContent}
                        getHeaderRedirectUrl={getHeaderRedirectUrl}
                        canEditUserInCurrentAccount={canEditUserInCurrentAccount}
                        deleteUserConfirmation={deleteUserConfirmation}
                        getUserCardLabel={getUserCardLabel}
                        getUserCardValue={getUserCardValue}
                        getAccountName={getAccountName}
                        getAccountEmail={getAccountEmail}
                        getAccountPhoneNumber={getAccountPhoneNumber}
                        getAccountLinkStatus={getAccountLinkStatus}
                        showPopoverActions={showPopoverActions}
                        isPrimaryAccount={isPrimaryAccount}
                    />
                </div>

                <ModalWrapper
                    open={addNewVisible}
                    title={formatMsg("userDetails")}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={formatMsg("submit")}
                    maskClosable={false}
                    confirmLoading={userReducer.isLoading}
                >
                    <Form
                        form={form}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                    >
                        <FormWrapper
                            form={form}
                            formFields={[...userFields, ...Helper.getAdditionalFormFields(settingReducer?.customFields)]}
                            editableRecords={editableRecords}
                            setEditableRecords={setEditableRecords}
                        />
                    </Form>
                </ModalWrapper>

                <LinkUserToOtherLinkedAccounts
                    form={form2}
                    isLoading={userReducer.isLoading}
                    isLinkUserModalVisible={isLinkUserModalVisible}
                    user={userReducer.lastCreatedUser || {}}
                    linkedAccountsList={
                        (accountDetails && accountDetails.linkedAccounts && accountDetails.linkedAccounts.length > 0)
                            ? accountDetails.linkedAccounts
                            : []
                    }
                    handleLinkUserOk={handleLinkUserOk}
                    handleLinkUserCancel={handleLinkUserCancel}
                />
            </>
        )
    }
}

export default AccountUsers;