'use client';
import { Col, Popover, Row, Tooltip } from "antd";
import React from "react";
import "./style.css";
import dayjs from "dayjs";
import formatMsg from "../../../util/formatMsg";
import Helper from "../../../util/helper";

const CalendarListDay = (props) => {

  function getListBody(item, ind) {
    return <Row style={{
      ...styles.spacedInline,
      borderBottom: ind !== props.dayData.length - 1 ? "0.5px solid #D0D0D0" : undefined,
      cursor: "pointer",
      padding: "15px 0px",
    }}
      onClick={() => props.onTourClick(item)}
    >
      <div style={{ minWidth: "150px" }}>
        <p style={styles.startEndTime}>{dayjs.tz(item.startTime).format("hh:mm a")} -</p>
        <p style={styles.startEndTime}>{dayjs.tz(item.endTime).format("hh:mm a")}</p>
      </div>
      <div style={{ width: "5px", height: "80px", backgroundColor: item.color?.darkColor || "#FF8FAA" }}></div>
      <div style={{ ...styles.spacedInline, width: "calc(100% - 170px)" }}>
        <div style={{ marginLeft: 25, marginRight: 0, width: "100%" }}>
          <div style={{ ...styles.clientName, ...styles.spacedInline }}>
            <div>
              <div className="global-table-other-text">{item.packageName || ""}</div>
              <div className="global-table-other-text">{item.batchName}</div>
            </div>
          </div>
          {props.packageMap.get(item.packageId)?.staffIds ? <div style={styles.aptWith}>
            {formatMsg("with") + " "}
            {props.packageMap.get(item.packageId)?.staffIds?.length > 3
              ? <Tooltip
                title={
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                    {props.packageMap.get(item.packageId)?.staffIds?.map((staffId, index) => {
                      return <><span>{Helper.getStaffObj(staffId).name}</span><span>{props.packageMap.get(item.packageId)?.staffIds?.length === index + 1 ? "" : "|"}</span></>;
                    })}
                  </div >
                }
              > <div style={styles.aptWith}>
                  {[...props.packageMap.get(item.packageId)?.staffIds || []].splice(0, 3).map((staffId, index) => {
                    return <><span style={styles.phoneStyle2}>{Helper.getStaffObj(staffId).name}</span><span>{index === 2 ? "" : "|"}</span></>;
                  })}
                  <span style={styles.phoneStyle2}> {"+" + (props.packageMap.get(item.packageId)?.staffIds?.length - 3) + " " + formatMsg("dashboard.more")} </span>
                </div>
              </Tooltip>
              : props.packageMap.get(item.packageId)?.staffIds?.map((staffId, index) => {
                return <><span style={styles.phoneStyle2}>{Helper.getStaffObj(staffId)?.name}</span><span>{props.packageMap.get(item.packageId)?.staffIds?.length === index + 1 ? "" : "|"} </span></>;
              })}
          </div>
            : <div style={styles.aptWith}>{formatMsg("noInstructor")}</div>}
        </div>
      </div>
    </Row>
  }

  return (
    <Row className="tour-list-card" style={{ backgroundColor: "white", padding: "0px 20px", borderBottom: "0.5px solid #D0D0D0" }}>
      <Col sm={24} md={6} lg={3}>
        {props.dayData && props.dayData.length > 0 &&
          <div style={styles.dateCard}>
            <p style={styles.dateCardMonth}>{dayjs.tz(props.dayData[0].date).format('MMM')}</p>
            <p style={styles.dateCardDate}>{dayjs.tz(props.dayData[0].date).format('DD')}</p>
            <p style={styles.dateCardYear}>{dayjs.tz(props.dayData[0].date).format('YYYY')}</p>
          </div>}
      </Col>
      <Col sm={24} md={18} lg={21} style={{ minWidth: 500 }}>
        {
          props.dayData && props.dayData.length > 0 && props.dayData.map((item, ind) => {
            return (
              Helper.isParent(props.firebase) ?
                <Popover
                  title={item.package.name}
                  content={
                    <>
                      <img
                        src={item.activity.coverPhoto}
                        style={{ height: 128, width: 268, objectFit: "cover", borderRadius: 4 }}
                      />
                      <p style={{ marginTop: 10, marginBottom: 10 }} className='global-card-header'>{item.activity.name}</p>
                      <div className='student-calendar-inline'>
                        <img src='/images/calendar.png' style={{ height: 15, width: 15 }} />
                        <p>{dayjs.tz(item.date).format("dddd, DD MMMM YYYY")}</p>
                      </div>
                      <div className='student-calendar-inline'>
                        <img src='/images/clock.png' style={{ height: 15, width: 15 }} />
                        <p>{dayjs.tz(item.startTime).format("hh:mm a") + " - " + dayjs.tz(item.endTime).format("hh:mm a") + " (" + dayjs.tz(item.endTime).diff(item.startTime, "minutes") + " mins)"}</p>
                      </div>
                      <div className='student-calendar-inline'>
                        <img src='/images/board.png' style={{ height: 14, width: 15 }} />
                        <p style={{ width: 250, whiteSpace: "pre-wrap" }}>{item.instructors?.map(s => s.name).join(", ")}</p>
                      </div>
                    </>
                  }
                  trigger={"click"}
                // placement='right'
                // open={(item.packageId + item.startTime) === props.selectedSchedule}
                // onOpenChange={v => { if (!v) props.setSelectedSchedule(undefined) }}
                >
                  {getListBody(item, ind)}
                </Popover> :
                getListBody(item, ind)
            )
          })
        }
      </Col>
    </Row>
  )
}
export default CalendarListDay;

const styles: any = {
  spacedInline: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  dateCard: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    borderRadius: "10px",
    boxShadow: "0px 4px 25px 0px #0000000F",
    overflow: "hidden",
    fontWeight: "bold",
    margin: "15px 0px 15px 0px",
    border: "0.2px solid #D0D0D0",
  },
  dateCardMonth: {
    textTransform: "uppercase",
    backgroundColor: "#D9F8FF",
    color: "#00ADD1",
    padding: "5px 0px",
    fontSize: "var(--font-size-16)",
    margin: "0px 0px 0px 0px"
  },
  dateCardDate: {
    color: "#5B5B5B",
    fontSize: "var(--font-size-22)",
    margin: 0,
  },
  dateCardYear: {
    color: "#8D8D8D",
    fontWeight: "500",
    paddingBottom: "8px",
    margin: 0,
    fontSize: "var(--font-size-18)",
  },
  startEndTime: {
    margin: "0px 0px 0px 30px",
    fontSize: "var(--font-size-18)",
    color: "#5B5B5B",
  },
  clientName: {
    fontSize: "var(--font-size-18)",
    margin: "0px 0px 6px 0px",
    fontWeight: "500",
    color: "#333333",
  },
  aptWith: {
    fontSize: "var(--font-size-14)",
    margin: "0px 0px 0px 0px",
    fontWeight: "normal",
    color: "#5B5B5B",
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    gap: 5
  },
  iconStyle: {
    height: "15px",
    width: "15px",
    objectFit: "contain",
    marginRight: "10px",
  },
  phoneStyle2: {
    fontSize: "var(--font-size-14)",
    margin: "0px 0px 0px 0px",
    color: "#5B5B5B",
    fontWeight: "500",
    display: "flex"
  },
}


