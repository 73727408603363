'use client'
import React from 'react'
import "./style.css"
import PrefixIconButton from '../buttons/prefixIconButton';
import formatMsg from '../../util/formatMsg';
import colors from '../../libs/colorFactory';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Helper } from '../..';
import { useRouter } from 'next/navigation';
import dayjs from 'dayjs';

const page = ({ showBookingConfirmation, setShowBookingConfirmation, bookingDetails, firebase }) => {
    const router = useRouter();
    const closePopup = () => {
        setShowBookingConfirmation(false);
    };
    function isLoggedIn() {
        return firebase?.user?.id;
    }
    const isTeacher = isLoggedIn() && !Helper.isAccount(firebase)
    return (
        <div>
            <>
                {showBookingConfirmation && (
                    <div>
                        <div className="booking-confirmation-overlay">
                            <div className="booking-confirmation-popup">
                                <div className="confirm-booking-container">
                                    <div className="confirm-booking-left-container">
                                        <div>
                                            <img className="confirm-booking-icon" src="/images/confirm-circle.png" alt="confirm-circle" />
                                        </div>
                                        <div className="confirm-booking-title">{bookingDetails?.approvalRequired ? formatMsg("confirmPopup.bookingRequested") : formatMsg("bookingConfirmed")}</div>
                                        <div className="confirm-booking-left-subtitle">{formatMsg("confirmationEmail")}</div>
                                        {isLoggedIn() &&
                                            <PrefixIconButton
                                                type={"primary"}
                                                title={formatMsg("viewBooking")}
                                                borderRadius={"8px"}
                                                padding={"4px 20px"}
                                                maxWidth={"201px"}
                                                minWidth={"126px"}
                                                minHeight={"32px"}
                                                onClick={() => {
                                                    isTeacher ? router.push("/dashboard/booking") : router.push(bookingDetails?.isDeferred ? "/dashboard/myMemberships" : "/dashboard/myBookings")
                                                }}
                                            />}
                                        <PrefixIconButton
                                            title={formatMsg("goHome")}
                                            borderRadius={"8px"}
                                            border={true}
                                            padding={"4px 20px"}
                                            maxWidth={"201px"}
                                            minWidth={"126px"}
                                            minHeight={"32px"}
                                            onClick={() => {
                                                isTeacher ? router.push("/dashboard") : router.push("/dashboard")
                                            }}
                                        />
                                    </div>

                                    <div className="confirm-booking-right-container">
                                        <Button
                                            type="text"
                                            icon={<CloseOutlined />}
                                            className="confirm-booking-close-btn"
                                            onClick={closePopup}
                                        />
                                        <div className="confirm-booking-right-subcontainer">
                                            <div className="confirm-booking-right-title">{formatMsg("bookingDetails")}</div>
                                            <div className="confirm-booking-inf-container">
                                                <div className="booking-inf-container">
                                                    <div className="booking-detail-title">{formatMsg("centerDetails")}</div>
                                                    {bookingDetails?.centerName && <div className="booking-detail-inf">{formatMsg("centerName") + ": " + bookingDetails.centerName}</div>}
                                                    {bookingDetails?.batchName && <div className="booking-detail-inf">{formatMsg("batch") + ": " + bookingDetails.batchName}</div>}
                                                </div>

                                                <div className="booking-inf-container confirm-booking-margin-top">
                                                    <div className="booking-detail-title">{formatMsg("participantDetails")}</div>
                                                    {bookingDetails?.profileDetails?.map((profile, index) => (
                                                        <div key={profile?.id || index}>
                                                            {profile?.name && <div className="booking-detail-inf">{formatMsg("name") + ": " + profile.name}</div>}
                                                            {/* <div className="participant-inf">
                                                            {profile?.age && <div className="booking-detail-inf">{formatMsg("age") + ": " + profile.age}</div>}
                                                            {profile?.gender && <div className="booking-detail-inf">{formatMsg("gender") + ": " + profile.gender}</div>}
                                                        </div> */}
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="booking-inf-container confirm-booking-margin-top">
                                                    <div className="booking-detail-title">{formatMsg("contactInformation")}</div>
                                                    {bookingDetails?.accountDetail?.name && <div className="booking-detail-inf">
                                                        {formatMsg("name") + ": " + bookingDetails.accountDetail.name}
                                                    </div>}
                                                    {bookingDetails?.accountDetail?.email && <div className="booking-detail-inf">
                                                        {formatMsg("email") + ": " + bookingDetails.accountDetail.email}
                                                    </div>}
                                                    {bookingDetails?.accountDetail?.phoneNumber && <div className="booking-detail-inf">
                                                        {formatMsg("contactNumber") + ": " + bookingDetails.accountDetail.phoneNumber}
                                                    </div>}
                                                </div>

                                                <div className="booking-inf-container confirm-booking-margin-top">
                                                    {bookingDetails?.additionalFields && bookingDetails?.additionalFields.length > 0 && <div className="booking-detail-title">{bookingDetails?.additionalFieldHeading ? bookingDetails?.additionalFieldHeading : formatMsg("additionalFields")}</div>}
                                                    {bookingDetails?.additionalFields?.map((field, index) =>
                                                        field.value && (
                                                            <div className="booking-detail-inf" key={index}>
                                                                {field.name + ": " + (field.dataType === "date" ? dayjs.tz(field.value).format(Helper.dateFormat(firebase)) : field.value)}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    )
}

export default page
