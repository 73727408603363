"use client";
const actions = {
    SIGN_IN: "login/signIn",
    INIT_LOGIN: "login/initLogin",
    SIGN_SUCCESS: "login/loginSuccess",
    SIGN_OUT_SUCCESS: "login/logoutSuccess",
    SIGN_OUT: "login/logout",
    UPDATE_CHANNEL: "login/updateChannel",
    CLOSE_CHANNELS: "login/closeChannels",
    UPDATE_FIREBASE_OBJ: "login/updateFirebaseObj",
    VALIDATE_USER_LOGIN: "login/validateUserLogin",
    VERIFY_PHONE_NUMBER: "login/verifyPhoneNumber",
    VERIFY_PHONE_SUCCESS: "login/verifyPhoneSuccess",
    LOGIN_WITH_PHONE: "login/loginWithPhone",
    LOGIN_WITH_PHONE_SUCCESS: "login/loginWithPhoneSuccess",
    SIGN_IN_WITH_GOOGLE: "login/signInWithGoogle",
    SIGN_UP_ACCOUNT: "login/signUpAccount",
    SIGN_UP_SUCCESS: "login/signUpSuccess",
    ATTACHHMENT_UPLOAD_PROGRESS: "login/attachmentUploadProgress",
    SLICE_OPERATION: "login/sagaSuccess",
    PARENT_SIGN_SUCCESS: "login/parentSignSuccess",
    NEW_USER_SUCCESS: "login/setNewUsers",
    CONTINUE_WITH_NEW_USER: "login/continueWithNewUser",
    LOGIN_SAGA_SUCCESS: "login/sliceOperation",
    FETCH_NEW_USERS: "login/fetchNewUsers",
    AUTH_SUCCESS: "login/authenticationSucess",
    LOGIN_SAGA_FAILED: "login/sagaFailed",
    SET_FIREBASE_OBJ_SCHOOL_CONFIG: "login/setFirebaseObjSchoolConfig",
    GET_SCHOOL_CONFIG: "login/GET_SCHOOL_CONFIG",


    // package actions
    SAVE_DISCOUNT: "package/saveDiscount",
    SAGA_SUCCESS: "package/sagaSuccess",
    SAGA_FAILED: "package/sagaFailed",
    GET_ALL_DISCOUNT: "package/getAllDiscount",
    DELETE_DISCOUNT: "package/deleteDiscount",
    CREATE_PACKAGE: "package/createPackage",
    FETCH_PACKAGES: "package/fetchPackage",
    DELETE_PACKAGE: "package/deletePackage",
    GET_PACKAGE_BY_ID: "package/getPackageById",
    GET_PKG_TYPE: "package/getPackageType",
    GET_AGE_RANGE: "package/getAgeRange",
    GET_SKILLS: "package/getSkills",
    GET_ALL_BOOKING: "package/getAllBooking",
    GET_BOOKING_STATS: "package/getBookingStats",
    GET_RESCHEDULE_DATA: "package/getRescheduleData",
    CHANGE_BOOKING_STATUS: "package/changeBookingStatus",
    GET_LISTING: "package/getListing",
    GET_LISTING_RAW_DATA: "package/getListingRawData",
    ACTIVE_AND_INACTIVE_PACKAGE: "package/activeInactivePackage",
    GET_ALL_CLASSROOMS: "package/getAllClassrooms",
    PACKAGE_UPDATE_GUEST: "package/updateGuest",
    GET_ID_NAME_LIST_OF_SCHEDULES: "package/getIdNameListOfSchedules",
    SAVE_TAX: "package/saveTax",
    DELETE_TAX: "package/deleteTax",
    GET_ALL_TAX: "package/getAllTax",
    GET_ALL_PRICE_PACK: "package/getAllPricePack",
    SAVE_PRICE_PACK: "package/savePricePack",
    DELETE_PRICING_PACK: "package/deletePricingPack",
    GET_ALL_REGISTRATION_FEE: "package/getAllRegistrationFee",
    SAVE_REGISTRATION_FEE: "package/saveRegistrationFee",
    DELETE_REGISTRATION_FEE: "package/deleteRegistrationFee",
    GET_ALL_ADDITIONAL_CHARGE: "package/getAllAdditionalCharge",
    SAVE_ADDITIONAL_CHARGE: "package/saveAdditionalCharge",
    DELETE_ADDITIONAL_CHARGE: "package/deleteAdditionalCharge",
    GET_ALL_PRICE_PACK_BY_CHANNEL: "package/getAllPricePackByChannel",
    FETCH_PACKAGE_BOOKINGS: "package/fetchPackageBookings",
    FETCH_PRICING_PACKS_FOR_ALL_PACKAGES: "package/fetchPricingPacksForAllPackages",
    FETCH_PRICING_PACKS_BY_PACKAGE: "package/fetchPricingPackByPackage",
    FETCH_UPCOMING_CLASSES: "package/fetchUpcomingClasses",
    FETCH_PACKAGE_ACTIVITIES: "package/fetchPackageActivites",
    FETCH_PACKAGE_SCHEDULE: "package/fetchPackageSchedule",
    GET_CLASS_PACK_BY_PACKAGE_ID: "package/getClassPackByPackageId",
    EDIT_PACKAGE_DETAILS: "package/editPackageDetails",
    GET_PRICE_PACK_DATA_FOR_EDIT: "package/getPricePackDataForEdit",
    GET_PACKAGE_FOR_EDIT: "package/getPackageForEdit",
    GET_CLASSLEVEL: "package/getClassLevel",
    GET_LANGUAGE: "package/getLanguage",
    GET_ALL_PACKAGE_NAMES: "package/getAllPackageNames",
    GET_ALLPRICEPACKS_FOR_PACKAGE: "package/getAllPricepacksForPackage",
    DUPLICATE_PACKAGE: "package/duplicatePackage",
    GET_AVAILABLE_BATCH_SLOTS: "package/getAvailableBatchSlots",
    ENHANCE_WITH_AI: "package/enhanceWithAi",
    GET_PACKAGE_LIST_DROPDOWN: "package/getPackageListDropdown",
    CREATE_SHORT_URL: "package/createShortUrl",

    // activity actions
    ACTIVITY_CREATE_ACTIVITY: "bookingActivity/createBookingActivity",
    CREATE_BOOKING_ACTIVITY_SUCCESS: "bookingActivity/createBookingActivitySuccess",
    REQUEST_FAILED: "bookingActivity/requestFailed",
    FETCH_ACTIVITY: "bookingActivity/fetchActivity",
    ACTIVITY_REQUEST_SUCCESS: "bookingActivity/requestSuccess",
    GET_ACTIVITY_BY_ID: "bookingActivity/getActivityById",
    DELETE_ACTIVITY: "bookingActivity/deleteActivity",
    FETCH_FACILITIES: "bookingActivity/fetchFacilities",
    GET_ALL_ACTIVITY_NAMES: "bookingActivity/getAllActivityNames",
    DUPLICATE_ACTIVITY: "bookingActivity/duplicateActivity",











    // student actions
    UPDATE_STUDENT: "students/updateStudent",
    STUDENT_REQ_SUCCESS: "students/requestSuccess",
    STUDENT_REQUEST_FAILED: "students/requestFailed",
    ADD_PARENT: "students/addParent",
    FETCH_PARENTS: "students/fetchParents",
    DELETE_PARENT: "students/deleteParent",
    FETCH_STUDENT_PACKAGES: "students/fetchStudentPackages",
    CONFIRM_BOOKING: "students/confirmBooking",
    CANCEL_BOOKING: "students/cancelBooking",
    FETCH_STUDENT_SCHEDULES: "students/fetchStudentSchedules",
    GET_ALL_STUDENT: "students/getAllStudent",
    GET_ALL_STUDENT_PACKAGES: "students/getAllStudentPackages",
    // STUDENT_SAGA_FAILED: "students/studentSagaFailed",
    // STUDENT_SAGA_SUCCESS: "students/studentSagaSuccess",
    STUDENT_RESET_OPERATION_TYPE: "students/studdentResetOperationType",
    DELETE_STUDENT: "students/deleteStudent",
    GET_STUENT_DETAIL_BY_ID: "students/getStudentDetailById",
    SEND_INVITE_TO_PARENTS: "students/sendInviteToParent",
    ADD_SIBLING: "students/addSibling",

    //staff actions
    UPDATE_STAFF: "staffs/updateStaff",
    STAFF_REQ_SUCCESS: "staffs/requestSuccess",
    STAFF_REQUEST_FAILED: "staffs/requestFailed",
    GET_ALL_STAFF: "staffs/getAllStaff",
    FETCH_STAFF_SCHEDULES: "staffs/fetchStaffSchedules",
    // STAFF_SAGA_FAILED: "staffs/satffSagaFailed",
    // STAFF_SAGA_SUCCESS: "staffs/staffSagaSuccess",
    STAFF_PACKAGES_AND_ACTIVITIES: "staffs/getAllStaffPackagesAndActivities",
    DELETE_STAFF: "staffs/deleteStaff",
    GET_ALL_STAFF_PACKAGES: "staffs/getAllPackages",
    SEND_INVITE_TO_STAFFS: "staffs/sendInviteToTeachers",
    SET_ACTIVE_INACTIVE_STAFF: "staffs/setActiveInactiveStaff",


    //invoice actions
    INVOICE_SAGA_SUCCESS: "invoice/sagaSuccess",
    INVOICE_SAGA_FAILED: "invoice/sagaFailed",
    GET_INVOICE_LIST: "invoice/getInvoiceList",
    GET_STUDENT_INVOICE_LIST: "invoice/getStudentInvoiceList",
    GET_STUDENT_INVOICE_LIST_BY_STUDENT_ID: "invoice/getStudentInvoiceListFromStudentId",
    GET_TRANSACTION_LIST: "invoice/getTransactions",
    GET_ALL_TAXES: "invoice/getAllTaxes",
    GET_ALL_DISCOUNTS: "invoice/getAllDiscounts",
    GET_ALL_PACKAGES: "invoice/getAllPackages",
    GET_PRICE_PACK_BY_PACKAGE_ID: "invoice/getPricePackByPackageId",
    CREATE_INVOICE: "invoice/createInvoice",
    EDIT_INVOICE: "invoice/editInvoice",
    CREATE_BOOKING: "invoice/createBooking",
    RESET_OPERATION_TYPE: "invoice/resetOperationType",
    GET_INVOICE_BY_ID: "invoice/getInvoiceById",
    ADD_REFUND: "invoice/addRefund",
    ADD_PAYMENT: "invoice/addPayment",
    DELETE_REFUND: "invoice/deleteRefund",
    DELETE_PAYMENT: "invoice/deletePayment",
    SEND_FEE_REMINDER: "invoice/sendFeeReminder",
    INVOICE_PDF: "invoice/invoicePDF",
    GET_PACKAGE_INVOICE_LIST: "invoice/getPackageInvoiceList",
    GET_TRANSACTION_LIST_BY_PACKAGE_ID: "invoice/getTransactionsByPackageId",

    //calendar actions
    GET_CALENDAR: "calendar/getCalendar",
    GET_SCHEDULES: "calendar/getSchedules",
    GET_SCHEDULE_DETAIL: "calendar/getScheduleDetail",
    CALENDAR_SAGA_SUCCESS: "calendar/sagaSuccess",
    CALENDAR_SAGA_FAILED: "calendar/sagaFailed",
    GET_ACCOUNT_SCHEDULE: "calendar/getEntitySchedule",

    //parent actions
    GET_PARENT_PRICE_PACK_BY_PACKAGE_ID: "parent/getPricePackByPackageId",
    FETCH_PARENT_UPCOMING_CLASSES: "parent/fetchUpcomingClasses",
    FETCH_PARENT_PACKAGE_ACTIVITIES: "parent/fetchPackageActivites",
    GET_PARENT_PACKAGE_BY_ID: "parent/getPackageById",
    PARENT_SAGA_SUCCESS: "parent/sagaSuccess",
    PARENT_SAGA_FAILED: "parent/sagaFailed",
    REQUEST_APPROVAL: "parent/requestApproval",
    PARENT_GET_ALL_PACKAGES: "parent/getAllPackages",
    PARENT_FETCH_PRICING_PACKS_FOR_ALL_PACKAGES: "parent/fetchPricingPacksForAllPackages",
    APPLY_DISCOUNT: "parent/applyDiscount",
    GET_SELLING_FAST: "parent/getSellingFast",
    UPDATE_WISHLIST: "parent/updateWishlist",
    GET_MY_BOOKINGS_OR_MEMBERSHIPS: "parent/getMyBookingsOrMemberships",
    GET_NOTIFICATION_SETTINGS: "parent/getNotificationSettings",
    CHANGE_NOTIFICATION_SETTINGS: "parent/changeNotificationSettings",
    PARENT_LEAVE_REVIEW: "parent/leaveReview",
    PARENT_AVAILABILITY_MAP: "parent/getAvailabilityMapForSlots",
    PARENT_PAY_NOW: "parent/payNow",
    PARENT_GET_SELLING_FAST: "parent/getSellingFast",
    PARENT_UPDATE_WISHLIST: "parent/updateWishlist",
    PARENT_APPLY_DISCOUNT: "parent/applyDiscount",


    // notification
    GET_LAST_UPDATE_NOTIFICATION: "notification/getLastUpdateNotification",
    GET_NOTIFICATIONS: "notification/getNotifications",
    MARK_READ_NOTIFICATION: "notification/markReadNotification",
    MARK_READ_ALL_NOTIFICATIONS: "notification/markReadAllNotifications",
    NOTIFICATION_SAGA_SUCCESS: "notification/sagaSuccess",
    NOTIFICATION_SAGA_FAILED: "notification/sagaFailed",

    //settings
    SETTING_SAGA_SUCCESS: "setting/sagaSuccess",
    SETTING_SAGA_FAILED: "setting/sagaFailed",
    SETTING_ADD_CLASSROOM: "setting/addClassroom",
    SETTING_DELETE_CLASSROOM: "setting/deleteClassroom",
    SETTING_ADD_SETTINGS: "setting/addSettings",
    SETTING_DELETE_SETTINGS: "setting/deleteSettings",
    SETTING_CONFIRM_SETTINGS: "setting/confirmSettings",
    SETTING_GET_USER_SETTINGS: "setting/getUserSettings",
    SETTING_GET_GUEST_SETTINGS: "setting/getGuestSettings",
    SETTINGS_CREATE_STUDENT_CUSTOMFIELDS: "setting/createStudentCustomField",
    SETTINGS_GET_CUSTOMFIELDS: "setting/getCustomFields",
    SETTINGS_DELETE_CUSTOMFIELD: "setting/deleteCustomField",
    SETTINGS_SAVE_CUSTOMFIELD_ORDER_CHANGES: "setting/saveOrderChangesInDB",
    SETTINGS_GET_DEFAULT_FIELDS: "setting/getDefaultFields",
    SETTINGS_GET_USER_TYPES_LIST: "setting/getUserTypesList",
    SETTINGS_CREATE_NEW_USER_TYPE: "setting/createNewUserType",
    SETTINGS_DELETE_USER_TYPE: "setting/deleteUserType",
    SETTINGS_UPDATE_SELECTED_USER_TYPE: "setting/updateSelectedUserType",
    SETTINGS_UPDATE_SCHOOL_CONFIG_IN_MASTER_DB: "setting/updateSchoolConfigInMasterDB",
    SETTING_SAVE_GENERAL_SETTINGS: "setting/saveGeneralSettings",
    SETTING_SETUP_STRIPE: "setting/sendStripeCodeToBackend",

    //dashboard
    DASHBOARD_SAGA_SUCCESS: "dashboard/sagaSuccess",
    DASHBOARD_SAGA_FAILED: "dashboard/sagaFailed",
    GET_DASH_BOOKINGS: "dashboard/getBookings",
    GET_DASH_REVENUE: "dashboard/getRevenue",
    GET_DASH_STUDENT_STAFF: "dashboard/getStudentStaff",
    GET_DASH_PACKAGES: "dashboard/getPackages",
    GET_DASH_UPCOMINGCLASSES: "dashboard/getUpcomingClasses",
    UPDATE_ONBOARDING_STATUS: "dashboard/updateOnboardingStatus",


    // multicenter 
    MULTICENTER_SAGA_SUCCESS: "multicenter/multiCenterSagaSuccess",
    MULTICENTER_SAGA_FAILED: "multicenter/multiCenterSagaFailed",
    GET_ALL_CENTERS_LIST: "multicenter/getAllCentersList",
    SAVE_CENTER: "multicenter/saveCenter",
    DELETE_CENTER: "multicenter/deleteCenter",
    GET_CENTER_LIST_OF_SINGLE_PACKAGE: 'multicenter/getCentersListOfSinglePackage',


    //accounts
    ACCOUNTS_SAGA_SUCCESS: "accounts/sagaSuccess",
    ACCOUNTS_SAGA_FAILED: "accounts/sagaFailed",
    ACCOUNTS_GET_ACCOUNT_DETAILS: "accounts/getAccountDetails",
    ACCOUNTS_CREATE_ACCOUNT: "accounts/createAccount",
    ACCOUNTS_LINK_ACCOUNT: "accounts/linkAccount",
    ACCOUNTS_DELETE_ACCOUNT: "accounts/deleteAccount",
    ACCOUNTS_GET_LIST_BY_DATE_RANGE: "accounts/getAccountsListByDateRange",
    ACCOUNTS_DELINK_ACCOUNT: "accounts/delinkAccount",
    ACCOUNTS_DELETE_LINKED_ACCOUNT: "accounts/deleteLinkedAccount",
    ACCOUNTS_GET_USERS_OF_ACCOUNT: "accounts/getUsersOfAccount",
    ACCOUNT_SAVE_BOOKING_REVIEW: "accounts/saveBookingReview",
    ACCOUNT_GET_ACCOUNT_USERIDS_MAP: 'accounts/getAccountUserIdsMap',

    // users
    USERS_SAGA_SUCCESS: "users/sagaSuccess",
    USERS_SAGA_FAILED: "users/sagaFailed",
    USERS_GET_USER_DETAILS: "users/getUserDetails",
    USERS_CREATE_USER: "users/createUser",
    USERS_DELETE_USER: "users/deleteUser",
    USERS_GET_LIST_BY_DATE_RANGE: "users/getUsersListByDateRange",
    USERS_DELETE_ACCOUNT_USER_LINK: "users/deleteAccountUserLink",
    USERS_DELINK_ACCOUNT_FROM_USER: "users/delinkAccountFromUser",
    USERS_LINK_ACCOUNT_TO_USER: "users/linkAccountToUser",
    USERS_LINK_NEW_USER_TO_LINKED_ACCOUNTS: 'users/linkNewUserToLinkedAccounts',
    USERS_GET_USER_ACCOUNTIDS_MAP: 'users/getUserAccountIdsMap',
    USERS_BULK_SAVE_USERS: 'users/bulkSaveUsers',

    // reports 
    REPORTS_SAGA_SUCCESS: "reports/reportsSagaSuccess",
    REPORTS_SAGA_FAILED: "reports/reportsSagaFailed",
    GET_ALL_GENERATED_REPORTS_BY_CHANNEL: "reports/getAllGeneratedReportsByChannel",
    GENERATE_REPORT: "reports/generateReport",
    DELETE_REPORT: "reports/deleteReport",
    CLEAR_ALL_REPORTS: "reports/clearAllReports",



    //listing

    LOGIN_AS_GUEST: "accountLogin/loginAsGuest",
    LOGOUT: "accountLogin/logout",
    CREATE_STUDENT_PROFILE: "accountLogin/createStudentProfile",
    ACCOUNT_LOGIN_SLICE_OPERATION: "accountLogin/sliceOperation",
    SET_SCHOOL_CONFIG: "accountLogin/setSchoolConfig",
    ACCOUNT_LOGIN_SAGA_FAILED: "accountLogin/sagaFailed",
    Add_SIBLING: "accountLogin/addSibling",
    ACCOUNT_LOGIN_ATTACHHMENT_UPLOAD_PROGRESS: "accountLogin/attachmentUploadProgress",
    CREATE_PARENT: "accountLogin/createParent",
    GET_CUSTOMFIELDS: "accountLogin/getCustomFields",



    //listing package actions
    GET_PACKAGE_LIST: "accountPackage/getPackageList",
    ACCOUNT_PACKAGE_GET_PACKAGE_BY_ID: "accountPackage/getPackageById",
    GET_BOOKING_OR_MEMBERSHIP_BY_ID: "accountPackage/getBookingOrMembershipById",
    GET_ACTIVITIES_BY_PACKAGE_ID: "accountPackage/getActivitiesByPackageId",
    GET_UPCOMING_CLASSES_BY_PACKAGE_ID: "accountPackage/getUpcomingClassesByPackageId",
    GET_PACKAGE_PRICING_BY_PACKAGE_ID: "accountPackage/getPackagePricingByPackageId",
    PACKAGE_SLICE_OPERATION: "accountPackage/sliceOperation",
    PACKAGE_SAGA_FAILED: "accountPackage/packageSagaFailed",
    ACCOUNT_PACKAGE_UPDATE_WISHLIST: "accountPackage/updateWishlist",
    REQUEST_PAYMENT_APPROVAL: "accountPackage/requestApproval",
    RESCHEDULE_BOOKING: "accountPackage/rescheduleBooking",
    GET_GUEST_SETTINGS: "accountPackage/getGuestSettings",
    GET_CLIENT_SECRED_ID: "accountPackage/getClientSecretId",
    DO_PAYMENT: "accountPackage/doPayment",
    ACCOUNT_PACKAGE_GET_SELLING_FAST: "accountPackage/getSellingFast",
    GET_PACKAGE_CLASS_PACKS: "accountPackage/getPackageClassPacks",
    ACCOUNT_PACKAGE_GET_AGE_RANGE: "accountPackage/getAgeRange",
    GET_ALL_PACKAGE_TYPE: "accountPackage/getAllPackageTypes",
    ACCOUNT_PACKAGE_FETCH_PACKAGE_SCHEDULE: "accountPackage/fetchPackageSchedule",
    PACKAGE_AVAILABILITY_MAP: "accountPackage/getAvailabilityMapForSlots",
    GET_PACKAGE_CENTERS: "accountPackage/fetchCentersOfPackage",
    ACCOUNT_PACKAGE_GET_AVAILABLE_BATCH_SLOTS: "accountPackage/getAvailableBatchSlots",
    ACCOUNT_PACKAGE_APPLY_DISCOUNT: "accountPackage/applyDiscount",
    ACCOUNT_PACKAGE_GET_BATCHES: "accountPackage/getBatchesByPackageId",
    GET_BOOKING_DETAILS_BY_BOOKING_PAYMENT_METADATA_ID: "accountPackage/getBookingDetailsByBookingPaymentMetadataId"
}

export default actions;