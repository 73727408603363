import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoading: false,
  packageLoading: false,
  activityLoading: false,
  upcomingClassesLoading: false,
  pricingPackLoading: false,
  operationType: undefined,
  packageObj: null,
  bookingDetailViewObj: null,
  membershipDetailViewObj: null,
  packageList: [],
  activities: [],
  upcomingClasses: [],
  packagePricing: [],
  guestSettings: null,
  clientSecretId: undefined,
  sellingFast: [],
  classPacks: [],
  classPackLoading: false,
  ageRange: [],
  ageRangeLoading: false,
  packagesTypes: [],
  packageTypesLoading: false,
  bookingStatus: undefined,
  packageSchedule: undefined,
  availabilityMapForSlots: undefined,
  redirectLink: undefined,
  packageCenters: [],
  batchSlotMap: {},
  currPackageSlotsStatus: undefined,
  studentAdditionalFields: [],
  appliedPromoDiscount: undefined,
  promoDiscountLoading: false,
  appliedAutoDiscounts: undefined,
  autoDiscountLoading: false,
  discountMessage: undefined,
  batchLoading: false,
  batches: [],
  reschedulingDetails: undefined,
}

export const packageSlice = createSlice({
  name: 'accountPackage',
  initialState: { ...initialState },
  reducers: {
    getPackageById: (state, action) => {
      state.packageLoading = true
    },
    getBookingOrMembershipById: (state, action) => {
      state.packageLoading = true;
    },
    getActivitiesByPackageId: (state, action) => {
      state.activityLoading = true
    },
    getUpcomingClassesByPackageId: (state, action) => {
      state.upcomingClassesLoading = true
    },
    getPackagePricingByPackageId: (state, action) => {
      state.pricingPackLoading = true
    },
    getPackageList: (state, acttion) => {
      state.isLoading = true
    },
    getPackageClassPacks: (state, acttion) => {
      state.classPackLoading = true
    },
    getAgeRange: (state, acttion) => {
      state.ageRangeLoading = true
    },
    updateWishlist: (state, acttion) => {
      state.isLoading = true
    },
    requestApproval: (state, action) => {
      state.isLoading = true
    },
    getGuestSettings: (state, action) => {
      state.isLoading = true
    },
    getSellingFast: (state, action) => {
    },
    sliceOperation: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    packageSagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false;
      state.classPackLoading = false;
      state.ageRangeLoading = false;
      state.packageTypesLoading = false;
      state.promoDiscountLoading = false;
      state.autoDiscountLoading = false;
      state.batchLoading = false;
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    getClientSecretId: (state, action) => {
      state.isLoading = true
    },
    doPayment: (state, action) => {
      state.isLoading = true
    },
    getAllPackageTypes: (state, action) => {
      state.packageTypesLoading = true
    },
    fetchPackageSchedule: (state, action) => {
      state.isLoading = true
    },
    getAvailabilityMapForSlots: (state, action) => {
      state.isLoading = true
    },
    fetchCentersOfPackage: (state, action) => {
      state.isLoading = true
    },
    getAvailableBatchSlots: (state, action) => {
      state.isLoading = true
    },
    rescheduleBooking: (state, action) => {
      state.isLoading = true
    },
    applyDiscount: (state, action) => {
      const { method } = action.payload.reqObj; 
      if (method === 'CODE') {
        state.promoDiscountLoading = true; 
      }
      else if (method === 'NO_CODE') {
        state.autoDiscountLoading = true; 
      }
    },
    getBatchesByPackageId: (state, action) => {
      state.batchLoading = true
    },
    getBookingDetailsByBookingPaymentMetadataId: (state, action) => {
      state.isLoading = true
    }
  },
})

export const {
  getPackageList,
  sliceOperation,
  packageSagaFailed,
  getPackageById,
  getActivitiesByPackageId,
  getUpcomingClassesByPackageId,
  getPackagePricingByPackageId,
  updateWishlist,
  requestApproval,
  getGuestSettings,
  getClientSecretId,
  doPayment,
  getSellingFast,
  getPackageClassPacks,
  getAgeRange,
  getAllPackageTypes,
  fetchPackageSchedule,
  getAvailabilityMapForSlots,
  fetchCentersOfPackage,
  getAvailableBatchSlots,
  applyDiscount,
  getBatchesByPackageId,
  getBookingOrMembershipById,
  rescheduleBooking,
  getBookingDetailsByBookingPaymentMetadataId
} = packageSlice.actions

export default packageSlice.reducer