import { loginApi } from "../firebase-api/login";
import { endPointUrl } from "../libs/fbConfig";
import Helper from "./helper";
import auth0 from "../okta/page"
const superagent = require("superagent");

async function callApi(loggedInData: any, method: any, callUrl: any, requestPayload: any, unauthorized?: any, endPoint?: any, queryParam?: any) {
    var endpoint = endPoint ? endPoint : endPointUrl;
    callUrl = callUrl + (queryParam ? "&" : "?") + "centerId=" + (loggedInData.sbDbName || loggedInData.centerId)
    let promise;
    requestPayload = Helper.getSuperagentBody(requestPayload, loggedInData.user);
    let authToken: any
    if (!unauthorized) {
        authToken = await auth0.getAuthToken()
    }
    switch (method.toLowerCase()) {
        case "post":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .post(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set("x-platform", "guest")
                    .set({
                        Authorization: "Bearer " + authToken
                    }).send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;

        case "get":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .get(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set("x-platform", "guest")
                    .set({
                        Authorization: "Bearer " + authToken
                    })
                    .send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;

        case "delete":
            promise = new Promise(function (resolve, reject) {
                superagent
                    .delete(endpoint + callUrl)
                    .set("Content-Type", "application/json")
                    .set("accept", "json")
                    .set("x-platform", "guest")
                    .set({
                        Authorization: "Bearer " + authToken
                    })
                    .send(requestPayload)
                    .end(function (err, res) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(res);
                        }
                    });
            });
            return promise;
    }
}
export { callApi };
