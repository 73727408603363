import "firebase/compat/storage";
import { call, takeLatest, put, take, all } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import dbRef from "../../firebase-api/dbRef";
import fbRefs from "../../libs/fbRef";
import Helper from "../../util/helper";
import dayjs from "dayjs";
import { getItem } from "../../libs/encryptedStorage";
import { e } from "mathjs";


function* saveDiscount(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = "bookingoApi/discount"

        if (values.duplicatingDiscount) {

            delete values.updatedOn;
            delete values.updatedBy;
            delete values.deleted;
            delete values.accountUsage;
            delete values.packages;
            delete values.id;

            values.createdBy = firebase.teacher.id;
            values.createdOn = dayjs.tz().valueOf();
            values.redemptions = values.totalRedemptions || null;
        }
        else values.totalRedemptions = values.redemptions || null;


        if (values.id) endpoint += "/" + values.id;

        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "discountEditedSuccessfullly" : "discountSavedSuccessfullly"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "SAVE_DISCOUNT" } });
        }
        else {
            notification("error", response.body.message);
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to save discount ", err);
    }
}

function* deleteDiscount(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/discount/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("discountDeletedSuccessfully"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: 'DELETE_DISCOUNT', isLoading: false } });
        }
        else if (response.status == 206) {
            notification("error", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete discount ", err);
    }
}


function* getAllDiscount(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/discount";
        let response = yield call(callApi, firebase, "get", endpoint, {});

        if (response.status === 200) {
            let discount = response.body.data ? Object.values(response.body.data) : [];
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: {
                    discount,
                    isLoading: false,
                    operationType: "DISCOUNTS_FETCHED"
                }
            });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all discount ", err);
    }
    finally {
        console.log("terminating getAllDiscount channel");
    }
}

function* createPackage(request) {
    try {
        const { firebase, values, actionType } = request.payload;
        let endpoint = "bookingoApi/package";
        let storagePath = firebase.sbp + "/media/images/";
        if (values.coverPhoto && typeof values.coverPhoto !== 'string') {
            let coverPhotourl = yield call(Helper.getAttachedMediaPath, storagePath, values.coverPhoto, firebase);
            if (coverPhotourl) {
                values.coverPhoto = coverPhotourl[0].path
            }
        }
        if (values.mediaProfiles) {
            let attachmentWithoutUrl = values.mediaProfiles.filter((item) => {
                if (!item.path) {
                    return item
                }
            })
            let attachmentWithUrl = values.mediaProfiles.filter((item) => {
                if (item.path) {
                    return item
                }
            })
            let mediaProfilesUrl = attachmentWithoutUrl?.length ? yield call(Helper.getAttachedMediaPath, storagePath, { file: "", fileList: attachmentWithoutUrl }, firebase) : [];
            if (mediaProfilesUrl) {
                values.mediaProfiles = mediaProfilesUrl.concat(attachmentWithUrl);
            }
        }
        values.gallery = values.mediaProfiles;
        delete values.mediaProfiles;
        let response: any;
        if (values.status === "DRAFT") {
            endpoint = `bookingoApi/package/draft`;
            response = yield call(callApi, firebase, "post", endpoint, values);

        } else {
            // if (values.id) {
            //     endpoint = `bookingoApi/package/${values.id}`;
            //     response = yield call(callApi, firebase, "post", endpoint, values);

            // } else {
            response = yield call(callApi, firebase, "post", endpoint, values);
            // }
        }

        if (response.status == 200) {
            if (values.id) {
                notification("success", formatMsg("packageUpdateSuccess"))
            }
            else {
                notification("success", formatMsg(values.status === "DRAFT" ? "packageDrafted" : "createPackageSuccess"))
            }
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: {
                    operationType: actionType == "edit" ? "EDIT_PACKAGE_DETAILS" :
                        values.status === "DRAFT" ? "PACKAGE_DRAFTED" :
                            values.status === "INACTIVE" ? "PACKAGE_INACTIVE" :
                                "CREATE_PACKAGE_SUCCESS",
                    isLoading: false,
                    savedPackage: response.body.data
                }
            });
        }
        else {
            notification("error", response?.body?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to create package ", err);
    }
}


function* saveTax(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = "bookingoApi/tax"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "taxEditedSuccessfullly" : "taxSavedSuccessfullly"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "SAVE_TAX" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to save discount ", err);
    }
}

function* deleteTax(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/tax/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("taxDeletedSuccessfully"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_TAX" } });
        }
        else if (response.status == 206) {
            notification("error", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_TAX" } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete discount ", err);
    }
}

function* getAllTax(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/tax";
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let tax = response.body.data ? Object.values(response.body.data) : []
            yield put({ type: actions.SAGA_SUCCESS, payload: { tax, isLoading: false, operationType: "TAXES_FETCHED" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all tax ", err);
    }
}

function* getAllPricePack(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/pricePack"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let pricePack = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { pricePack, isLoading: false, operationType: "PRICING_PACKS_FETCHED" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete discount ", err);
    }
}

function* getAllClassrooms(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/classroom"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let classrooms = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { classrooms, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all classrooms", err);
    }
}

function* savePricePack(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = "bookingoApi/pricePack"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            if (values.id) {
                notification("success", formatMsg("pricePackEditedSuccessfullly"))
            }
            else {
                notification("success", formatMsg("pricePackSavedSuccessfullly"))
            }
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "SAVE_PRICE_PACK" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to save price pack ", err);
    }
}

function* deletePricingPack(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/pricePack/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("pricePackDeletedSuccessfully"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_PRICE_PACK" } });
        }
        else if (response.status == 206) {
            notification("success", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_PRICE_PACK" } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        Helper.notifyBugsnag(err, true);
        console.log("failed to delete discount ", err);
    }
}
function* fetchPackages(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/package" + "?teacherId=" + firebase.teacher.id
        let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
        if (response.status == 200) {
            let data = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { packagePageLoading: false, isLoading: false, operationType: "FETCH_PACKAGES", packages: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.REQUEST_FAILED
        });
        console.log("failed to fetch packages", err);
    }
}

function* deletePackage(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/package/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("deletePackageSuccess"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "PACKAGE_DELETED" } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete discount ", err);
    }
}


function* getPackageById(request) {
    try {
        const { firebase, packageId } = request.payload;
        let endpoint = `bookingoApi/package/${packageId}`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: { isLoading: false, packageLoading: false, packageObj: response.body.data.package }
            });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package", err);
    }
}

function* getClassPackByPackageId(request) {
    try {
        const { firebase, packageId, startDate, endDate } = request.payload;
        let endpoint = `bookingoApi/package/pricingPacksAmount/${packageId}?startDate=${startDate}&endDate=${endDate}`;
        let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
        if (response.status == 200) {
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: { isLoading: false, classPack: response.body.data }
            });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package", err);
    }
}

function* getAllRegistrationFee(request) {
    try {
        const { firebase } = request.payload;

        let endpoint = "bookingoApi/registrationFee"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let registrationFee = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { registrationFee, isLoading: false, operationType: "REGISTRATION_FEES_FETCHED" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all registration fee ", err);
    }
}

function* saveRegistrationFee(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = "bookingoApi/registrationFee"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "registrationFeeEditedSuccessfullly" : "registrationFeeSavedSuccessfullly"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "SAVE_REGISTRATION_FEE" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to save registratin fee ", err);
    }
}

function* deleteRegistrationFee(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/registrationFee/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("registrationFeeDeletedSuccessfully"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_REGISTRATION_FEE" } });
        }
        else if (response.status == 206) {
            notification("error", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_REGISTRATION_FEE" } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete registration fee ", err);
    }
}



function* getAllAdditionalCharge(request) {
    try {
        const { firebase } = request.payload;

        let endpoint = "bookingoApi/additionalCharge"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let additionalCharge = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { additionalCharge, isLoading: false, operationType: "ADDITIONAL_CHARGES_FETCHED" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all additional charge ", err);
    }
}

function* saveAdditionalCharge(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = "bookingoApi/additionalCharge"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "additionalChargeEditedSuccessfullly" : "additionalChargeSavedSuccessfullly"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "SAVE_REGISTRATION_FEE" } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to save additional charge ", err);
    }
}

function* deleteAdditionalCharge(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingoApi/additionalCharge/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("additionalChargeDeletedSuccessfully"));
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_PRICE_PACK" } });
        }
        else if (response.status == 206) {
            notification("error", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_PRICE_PACK" } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to delete additional charge ", err);
    }
}

function* getAllPricePackByChannel(request) {
    try {
        const { firebase } = request.payload;
        let pricePackChannel = yield call(dbRef.getEntitiesByChannel, firebase, fbRefs.pricingPacks);
        while (true) {
            let pricePack = yield take(pricePackChannel);
            yield put({ type: actions.SAGA_SUCCESS, payload: { pricePack, pricePackChannel, isLoading: false } });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all price pack ", err);
    }
    finally {
        console.log("terminating getAllPricePackByChannel channel");
    }
}

function* getPackageType(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/packageType"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let packageType = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { packageType, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all packageType", err);
    }
}

function* getAgeRange(request) {
    try {
        const { firebase } = request.payload;
        let ageRange = yield call(dbRef.getEntityById, firebase, fbRefs.ageRangeRef);
        if (ageRange) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { ageRange, isLoading: false, operationType: "AGE_RANGE_FETCHED" } });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all age range ", err);
    }
}

function* getSkills(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/skill"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let skills = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { skills, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all skills", err);
    }
}

function* fetchPackageBookings(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingoApi/package/${id}/bookings` + "?teacherId=" + firebase.teacher.id
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, packageBookings: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package bookings", err);
    }
}

function* fetchPricingPacksForAllPackages(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = `bookingoApi/package/packages/classPacks`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, packagePricingPacks: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package pricing packs", err);
    }
}

function* fetchPricingPackByPackage(request) {
    try {
        const { firebase, id, packageType } = request.payload;
        let endpoint = `bookingoApi/package/${id}/pricingPacks`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, pricingPacksByPackage: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        const { packageType } = request.payload;
        Helper.notifyBugsnag(err, packageType !== "DRAFT");
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package pricing packs", err);
    }
}

function* fetchUpcomingClasses(request) {
    try {
        const { firebase, id, packageType } = request.payload;
        let endpoint = `bookingoApi/package/${id}/upcomingClasses`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, upcomingClasses: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        const { packageType } = request.payload;
        Helper.notifyBugsnag(err, packageType !== "DRAFT");
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package upcoming classes", err);
    }
}

function* fetchPackageActivites(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingoApi/package/${id}/activities`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, packageActivites: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package activites", err);
    }
}

function* fetchPackageSchedule(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingoApi/package/${id}/schedules`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, packageSchedule: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch package schedule", err);
    }
}

function* editPackageDetails(request) {
    try {
        const { firebase, id, formType, reqObj } = request.payload;
        let endpoint = `bookingoApi/package/${id}/${formType}`;

        let storagePath = firebase.sbp + "/media/images/";
        if (reqObj.coverPhoto && typeof reqObj.coverPhoto !== 'string') {
            let coverPhotourl = yield call(Helper.getAttachedMediaPath, storagePath, reqObj.coverPhoto, firebase);
            if (coverPhotourl) {
                reqObj.coverPhoto = coverPhotourl[0].path
            }
        }
        let operationType;
        switch (formType) {
            case "basicInfo":
                operationType = "EDIT_PACKAGE_BASIC_INFO";
                break;
            case "courseStructure":
            case "specialConsiderations":
            case "classPack":
                operationType = "EDIT_PACKAGE_DETAILS";
                break;
            case "activities":
            case "schedules":
                operationType = "EDIT_PACKAGE_DETAILS_ACTIVITIES";
                break;
        }
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: operationType } });
            notification("success", formatMsg("editPackageSuccessfully"));
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to update package", err);
    }
}
function* getAllBooking(request) {
    try {
        const { firebase, startDate, endDate, packageId } = request.payload;
        let endpoint = "bookingoApi/listing/bookings?startDate=" + startDate + "&endDate=" + endDate + "&teacherId=" + firebase.teacher.id + (packageId ? "&packageId=" + packageId : "")
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            let bookings = response.body.data
            bookings.sort((a, b) => b.createdOn - a.createdOn);
            yield put({ type: actions.SAGA_SUCCESS, payload: { bookings, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to fetch  all booking ", err);
    }
}

function* getBookingStats(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = "bookingoApi/booking/statsForBooking?teacherId=" + firebase.teacher.id
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            let bookingStats = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { bookingStats, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to fetch getBookingStats", err);
    }
}


function* changeBookingStatus(request) {
    try {
        const { firebase, record, confirmOrNot } = request.payload;
        let obj = { ...record, paymentStatus: "PENDING" };
        delete obj.package;
        delete obj.pricingPack;
        delete obj.student;
        delete obj.confirmedOn;
        delete obj.account;
        delete obj.invoice;
        delete obj.batchName;

        let endpoint = `bookingoApi/booking/${obj.id}/${confirmOrNot ? "confirm" : "cancel"}`;
        let response = yield call(callApi, firebase, "post", endpoint, confirmOrNot ? { ...obj } : { bookingId: Helper.isAccount(firebase) ? obj.bookingBasicDetails.id : obj.id });
        if (response.status == 200) {
            notification("success", formatMsg(confirmOrNot ? "bookingHasConfiredNow" : "bookingHasRejected"))
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "BOOKING_UPDATED", isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to all changeBookingStatus ", err);
    }
}

function* getListing(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = "bookingoApi/listing/packages?startDate=" + startDate + "&endDate=" + endDate + "&teacherId=" + firebase.teacher.id
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            let listing = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { listing, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to all listing ", err);
    }
}

function* getListingRawData(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = "bookingoApi/listing/packages/bookingStats?startDate=" + startDate + "&endDate=" + endDate + "&teacherId=" + firebase.teacher.id
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            let listingRawData = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { listingRawData, listingRowDataLoading: false, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to all getListingRawData ", err);
    }
}

function* activeAndInactivePackage(request) {
    try {
        const { firebase, packageId, status, cancelBookings } = request.payload;
        let endpoint = `bookingoApi/package/${packageId}/activeInactive`
        let response = yield call(callApi, firebase, "post", endpoint, { status: status, cancelBookings: cancelBookings || false });
        if (response.status == 200) {
            notification("success", formatMsg("packageStatusUpdated"))
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "STATUS_UPDATED", isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
            notification("error", response?.body?.message || formatMsg("error.occured"))
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to update package status ", err);
    }
}


function* getPricePackDataForEdit(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingoApi/package/${id}/classPack`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { pricePackDataLoader: false, pricePackData: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch price pack data", err);
    }
}

function getLoggedInData(loggedInData) {
    // not logged in case
    if (!loggedInData || !loggedInData.user || !loggedInData.user.id) {
        let centerId = getItem("centerId") || Helper.getUrlParam("centerId");
        return { centerId: centerId, user: { name: "" } };
    }
    return loggedInData;
}

function* getPackageForEdit(request) {
    try {
        let { firebase, id } = request.payload;
        firebase = getLoggedInData(firebase);
        let endpoint
        if (firebase?.user?.id) {
            // means user exit
            endpoint = "bookingoApi/package/edit/" + id
        }
        else {
            endpoint = "bookingoApi/guest/package/edit/" + id + "?loggedOut=" + true;
        }
        let response = yield call(callApi, firebase, "get", endpoint, {}, !firebase?.user?.id, null, !firebase?.user?.id ? true : false);
        if (response.status == 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { editPackageLoading: false, editPackage: response.body.data.package, operationType: "EDIT_PACKAGE_FETCHED" } });
            if (response.body.data?.schoolConfig) {
                yield put({
                    type: actions.SET_FIREBASE_OBJ_SCHOOL_CONFIG,
                    payload: {
                        schoolConfig: response.body.data?.schoolConfig
                    }
                });
            }

        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to getPackageForEdit", err);
    }
}

function* getClassLevel(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/classlevel"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let classlevel = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { classlevel, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all classlevels", err);
    }
}
function* getLanguage(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/language"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let language = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { language, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all language", err);
    }
}

function* getAllPackageNames(request) {
    try {
        const { firebase, status } = request.payload;
        let endpoint = "bookingoApi/package/all/names?teacherId=" + firebase.teacher.id
        let response
        if (status) {
            endpoint += "&status=" + status;
        }
        response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response.status == 200) {
            let packageNames = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { packageNames, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all package names", err);
    }
}

function* updateGuest(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingoApi/guest/${reqObj.id}`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response.status == 200) {
            notification("success", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "BOOKING_UPDATED", isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to update guest ", err);
    }
}

function* getAllPricepacksForPackage(request) {
    try {
        const { firebase, packageId } = request.payload;
        let endpoint = `bookingoApi/package/${packageId}/classPack`
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            notification("success", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { operationType: "ALL_PRICEPACKS_FOR_PACKAGE", isLoading: false, allPricePacksForPackage: response.body.data } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all pricepacks for package", err);
    }
}

function* duplicatePackage(request) {
    try {
        const { firebase, requestObj } = request.payload;
        let endpoint = `bookingoApi/package/duplicate`
        let response = yield call(callApi, firebase, "post", endpoint, { packageId: requestObj.id, createdOn: dayjs.tz().valueOf(), createdBy: firebase.teacher.id });
        if (response.status == 200) {
            notification("success", response.body.message);
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, operationType: "STATUS_UPDATED" } });
        }
        else {
            notification("success", response.body.message || formatMsg("error.occured"));
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to duplicate package ", err);
    }
}


function* getIdNameListOfSchedules(actionObj) {
    try {
        const { firebase } = actionObj.payload;

        let endpoint = `bookingoApi/package/idNameListOfAllSchedules`;

        let response = yield call(callApi, firebase, "get", endpoint, {});

        if (response.status == 200) {

            const idNameObjListOfAllSchedules = response.body.data.idNameListOfAllSchedules;

            const idToNameMapOfAllSchedules = new Map();

            idNameObjListOfAllSchedules.forEach((miniScheduleObj: Record<string, any>): void => {
                idToNameMapOfAllSchedules.set(miniScheduleObj.id, miniScheduleObj.name);
            });

            yield put({
                type: actions.SAGA_SUCCESS,
                payload: {
                    isLoading: false,
                    idNameObjListOfAllSchedules,
                    idToNameMapOfAllSchedules,
                    operationType: 'FETCHED_ID_NAME_OBJ_LIST_OF_SCHEDULES'
                }
            });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
            notification('error', formatMsg(`oopsSomthingWentWrong`));
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to fetch id-name obj list of all schedules", err);
    }
}


function* getAvailableBatchSlots(request) {
    try {
        let { firebase, reqObj, upcomingClasses } = request.payload;
        let endpoint = `bookingoApi/multicenter/centerBatchAvailabilityMap`
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response.status == 200) {
            let batchSlotMap = response.body.data?.batchSlotMap;
            if (upcomingClasses)
                upcomingClasses = upcomingClasses?.map(cls => {
                    const obj = { ...cls }
                    obj.displayName = batchSlotMap?.[cls.batchId] === 0 ? cls.batchName + "Sold out" : cls.batchName
                    // ` (${batchSlotMap?.[cls.batchId] || 0} slots available)`
                    return obj
                })
            yield put({ type: actions.SAGA_SUCCESS, payload: { isLoading: false, batchSlotMap: batchSlotMap, upcomingClasses: upcomingClasses || [] } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    } catch (err) {
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to getAvailableBatchSlots", err);
    }
}

function* enhanceWithAi(request) {
    try {
        const { firebase, text } = request.payload;
        const endpoint = `bookingoApi/ai/paraphrase`;

        let response = yield call(callApi, firebase, "post", endpoint, { text });
        if (response?.status === 200) {
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: { isLoading: false, enchancedAIText: response.body.data.paraphraseText }
            })
        } else {
            notification("error", response.body.message || formatMsg("error.errorOccured"))
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: { isLoading: false, enhancedAIText: undefined }
            })
        }
    } catch (err) {
        notification("error", err?.response?.body?.message || formatMsg("error.occured"))
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to enhanceWithAi", err);
    }
}
function* getPackageListDropdown(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingoApi/package/packageList";
        let response = yield call(callApi, firebase, "get", endpoint, {});

        if (response.status === 200) {
            let packageList = response.body.data ? Object.values(response.body.data) : [];
            yield put({
                type: actions.SAGA_SUCCESS,
                payload: {
                    packageList,
                    isLoading: false
                }
            });
        }
        else {
            yield put({ type: actions.SAGA_FAILED });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED });
        console.log("failed to get all package list ", err);
    }
    finally {
        console.log("terminating getPackageList channel");
    }
}

function* getRescheduleData(request) {
    try {
        const { firebase, bookingId } = request.payload;
        let endpoint = `bookingoApi/booking/${bookingId}/reschedule`
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let rescheduleData = response.body.data
            yield put({ type: actions.SAGA_SUCCESS, payload: { rescheduleData, isLoading: false } });
        }
        else {
            yield put({ type: actions.SAGA_FAILED, isLoading: false });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.SAGA_FAILED, isLoading: false });
        console.log("failed to fetch getRescheduleData", err);
    }
}

function* createShortUrl(action) {
    try {

        const { url, firebase } = action.payload;
        let endpoint = `shortUrl`
        const response = yield call(callApi, firebase, "post", endpoint, { url: encodeURI(url) }, true, "https://us-central1-masterbooking-f8b6e.cloudfunctions.net/");

        if (response.status === 200) {
            yield put({ type: actions.SAGA_SUCCESS, payload: { urlShortenerLoading: false, shortUrl: response.body.data.shortUrl, operationType: "URL_CREATED" } });
        } else {
            yield put({ type: actions.SAGA_FAILED, urlShortenerLoading: false });
        }
    } catch (error) {
        Helper.notifyBugsnag(error, true);
        yield put({ type: actions.SAGA_FAILED, urlShortenerLoading: false });
        console.log("failed creating short url ", error);
    }
}

export default function* rootSaga() {
    yield takeLatest(actions.SAVE_DISCOUNT, saveDiscount);
    yield takeLatest(actions.GET_ALL_DISCOUNT, getAllDiscount);
    yield takeLatest(actions.DELETE_DISCOUNT, deleteDiscount);
    yield takeLatest(actions.CREATE_PACKAGE, createPackage);
    yield takeLatest(actions.SAVE_TAX, saveTax);
    yield takeLatest(actions.DELETE_TAX, deleteTax);
    yield takeLatest(actions.GET_ALL_TAX, getAllTax);
    yield takeLatest(actions.GET_ALL_PRICE_PACK, getAllPricePack);
    yield takeLatest(actions.SAVE_PRICE_PACK, savePricePack);
    yield takeLatest(actions.DELETE_PRICING_PACK, deletePricingPack);
    yield takeLatest(actions.GET_ALL_REGISTRATION_FEE, getAllRegistrationFee);
    yield takeLatest(actions.SAVE_REGISTRATION_FEE, saveRegistrationFee);
    yield takeLatest(actions.DELETE_REGISTRATION_FEE, deleteRegistrationFee);
    yield takeLatest(actions.GET_ALL_ADDITIONAL_CHARGE, getAllAdditionalCharge);
    yield takeLatest(actions.SAVE_ADDITIONAL_CHARGE, saveAdditionalCharge);
    yield takeLatest(actions.DELETE_ADDITIONAL_CHARGE, deleteAdditionalCharge);
    yield takeLatest(actions.GET_ALL_PRICE_PACK_BY_CHANNEL, getAllPricePackByChannel);
    yield takeLatest(actions.FETCH_PACKAGES, fetchPackages);
    yield takeLatest(actions.DELETE_PACKAGE, deletePackage);
    yield takeLatest(actions.GET_PACKAGE_BY_ID, getPackageById);
    yield takeLatest(actions.GET_PKG_TYPE, getPackageType);
    yield takeLatest(actions.GET_AGE_RANGE, getAgeRange);
    yield takeLatest(actions.GET_SKILLS, getSkills);
    yield takeLatest(actions.FETCH_PACKAGE_BOOKINGS, fetchPackageBookings);
    yield takeLatest(actions.FETCH_PRICING_PACKS_FOR_ALL_PACKAGES, fetchPricingPacksForAllPackages);
    yield takeLatest(actions.FETCH_PRICING_PACKS_BY_PACKAGE, fetchPricingPackByPackage);
    yield takeLatest(actions.FETCH_UPCOMING_CLASSES, fetchUpcomingClasses);
    yield takeLatest(actions.FETCH_PACKAGE_ACTIVITIES, fetchPackageActivites);
    yield takeLatest(actions.FETCH_PACKAGE_SCHEDULE, fetchPackageSchedule);
    yield takeLatest(actions.GET_CLASS_PACK_BY_PACKAGE_ID, getClassPackByPackageId);
    yield takeLatest(actions.EDIT_PACKAGE_DETAILS, editPackageDetails);
    yield takeLatest(actions.GET_PRICE_PACK_DATA_FOR_EDIT, getPricePackDataForEdit);
    yield takeLatest(actions.GET_PACKAGE_FOR_EDIT, getPackageForEdit);
    yield takeLatest(actions.GET_ALL_BOOKING, getAllBooking);
    yield takeLatest(actions.GET_BOOKING_STATS, getBookingStats);
    yield takeLatest(actions.CHANGE_BOOKING_STATUS, changeBookingStatus);
    yield takeLatest(actions.GET_LISTING, getListing);
    yield takeLatest(actions.GET_LISTING_RAW_DATA, getListingRawData)
    yield takeLatest(actions.ACTIVE_AND_INACTIVE_PACKAGE, activeAndInactivePackage);
    yield takeLatest(actions.GET_CLASSLEVEL, getClassLevel);
    yield takeLatest(actions.GET_ALL_CLASSROOMS, getAllClassrooms)
    yield takeLatest(actions.GET_LANGUAGE, getLanguage);
    yield takeLatest(actions.GET_ALL_PACKAGE_NAMES, getAllPackageNames);
    yield takeLatest(actions.PACKAGE_UPDATE_GUEST, updateGuest);
    yield takeLatest(actions.GET_ALLPRICEPACKS_FOR_PACKAGE, getAllPricepacksForPackage);
    yield takeLatest(actions.DUPLICATE_PACKAGE, duplicatePackage);
    yield takeLatest(actions.GET_ID_NAME_LIST_OF_SCHEDULES, getIdNameListOfSchedules);
    yield takeLatest(actions.GET_AVAILABLE_BATCH_SLOTS, getAvailableBatchSlots);
    yield takeLatest(actions.ENHANCE_WITH_AI, enhanceWithAi);
    yield takeLatest(actions.GET_PACKAGE_LIST_DROPDOWN, getPackageListDropdown);
    yield takeLatest(actions.GET_RESCHEDULE_DATA, getRescheduleData);
    yield takeLatest(actions.CREATE_SHORT_URL, createShortUrl);
}
