'use client'


import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Helper from '../../util/helper';
import { getCustomFields } from '../../redux/setting/reducer';
import formatMsg from '../../util/formatMsg';
import { notification } from '../../util/notification';
import { createAccount } from '../../redux/accounts/reducer';
import { ModalWrapper } from '../wrapperComponents/page';
import FormWrapper from '../formWrapper/page';


const AddEditAccountModal = (props) => {

    const { 
        firebase, 
        form, 
        open, 
        setOpen,
        phone, 
        setPhone, 
        editableRecords, 
        setEditableRecords
    } = props; 

    const { settingReducer, accountReducer } = Helper.getSelectorState(["settingReducer", "accountReducer"]);

    useEffect(() => {
        dispatch(getCustomFields({ firebase, fieldType: "account" }));
    }, [])


    useEffect(() => {
        
        const operationType = accountReducer.operationType;

        if (
            operationType && 
            (operationType === "ACCOUNT_CREATED" || operationType === "ACCOUNT_UPDATED" || operationType === "ACCOUNT_DELETED")
        ) {
            handleCancel();
        }

    }, [accountReducer.operationType]); 
    
    const dispatch = useDispatch();  

    const AccountFields = [
        [{
            label: formatMsg("addProfilePhoto"),
            type: "media",
            name: "profileImageUrl",
            placeHolder: formatMsg("addProfilePhoto"),
            warningMessage: "Please select profile photo!",
            inputType: "picture",
            values: form.getFieldValue("profileImageUrl")
        }],
        [{
            label: formatMsg("name"),
            type: "input",
            name: "name",
            placeHolder: formatMsg("name"),
            required: true,
            warningMessage: "Please input name!",
            inputType: "string",
        }],
        [{
            label: formatMsg("phoneNumber"),
            type: "phoneInput",
            name: "phoneNumber",
            placeHolder: formatMsg("phoneNumber"),
            warningMessage: "Please input phone number!",
            required: settingReducer && settingReducer.defaultFieldsNameToObjMap ? settingReducer.defaultFieldsNameToObjMap.get('Phone Number')?.mandatory : true,
            inputType: "phoneInput",
            onPhoneChange: (value, data) => {
                setPhone({
                    phoneNumber: value ? "+" + value : null,
                    countryCode: "+" + data.dialCode,
                })
            },
            phoneValue: phone.phoneNumber ?
                phone.phoneNumber.toString().slice(1) :
                undefined
        }],
        [{
            label: formatMsg("placeholder.email"),
            type: "input",
            name: "email",
            placeHolder: formatMsg("placeholder.email"),
            required: settingReducer && settingReducer.defaultFieldsNameToObjMap ? settingReducer.defaultFieldsNameToObjMap.get('Email')?.mandatory : true,
            warningMessage: "Please input email!",
            inputType: "email",
        }],
    ]


    function handleOk() {

        form.validateFields()
            .then((values) => {
                const { defaultFieldsNameToObjMap } = settingReducer;

                if (defaultFieldsNameToObjMap) {
                    if (defaultFieldsNameToObjMap.get('Phone Number')?.mandatory && !phone.phoneNumber) {
                        notification("error", formatMsg("error.phone"));
                        return;
                    }
                    else if (defaultFieldsNameToObjMap.get('Email')?.mandatory && !form.getFieldValue("email")) {
                        notification("error", formatMsg("error.email"));
                        return;
                    }
                }
                else {
                    notification("error", formatMsg("error.oopsSomthingWentWrong"));
                    return;
                }

                values.status = (editableRecords && editableRecords.status) || "ACTIVE";
                values.id = editableRecords && editableRecords.id;
                if (!values.profileImageUrl && editableRecords) {
                    values.profileImageUrl = editableRecords.profileImageUrl
                }

                values.childConfig = settingReducer.customFields ?
                    settingReducer.customFields.map((additionalFieldObj) => {
                        const tempAdditionalFieldObj = { ...additionalFieldObj };
                        tempAdditionalFieldObj.value = (values[additionalFieldObj.name]) ?
                            (additionalFieldObj.dataType === 'date' || additionalFieldObj.dataType === 'time') ?
                                dayjs.tz(values[additionalFieldObj.name]).valueOf()
                                :
                                values[additionalFieldObj.name]
                            :
                            null;
                        delete values[additionalFieldObj.name];
                        return tempAdditionalFieldObj;
                    })
                    :
                    null;

                values.phoneNumber = phone.phoneNumber;
                dispatch(createAccount({ reqBody: values, firebase }));
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }


    function handleCancel() {
        form.resetFields();
        setOpen(undefined);
        if (setEditableRecords) setEditableRecords(undefined);
        setPhone({ phoneNumber: undefined, countryCode: undefined });
    }

    return (
        <ModalWrapper
            open={open}
            title={formatMsg("accountDetails")}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={formatMsg("submit")}
            maskClosable={false}
            confirmLoading={accountReducer.isLoading}
        >
            <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
            >
                <FormWrapper
                    form={form}
                    formFields={[...AccountFields, ...Helper.getAdditionalFormFields(settingReducer?.customFields)]}
                    editableRecords={editableRecords}
                    setEditableRecords={setEditableRecords}
                />
            </Form>
        </ModalWrapper>
    )
}

export default AddEditAccountModal