"use client"
import { Button, Checkbox, Progress, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.css'
import formatMsg from '../../util/formatMsg'
import Helper from '../../util/helper'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/navigation'
import { LinkWrapper, packageActions, SpinWrapper, dashboardActions } from '../..'
import { updateSchoolConfigInMasterDB } from '../../redux/setting/reducer'


const { getAllPackageNames } = packageActions;
const { updateOnboardingStatus, resetOperationType } = dashboardActions;

function page() {
    const dispatch = useDispatch();
    const router = useRouter();
    const { firebase, packageReducer, dashboardReducer } = Helper.getSelectorState(["login", "packageReducer", "dashboardReducer"]);

    const [isLogoUploaded, setIsLogoUploaded] = useState(false);
    const [isUserProfileSet, setIsUserProfileSet] = useState(false);
    const [isPaymentConnected, setIsPaymentConnected] = useState(false);
    const [isPackageCreated, setIsPackageCreated] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [completedTasks, setCompletedTasks] = useState(0);

    const totalTasks: number = 3;

    useEffect(() => {
        if (firebase) {
            dispatch(getAllPackageNames({ firebase }));
        }
    }, [])

    useEffect(() => {
        if (firebase?.schoolConfig) {
            firebase.schoolConfig?.logoByUser && setIsLogoUploaded(!!firebase.schoolConfig.logoUrl);
            firebase.schoolConfig?.selectedUserTypeNameByUser && setIsUserProfileSet(!!firebase.schoolConfig.selectedUserTypeName);
            // setIsPaymentConnected(!!firebase.schoolConfig.paymentAccount);
        }
    }, [firebase?.schoolConfig]);

    useEffect(() => {
        setIsPackageCreated(packageReducer.packageNames.length > 0);
    }, [packageReducer.packageNames])

    useEffect(() => {
        const newCompletedTasks = [
            isLogoUploaded,
            isUserProfileSet,
            packageReducer.packageNames.length > 0
        ].filter(Boolean).length;
        setCompletedTasks(newCompletedTasks);
    }, [packageReducer.packageNames, firebase?.schoolConfig])

    useEffect(() => {
        setProgressPercentage((completedTasks / totalTasks) * 100);
        if (completedTasks === totalTasks) {
            dispatch(updateOnboardingStatus({
                firebase, reqObj: {
                    onboardingCompleted: true
                }
            }));
        }
    }, [completedTasks]);

    useEffect(() => {
        if (dashboardReducer.operationType === "ONBOARDING_STATUS_UPDATED") {
            router.push("/")
            dispatch(resetOperationType(null))
        }
    }, [dashboardReducer.operationType])

    const handleSkip = () => {
        dispatch(updateOnboardingStatus({
            firebase,
            reqObj: {
                onboardingCompleted: true
            }
        }));
    };

    const TaskItem = (key, isCompleted, title, linkPath) => {
        return (
            <div className="task-item">
                <Checkbox checked={isCompleted} disabled />
                {linkPath ? (
                    <LinkWrapper href={linkPath}>
                        <div className="task-item-title">{title}</div>
                    </LinkWrapper>
                ) : (
                    <div className="task-item-title">{title}</div>
                )}
            </div>
        );
    };

    const ActionCard = (iconSrc, iconAlt, title, subtitle, linkPath) => {
        return (
            <LinkWrapper href={linkPath}>
                <div className="onboarding-action-container">
                    <div className="onboarding_action">
                        <div className="onboarding-action-img">
                            <img src={iconSrc} className="onboarding-action-icon" alt={iconAlt} />
                        </div>
                        <div className="onboarding-action-subcontainer">
                            <div className="onboarding-action-title">{title}</div>
                            <div className="onboarding-action-subtitle">{subtitle}</div>
                        </div>
                    </div>
                    <div className='onboarding-arrow-container'>
                        <img src="/images/arrow-right.png" className="onboarding_arrow" alt="arrow-right" />
                    </div>
                </div>
            </LinkWrapper>
        );
    };
    const tasks = [
        {
            key: 0,
            isCompleted: isLogoUploaded,
            title: formatMsg("uploadYourLogo"),
            linkPath: isLogoUploaded ? null : {
                pathname: "/dashboard/setting/settingForm",
                query: { settingType: "generalSettings", redirectToOnboarding: "true" }
            }
        },
        {
            key: 1,
            isCompleted: isUserProfileSet,
            title: formatMsg("setUpCustomerAndUsersProfile"),
            linkPath: isUserProfileSet ? null : {
                pathname: "/dashboard/setting/settingForm",
                query: { settingType: "customerAccountsAndUsers", tab: "userFields", redirectToOnboarding: "true" }
            }
        },
        {
            key: 2,
            isCompleted: isPackageCreated,
            title: formatMsg("createYourFirstPackage"),
            linkPath: isPackageCreated ? null : {
                pathname: "/dashboard/package/createPackage",
                query: { redirectToOnboarding: "true" }
            }
        }
    ];

    const actionCards = [
        {
            iconSrc: "/images/pinkCalender.png",
            iconAlt: "calender",
            title: formatMsg("createANewPackage"),
            subtitle: formatMsg("createPackageDesc"),
            linkPath: {
                pathname: "/dashboard/package/createPackage",
                query: { redirectToOnboarding: "true" }
            }
        },
        {
            iconSrc: "/images/pinkAddAccount.png",
            iconAlt: "add-account",
            title: formatMsg("addNewstaff"),
            subtitle: formatMsg("addStaffDesc"),
            linkPath: {
                pathname: "/dashboard/staffs",
                query: { openModal: "true" }
            }
        }
    ];
    return (
        <SpinWrapper spinning={packageReducer.isLoading}>
            <div style={{ height: '100%' }}>
                <div className="onboarding-container">
                    <div className='onboardingHeadingContainer'>
                        <div className='onboarding-main-title'>
                            {formatMsg(Helper.getGreetingTime()) + ", " + (firebase?.user?.name || "User")}
                        </div>
                    </div>
                    <div className="onboarding-subContainer">
                        <div className="progress-section">
                            <div className="progress-bar">
                                <div className="progress">
                                    <div className='onboarding-action-title' style={{ color: '#7413BD' }}>{formatMsg("yourProgress")}</div>
                                </div>
                                <div className='progress-bar-container'>
                                    <Progress
                                        percent={progressPercentage}
                                        showInfo={false}
                                        strokeColor="#7413BD"
                                    />
                                    <div className="onboarding-action-subtitle">{completedTasks}/{totalTasks} {formatMsg("completed")}</div>
                                </div>
                            </div>
                            <div className="onboarding-tasks">
                                {tasks.map((task, index) => (
                                    TaskItem(task.key, task.isCompleted, task.title, task.linkPath)
                                ))}
                                <div className="task-item-coming-soon">
                                    <div className="task-item-title-coming-soon">{formatMsg("connectToPaymentProvider")}</div>
                                    <div>
                                        <Tag color="#D9A6FF33" style={styles.tag}>
                                            <div className='onboarding-coming-soon'>{formatMsg("comingSoon")}</div>
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="onboarding-actions-section">
                            <div className="onboarding-actions-section-title">{formatMsg("whatWouldYouLikeToDoToday")}</div>
                            {actionCards.map((card, index) => (
                                ActionCard(card.iconSrc, card.iconAlt, card.title, card.subtitle, card.linkPath)
                            ))}
                            <div className='onboarding-action-container-coming-soon'>
                                <div className="onboarding_action">
                                    <div className='onboarding-action-img'>
                                        <img src="/images/greyAddAccount.png" alt="add_account" className="onboarding-action-icon" />
                                    </div>
                                    <div className="onboarding-action-subcontainer" >
                                        <div className="onboarding-action-title">{formatMsg("importYourCustomer")}</div>
                                        <div className='onboarding-action-subtitle'>{formatMsg("youWantToImportYourCustomers")}</div>
                                        <div className='onboarding-action-sub-subtitle'>{formatMsg("contactOurTeam")} support@bookingo.com</div>
                                    </div>
                                </div>
                                <div className='adjust-onboarding-soon-pos'>
                                    <Tag color="#D9A6FF33" style={styles.tag}>
                                        <div className='onboarding-coming-soon'>{formatMsg("comingSoon")}</div>
                                    </Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='onboardingSkipContainer'>
                        <div className="onboarding-skip-section">
                            <div className="button-container"
                                onClick={handleSkip}
                            >
                                <span style={styles.prevButton}>{formatMsg("skip")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SpinWrapper>
    )
}

export default page

const styles = {
    prevButton: {
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: "700",
        fontSize: "18px",
        color: "#7413BD"
    },
    tag: {
        borderRadius: "12px",
        color: "black"
    }
}




