'use client'

import React, { useEffect, useState } from 'react'
import { Box, PageHeaderWrapper } from '../../wrapperComponents/page'
import formatMsg from '../../../util/formatMsg'
import { SearchOutlined } from "@ant-design/icons"
import { Input } from 'antd'
import dayjs from 'dayjs'
import MobileCalendar from './MobileCalendar/page'
import Helper from '../../../util/helper'
import MobileCalendarListView from './MobileCalendarListView/page'
import SpinWrapper from '../../SpinWrapper/page'
import MobileSchedulePreviewOfSelectedDate from './MobileSchedulePreviewOfSelectedDate/page'
import MobileCalendarSingleScheduleView from './MobileCalendarSingleScheduleView/page'
import { removeItem } from '../../../libs/encryptedStorage'
import FiltersBottomSheet from '../../BottomSheets/FiltersBottomSheet'
import MobileFilterButton from './MobileFilterButton/page'


const MobileCalendarView = (props: any) => {

    const {
        search,
        handleSearch,
        packageList,
        packageFilter,
        handlePackageFilterChange,
        instructorFilter,
        handleInstructorFilterChange,
        allCentersList,
        centerFilter,
        handleCenterFilterChange,
        filteredCalendars,
        selectedSchedule,
        setSelectedSchedule,
        calendarReducer,
        firebase
    } = props;

    const [dateToFilteredSlotsArrMap, setDateToFilteredSlotsArrMap] = useState(new Map());

    const [monthInDisplay, setMonthInDisplay] = useState(dayjs.tz().startOf('day'));
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(dayjs.tz().startOf('day'));

    const [showDailyView, setShowDailyView] = useState(false);
    const [showMobileSingleScheduleView, setShowMobileSingleScheduleView] = useState(false);

    const [showFiltersBottomSheet, setShowFiltersBottomSheet] = useState(false);
    const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

    useEffect(() => {

        const hashVal = window.location.hash;

        if (hashVal === '#list-view-open') setShowDailyView(true);
        else if (hashVal === '#schedule-detail-view-open') {
            setShowMobileSingleScheduleView(true);
        }

    }, []);

    useEffect(() => {

        const tempDateToFilteredSlotsArrMap = new Map();

        if (filteredCalendars) {
            filteredCalendars.forEach((slotsObj) => {
                if (!tempDateToFilteredSlotsArrMap.has(dayjs.tz(slotsObj.date).format('DD-MM-YYYY'))) {
                    tempDateToFilteredSlotsArrMap.set(
                        dayjs.tz(slotsObj.date).format('DD-MM-YYYY'),
                        [{ ...slotsObj }]
                    );
                }
                else {
                    const newArr = [...tempDateToFilteredSlotsArrMap.get(
                        dayjs.tz(slotsObj.date).format('DD-MM-YYYY')
                    ),
                    { ...slotsObj }];

                    tempDateToFilteredSlotsArrMap.set(
                        dayjs.tz(slotsObj.date).format('DD-MM-YYYY'),
                        newArr
                    );
                }
            });

            tempDateToFilteredSlotsArrMap.forEach((value, key) => {
                value.sort((a, b) => Helper.sortIntegerValue(a, b, '1740792600000'));
            });

            setDateToFilteredSlotsArrMap(tempDateToFilteredSlotsArrMap);
        }
    }, [filteredCalendars]);


    useEffect(() => {
        const handleHashChange = () => {
            const hashVal = window.location.hash;
            if (!hashVal) {
                setShowDailyView(false);
                setShowMobileSingleScheduleView(false);
            }
            else if (hashVal === '#list-view-open') {
                setShowDailyView(true);
                setShowMobileSingleScheduleView(false);
            }
            else if (hashVal === '#schedule-detail-view-open') {
                setShowMobileSingleScheduleView(true);
                setShowDailyView(false);
            }
        };

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);


    function closeMobileSingleScheduleView() {
        setSelectedSchedule(undefined);
        removeItem('selectedSchedule');
        setShowMobileSingleScheduleView(false);
    }

    const [filterNameToFilterMetaDataObjMap, setFilterNameToFilterMetaDataObjMap] = useState(
        (Helper.isAccount(firebase))
            ? getFilterNameToFilterMetaDataObjMapForAccountLogin()
            : getFilterNameToFilterMetaDataObjMapForStaffLogin()
    );

    function getFilterNameToFilterMetaDataObjMapForAccountLogin() {
        const newFilterNameToFilterMetaDataObjMap = new Map([
            ['filterLabel.packages', {
                optionsArr: packageList || [],
                selectedVal: packageFilter,
                setSelectedVal: handlePackageFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            }]
        ]);
        return newFilterNameToFilterMetaDataObjMap;
    }

    function getFilterNameToFilterMetaDataObjMapForStaffLogin() {
        const newFilterNameToFilterMetaDataObjMap = new Map([
            ['filterLabel.packages', {
                optionsArr: packageList || [],
                selectedVal: packageFilter,
                setSelectedVal: handlePackageFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            }],
            ['filterLabel.centers', {
                optionsArr: allCentersList || [],
                selectedVal: centerFilter,
                setSelectedVal: handleCenterFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            }],
            ['filterLabel.instructors', {
                optionsArr: Helper.getTeacherList() || [],
                selectedVal: instructorFilter,
                setSelectedVal: handleInstructorFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            }]
        ]);
        return newFilterNameToFilterMetaDataObjMap;
    }

    useEffect(() => {
        setFilterNameToFilterMetaDataObjMap(
            Helper.isAccount(firebase)
                ? getFilterNameToFilterMetaDataObjMapForAccountLogin()
                : getFilterNameToFilterMetaDataObjMapForStaffLogin()
        );
    }, [packageList, packageFilter, handlePackageFilterChange, instructorFilter, handleInstructorFilterChange, centerFilter, handleCenterFilterChange, allCentersList]);


    return (
        <SpinWrapper
            spinning={calendarReducer.isLoading || false}
        >
            <Box>
                {
                    (!showMobileSingleScheduleView) ?
                        <>
                            <PageHeaderWrapper>
                                <div className="global-page-header">
                                    {formatMsg('mySchedule')}
                                </div>
                            </PageHeaderWrapper>

                            <div className='mobile-calendar-filter-row'>
                                <div className='mobile-calendar-search'>
                                    <Input
                                        placeholder={formatMsg("search")}
                                        value={search}
                                        onChange={(e) => handleSearch(e)}
                                        suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                </div>
                                <MobileFilterButton
                                    setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                                    appliedFiltersCount={appliedFiltersCount}
                                />
                            </div>

                            {
                                (!showDailyView) ?
                                    <>
                                        <MobileCalendar
                                            monthInDisplay={monthInDisplay}
                                            setMonthInDisplay={setMonthInDisplay}
                                            calendarSelectedDate={calendarSelectedDate}
                                            setCalendarSelectedDate={setCalendarSelectedDate}
                                            dateToFilteredSlotsArrMap={dateToFilteredSlotsArrMap}
                                            setShowDailyView={setShowDailyView}
                                            firebase={firebase}
                                        />
                                        <MobileSchedulePreviewOfSelectedDate
                                            calendarSelectedDate={calendarSelectedDate}
                                            dateToFilteredSlotsArrMap={dateToFilteredSlotsArrMap}
                                            setSelectedSchedule={setSelectedSchedule}
                                            setShowMobileSingleScheduleView={setShowMobileSingleScheduleView}
                                            firebase={firebase}
                                        />
                                    </>
                                    :
                                    <MobileCalendarListView
                                        calendarSelectedDate={calendarSelectedDate}
                                        dateToFilteredSlotsArrMap={dateToFilteredSlotsArrMap}
                                        monthInDisplay={monthInDisplay}
                                        setMonthInDisplay={setMonthInDisplay}
                                        setCalendarSelectedDate={setCalendarSelectedDate}
                                        setSelectedSchedule={setSelectedSchedule}
                                        setShowMobileSingleScheduleView={setShowMobileSingleScheduleView}
                                        firebase={firebase}
                                    />
                            }
                        </>
                        :
                        <MobileCalendarSingleScheduleView
                            selectedSchedule={selectedSchedule}
                            drawerOnClose={closeMobileSingleScheduleView}
                            isLoading={calendarReducer.isLoading}
                            scheduleDetail={calendarReducer.scheduleDetail}
                            firebase={firebase}
                        />
                }
            </Box>
            <FiltersBottomSheet
                filterNameToFilterMetaDataObjMap={filterNameToFilterMetaDataObjMap}
                showFiltersBottomSheet={showFiltersBottomSheet}
                setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                setAppliedFiltersCount={setAppliedFiltersCount}
            />
        </SpinWrapper>
    )
}

export default MobileCalendarView