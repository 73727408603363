import { createSlice } from '@reduxjs/toolkit'


export const defaultMulticenterReducerState = {
    isLoading: false,
    allCentersList: [],
    lowercasedCenterNamesSet: new Set(),
    centerIdToCenterObjMap: new Map(),
    centersListOfCurrPackageInView: [],
    operationType: ''
}

const initialState = defaultMulticenterReducerState; 


export const mulitCenterSlice = createSlice({
    name: 'multicenter',
    initialState: { ...initialState },
    reducers: {
        getAllCentersList: (state, action) => {
            state.isLoading = true;
        },
        saveCenter: (state, action) => {
            state.isLoading = true;
        },
        deleteCenter: (state, action) => {
            state.isLoading = true;
        },
        getCentersListOfSinglePackage: (state, action) => {
            state.isLoading = true;
        },
        multiCenterSagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        multiCenterSagaFailed: (state, action) => {
            state.operationType = '';
            state.isLoading = false;
        },
        resetMultiCenterOperationType: (state, action) => {
            state.operationType = '';
            state.isLoading = false;
        },
        resetMulticenterReducer: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        }
    },
})


export const {
    getAllCentersList,
    saveCenter,
    deleteCenter,
    getCentersListOfSinglePackage,
    multiCenterSagaSuccess,
    multiCenterSagaFailed,
    resetMultiCenterOperationType,
    resetMulticenterReducer
} = mulitCenterSlice.actions;


export default mulitCenterSlice.reducer;