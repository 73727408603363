'use client'

import React from 'react'
import MobileCalendarSlotViewCard from '../MobileCalendarSlotViewCard/page';
import dayjs from 'dayjs';
import styles from './style.module.css'


const MobileSchedulePreviewOfSelectedDate = (props: any) => {

    const {
        calendarSelectedDate,
        dateToFilteredSlotsArrMap,
        setSelectedSchedule,
        setShowMobileSingleScheduleView,
    } = props;

    const currDateStr = calendarSelectedDate?.format('DD-MM-YYYY') || '';
    const selectedDaySlotsArr = dateToFilteredSlotsArrMap?.get(currDateStr) || [];

    return (
        <div className={styles.schedulePreview}>
            <div className={styles.selectedDate}>
                {dayjs.tz(dayjs(currDateStr, 'DD-MM-YYYY')).format('Do MMMM, YYYY')}
            </div>
            {
                selectedDaySlotsArr.map((slotsObj) => {
                    return (
                        <MobileCalendarSlotViewCard
                            slotsObj={slotsObj}
                            setSelectedSchedule={setSelectedSchedule}
                            setShowMobileSingleScheduleView={setShowMobileSingleScheduleView}
                        />
                    )
                })
            }
        </div>
    )
}

export default MobileSchedulePreviewOfSelectedDate