"use client"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./style.css"
import Sticker from '../sticker/page'
import formatMsg from '../../util/formatMsg'
import { TableWrapper } from '../wrapperComponents/page'
import { Col, Input, Row, Select, Spin, Tag } from 'antd'
import Helper from '../../util/helper'
import { SearchOutlined } from '@ant-design/icons';
import { FilterDateSelector, FiltersBottomSheet, MobileFilterButton, multicenterActions, packageActions } from 'sharedresources'
import { useDispatch } from 'react-redux'
import dayjs from '../../libs/day'
import TransactionMobileCard from './TransactionMobileCard'
const { getAllCentersList } = multicenterActions;
const { getPackageListDropdown } = packageActions;

const page = (props) => {

    const dispatch = useDispatch();
    const { accountReducer, multicenterReducer, packageReducer, invoiceReducer, firebase } = Helper.getSelectorState(["accountReducer", "multicenterReducer", "packageReducer", "invoiceReducer"]);

    const { allCentersList } = multicenterReducer;
    const { packageList } = packageReducer;

    const { transactionLists } = props;

    const [transactionList, setTransactionList] = useState([]);
    const [totalTransaction, setTotalTransaction] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [failedTransaction, setFailedTransaction] = useState({
        amount: 0,
        count: 0
    })

    const currency = Helper.getCurrency(firebase);

    const [search, setSearch] = useState(undefined);
    const [packageFilter, setPackageFilter] = useState(undefined);
    const [centerFilter, setCenterFilter] = useState(undefined);
    const [accountFilter, setAccountFilter] = useState(undefined);
    const [statusFilter, setStatusFilter] = useState(undefined);

    const [accountsMap, setAccountsMap] = useState(new Map());
    const [centersMap, setCentersMap] = useState(new Map());
    const [packagesMap, setPackagesMap] = useState(new Map());

    const [showFiltersBottomSheet, setShowFiltersBottomSheet] = useState(false);
    const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
    const [txPageNo, setTxPageNo] = useState(1);
    const [txPageSize, setTxPageSize] = useState(10);

    const handleAccountFilterChange = useCallback((acountFilterVal) => {

        const isAllSelected: boolean = acountFilterVal?.some((val) => val === 'all accounts') || false;

        if (Helper.getAccountList() && isAllSelected) {
            acountFilterVal = Helper.getAccountList().map((item) => item.id);
        }

        let stringifiedData = acountFilterVal ? JSON.stringify(acountFilterVal) : '';

        setAccountFilter(acountFilterVal);
        Helper.setUrlParam("transactAccountFilter", stringifiedData);

    }, [setAccountFilter, accountReducer.accountList]);

    const handleCenterFilterChange = useCallback((centerFilterVal) => {

        const isAllSelected: boolean = centerFilterVal?.some((val) => val === 'all centers') || false;

        if (allCentersList && isAllSelected) {
            centerFilterVal = allCentersList.map((item) => item.id);
        }

        let stringifiedData = centerFilterVal ? JSON.stringify(centerFilterVal) : '';

        setCenterFilter(centerFilterVal);
        Helper.setUrlParam('transactCenterFilter', stringifiedData);

    }, [setCenterFilter, allCentersList]);

    const handlePackageFilterChange = useCallback((packageFilterVal) => {

        const isAllSelected: boolean = packageFilterVal?.some((val) => val === 'all packages') || false;

        if (packageList && isAllSelected) {
            packageFilterVal = packageList.map((item) => item.id);
        }

        let stringifiedData = packageFilterVal ? JSON.stringify(packageFilterVal) : '';

        setPackageFilter(packageFilterVal);
        Helper.setUrlParam('transactPackageFilter', stringifiedData);

    }, [setPackageFilter, packageList]);

    const handleStatusFilterChange = useCallback((statusFilterVal) => {

        const isAllSelected: boolean = statusFilterVal?.some((val) => val === 'all status') || false;

        if (transactionStatusesList.current && isAllSelected) {
            statusFilterVal = transactionStatusesList.current.map((item) => item);
        }

        let stringifiedData = statusFilterVal ? JSON.stringify(statusFilterVal) : '';

        setStatusFilter(statusFilterVal);
        Helper.setUrlParam('transactStatusFilter', stringifiedData);

    }, [setStatusFilter]);


    const handleSearchChange = useCallback((val) => {
        setSearch(val);
        Helper.setUrlParam('transactSearch', val ? val : '');
    }, [setSearch]);

    const handlePageChange = useCallback((newPageNo, newPageSize) => {
        setTxPageNo(newPageNo);
        setTxPageSize(newPageSize);
        Helper.setUrlParam('txPageNo', newPageNo ? newPageNo : 1);
        Helper.setUrlParam('txPageSize', newPageSize ? newPageSize : 10);
    }, [setTxPageNo, setTxPageSize]);


    useEffect(() => {
        if (Helper.getAccountList()) {
            const newAccountsMap = new Map();
            Helper.getAccountList().forEach((item) => newAccountsMap.set(item.id, item));
            setAccountsMap(newAccountsMap);
        }
    }, [accountReducer.accountList]);

    useEffect(() => {
        if (allCentersList) {
            const newCentersMap = new Map();
            allCentersList.forEach((item) => newCentersMap.set(item.id, item));
            setCentersMap(newCentersMap);
        }
    }, [allCentersList]);

    useEffect(() => {
        if (packageList) {
            const newPackagesMap = new Map();
            packageList.forEach((item) => newPackagesMap.set(item.id, item));
            setPackagesMap(newPackagesMap);
        }
    }, [packageList]);


    const transactionStatusesList = useRef([
        "successful",
        "failed"
    ]);


    const isTeacherLoggedIn = Helper.getLoggedInUserType(firebase).toLowerCase() === "teacher";

    const renderSearchBar = isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);
    const renderAccountFilter = isTeacherLoggedIn && isView(['adminPaymentsView', 'adminPackageDetail']);
    const renderCenterFilter = isTeacherLoggedIn && isView(['adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);
    const renderPackageFilter = isView(['userPaymentsView', 'adminPaymentsView', 'adminAccountDetail']);
    const renderStatusFilter = isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']);


    const [filterNameToFilterMetaDataObjMap, setFilterNameToFilterMetaDataObjMap] = useState(
        getFilterNameToFilterMetaDataObjMapForView()
    );


    function getFilterNameToFilterMetaDataObjMapForView() {

        const newFilterNameToFilterMetaDataObjMap = new Map();

        if (renderAccountFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.accounts', {
                optionsArr: Helper.getAccountList() || [],
                selectedVal: accountFilter,
                setSelectedVal: handleAccountFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderCenterFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.centers', {
                optionsArr: allCentersList || [],
                selectedVal: centerFilter,
                setSelectedVal: handleCenterFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderPackageFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.packages', {
                optionsArr: packageList || [],
                selectedVal: packageFilter,
                setSelectedVal: handlePackageFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }
        if (renderStatusFilter) {
            newFilterNameToFilterMetaDataObjMap.set('filterLabel.status', {
                optionsArr: transactionStatusesList.current || [],
                selectedVal: statusFilter,
                setSelectedVal: handleStatusFilterChange,
                showAllOption: true,
                filterType: 'multiSelect'
            });
        }

        return newFilterNameToFilterMetaDataObjMap;
    }

    useEffect(() => {
        setFilterNameToFilterMetaDataObjMap(
            getFilterNameToFilterMetaDataObjMapForView()
        );
    }, [accountReducer.accountList, accountFilter, handleAccountFilterChange, allCentersList, centerFilter, handleCenterFilterChange, packageList, packageFilter, handlePackageFilterChange, statusFilter, handleStatusFilterChange]);

    useEffect(() => {
        getTotalTransaction();
        getTotalRevenue();
        getTotalFailedTransaction();
    }, [transactionLists])

    useEffect(() => {
        filterList();
    }, [transactionLists, search, packageFilter, centerFilter, accountFilter, statusFilter]);

    const userIdToObjMap = useRef(new Map());
    const usersList = Helper.getUserList();

    const newUserMap = new Map();
    usersList.forEach((userObj) => {
        newUserMap.set(userObj.id, userObj);
    });

    userIdToObjMap.current = newUserMap;

    const [accountIdToObjMap, setAccountIdToObjMap] = useState(new Map());
    const [packageIdToObjMap, setPackageIdToObjMap] = useState(new Map());

    useEffect(() => {
        const newAccountMap = new Map();
        Helper.getAccountList()?.forEach((accountObj) => {
            newAccountMap.set(accountObj.id, accountObj);
        });
        setAccountIdToObjMap(newAccountMap);
    }, [accountReducer.accountList]);

    useEffect(() => {
        const newPackageMap = new Map();
        packageList.forEach((packageObj) => {
            newPackageMap.set(packageObj.id, packageObj);
        });
        setPackageIdToObjMap(newPackageMap);
    }, [packageList]);


    const getFilterUrlParam = () => {

        let search = Helper.getUrlParam("transactSearch");
        search = Helper.checkUrlParam(search, undefined);
        if (search) setSearch(search);

        let accountFilterJSONString = Helper.getUrlParam("transactAccountFilter");
        let accountFilterParsedJSON = accountFilterJSONString ? JSON.parse(accountFilterJSONString) : undefined;
        if (accountFilterParsedJSON) setAccountFilter(accountFilterParsedJSON);

        let centerFilterJSONString = Helper.getUrlParam("transactCenterFilter");
        let centerFilterParsedJSON = centerFilterJSONString ? JSON.parse(centerFilterJSONString) : undefined;
        if (centerFilterParsedJSON) setCenterFilter(centerFilterParsedJSON);

        let packageFilterJSONString = Helper.getUrlParam("transactPackageFilter");
        let packageFilterParsedJSON = packageFilterJSONString ? JSON.parse(packageFilterJSONString) : undefined;
        if (packageFilterParsedJSON) setPackageFilter(packageFilterParsedJSON);

        let statusFilterJSONString = Helper.getUrlParam("transactStatusFilter");
        let statusFilterParsedJSON = statusFilterJSONString ? JSON.parse(statusFilterJSONString) : undefined;
        if (statusFilterParsedJSON) setStatusFilter(statusFilterParsedJSON);

        let txPageNo: any = Helper.getUrlParam("txPageNo");
        txPageNo = Helper.checkUrlParam(txPageNo, 1, "number");
        if (txPageNo) setTxPageNo(txPageNo);

        let txPageSize: any = Helper.getUrlParam("txPageSize");
        txPageSize = Helper.checkUrlParam(txPageSize, 10, "number");
        if (txPageSize) setTxPageSize(txPageSize);
    }


    useEffect(() => {

        getFilterUrlParam();

        if (isTeacherLoggedIn) {
            dispatch(getAllCentersList({ firebase }));
        }
        dispatch(getPackageListDropdown({ firebase }));
    }, [])

    const getTotalTransaction = () => {
        setTotalTransaction(transactionLists.length);
    };

    const getTotalRevenue = () => {
        const revenue = (Array.isArray(transactionLists) ? transactionLists : []).reduce((sum, transaction) => {
            const amount = Number(transaction?.amount);
            return sum + (isNaN(amount) ? 0 : amount);
        }, 0);

        setTotalRevenue(revenue);
    };

    const getTotalFailedTransaction = () => {
        const summary = (Array.isArray(transactionLists) ? transactionLists : []).reduce(
            (acc, transaction) => {
                if (transaction?.status === "failed") {
                    const amount = Number(transaction.amount);
                    if (!isNaN(amount)) {
                        acc.amount += amount;
                        acc.count += 1;
                    }
                }
                return acc;
            },
            { amount: 0, count: 0 }
        );

        setFailedTransaction(summary);
    };

    const filterList = () => {

        let selectedAccountsSet = new Set();
        if (accountFilter?.length) selectedAccountsSet = new Set(accountFilter);

        let selectedCentersSet = new Set();
        if (centerFilter?.length) selectedCentersSet = new Set(centerFilter);

        let selectedPackagesSet = new Set();
        if (packageFilter?.length) selectedPackagesSet = new Set(packageFilter);

        let selectedStatusesSet = new Set();
        if (statusFilter?.length) {
            statusFilter.forEach((status) => {
                selectedStatusesSet.add(status.toLowerCase());
            });
        }

        let filteredData = (Array.isArray(transactionLists) ? transactionLists : []).filter((transaction) => {
            
            const matchedUser = userIdToObjMap.current && userIdToObjMap.current.get(transaction.profileId);
            const matchedAccount = accountIdToObjMap && accountIdToObjMap.get(transaction.accountId);

            const searchMatch = !search || search.trim() == "" || (transaction.transactionId && transaction.transactionId.toLowerCase().includes(search.toLowerCase())) || (transaction.invoiceNumber && transaction.invoiceNumber.toLowerCase().includes(search.toLowerCase())) || (matchedAccount?.name && matchedAccount.name.toLowerCase().includes(search.toLowerCase())) || (matchedUser?.name && matchedUser.name.toLowerCase().includes(search.toLowerCase())) || (transaction.packageName && transaction.packageName.toLowerCase().includes(search.toLowerCase()));

            const packageMatch = !packageFilter?.length || selectedPackagesSet.has(transaction.packageId);

            const centerMatch = !centerFilter?.length || selectedCentersSet.has(transaction.centerId);

            const accountMatch = !accountFilter?.length || (transaction.accountId && selectedAccountsSet.has(transaction.accountId));

            const statusMatch = !statusFilter?.length || selectedStatusesSet.has(transaction.status.toLowerCase());

            return searchMatch && packageMatch && centerMatch && accountMatch && statusMatch;
        });

        setTransactionList(filteredData);
    };



    let columns = [
        {
            key: 'transactionId',
            dataIndex: 'transactionId',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("transactionId")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "transactionId"),
            render: (text, record) => {
                return (
                    <div className="inv-main-body">{record.transactionId || " - "}</div>
                )
            }
        },
        ...(isTeacherLoggedIn ? [{
            key: 'customerAccounts',
            dataIndex: 'accountId',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("customerAccounts")}</div>
            </div>,
            sorter: (a, b) => {
                const accountA = accountIdToObjMap && accountIdToObjMap.get(a.accountId)?.name || "";
                const accountB = accountIdToObjMap && accountIdToObjMap.get(b.accountId)?.name || "";
                return accountA.localeCompare(accountB);
            },
            render: (text, record) => {
                const matchedUser = userIdToObjMap.current && userIdToObjMap.current.get(record.profileId);
                const matchedAccount = accountIdToObjMap && accountIdToObjMap.get(record.accountId);
                return (
                    <div>
                        {matchedAccount?.name && <div className="inv-main-body" style={{ marginBottom: "10px" }}>{matchedAccount.name}</div>}
                        {matchedUser?.name && (
                            <Tag color={"#DDDDDD"} style={{ borderRadius: "12px", color: "black" }}>
                                {matchedUser?.name}
                            </Tag>
                        )}
                    </div>
                )
            }
        }] : []),
        {
            key: 'invoiceId',
            dataIndex: 'invoiceNumber',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("invoice.invoiceNumber")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "invoiceNumber"),
            render: (text, record) => {
                return (
                    <div className="inv-main-body">
                        {record.invoiceNumber || " - "}
                    </div>
                )
            }
        },
        {
            key: 'packageName',
            dataIndex: 'packageId',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("invoice.packageName")}</div>
            </div>,
            sorter: (a, b) => {
                const packageA = packageIdToObjMap && packageIdToObjMap.get(a.packageId)?.name || "";
                const packageB = packageIdToObjMap && packageIdToObjMap.get(b.packageId)?.name || "";
                return packageA.localeCompare(packageB);
            },
            render: (text, record) => {
                {
                    const matchedPackage = packageIdToObjMap && packageIdToObjMap.get(record.packageId);
                    return (
                        matchedPackage?.name && <div className="inv-main-body">
                            {matchedPackage?.name}
                        </div>
                    )
                }
            }
        },
        {
            key: 'paymentMethod',
            dataIndex: 'paymentMode',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("paymentMethod")}</div>
            </div>,
            sorter: (a, b) => Helper.sortStringValue(a, b, "paymentMode"),
            render: (text, record) => (
                <div className="inv-main-body">
                    <div>{record.paymentMode}</div>
                </div>
            )
        },
        {
            key: 'date',
            dataIndex: 'date',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("date")}</div>
            </div>,
            sorter: (a, b) => Helper.sortIntegerValue(a, b, "date"),
            render: (text, record) => {
                return (
                    <div className="inv-main-body">
                        {dayjs.tz(record.date).format("DD MMM YYYY")}
                    </div>
                )
            }
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("amount")}</div>
            </div>,
            sorter: (a, b) => Helper.sortIntegerValue(a, b, "amount"),
            render: (text, record) => {
                return (
                    <div>
                        <div className="inv-main-body">{formatMsg('paid')}: {currency}{record.amount}</div>
                    </div>
                )
            }

        },
        {
            key: 'status',
            dataIndex: 'status',
            title: <div>
                <div className="tsx-main-heading">{formatMsg("status")}</div>
            </div>,
            render: (text, record) => {

                return (
                    <div>
                        <div className="inv-main-body">{Helper.getAmountStatusTag(text)}</div>
                    </div>

                )
            }
        },
    ]

    function isView(inputArr) {
        return inputArr.some((viewVal) => viewVal === props.view);
    }

    const isLoading = accountReducer.isLoading || multicenterReducer.isLoading || packageReducer.isLoading || invoiceReducer.isLoading;

    return (
        <>
            <div>
                <Row align={"middle"} justify={"center"} style={{ marginBottom: 24 }}>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className="transaction-container">

                            {isTeacherLoggedIn &&
                                isView(['adminPaymentsView', 'adminPackageDetail']) &&
                                <div className="stickers-container">
                                    <Sticker
                                        name={"TOTAL_TRANSACTION"}
                                        style={styles.stickerStyle}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>

                                                <div className="sticker-left">
                                                    <img src="/images/oustanding.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("totalTransaction")}</div>
                                                </div>
                                                <div className="sticker-value">{totalTransaction}</div>
                                            </div>

                                        }
                                        onClick={() => {
                                            return null;
                                        }}
                                    />

                                    <Sticker
                                        name={"TOTAL_REVENUE"}
                                        style={styles.stickerStyle}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="sticker-left">
                                                    <img src="/images/paid.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("totalRevenue")}</div>
                                                </div>
                                                <div className="sticker-value">{currency} {Helper.roundNumberToTwo(totalRevenue)}</div>

                                            </div>
                                        }
                                        onClick={() => {
                                            return null;
                                        }}

                                    />

                                    <Sticker
                                        name={"FAILED_TRANSACTION"}
                                        style={{ ...styles.stickerStyle, justifyContent: "space-between" }}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="sticker-left">
                                                    <img src="/images/failedTransaction.png" className="sticker-icon" />
                                                    <div className="sticker-title">{formatMsg("failedTransaction")}</div>
                                                </div>
                                                <div className="sticker-value">{currency} {Helper.roundNumberToTwo(failedTransaction.amount)}</div>

                                            </div>
                                        }
                                        subTitle={
                                            <div className="sticker-badge overdue">{failedTransaction.count}</div>
                                        }
                                        onClick={() => {
                                            return null;
                                        }}
                                    />
                                </div>}

                            <Row
                                justify="space-between"
                                typeof="flex"
                                gutter={[15, 12]}
                                className='laptop-payment-filters-row'
                            >
                                {
                                    renderSearchBar &&
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                                        <Row typeof="flex" gutter={[15, 12]}>
                                            <Col
                                                xxl={20}
                                                xl={20}
                                                lg={12}
                                                md={12}
                                                sm={24}
                                                xs={24}
                                            >
                                                <Input
                                                    placeholder={formatMsg("transaction.search")}
                                                    style={{ width: "100%" }}
                                                    value={search}
                                                    onChange={(e) => handleSearchChange(e.target.value)}
                                                    suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                }
                                <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                    <Row typeof="flex" justify="end" gutter={[15, 12]}>
                                        {
                                            isView(['adminPackageDetail', "adminAccountDetail"]) &&
                                            <Col
                                                xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}
                                            >
                                                <FilterDateSelector
                                                    dateRange={props.dateRange}
                                                    setDateRange={props.setDateRange}
                                                />
                                            </Col>
                                        }
                                        {
                                            renderAccountFilter &&
                                            <Col
                                                xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}
                                            >
                                                <Select
                                                    mode='multiple'
                                                    onChange={handleAccountFilterChange}
                                                    maxTagCount={0}
                                                    maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('account', accountFilter, accountsMap)}
                                                    placeholder={formatMsg("placeholder.selectCustomerAccounts")}
                                                    style={{ width: "100%" }}
                                                    value={accountFilter}
                                                    allowClear
                                                    popupMatchSelectWidth={false}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                                >
                                                    <Select.Option
                                                        key={'all accounts'}
                                                        value={'all accounts'}
                                                    >
                                                        {formatMsg('caps.allAccounts')}
                                                    </Select.Option>
                                                    {Helper.getAccountList()?.map((account, index) => (
                                                        <Select.Option key={account.id} value={account.id}>
                                                            {account.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        }
                                        {
                                            renderCenterFilter &&
                                            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                                <Select
                                                    mode='multiple'
                                                    onChange={handleCenterFilterChange}
                                                    maxTagCount={0}
                                                    maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('center', centerFilter, centersMap)}
                                                    placeholder={formatMsg("placeholder.selectCenters")}
                                                    style={{ width: "100%" }}
                                                    value={centerFilter}
                                                    allowClear
                                                    popupMatchSelectWidth={false}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                                >
                                                    <Select.Option
                                                        key={"all"}
                                                        value={"all centers"}
                                                    >
                                                        {formatMsg("allCenters")}
                                                    </Select.Option>
                                                    {allCentersList.map((center, index) => (
                                                        <Select.Option key={center.id} value={center.id}>
                                                            {center.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        }
                                        {
                                            renderPackageFilter &&
                                            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                                <Select
                                                    mode='multiple'
                                                    onChange={handlePackageFilterChange}
                                                    maxTagCount={0}
                                                    maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('package', packageFilter, packagesMap)}
                                                    placeholder={formatMsg("placeholder.selectPackages")}
                                                    style={{ width: "100%" }}
                                                    value={packageFilter}
                                                    allowClear
                                                    popupMatchSelectWidth={false}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                                >
                                                    <Select.Option
                                                        key={"all"}
                                                        value={"all packages"}
                                                    >
                                                        {formatMsg("caps.allPackages")}
                                                    </Select.Option>
                                                    {packageList.map((item, index) => (
                                                        <Select.Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        }

                                        {
                                            renderStatusFilter &&
                                            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={styles.colStyle}>
                                                <Select
                                                    mode='multiple'
                                                    onChange={handleStatusFilterChange}
                                                    maxTagCount={0}
                                                    maxTagPlaceholder={Helper.getFilterMaxTagPlaceholder('status', statusFilter)}
                                                    placeholder={formatMsg("selectStatus")}
                                                    style={{ width: "100%" }}
                                                    value={statusFilter}
                                                    allowClear
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                                                >
                                                    {
                                                        ["all status", ...transactionStatusesList.current].map((status) => {
                                                            return (
                                                                <Select.Option
                                                                    value={status}
                                                                >
                                                                    {formatMsg(status || '-')}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            {
                                isView(['userPaymentsView', 'adminPaymentsView', 'adminPackageDetail', 'adminAccountDetail']) &&
                                <div className='mobile-payment-filters-row'>
                                    <div className='mob-payments-search-filter-container'>
                                        <Input
                                            width={"100%"}
                                            value={search}
                                            onChange={e => handleSearchChange(e.target.value)}
                                            placeholder={formatMsg("search")}
                                            suffix={<SearchOutlined />}
                                        />
                                    </div>

                                    <div className='mob-date-range-selector-row'>
                                        {
                                            (props.dateRange && props.setDateRange) &&
                                            <div className='mobile-date-range-filter'>
                                                <FilterDateSelector
                                                    dateRange={props.dateRange}
                                                    setDateRange={props.setDateRange}
                                                />
                                            </div>
                                        }

                                        <MobileFilterButton
                                            setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                                            appliedFiltersCount={appliedFiltersCount}
                                        />
                                    </div>
                                </div>
                            }

                            <div className='transactions-table-view'>
                                <TableWrapper
                                    columns={columns}
                                    dataSource={transactionList}
                                    pagination={{
                                        current: txPageNo,
                                        pageSize: txPageSize,
                                        onChange: handlePageChange,
                                        showSizeChanger: true,
                                        hideOnSinglePage: false
                                    }}
                                    loading={isLoading}
                                />
                            </div>

                            <Spin spinning={isLoading}>
                                <div className='transactions-list-view'>
                                    {transactionList.map((transactionObj) => (
                                        <TransactionMobileCard
                                            transactionObj={transactionObj}
                                            firebase={firebase}
                                            packageIdToObjMap={packageIdToObjMap}
                                        />
                                    ))}
                                </div>
                            </Spin>
                        </div>
                    </Col>
                </Row>
            </div>
            <FiltersBottomSheet
                filterNameToFilterMetaDataObjMap={filterNameToFilterMetaDataObjMap}
                showFiltersBottomSheet={showFiltersBottomSheet}
                setShowFiltersBottomSheet={setShowFiltersBottomSheet}
                setAppliedFiltersCount={setAppliedFiltersCount}
            />
        </>
    )
}

export default page
const styles = {
    colStyle: {
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: "nowrap"
    },
    stickerStyle: {
        minWidth: "320px",
        maxWidth: "400px",
        flex: "1 1 fit-content",
        boxShadow: "0px 4px 25px 0px #00000026",
        // border: "1px solid #D0D0D0",
        borderRadius: "12px"
    }
}
