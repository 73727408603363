"use client";
import styled from "styled-components";
import { palette } from "styled-theme";
// import WithDirection from "../../settings/withDirection";
import colors from "../../../admin/src/libs/colorFactory";
import webFonts from "../../../admin/src/libs/fonts";
// import "./style.css";

const SignInStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
    right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
    background-color: #511c7a;
  }

  .isoLoginContentWrapper {
    width: 525px;
    height: 100%;
    z-index: 10;
    position: relative;
  }

  .isoLoginContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 50px;
    margin-top: 8vh;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 70px 20px;
    }

    .signinButtonsHolder {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: black;
      font-weight: 500;
      text-align: center;
    }

    .isoFormHeadText {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      justify-content: center;

      h3 {
        font-size: var(--font-size-14);
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 7px;
        color: ${palette("text", 0)};
      }

      p {
        font-size: var(--font-size-13);
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        color: ${palette("text", 2)};
      }
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .isoEmailWrapper {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-input-outlined {
          background: transparent;
        }

        input {
          height: 50px;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid ${colors.color_lightGrey};
          box-shadow: none;
          background: transparent;
          &::-webkit-input-placeholder {
            color: #d0d0d0;
          }

          &:-moz-placeholder {
            color: #d0d0d0;
          }

          &::-moz-placeholder {
            color: #d0d0d0;
          }
          &:-ms-input-placeholder {
            color: #d0d0d0;
          }
        }
      }
      .isoCenterComponent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .isoInputWrapper {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-input-outlined {
          background: transparent;
        }

        .ant-input-affix-wrapper {
          border: 0;
          border-bottom: 1px solid ${colors.color_lightGrey};
          border-radius: 0;
        }

        .ant-input {
          border: 0;
          // border-bottom: 1px solid ${colors.color_lightGrey};
          border-radius: 0;
        }

        .ant-select-selector {
          background: transparent !important;
        }

        input {
          height: 50px;
          border: 0;
          border-radius: 0;
          // border-bottom: 1px solid ${colors.color_lightGrey};
          box-shadow: none;
          background: transparent;
          &::-webkit-input-placeholder {
            color: #d0d0d0;
          }

          &:-moz-placeholder {
            color: #d0d0d0;
          }

          &::-moz-placeholder {
            color: #d0d0d0;
          }
          &:-ms-input-placeholder {
            color: #d0d0d0;
          }
        }

        .ant-input {
          color: ${colors.color_mediumGrey};
          font-size: ${webFonts.large};
        }

        .form-control {
          border: 0;
          border-bottom: 1px solid ${colors.color_lightGrey};
          background: transparent;
        }

        .flag-dropdown {
          background: transparent;
          border: 0;
        }

        .react-tel-input .selected-flag:hover,
        .react-tel-input .selected-flag:focus {
          background: transparent;
        }

        .special-label {
          display: none;
        }

        .react-tel-input .country-list .divider {
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .react-tel-input .selected-flag {
          width: 50px;
          height: 55%;
          padding: 0 0px 1px 8px;
          border-radius: 3px 0 0 3px;
          border-right: 1px solid ${colors.color_lightGrey};
          margin-top: 26%;
        }

        .ant-select-selector {
          height: 50px;
          border-color: ${palette("grayscale", 0)};
          box-shadow: none;
          &::-webkit-input-placeholder {
            color: #d0d0d0;
          }

          &:-moz-placeholder {
            color: #d0d0d0;
          }

          &::-moz-placeholder {
            color: #d0d0d0;
          }
          &:-ms-input-placeholder {
            color: #d0d0d0;
          }
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .ant-select {
          border: none;
        }

        .ant-select-selector {
          border: none;
          border-radius: 0px;
          border-bottom: 1px solid ${colors.color_lightGrey};
        }

        .ant-select-selection.ant-select-selection--single {
          height: 50px;
          padding-top: 5px;
          border: 0;
          border-bottom: 1px solid ${colors.color_lightGrey};
          border-radius: 0;
          background: transparent;
        }

        .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus,
        .ant-select-selection:active {
          box-shadow: none !important;
        }
      }

      .isoHelperText {
        font-size: ${webFonts.small};
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${(props) =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${(props) =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: ${webFonts.medium};
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 25px;
        flex-direction: column;
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;

          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }
        }
      }

      .isoForgotPass {
        font-size: ${webFonts.lm};
        color: ${colors.v2_time_grey};
        margin-bottom: 20px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: ${colors.v2_deep_cerulean};
        }
      }

      button {
        font-weight: 500;
      }
    }
  }
`;

// export default WithDirection(SignInStyleWrapper);
export default SignInStyleWrapper;
