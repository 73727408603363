'use client'

import React, { useEffect, useState } from 'react'
import Helper from '../../util/helper'
import LinkWrapper from '../linkWrapper/page'

import styles from './style.module.css';
import formatMsg from '../../util/formatMsg';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import colors from '../../libs/colorFactory';
import MobileAccountUserMetaDataCard from '../accountProfile/AccountProfileMobileView/MobileAccountUserMetaDataCard/page';


const MobileUserCard = (props: any) => {

  const {
    user,
    currentDetailedViewOfAccountId,
    firebase,
    accounts,
    action,
    getPopContent,
    getHeaderRedirectUrl,
    canEditUserInCurrentAccount,
    deleteUserConfirmation,
    getUserCardLabel,
    getUserCardValue,
    getAccountName,
    getAccountEmail,
    getAccountPhoneNumber,
    getAccountLinkStatus,
    showPopoverActions,
    isPrimaryAccount
  } = props;

  const [fields, setFields] = useState([]);
  const [showLinkedAccounts, setShowLinkedAccounts] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [additionalFieldMap, setAdditionalFieldMap] = useState(new Map());

  useEffect(() => {

    const newAdditionalFieldMap = new Map();
    if (user?.childConfig) {
      user.childConfig.forEach((customFieldObj) => {
        newAdditionalFieldMap.set(customFieldObj.id, customFieldObj);
      });
    }
    setAdditionalFieldMap(newAdditionalFieldMap);
  }, [user]);

  useEffect(() => {

    let fieldsToRender: any[] = [
      {
        key: 'userName',
        heading: getUserCardLabel('name', additionalFieldMap),
        value: getUserCardValue('name', user, additionalFieldMap),
        prefixImg: Helper.getProfileImageUrl(user) || '-'
      },
      {
        key: 'status',
        heading: getUserCardLabel('status', additionalFieldMap),
        value: getUserCardValue('status', user, additionalFieldMap) || '-'
      }
    ];

    if (user?.childConfig) {
      user.childConfig.forEach((customFieldObj) => {
        fieldsToRender.push({
          key: customFieldObj.id,
          heading: getUserCardLabel(customFieldObj.id, additionalFieldMap),
          value: getUserCardValue(customFieldObj.id, user, additionalFieldMap)
        });
      }); 
    }

    setFields(fieldsToRender);
  }, [additionalFieldMap]);


  const getFieldsList = (accountObj, linkStatus, accountType) => {

    const fieldsList = [
      {
        key: 'accountName',
        heading: formatMsg('accountName'),
        value: getAccountName(accountObj),
        subText: isPrimaryAccount(accountType), 
        prefixImg: Helper.getProfileImageUrl(accountObj) || '-'
      },
      {
        key: 'linkStatus',
        heading: formatMsg('status'),
        value: getAccountLinkStatus(linkStatus)
      },
      {
        key: 'email',
        heading: formatMsg('email'),
        value: getAccountEmail(accountObj) || '-'
      },
      {
        key: 'phoneNumber',
        heading: formatMsg('phoneNumber'),
        value: getAccountPhoneNumber(accountObj) || '-'
      }
    ];

    return fieldsList;
  }

  return (
    <div className={styles.cardContainer}>
      <div
        className={styles.cardHeader}
        style={{ borderBottom: "0.5px solid #E0E0E0" }}
      >
        {
          (Helper.getLoggedInUserType(firebase) === 'TEACHER') ?
            <LinkWrapper href={getHeaderRedirectUrl(user)}>
              <div className={styles.headingText}>
                {user.name}
              </div>
            </LinkWrapper>
            :
            <div className={styles.headingText}>{user.name}</div>
        }
        {canEditUserInCurrentAccount(user) ?
          <div>
            <img
              src="/images/newEditImg.png"
              className={styles.editIcon}
              onClick={() => action(user)}
            />
            <img
              src="/images/newDeleteIcon.png"
              className={styles.deleteIcon}
              onClick={() => deleteUserConfirmation(user)}
            />
          </div>
          :
          null
        }
      </div>

      <div>
        {
          <MobileAccountUserMetaDataCard
            fieldsList={fields}
          />
        }
      </div>

      <div>
        {
          showLinkedAccounts &&
          <div>
            <div className="user-linked-accounts-header">
              <div className="user-linked-accounts-style">
                {formatMsg("linkedAccounts")}
              </div>
            </div>
            {user?.linkedAccountsIdAndStatusObjArr
              ?.map(({ accountId, linkStatus, accountType }, index) => {
                let accountObj = accounts?.find((item) => item.id === accountId);
                if (accountObj) {
                  return (
                    <MobileAccountUserMetaDataCard
                      fieldsList={getFieldsList(accountObj, linkStatus, accountType)}
                      showThreeDots={showPopoverActions(accountType)}
                      getThreeDotsPopContent={() => getPopContent(accountObj, user, linkStatus)}
                      showBorderBottom={index !== (user.linkedAccountsIdAndStatusObjArr.length - 1)}
                    />
                  )
                }
              })}
          </div>
        }
      </div>

      <div 
        className={styles.hideshowLinkedAccounts}
        style={{ color: colors.v2_Cerulean }}
        onClick={() => setShowLinkedAccounts(!showLinkedAccounts)}
      >
        {showLinkedAccounts ? formatMsg("hideLinkedAccounts") : formatMsg("viewLinkedAccounts")}
        {showLinkedAccounts ?
          <UpOutlined
            style={{ marginLeft: 10, marginTop: 3 }}
          /> :
          <DownOutlined
            style={{ marginLeft: 10, marginTop: 3 }}
          />}
      </div>
    </div>
  )
}

export default MobileUserCard