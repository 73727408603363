import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";

function* getCalendar(request) {
  try {
    const { firebase, startDate, endDate, packageId } = request.payload;
    let endpoint = `bookingoApi/calendar?startDate=${startDate}&endDate=${endDate}&teacherId=${firebase.teacher.id}`;
    if (packageId) {
      endpoint += "&packageId=" + packageId
    }
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, calendars: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getScheduleDetail(request) {
  try {
    const { firebase, packageId, date, batchId, slotRandomId, entityType, entityId } = request.payload;
    let endpoint = `bookingoApi/calendar/scheduleDetail?packageId=${packageId}&date=${date}`;
    let reqObj = {
      packageId, date, batchId, slotRandomId, entityType, entityId
    }
    let response = yield call(callApi, firebase, "post", endpoint, reqObj, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, scheduleDetail: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getSchedules(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingoApi/calendar/schedules?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, schedules: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getEntitySchedule(request) {
  try {
    const { firebase, entityId, entityType } = request.payload;

    let endpoint = `bookingoApi`;
    if (entityType === "account") {
      endpoint += `/account/schedule/${entityId}`;
    } else if (entityType === "user") {
      endpoint += `/user/schedule/${entityId}`;
    } else if (entityType === "package") {
      endpoint += `/package/schedule/${entityId}`;
    } else if (entityType === "booking") {
      endpoint += `/booking/schedule/${entityId}`;
    }
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, false);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, calendars: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch account schedule", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_CALENDAR, getCalendar);
  yield takeLatest(actions.GET_SCHEDULES, getSchedules);
  yield takeLatest(actions.GET_SCHEDULE_DETAIL, getScheduleDetail);
  yield takeLatest(actions.GET_ACCOUNT_SCHEDULE, getEntitySchedule)
}