"use client"
import React, { useEffect, useState } from 'react'
import { ModalWrapper } from '../wrapperComponents/page'
import formatMsg from '../../util/formatMsg'
import BookingCalendar from './BookingCalendar'
import dayjs, { Dayjs } from 'dayjs'
import Helper from '../../util/helper'
import { Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { getRescheduleData } from '../../redux/package/reducer'
import "./style.css"
import { notification } from '../../util/notification'
import { rescheduleBooking } from '../../redux/accountPackage/reducer'
import { accountPackageActions, colors, packageActions, PrefixIconButton } from '../..'
import { initialState as initialAccountPackageReducer } from '../../redux/accountPackage/reducer';
import { initialState as initialPackageReducer } from '../../redux/package/reducer';

const formatTime = (epoch: number) => dayjs.tz(epoch).format("h:mm A");

const RescheduleBookingModal = ({ open, setOpen, remainingCredits = 0 }) => {
	const dispatch = useDispatch();
	const { packageReducer, accountPackageReducer, firebase } = Helper.getSelectorState(["packageReducer", "accountPackageReducer"])
	const [selectedPricingPack, setSelectedPricingPack] = useState<any>({})
	const [selectedBatch, setSelectedBatch] = useState<any>();
	const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
	const [selectedDays, setSelectedDays] = useState<Date[]>([]);
	const [selectedMonths, setSelectedMonths] = useState<Dayjs[]>([]);
	const [currentMonth, setCurrentMonth] = useState(dayjs.tz());
	const [selectedTimings, setSelectedTimings] = useState<Record<string, string>>({});
	const [selectedWeekDays, setSelectedWeekDays] = useState<any>([]);
	const [groupedTimings, setGroupedTimings] = useState<any>({});
	const [pricePackDuration, setPricePackDuration] = useState(1);
	const [numbers, setNumbers] = useState({
		selectedWeeks: 0,
		selectedDays: 0,
		selectedMonths: 0,
		selectedWeekDays: 0
	});
	const [afterReSchedule, setAfterReSchedule] = useState(false);

	useEffect(() => {
		return () => {
			dispatch(accountPackageActions.sliceOperation(initialAccountPackageReducer))
			dispatch(packageActions.sagaSuccess(initialPackageReducer))
		}
	}, [])

	useEffect(() => {
		if (open) {
			dispatch(getRescheduleData({ firebase, bookingId: open }))
		}
	}, [open])

	useEffect(() => {
		if (accountPackageReducer.operationType === "BOOKING_RESCHEDULED") {
			notification("success", formatMsg("success.bookingReschedules"))
			setAfterReSchedule(true);
			dispatch(accountPackageActions.sliceOperation({ operationType: undefined }))
		}
	}, [accountPackageReducer.operationType])

	const getWeekOfMonth = (date) => {
		const day = dayjs.tz(date);
		const startOfMonth = day.startOf('month');
		const startOfWeek = startOfMonth.startOf("isoWeek");
		const weekNumber = day.diff(startOfWeek, 'week') + 1;
		return weekNumber;
	};

	useEffect(() => {
		let tempPackage;
		let mergeSlots;
		if (packageReducer.rescheduleData?.pricePack?.frequency === "ENTIRE_PACKAGE_DURATION") {
			notification("error", formatMsg("cannotRescheduleEntirePackage"))
			setOpen(false);
		}
		if (packageReducer.rescheduleData.batch) {
			tempPackage = packageReducer.rescheduleData.batch
			mergeSlots = processBatchTimings(tempPackage)
			setSelectedBatch(tempPackage);
		}
		if (packageReducer.rescheduleData.pricePack) {
			setSelectedPricingPack({ pricingPack: packageReducer.rescheduleData.pricePack })
		}
		if (packageReducer.rescheduleData.slots) {
			setCurrentMonth(dayjs.tz(packageReducer.rescheduleData.slots?.[0]?.date || undefined))
			const updatedWeeks = new Set<string>();
			const updatedDays: Date[] = [];
			const updatedMonths = new Set<number>();
			const newSelectedTimings = {};
			const updatedWeekDays = new Set<string>();

			for (const { slotRandomId, date, startTime, endTime } of packageReducer.rescheduleData.slots) {
				const day = dayjs.tz(date);
				const isRecurring = tempPackage.type === "recurring";

				const formattedDate = Helper.convertToDate(day, firebase);
				const formattedMonth = day.startOf("month").valueOf();
				const weekNumber = `Week ${getWeekOfMonth(day)}+${day.startOf("month").valueOf()}`;
				updatedWeeks.add(weekNumber);
				updatedDays.push(formattedDate);
				updatedMonths.add(formattedMonth);
				updatedWeekDays.add(day.format("dddd").toUpperCase())
				const time = `${formatTime(startTime)} - ${formatTime(endTime)}`;
				const randomId = mergeSlots["Common slots"] ? mergeSlots["Common slots"].find(obj => obj.label === time)?.key : slotRandomId
				const tempDate = mergeSlots["Common slots"] ? "Common slots" : isRecurring ? day.format("dddd").toUpperCase() : day.format(Helper.dateFormat(firebase));
				if (tempPackage.type === "recurring") {
					newSelectedTimings[tempDate] = newSelectedTimings[tempDate] || [];
					if ((mergeSlots["Common slots"] && !newSelectedTimings[tempDate]?.length) || !mergeSlots["Common slots"]) newSelectedTimings[tempDate].push(randomId);
				} else {
					const formattedDate = mergeSlots["Common slots"] ? "Common slots" : day.format(Helper.dateFormat(firebase));
					newSelectedTimings[formattedDate] = newSelectedTimings[formattedDate] || [];
					if ((mergeSlots["Common slots"] && !newSelectedTimings[tempDate]?.length) || !mergeSlots["Common slots"]) newSelectedTimings[formattedDate].push(randomId);
				}
			}

			setSelectedWeeks(Array.from(updatedWeeks));
			setSelectedDays(updatedDays);
			setSelectedMonths(Array.from(updatedMonths).map(d => dayjs.tz(d)));
			setSelectedTimings(prev => ({ ...prev, ...newSelectedTimings }));
			setSelectedWeekDays(Array.from(updatedWeekDays));

			setNumbers({
				selectedWeeks: Array.from(updatedWeeks)?.length || 0,
				selectedDays: updatedDays?.length || 0,
				selectedMonths: Array.from(updatedMonths)?.length || 0,
				selectedWeekDays: Array.from(updatedWeekDays)?.length || 0
			})
		}
		if (packageReducer.rescheduleData.pricePackDuration) {
			setPricePackDuration(packageReducer.rescheduleData.pricePackDuration)
		}
	}, [packageReducer.rescheduleData])

	useEffect(() => {
		setGroupedTimings(processBatchTimings(selectedBatch || {}))
	}, [selectedBatch])

	const processBatchTimings = (batch: any) => {
		const groupTimings: Record<string, any> = {};

		if (batch.type === "recurring") {
			batch.intervals?.forEach(({ day, startTime, endTime, randomId }: { day: string; startTime: number; endTime: number, randomId: string }) => {
				const timing = { key: randomId, label: `${formatTime(startTime)} - ${formatTime(endTime)}` };
				if (!groupTimings[day]) groupTimings[day] = [];
				groupTimings[day].push(timing);
			});
		} else {
			batch.intervals?.forEach(({ date, startTime, endTime, randomId }: { date: number; startTime: number; endTime: number, randomId: string }) => {
				const dateLabel = dayjs.tz(date).format(Helper.dateFormat(firebase));
				const timing = { key: randomId, label: `${formatTime(startTime)} - ${formatTime(endTime)}` };
				if (!groupTimings[dateLabel]) groupTimings[dateLabel] = [];
				groupTimings[dateLabel].push(timing);
			});
		}
		const mergeCommonSlots = Helper.mergeCommonSlots(groupTimings);
		if (mergeCommonSlots["Common slots"]?.length === 1) {
			handleTimingClick("Common slots", mergeCommonSlots["Common slots"][0].key, true)
		}
		else {
			Object.entries(mergeCommonSlots)?.forEach(([dayOrDate, timings]: any) => {
				if (timings?.length === 1) {
					handleTimingClick(dayOrDate, timings[0].key, true)
				}
			})
		}
		return mergeCommonSlots;
	}

	const disabled: any = (date, tempPack?) => {
		// if (dayjs.tz(date).format("DD").includes("10")) 
		const selectedPack = tempPack || selectedPricingPack?.pricingPack
		const time = dayjs.tz(date).endOf("day").valueOf();
		const month = dayjs.tz(date).startOf("month").valueOf();
		if (selectedBatch?.excludedDates?.map(d => dayjs.tz(d).endOf("day").valueOf()).includes(time)) return true

		if (selectedPack?.frequency === "MONTH" && selectedPack?.frequencyType && !selectedPack.all) {
			const isMonthSelected = selectedMonths.some((selected) =>
				dayjs.tz(selected).startOf("month").isSame(month)
			);
			if (!isMonthSelected) return true;
		}

		if (selectedBatch.type === "recurring") {
			let start = selectedBatch.startDate;
			let end = selectedBatch.endDate;
			if (selectedPack?.frequency === "CUSTOM") {
				start = Math.max(start, selectedPack?.startDate);
				end = Math.min(end, selectedPack?.endDate)
			}
			const validWeekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].filter(w =>
				selectedBatch.intervals.some(interval => interval.day === w)
			);
			const dayOfWeek = dayjs.tz(date).format("dddd").toUpperCase();
			return time < start || time > end || !validWeekdays.includes(dayOfWeek);
		} else {
			const intervalDates = selectedBatch.intervals.map((interval) => dayjs.tz(interval.date).endOf("day").valueOf());
			return !intervalDates.includes(time);
		}
	}

	const handleTimingClick = (dayOrDate: string, timing: string, force?: boolean) => {
		setSelectedTimings((prev) => {
			const isMultiple = selectedBatch?.allowMultipleTimeSlots;
			const currentSelection: any = prev[dayOrDate] || (isMultiple ? [] : "");

			if (isMultiple && !force) {
				const newSelection = currentSelection.includes(timing)
					? currentSelection.filter((t: string) => t !== timing)
					: [...currentSelection, timing];

				return {
					...prev,
					[dayOrDate]: force ? [timing] : newSelection,
				};
			} else {
				return {
					...prev,
					[dayOrDate]: currentSelection === timing && !force ? "" : timing,
				};
			}
		});
	};

	const onFinish = () => {
		if (!selectedDays?.length) {
			notification("error", formatMsg("error.selectslots"))
			return
		}

		switch (selectedPricingPack.pricingPack.frequency) {
			case "MONTH":
				if (selectedMonths?.length > pricePackDuration) {
					notification("error", formatMsg("error.moreMonths"))
					return;
				}
				if (remainingCredits) remainingCredits = pricePackDuration - selectedMonths.length
				break;
			case "WEEK":
				if (selectedWeeks?.length > pricePackDuration) {
					notification("error", formatMsg("error.moreWeeks"))
					return;
				}
				if (remainingCredits) remainingCredits = pricePackDuration - selectedWeeks.length
				break;
			case "DAILY":
				if (selectedDays?.length > pricePackDuration) {
					notification("error", formatMsg("error.moreDays"))
					return;
				}
				if (remainingCredits) remainingCredits = pricePackDuration - selectedDays.length
				break;

			default:
				if (remainingCredits) remainingCredits = 0
		}

		let tempWeeks: any = new Set();
		let tempMonths: any = new Set();
		selectedDays.forEach(d => {
			const tempD = dayjs.tz(d);
			tempWeeks.add(tempD.clone().startOf("week").valueOf());
			tempMonths.add(tempD.clone().startOf("month").valueOf());
		})
		tempWeeks = Array.from(tempWeeks).sort((a: any, b: any) => a - b);
		tempMonths = Array.from(tempMonths).sort((a: any, b: any) => a - b);

		if (selectedBatch.atleastValue) {
			let missing = false;
			switch (selectedBatch.atleastFrequency) {
				case "days":
					if (selectedDays?.length < selectedBatch.atleastValue)
						missing = true;
					break;
				case "weeks":
					if (tempWeeks?.length < selectedBatch.atleastValue)
						missing = true;
					break;
				case "months":
					if (tempMonths?.length < selectedBatch.atleastValue)
						missing = true;
					break;
			}
			if (missing) {
				notification("error", formatMsg("error.atleast", { x: selectedBatch.atleastValue, y: selectedBatch.atleastFrequency }))
				return
			}
		}

		if (selectedBatch.onlyConsecutive) {
			switch (selectedBatch.consecutiveFrequency) {
				case "days":
					const sortedDays = [...selectedDays].sort((a, b) => a.getTime() - b.getTime());
					let daysAreConsecutive = true;
					for (let i = 1; i < sortedDays.length; i++) {
						const prevDate = dayjs.tz(sortedDays[i - 1]);
						const currentDate = dayjs.tz(sortedDays[i]);
						const dayDiff = currentDate.diff(prevDate, 'day');

						if (dayDiff === 1) continue;

						let allDisabled = true;
						let dayToCheck = prevDate.clone().add(1, 'day');
						while (dayToCheck.isBefore(currentDate)) {
							if (!disabled(dayToCheck)) {
								allDisabled = false;
								break;
							}
							dayToCheck = dayToCheck.add(1, 'day');
						}

						if (!allDisabled) {
							daysAreConsecutive = false;
							break;
						}
					}

					if ((selectedPricingPack?.pricingPack?.frequency === "WEEK" && selectedPricingPack?.pricingPack?.frequencyValue < 7)) {
						const weekDays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].filter(w =>
							selectedBatch.type === "recurring" ? selectedBatch.intervals.some(interval => interval.day === w) : true
						);
						const indices = selectedWeekDays.map(day => weekDays.indexOf(day)).sort((a, b) => a - b);
						for (let i = 1; i < indices.length; i++) {
							if (indices[i] !== indices[i - 1] + 1) {
								daysAreConsecutive = indices[0] === 0 && indices[indices.length - 1] === weekDays.length - 1;
								break;
							}
						}
					}

					if (!daysAreConsecutive) {
						notification("error", formatMsg("consecutiveXRequired", { x: selectedBatch.consecutiveFrequency }));
						return;
					}
					break;

				case "weeks":
					const weekStartDates = selectedWeeks.map(ws => {
						const [weekPart, monthTimestamp] = ws.split("+");
						const weekNumber = parseInt(weekPart.replace("Week ", "").trim(), 10);
						return dayjs.tz(parseInt(monthTimestamp, 10)).add(weekNumber - 1, "week");
					});
					weekStartDates.sort((a, b) => a.valueOf() - b.valueOf());
					let weeksAreConsecutive = true;
					for (let i = 1; i < tempWeeks.length; i++) {
						if (dayjs.tz(tempWeeks[i]).diff(dayjs.tz(tempWeeks[i - 1]), "week") !== 1) {
							weeksAreConsecutive = false;
							break;
						}
					}
					if (!weeksAreConsecutive) {
						notification("error", formatMsg("consecutiveXRequired", { x: selectedBatch.consecutiveFrequency }));
						return;
					}
					break;

				case "months":
					let monthsAreConsecutive = true;
					for (let i = 1; i < tempMonths.length; i++) {
						if (dayjs.tz(tempMonths[i]).diff(dayjs.tz(tempMonths[i - 1]), "month") !== 1) {
							monthsAreConsecutive = false;
							break;
						}
					}
					if (!monthsAreConsecutive) {
						notification("error", formatMsg("consecutiveXRequired", { x: selectedBatch.consecutiveFrequency }));
						return;
					}
					break;
			}
		}

		let frequencyValueError = false
		const pricingPack = selectedPricingPack.pricingPack;
		if (
			pricingPack.frequency === "CUSTOM" &&
			pricingPack.noOfClasses &&
			!pricingPack.unlimitedClasses &&
			selectedDays?.length < pricingPack.noOfClasses) {
			notification("error", "Select " + pricingPack.noOfClasses + " classes")
			return
		}

		if (
			pricingPack.frequency === "WEEK" &&
			pricingPack.frequencyValue &&
			!pricingPack.all &&
			selectedWeekDays?.length < pricingPack.frequencyValue) {
			notification("error", "Select " + pricingPack.frequencyValue + " weekdays")
			return
		}

		if (
			pricingPack.frequency === "MONTH" &&
			pricingPack.frequencyType === "WEEKS" &&
			!pricingPack.all &&
			pricingPack.frequencyValue
		) {
			const weeksByMonth = selectedWeeks.reduce((acc, weekStr) => {
				const parts = weekStr.split("+");
				if (parts.length === 2) {
					const monthTimestamp = parts[1];
					if (!acc[monthTimestamp]) {
						acc[monthTimestamp] = [];
					}
					acc[monthTimestamp].push(weekStr);
				}
				return acc;
			}, {} as Record<string, string[]>);

			if (Object.entries(weeksByMonth)?.length != selectedMonths.length) {
				notification("error", `Select ${pricingPack.frequencyValue} weeks for each selected month`)
				frequencyValueError = true
			}
			else Object.entries(weeksByMonth).forEach(([monthTimestamp, weeks]) => {
				if (weeks.length !== pricingPack.frequencyValue) {
					notification(
						"error",
						`${pricingPack.frequencyValue} weeks need to be selected for month ${dayjs.tz(
							parseInt(monthTimestamp, 10)
						).format("MMMM YYYY")}`
					);
					frequencyValueError = true
				}
			});
		}

		if (
			pricingPack.frequency === "MONTH" &&
			pricingPack.frequencyType === "DAYS" &&
			!pricingPack.all &&
			pricingPack.frequencyValue
		) {
			const daysByMonth = selectedDays.reduce((acc, day) => {
				const monthKey = dayjs.tz(day).startOf("month").valueOf().toString();
				if (!acc[monthKey]) {
					acc[monthKey] = [];
				}
				acc[monthKey].push(day);
				return acc;
			}, {} as Record<string, Date[]>);

			if (Object.entries(daysByMonth)?.length != selectedMonths.length) {
				notification("error", `Select ${pricingPack.frequencyValue} days for each selected month`)
				frequencyValueError = true
			}
			else Object.entries(daysByMonth).forEach(([monthKey, days]) => {
				if (days.length !== pricingPack.frequencyValue) {
					notification(
						"error",
						`${pricingPack.frequencyValue} days need to be selected for month ${dayjs.tz(
							parseInt(monthKey, 10)
						).format("MMMM YYYY")}`
					);
					frequencyValueError = true;
				}
			});
		}
		if (frequencyValueError) return

		const slots = [];
		for (const date of selectedDays) {
			const isRecurring = selectedBatch.type === "recurring";
			const day = dayjs.tz(date);
			let randomIds = [];

			if (selectedTimings["Common slots"]) {
				const selectedSlotObj = selectedBatch.intervals.find(obj => obj.randomId === selectedTimings["Common slots"] || obj.randomId === selectedTimings["Common slots"]?.[0]);
				const time = `${formatTime(selectedSlotObj.startTime)} - ${formatTime(selectedSlotObj.endTime)}`;
				const tempDate = isRecurring ? day.format("dddd").toUpperCase() : day.format(Helper.dateFormat(firebase));

				if (selectedPricingPack.pricingPack.frequency === "WEEK" && selectedPricingPack.pricingPack.frequencyType && selectedWeekDays.length && !selectedWeekDays.includes(tempDate)) continue;

				randomIds = selectedBatch.intervals
					.filter(obj => {
						const tempDayOrDate = isRecurring ? obj.day : dayjs.tz(obj.date).format(Helper.dateFormat(firebase));
						return tempDate === tempDayOrDate && `${formatTime(obj.startTime)} - ${formatTime(obj.endTime)}` === time;
					})
					.map(obj => obj.randomId);
			} else {
				const selectedTiming = isRecurring
					? selectedTimings[day.format("dddd").toUpperCase()]
					: selectedTimings[day.format(Helper.dateFormat(firebase))];

				randomIds = Array.isArray(selectedTiming) ? selectedTiming : [selectedTiming];
			}

			if (randomIds.filter(i => i).length) {
				for (const randomId of randomIds) {
					const selectedInterval = selectedBatch.intervals.find(obj => obj.randomId === randomId);
					if (selectedInterval) {
						slots.push({
							randomId,
							date: day.valueOf(),
							startTime: selectedInterval.startTime,
							endTime: selectedInterval.endTime,
						});
					}
				}
			} else {
				notification("error", formatMsg("selectRequiredTimeSlots"));
				return;
			}
		}
		const payload = {
			"slots": slots.length ? slots : null,
			"id": open,
			"startDate": Math.min(...selectedDays.map(d => dayjs.tz(d).valueOf())),
			"endDate": Math.max(...selectedDays.map(d => dayjs.tz(d).valueOf())),
			remainingCredits
		}
		dispatch(rescheduleBooking({ firebase, payload }))
	}

	function getButtonGroup() {
		return (
			<div>
				{
					afterReSchedule &&
					<div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
						<PrefixIconButton
							title={formatMsg("close")}
							border={true}
							backgroundColor={colors.color_purple}
							fontColor={colors.color_white}
							onClick={() => {
								setAfterReSchedule(false)
								setOpen(false);
							}}
							borderRadius={"4px"}
						/>
					</div>}
			</div>
		)
	}
	return (
		<div>
			{!afterReSchedule && <ModalWrapper
				title={remainingCredits ? formatMsg("bookSlots") : formatMsg("reschedule")}
				okText={remainingCredits ? formatMsg("book") : formatMsg("reschedule")}
				open={open}
				onCancel={() => setOpen(false)}
				width={"fit-content"}
				onOk={onFinish}
				confirmLoading={accountPackageReducer.isLoading}
			>
				{!packageReducer.isLoading && selectedBatch && selectedPricingPack?.pricingPack ?
					<BookingCalendar
						selectedPricingPack={selectedPricingPack} selectedTimings={selectedTimings}
						selectedBatch={selectedBatch} selectedMonths={selectedMonths} currentMonth={currentMonth}
						setSelectedMonths={setSelectedMonths} setSelectedDays={setSelectedDays} disabled={disabled}
						selectedWeeks={selectedWeeks} setSelectedWeeks={setSelectedWeeks} selectedDays={selectedDays}
						setCurrentMonth={setCurrentMonth} selectedWeekDays={selectedWeekDays}
						setSelectedWeekDays={setSelectedWeekDays} setSelectedTimings={setSelectedTimings}
						firebase={firebase} handleTimingClick={handleTimingClick} groupedTimings={groupedTimings}
					/>
					: <Skeleton active style={{ width: 500 }} />
				}
			</ModalWrapper>}
			{
				afterReSchedule &&
				<div >
					<div className='res-cpo-overlay'>
						<div className='res-cpo-popup'>
							<p className='res-cpo-textAlignCenter fw-700' style={{ whiteSpace: "pre-wrap" }}>{formatMsg("afterReschedule")}</p>
							<div className='res-cpo-textAlignLeft mt-16'>
								<div className='res-popup-reschedule'>{formatMsg("yourNewSchedule") + ": " + dayjs.tz(accountPackageReducer?.reschedulingDetails?.startDate).format(Helper.dateFormat(firebase)) + "-" + dayjs.tz(accountPackageReducer?.reschedulingDetails?.endDate).format(Helper.dateFormat(firebase))}</div>
							</div>
							<div>{getButtonGroup()}</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default RescheduleBookingModal