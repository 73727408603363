'use client'

import React from 'react'
import styles from './style.module.css'
import Helper from '../../../util/helper';
import SpinWrapper from '../../SpinWrapper/page';
import formatMsg from '../../../util/formatMsg';
import AdditionalFieldCard from '../../additionalFieldCard/page';
import LinkedAccountsMobileCard from './LinkedAccountsMobileCard/page';
import colors from '../../../libs/colorFactory';
import PrefixIconButton from '../../buttons/prefixIconButton';
import { PlusOutlined } from "@ant-design/icons";


const AccountProfileMobileView = (props: any) => {

  const {
    firebase,
    entityType,
    accountDetails,
    customFields,
    handleAddFieldForm,
    editBasicDetails,
    setLinkModalVisible,
    getStatusShowAndChangeDropdown,
    getAdditionalFieldDetails,
    getAccountName,
    getAccountEmail,
    isPrimaryAccount,
    getAccountPhoneNumber,
    getLinkStatus,
    showPopoverActions,
    getPopContent, 
    dataSource 
  } = props;

  const getEmptyState = () => {
    return (
      <div
        className={styles.noLinkedAccountsContainer}
      >
        <img
          src='/images/noLinkedAccounts.png'
          className={styles.noLinkedAccounts}
        />
      </div>
    )
  }

  return (accountDetails && Object.keys(accountDetails).length) &&
    (<div>
      <div className={styles.accountDetailsContainer}>
        <img
          src={Helper.getProfileImageUrl({ ...accountDetails })}
          className={styles.profilePhotoStyle}
        />
        <div className={styles.accountNameStyle}>
          <div className="global-page-header margin-left-4" >{accountDetails.name}</div>
          <div className={styles.accountStatusName}>
            {
              accountDetails.status
                ? getStatusShowAndChangeDropdown(accountDetails)
                : "-"
            }
          </div>
        </div>
        <div className={styles.editIconContainer}
          onClick={() => editBasicDetails(accountDetails)}
        >
          <img src="/images/pencil.png" className={styles.editIconStyle}></img>
        </div>
      </div>

      <div className={styles.accountDetailsContainer2}>
        {
          entityType === "account" &&
          <div className={styles.detailStyle}>
            <div>
              <div className={styles.profileLabelStyle}>{formatMsg("phoneNumber")}</div>
              <div className={styles.profileLabelStyle}>{formatMsg("email")}</div>
            </div>
            <div className={styles.valueContainer}>
              <div className={styles.profileValueStyle}>{accountDetails.phoneNumber ? accountDetails.phoneNumber : "-"}</div>
              <div className={styles.profileValueStyle}>{accountDetails.email ? accountDetails.email : "-"}</div>
            </div>
          </div>
        }
      </div>

      {Boolean(customFields?.length) &&
        <div>
          <AdditionalFieldCard
            title={formatMsg('accountDetails')}
            action={() => handleAddFieldForm(entityType === "account" ? "accountAdditionalFields" : "userAdditionalFields")}
            fields={getAdditionalFieldDetails()}
            firebase={firebase}
            entity="accountOrUser"
            view="mobileCard"
          />
        </div>
      }

      {
        !Helper.isAccount(firebase) && entityType !== "user" ?
          <PrefixIconButton
            title={formatMsg("link")}
            backgroundColor={colors.v2_Cerulean}
            fontColor={colors.color_primarytext}
            width="100px"
            height="32px"
            prefixIcon={<PlusOutlined />}
            onClick={() => {
              setLinkModalVisible(true)
            }}
          /> : null
      }

      <LinkedAccountsMobileCard
        title={formatMsg('linkedAccounts')}
        entityType={entityType}
        getAccountName={getAccountName}
        getAccountEmail={getAccountEmail}
        isPrimaryAccount={isPrimaryAccount}
        getAccountPhoneNumber={getAccountPhoneNumber}
        getLinkStatus={getLinkStatus}
        showPopoverActions={showPopoverActions}
        getPopContent={getPopContent}
        getEmptyState={getEmptyState}
        dataSource={dataSource}
      />
    </div>)
}

export default AccountProfileMobileView