'use client'

import React, { useCallback, useEffect, useState } from 'react'
import { Box, PageHeaderWrapper, TableWrapper } from '../../wrapperComponents/page';
import { Col, Popover, Row } from 'antd';
import Helper from '../../../util/helper';
import formatMsg from '../../../util/formatMsg';
import AdditionalFieldCard from '../../additionalFieldCard/page';
import SpinWrapper from '../../SpinWrapper/page';
import PrefixIconButton from '../../buttons/prefixIconButton';
import colors from '../../../libs/colorFactory';
import { PlusOutlined } from "@ant-design/icons";
import '../style.css';


const AccountProfileLaptopView = (props: any) => {


    const {
        firebase,
        entityType,
        accountDetails,
        customFields,
        handleAddFieldForm,
        editBasicDetails,
        setLinkModalVisible,
        getStatusShowAndChangeDropdown,
        getAdditionalFieldDetails,
        getAccountName,
        getAccountEmail,
        isPrimaryAccount,
        getAccountPhoneNumber,
        getLinkStatus,
        showPopoverActions,
        getPopContent, 
        dataSource 
    } = props;


    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    function getUrlParam() {
        let pageNo: any = Helper.getUrlParam("pageNo");
        pageNo = Helper.checkUrlParam(pageNo, 1, "number");
        if (pageNo) setPageNo(pageNo);

        let pageSize: any = Helper.getUrlParam("pageSize");
        pageSize = Helper.checkUrlParam(pageSize, 10, "number");
        if (pageSize) setPageSize(pageSize);
    }


    useEffect(() => {
        getUrlParam();
    }, [])


    const handlePageChange = useCallback((newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
        Helper.setUrlParam('pageNo', newPageNo ? newPageNo : 1);
        Helper.setUrlParam('pageSize', newPageSize ? newPageSize : 10);
    }, [setPageNo, setPageSize]);


    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "25%",
            sorter: (a, b) =>
                Helper.sortStringValue(a, b, "name"),
            render: (data, record) => {
                return <div className="account-name-column">
                    <img className='account-profileIcon' src={Helper.getProfileImageUrl(record)}></img>
                    <div className="title-container">
                        <div className="global-subheader-regular">{getAccountName(record)}</div>
                        {
                            (entityType === 'user') ?
                                isPrimaryAccount(record)
                                :
                                null
                        }
                    </div>
                </div>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: "25%",
            sorter: (a, b) =>
                Helper.sortStringValue(a, b, "email"),
            render: (data, record) => {
                return <div className="account-name-column">
                    <div className="global-regular-font">{getAccountEmail(record)}</div>
                </div>
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: "25%",
            render: (data, record) => {
                return <div className="account-name-column">
                    <div className="global-regular-font">{getAccountPhoneNumber(record)}</div>
                </div>
            }
        },
        {
            title: 'Status',
            dataIndex: 'linkStatus',
            key: 'linkStatus',
            width: "25%",
            render: (data, record) => {
                return <div className="account-name-column">
                    <div className="global-subheader-regular">
                        {getLinkStatus(record)}
                    </div>
                </div>
            }
        },
        {
            key: 'threeDots',
            width: "10%",
            fixed: "right",
            render: (data, record) => {
                return (showPopoverActions(record)) ?
                    (<div className="account-name-column">
                        <Popover
                            id='custom-pop-over-style'
                            content={getPopContent(record)}
                            trigger='click'
                            placement='bottomRight'
                        >
                            <div
                                className='three-dots-container'
                            >
                                <img
                                    className='linked-accounts-three-dots'
                                    src='/images/threeDots.png'
                                />
                            </div>
                        </Popover>
                    </div>)
                    :
                    null
            }
        },
    ];


    return (accountDetails && Object.keys(accountDetails).length) &&
        <Box className='mobile-respon-box-p'>
            <Row style={{ marginTop: 10 }} >
                <Col lg={7} md={24} sm={24} xs={24} className="account-text-wrap">
                    <div className={(entityType === 'account') ? "profile-title-container-account" : "profile-title-container-user"}>
                        <img src={Helper.getProfileImageUrl({ ...accountDetails })} className="profile-photo-style"></img>
                        <div className="student-name-style">
                            <div className="global-page-header margin-left-4 " >{accountDetails.name}</div>
                            <div className="status-style">
                                {
                                    accountDetails.status
                                        ? getStatusShowAndChangeDropdown(accountDetails)
                                        : "-"
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={17} md={24} sm={24} xs={24}>
                    <div className={(entityType === 'account') ? "profile-detail-container-account" : "profile-detail-container-user"}>
                        {
                            entityType === "account" &&
                            <div className="detail-style">
                                <div>
                                    <div className="profile-label-style">{formatMsg("phoneNumber")}</div>
                                    <div className="profile-label-style">{formatMsg("email")}</div>
                                </div>
                                <div className="value-container">
                                    <div className="profile-value-style">{accountDetails.phoneNumber ? accountDetails.phoneNumber : "-"}</div>
                                    <div className="profile-value-style">{accountDetails.email ? accountDetails.email : "-"}</div>
                                </div>
                            </div>
                        }

                        <div className="profile-action-style">
                            <div className="editIcon-container"
                                onClick={() => editBasicDetails(accountDetails)}
                            >
                                <img src="/images/pencil.png" className="editIconStyle"></img>
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            {Boolean(customFields?.length) &&
                <Row>
                    <AdditionalFieldCard
                        action={() => handleAddFieldForm(entityType === "account" ? "accountAdditionalFields" : "userAdditionalFields")}
                        fields={getAdditionalFieldDetails()}
                        firebase={firebase}
                        entity="accountOrUser"
                        view="laptopCard"
                    />
                </Row>
            }
            <PageHeaderWrapper style={{ marginTop: 40 }}>

                <div className='global-page-header'>
                    {entityType === "user" ? formatMsg("sidebar.accounts") : formatMsg("linkedAccounts")}
                </div>
                {
                    !Helper.isAccount(firebase) && entityType !== "user" ?
                        <PrefixIconButton
                            title={formatMsg("link")}
                            backgroundColor={colors.v2_Cerulean}
                            fontColor={colors.color_primarytext}
                            width="100px"
                            height="32px"
                            prefixIcon={<PlusOutlined />}
                            onClick={() => {
                                setLinkModalVisible(true)
                            }}
                        /> : null
                }
            </PageHeaderWrapper>
            <TableWrapper
                columns={columns}
                dataSource={dataSource || []}
                scroll={{ x: 1200 }}
                pagination={{
                    current: pageNo,
                    pageSize: pageSize,
                    onChange: handlePageChange,
                    showSizeChanger: true,
                    hideOnSinglePage: true
                }}
            />
        </Box>
}

export default AccountProfileLaptopView