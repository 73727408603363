'use client'

import React from 'react';
import formatMsg from '../../util/formatMsg';
import type { ToggleOption, ToggleProps } from '../../types/toggleComponent';
import './style.css';


const ToggleComponent = (props: ToggleProps) => {


    const {
        toggleOptionsArr,
        selectedToggleOptionObj,
        onToggle,
        customStyleClassObj
    } = props;

    const {
        containerDivStyle,
        outerDivStyle,
        innerDivSelectedStyle,
        innerDivUnselectedStyle
    }: any = customStyleClassObj ? customStyleClassObj : {};


    function getSelectionDivCss(selected: boolean): string {

        const selectionCss: string = (selected)
            ? (innerDivSelectedStyle)
                ? innerDivSelectedStyle
                : 'toggle-inner-div-selected'
            : (innerDivUnselectedStyle)
                ? innerDivUnselectedStyle
                : 'toggle-inner-div-unselected';

        return selectionCss;
    }

    function getTooltipBasedMarginCss(): string {

        const marginCss = (selectedToggleOptionObj && !selectedToggleOptionObj.toolTip)
            ? 'margin-botton-24'
            : 'margin-bottom-10';

        return marginCss;
    }


    return (
        <div className={`${(containerDivStyle) ? containerDivStyle : 'toggle-container-div'}`}>
            <div className={`${(outerDivStyle) ? outerDivStyle : 'toggle-outer-div'} ${getTooltipBasedMarginCss()}`}>
                {
                    toggleOptionsArr.map(({ key, label, selected }: ToggleOption) => {
                        return (
                            <div
                                onClick={(e) => onToggle(key)}
                                className={`${getSelectionDivCss(selected)}`}
                            >
                                {formatMsg(label)}
                            </div>
                        );
                    })
                }
            </div>
            {
                (selectedToggleOptionObj && selectedToggleOptionObj.toolTip) ?
                    <div className={`margin-bottom-24 selected-tooltip`}>
                        {selectedToggleOptionObj.toolTip}
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default ToggleComponent