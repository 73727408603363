import React from "react";
import { Layout } from "antd";
import Link from "next/link";
import "./style.css"
const LinkWrapper: React.FC<any> = ({ children, href, style, target, aTagStyle,  onClick }) => {
    return (
        <Link
            href={href}
            prefetch={true}
            target={target || undefined}
            onClick={() => onClick ? onClick() : null}
            style={aTagStyle ? aTagStyle : {}}
        >
            <div className="lw-main-link-wrapper" style={style}>
                {children}
            </div>
        </Link>

    )
}
export default LinkWrapper;