"use client";

import "./style.css";
import Helper from "../../util/helper";
import { Row, Col, Popover } from "antd";
import { useEffect, useState } from "react";
import formatMsg from "../../util/formatMsg";
import colors from "../../libs/colorFactory";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import LinkWrapper from "../linkWrapper/page";


const UserCard: React.FC<any> = (props) => {

    const {
        user,
        currentDetailedViewOfAccountId,
        firebase,
        accounts,
        action,
        getPopContent,
        getHeaderRedirectUrl,
        canEditUserInCurrentAccount,
        deleteUserConfirmation,
        getUserCardLabel,
        getUserCardValue,
        getAccountName,
        getAccountEmail,
        getAccountPhoneNumber,
        getAccountLinkStatus,
        showPopoverActions,
        isPrimaryAccount
    } = props;

    const [fields, setFields] = useState([]);
    const [showLinkedAccounts, setShowLinkedAccounts] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [additionalFieldMap, setAdditionalFieldMap] = useState(new Map());

    useEffect(() => {
        let fieldsToRender = ["name", "status"];
        const additionalFieldMap = new Map();
        if (user?.childConfig) {
            user.childConfig.forEach((customFieldObj) => {
                fieldsToRender.push(customFieldObj.id);
                additionalFieldMap.set(customFieldObj.id, customFieldObj);
            });
        }
        setAdditionalFieldMap(additionalFieldMap);
        setFields(fieldsToRender);
    }, [user]);

    return (
        <div className='user-card-container'>
            <div className="user-card-header" style={{ borderBottom: "0.5px solid #E0E0E0" }}>
                {
                    (Helper.getLoggedInUserType(firebase) === 'TEACHER') ?
                        <LinkWrapper href={getHeaderRedirectUrl(user)}>
                            <div className="custom-user-card-heading">{user.name}</div>
                        </LinkWrapper>
                        :
                        <div className="custom-user-card-heading">{user.name}</div>
                }
                {canEditUserInCurrentAccount(user) ?
                    <div>
                        <img src="/images/newEditImg.png" className="user-in-account-edit"
                            onClick={() => action(user)}
                        ></img>
                        <img src="/images/newDeleteIcon.png" className="user-in-account-delete"
                            onClick={() => deleteUserConfirmation(user)}
                        ></img>
                    </div>
                    :
                    null
                }
            </div>

            <div className="user-main-card">
                <div className='user-details-rows'>
                    <Row style={{ padding: 8 }}>
                        {
                            fields?.map((item) => {
                                return (
                                    <Col lg={5} md={5} sm={5} xs={5} className="user-field-style">
                                        {
                                            !Helper.isAccount(firebase) ?
                                                <LinkWrapper href={"/dashboard/users/userDetailView?id=" + user.id + "&entityType=user"}>
                                                    <div className="user-label-container">
                                                        {item === "name" &&
                                                            <div className="account-name-column">
                                                                <img className='user-profileIcon' src={Helper.getProfileImageUrl(user)}></img>
                                                            </div>
                                                        }
                                                        <div>
                                                            <div className="user-card-label-style user-text-wrap">
                                                                {getUserCardLabel(item, additionalFieldMap)}
                                                            </div>
                                                            <div className="user-card-value-style user-text-wrap">
                                                                {getUserCardValue(item, user, additionalFieldMap)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </LinkWrapper>
                                                :
                                                <div className="user-label-container">
                                                    {item === "name" &&
                                                        <div className="account-name-column">
                                                            <img className='user-profileIcon' src={Helper.getProfileImageUrl(user)}></img>
                                                        </div>
                                                    }
                                                    <div>
                                                        <div className="user-card-label-style">
                                                            {getUserCardLabel(item, additionalFieldMap)}
                                                        </div>
                                                        <div className="user-card-value-style">
                                                            {getUserCardValue(item, user, additionalFieldMap)}
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        showLinkedAccounts &&
                        <div>
                            <Row className="user-linked-accounts-header">
                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                    {formatMsg("linkedAccounts")}
                                </Col>
                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                    {formatMsg("email")}
                                </Col>
                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                    {formatMsg("phoneNumber")}
                                </Col>
                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                    {formatMsg("status")}
                                </Col>
                            </Row>
                            {user?.linkedAccountsIdAndStatusObjArr
                                ?.map(({ accountId, linkStatus, accountType }) => {
                                    let accountObj = accounts?.find((item) => item.id === accountId);
                                    if (accountObj) {
                                        return (
                                            <Row className="user-linked-account-row">
                                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                                    <div className="account-name-column">
                                                        <img className='account-profileIcon' src={Helper.getProfileImageUrl(accountObj)}></img>
                                                        <div className="title-container">
                                                            <div className="global-subheader-regular">{getAccountName(accountObj)}</div>
                                                            {isPrimaryAccount(accountType)}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                                    {getAccountEmail(accountObj)}
                                                </Col>
                                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                                    {getAccountPhoneNumber(accountObj)}
                                                </Col>
                                                <Col lg={5} md={5} sm={5} xs={5} className="user-linked-accounts-style">
                                                    {getAccountLinkStatus(linkStatus)}
                                                </Col>
                                                {
                                                    showPopoverActions(accountType)
                                                        ? (<Col>
                                                            <div className="pop-content-column">
                                                                <Popover
                                                                    open={showPop}
                                                                    onOpenChange={setShowPop}
                                                                    content={getPopContent(accountObj, user, linkStatus)}
                                                                    trigger='click'
                                                                    placement='bottomRight'
                                                                    id='custom-pop-over-style'
                                                                >
                                                                    <div
                                                                        className='three-dots-div'
                                                                    >
                                                                        <img
                                                                            className='three-dots'
                                                                            src='/images/threeDots.png'
                                                                        />
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </Col>)
                                                        : null
                                                }
                                            </Row>
                                        )
                                    }

                                })}
                        </div>
                    }
                </div>

                <Row className="user-hideshow-linked-accounts"
                    style={{ color: colors.v2_Cerulean }}
                    onClick={() => setShowLinkedAccounts(!showLinkedAccounts)}>
                    {showLinkedAccounts ? formatMsg("hideLinkedAccounts") : formatMsg("viewLinkedAccounts")}
                    {showLinkedAccounts ?
                        <UpOutlined
                            style={{ marginLeft: 10, marginTop: 3 }}
                        /> :
                        <DownOutlined
                            style={{ marginLeft: 10, marginTop: 3 }}
                        />}
                </Row>
            </div>
        </div>

    )
}
export default UserCard;