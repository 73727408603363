"use client";
import React, { useEffect, useState } from 'react';
import Helper from '../../util/helper';
import dayjs from "../../libs/day";
import { DatePicker, Modal, Select } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import formatMsg from '../../util/formatMsg';

interface FilterObject {
  dateRange: any
  setDateRange: any,
}

const FilterDateSelector: React.FC<FilterObject> = ({ dateRange, setDateRange }) => {
  const [tempDateRange, settempDateRange] = useState(null)
  const [dateRangeType, setDateRangeType] = useState(Helper.getUrlParam("dateRange") || "lifetime")
  const [showCustomDateRangeModal, setshowCustomDateRangeModal] = useState(false);

  useEffect(() => {
    if (dateRangeType != "Custom") Helper.setUrlParam("dateRange", dateRangeType);
    switch (dateRangeType) {
      case "thisMonth":
        setDateRange([dayjs.tz().startOf("month"), dayjs.tz().endOf("month")]);
        break;
      case "lastMonth":
        setDateRange([dayjs.tz().subtract(1, "month").startOf("month"), dayjs.tz().subtract(1, "month").endOf("month")]);
        break;
      case "thisYear":
        setDateRange([dayjs.tz().startOf("year"), dayjs.tz().endOf("year")]);
        break;
      case "lifetime":
        setDateRange([dayjs.tz(1), dayjs.tz(9999999999999)]);
        break;
      case "customDateParam":
        if (Helper.getUrlParam("startDate") && Helper.getUrlParam("endDate")) {
          setDateRangeType("Custom")
          setDateRange([dayjs.tz(Number(Helper.getUrlParam("startDate"))), dayjs.tz(Number(Helper.getUrlParam("endDate")))])
          settempDateRange([dayjs.tz(Number(Helper.getUrlParam("startDate"))), dayjs.tz(Number(Helper.getUrlParam("endDate")))])
        } else {
          setDateRangeType("thisMonth");
        }
        break;
    }
  }, [dateRangeType])

  useEffect(() => {
    if (dateRange?.length === 2) {
      Helper.setUrlParam("startDate", dateRange[0].valueOf())
      Helper.setUrlParam("endDate", dateRange[1].valueOf())
    }
  }, [dateRange])

  return (
    <div>
      <Select
        placeholder={formatMsg("search")}
        suffixIcon={<CalendarOutlined />}
        value={dateRangeType}
        onChange={(e) => {
          if (e === "custom") {
            setshowCustomDateRangeModal(true)
            setDateRangeType("Custom")
          }
          else setDateRangeType(e)
        }}
        style={{ width: "100%" }}
      >
        {Helper.getDateRanges().map(dateRange =>
          <Select.Option
            key={dateRange}
            value={dateRange}
          >
            {dateRange === "Custom" ? formatMsg("customDateRange") : formatMsg(dateRange)}
          </Select.Option>
        )}
      </Select>
      <Modal
        title={"Select date range"}
        open={showCustomDateRangeModal}
        onOk={() => {
          Helper.setUrlParam("dateRange", "customDateParam");
          setDateRange(tempDateRange)
          setshowCustomDateRangeModal(false)
        }}
        onCancel={() => {
          settempDateRange(null)
          setshowCustomDateRangeModal(false)
        }}
        okText="Save"
        maskClosable={false}
      >
        <DatePicker.RangePicker
          format={"DD MMM'YY"}
          value={tempDateRange}
          onChange={settempDateRange}
          allowClear={false}
          style={{ width: "100%", marginTop: 24, marginBottom: 24 }}
        />
      </Modal>
    </div>
  )
}

export default FilterDateSelector