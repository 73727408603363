"use client";
import userColored from "../../public/images/userColored.png";
import userNoColor from "../../public/images/NoPhoto.png";
import boyIcon from "../../public/images/boy.svg";
import girlIcon from "../../public/images/girl.svg";
import { confirmAlert } from "react-confirm-alert";
import "./confirmAlert.css";
import formatMsg from "./formatMsg";
import { getItem } from "../libs/encryptedStorage";
import dayjs from "../libs/day";
import { useSelector } from 'react-redux';
import imageCompression from "browser-image-compression";
import Firebase from "firebase/compat/app";
import store from "../redux/store";
import actions from "../redux/actionType";
import colors from "../libs/colorFactory";
import { floor, round } from "mathjs";
import { notification } from "./notification";
import { DatePickerProps, FormInstance } from "antd";
import { timeZones } from "./momentTimezone";
import bugsnagClient from "@bugsnag/js";
import { Row } from 'antd'
import { RightOutlined } from "@ant-design/icons";
import { useRouter } from 'next/navigation';
const superagent = require("superagent");
import { NewBookingStatuses, NewMembershipStatuses, ReportEndpointsSuffix } from "./enums";

import type { SetStateFunc } from "../types";
import type { Discount, DiscountStatusStrAndColorObj } from "../types/discount";
import { ToggleOption } from "../types/toggleComponent";
import { TZDate } from "react-day-picker";


export default class Helper {
    constructor() {

    }


    static escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static replaceAll = (str: string, term: string, replacement: string) => {
        return str.replace(new RegExp(Helper.escapeRegExp(term), "g"), replacement);
    }

    static getEncodedValue = (val: string) => {
        var encodedEmail = Helper.replaceAll(val, "@", "%40");
        return encodedEmail = Helper.replaceAll(encodedEmail, ".", "%2E");
    }

    static getDecodedValue = (val: string) => {
        var decodedEmail = Helper.replaceAll(val, "%40", "@");
        return decodedEmail = Helper.replaceAll(decodedEmail, "%2E", ".");
    }

    static getUrlParam = (key: string): string | null => {
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(key);
        }
    };

    static setUrlParam = (key: string, value: string) => {
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.href);
            url.searchParams.set(key, value);
            window.history.replaceState(null, null, url);
        }
    };
    static checkUrlParam = (value: any, defaultValue: any, valueType: "number" | "string" | null = null) => {
        if (value && value != "null" && value != "undefined") {
            if (valueType === "number") {
                return Number(value);
            }
            if (valueType === "string") {
                return String(value);
            }
            return value;
        }
        return defaultValue
    };
    static getProfileImageUrl = (obj: any, defaultImgColored?: boolean) => {
        let gender = obj?.gender?.toLowerCase();
        let icon = defaultImgColored ? userColored : userNoColor;
        if (obj?.profileImageUrl || obj?.profilePhoto) {
            return obj?.profileImageUrl || obj?.profilePhoto;
        } else {
            switch (gender) {
                case "male":
                    icon = boyIcon;
                    break;
                case "female":
                    icon = girlIcon;
                    break;
            }
            return icon.src
        }
    }
    static getWeekDays = () => {
        return [
            "Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"
        ]
    }

    static getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    static confirmAlert = (confirmAlertObject) => {
        const { title, message, yes, no, yesOnClick, closeOnClickOutside = false, noFormatMsg, styles } = confirmAlertObject;

        confirmAlert({
            customUI: ({ onClose }) => (
                <div className='react-confirm-alert-body'>
                    <h1>{title}</h1>
                    <p className='text-wrap'>
                        {message}
                    </p>
                    <div className='react-confirm-alert-button-group'>
                        <button
                            onClick={() => {
                                yesOnClick();
                                onClose();
                            }}
                            style={styles && styles.primaryButton ? styles.primaryButton : null}
                        >
                            {noFormatMsg ? yes : formatMsg(yes)}
                        </button>
                        <button
                            onClick={onClose}
                            style={styles && styles.secondaryButton ? styles.secondaryButton : null}
                        >
                            {noFormatMsg ? no : formatMsg(no)}
                        </button>
                    </div>
                </div>
            ),
            closeOnClickOutside: closeOnClickOutside
        });
    }

    static getDefaultLanguageKey = () => {
        let storedKey = getItem("lng");
        return storedKey ? storedKey : "en";
    };

    static getSuperagentBody = (obj, firebase, godMode?: any) => {
        obj.platform = "web";
        // obj.version = packageJson.version;
        obj.updatedOn = dayjs.tz().valueOf();
        // obj.createdOn = dayjs.tz().valueOf();

        if (firebase?.teacher) obj.updatedBy = firebase?.teacher?.name;
        if (firebase?.account) obj.updatedBy = firebase?.account?.name;

        if (godMode) obj.updatedBy = "GOD";

        return obj;
    };

    static getSelectorState = (keys: string[]) => {
        const result: any = {};
        const allSlices = useSelector((state: any) => {
            const slices: any = {};
            keys.forEach(key => {
                slices[key] = state[key];
            });
            return slices;
        });

        keys.forEach(key => {
            result[key] = allSlices[key];
        });

        const login = useSelector((state: any) => state.login);
        result.firebase = login.firebase;
        result.loggedInData = login.loggedInData;

        return result;
    }

    static getPathToStorage(contentType, firebase, mediaType?: any) {
        let storagePath;
        switch (contentType) {
            case "application/octet-stream":
                storagePath = firebase.sbp + "/media/file/";
                break;
            case "text/csv":
                storagePath = firebase.sbp + "/media/file/";
                break;
            case "image/jpeg":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/png":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/bmp":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/gif":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/webp":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "video/mp4":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/3gpp":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/mpeg":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/quicktime":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "audio/mp3":
                storagePath = firebase.sbp + "/media/audio/";
                break;
            default:
                storagePath = firebase.sbp + "/media/images/";
        }

        if (contentType && contentType.includes("application")) {
            storagePath = firebase.sbp + "/media/file/";
        }

        if (!storagePath && mediaType && mediaType.toLowerCase() === "video") {
            storagePath = firebase.sbp + "/media/videos/";
        }

        return storagePath;
    }

    static getMediaType(contentType, file) {
        let mediaType;
        let incomingContentType = contentType;
        if (!incomingContentType) {
            incomingContentType = file.type;
        }

        switch (incomingContentType) {
            case "application/octet-stream":
                mediaType = "file";
                break;
            case "text/csv":
                mediaType = "file";
                break;
            case "image/jpeg":
                mediaType = "image";
                break;
            case "image/png":
                mediaType = "image";
                break;
            case "image/bmp":
                mediaType = "image";
                break;
            case "image/gif":
                mediaType = "image";
                break;
            case "image/webp":
                mediaType = "image";
                break;
            case "video/mp4":
                mediaType = "video";
                break;
            case "video/3gpp":
                mediaType = "video";
                break;
            case "video/mpeg":
                mediaType = "video";
                break;
            case "video/quicktime":
                mediaType = "video";
                break;
            case "audio/mp3":
                mediaType = "audio";
                break;
            default:
                mediaType = "image";
        }

        if (incomingContentType && incomingContentType.includes("application")) {
            mediaType = "file";
        }

        return mediaType;
    }

    static convertImage = (selectedFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            reader.onload = (e) => {
                const img: any = new Image();
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    // Convert the canvas content to PNG
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], selectedFile.name + '.png', { type: 'image/png' }));
                    }, 'image/png');
                };

                img.src = e.target.result;
            };
            reader.readAsDataURL(selectedFile);
        });
    };

    static async checkFileSizeAndCompress(originalFile, options, firebase) {
        let compressedFilePromise;
        if (
            (originalFile &&
                originalFile.size &&
                originalFile.size <= 1000000) ||
            (firebase.schoolName &&
                firebase.schoolName.toLowerCase().includes("woodland") &&
                originalFile &&
                originalFile.size &&
                originalFile.size <= 5000000)
        ) {
            return originalFile;
            // compressedFilePromise = new Promise((resolve, reject) => {
            //     resolve(originalFile)
            // })
        } else {
            let convertedFile;
            if (originalFile.type && originalFile.type.includes("jpeg")) {
                convertedFile = await Helper.convertImage(originalFile);
            }

            if (convertedFile) {
                compressedFilePromise = imageCompression(convertedFile, options);
            } else {
                compressedFilePromise = imageCompression(originalFile, options);
            }
            return compressedFilePromise;
        }
    }

    static getAttachmentDownloadUrl(
        attachmentRef: any,
        file: any,
        metadata: { contentType: any; cacheControl: any; },
        activityId?: any,
        urls?: any,
        singleFile?: { type: any; },
        firebase?: any,
        branchPath?: any,
        fileName?: any,
        thumbnailSource?: any,
        index?: any,
        studentIds?: any,
        base64String?: any,
        ogFileName?: any,
        moreThanOne?: number
    ) {
        return new Promise(function (resolve, reject) {
            let mediaType;
            let atchRef = attachmentRef.put(file, metadata);
            if (base64String) {
                mediaType = "file";
                atchRef = attachmentRef.putString(base64String);
            }
            atchRef.on(
                Firebase.storage.TaskEvent.STATE_CHANGED,
                function (snapshot) {
                    mediaType = Helper.getMediaType(
                        snapshot.metadata.contentType ? snapshot.metadata.contentType : undefined,
                        singleFile
                    );
                    var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    store.dispatch({
                        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
                        attachmentProgress: moreThanOne ? progress && progress > 95 ? moreThanOne : 0 : progress,
                        moreThanOne: moreThanOne ? true : false,
                    });
                },
                function (error) {
                    console.log("error", error);
                    reject(error);
                },
                function () {
                    var p2 = attachmentRef.getDownloadURL().then(function (downloadURL) {
                        urls.push({
                            path: downloadURL,
                            type: mediaType,
                            name: Helper.getPathToStorage(singleFile.type, firebase, mediaType) + fileName,
                            fileName: ogFileName,
                        });
                        resolve(p2);
                    });
                }
            );
        });
    }

    static async getAttachedMediaPath(storagePath: string, files: any, firebase: any) {
        var urls = [];
        var promiseList = [];
        let branchPath = firebase.sbp;
        files.fileList.forEach(function (singleFile, index) {
            var originalFile =
                singleFile.originFileObj && singleFile.originFileObj.name
                    ? singleFile.originFileObj
                    : singleFile;
            const options = {
                maxSizeMB: 20,
                maxWidthOrHeight: 1200,
                useWebWorker: true,
            };
            var metadata = {
                contentType: singleFile.type,
                cacheControl: "public,max-age=604800",
            };
            const storage = firebase.secondaryStorage;
            const storageRef = storage.ref();
            let fileName = new Date().getTime() + originalFile.name;
            let ogFileName = originalFile.name;
            const attachmentRef = storageRef.child(Helper.getPathToStorage(singleFile.type, firebase) + fileName);
            let compressedFilePromise = Helper.checkFileSizeAndCompress(originalFile, options, firebase);

            let p2 = compressedFilePromise
                .then((fileRes) => {
                    let file = fileRes;
                    return Helper.getAttachmentDownloadUrl(
                        attachmentRef,
                        file,
                        metadata,
                        null,
                        urls,
                        singleFile,
                        firebase,
                        branchPath,
                        fileName,
                        null,
                        index,
                        null,
                        originalFile.base64String ? originalFile.base64String : undefined,
                        ogFileName,
                        files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0
                    )
                })
                .catch((err) => {
                    return Helper.getAttachmentDownloadUrl(
                        attachmentRef,
                        originalFile,
                        metadata,
                        null,
                        urls,
                        singleFile,
                        firebase,
                        branchPath,
                        fileName,
                        null,
                        index,
                        null,
                        originalFile.base64String ? originalFile.base64String : undefined,
                        ogFileName,
                        files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0
                    )
                });
            promiseList.push(p2);
            // store.dispatch({
            //     type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
            //     attachmentProgress: files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0,
            //     allFilesUploaded: false,
            // });

        })
        return Promise.all(promiseList)
            .then(function () {
                store.dispatch({
                    type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
                    attachmentProgress: 100,
                    allFilesUploaded: true,
                });
                return urls;
            })
            .catch((error) => {
                throw error;
            });
    }

    static getPackageTypeList() {
        return ["Activity Camp", "Full day camp", "Half day camp", "Workshop", "Party", "Events & Shows"]
    }

    static getTeacherList() {
        return JSON.parse(getItem("teacherList"))?.filter((item) => !item.deleted) || [];
    }

    static getStudentList() {
        return JSON.parse(getItem("studentList"))?.filter((item) => !item.deleted) || [];
    }

    // When using this helper, always subscribe to "userReducer" 
    static getUserList() {
        return JSON.parse(getItem("userList"))?.filter((item) => !item.deleted) || [];
    }

    // When using this helper, always subscribe to "accountReducer" 
    static getAccountList() {
        return JSON.parse(getItem("accountList"))?.filter((item) => !item.deleted) || [];
    }

    static getActiveStudentList() {
        return JSON.parse(getItem("studentList"))?.filter((item) => !item.deleted && item.status.toLowerCase() === "active") || [];
    }

    static getStudentObj(id) {
        return JSON.parse(getItem("studentList"))?.find((item) => item.id === id);
    }

    static getStaffObj(id) {
        return JSON.parse(getItem("teacherList"))?.find((item) => item.id === id);
    }

    static getStatustag(status) {
        let backgorundColor;
        switch (status?.toLowerCase()) {
            case "active":
                backgorundColor = colors.color_activeColor;
                break;
            case "withdrawn":
                backgorundColor = "#F8CB5D";
                break;
            default:
                backgorundColor = colors.color_activeColor;
        }

        return <div
            style={{
                backgroundColor: backgorundColor,
                color: colors.color_white,
                height: 28,
                padding: "0 19px",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                fontSize: "var(--font-size-14)",
                fontWeight: 600
            }}
        >
            {formatMsg(status)}
        </div>;
    }

    static getDateRanges() {
        return ["thisMonth", "lastMonth", "thisYear", "lifetime", "custom"];
    }

    static getAmountStatusTag(status) {
        let bgColor = "#28A745";

        switch (status.toLowerCase()) {
            case "partiallypaid":
                bgColor = "#CE9C03";
                break;
            case "failed":
            case "partiallyoverdue":
            case "overdue":
                bgColor = "#D6001C";
                break;
            case "draft":
                bgColor = "#FD7E14";
                break;
            case "due":
                bgColor = "#CE9C03";
                break;
            case "cancelled":
                bgColor = "#8B4513";
                break;
            case "inactive":
                bgColor = "#FD7E14";
                break;
            case "pending":
                bgColor = "#FFC107";
                break;
        }
        return <div
            style={{
                color: "white",
                background: bgColor,
                minHeight: 22,
                fontSize: "var(--font-size-13)",
                fontWeight: 500,
                paddingLeft: 14,
                paddingRight: 14,
                borderRadius: 6,
                width: "fit-content",
                display: "flex",
                alignItems: "center"
            }}
        >
            {formatMsg(status)}
        </div>
    }

    static getStatusWithColorDot = (text, fs?) => {
        if (!text) return "-"
        let dotColor;

        switch (text.toLowerCase()) {
            case "confirmed":
                dotColor = "#007BFF"
                break;
            case "waitlisted":
                dotColor = "#FD7E14"
                break;
            case "cancelled":
                dotColor = "#DC3545"
                break;
            case "pending":
                dotColor = "#FFC107"
                break;
            case "active":
                dotColor = "#28A745"
                break;
            case "expired":
                dotColor = "#6B7280"
                break;
            case "confirmation_pending":
                dotColor = "#FFC107"
                break;
            default:
                dotColor = "#4EA000"
                break;
        }

        const style = {
            color: "#333",
            fontWeight: 300,
            fontSize: "var(--font-size-15)",
            display: "flex",
            alignItems: "center"
        }
        const iconStyle = {
            width: 8,
            height: 8,
            flexShrink: 0,
            borderRadius: 10,
            marginRight: 8,
            backgroundColor: dotColor
        }

        return <div style={style}>
            <div style={iconStyle}></div>
            <div style={{ fontSize: fs || undefined, whiteSpace: "nowrap" }}>
                {formatMsg(text)}
            </div>
        </div>
    }

    static dateFormat(firebase) {
        return firebase?.schoolConfig?.dateFormat || "DD MMM YY"
    }

    static formatDate(date, firebase) {
        return dayjs.tz(date).format(Helper.dateFormat(firebase))
    }

    static timeFormat(firebase) {
        return (firebase?.schoolConfig?.timeFormat || Helper.getDefaultDateTimeFormat('time'))
    }

    static sortIntegerValue = (a, b, key) => {
        if (!a || a[key] == null || a[key] == undefined) {
            return 1;
        }

        if (!b || b[key] == null || b[key] == undefined) {
            return -1;
        }

        return a[key] - b[key];
    };

    static sortStringValue = (a, b, key, key2?) => {
        let valueA, valueB;

        if (key2) {
            valueA = a && a[key] ? a[key][key2] : null;
            valueB = b && b[key] ? b[key][key2] : null;
        } else {
            valueA = a ? a[key] : null;
            valueB = b ? b[key] : null;
        }

        if (valueA === null) return 1;
        if (valueB === null) return -1;

        return valueA?.toString().localeCompare(valueB?.toString());
    };

    static decimalFormat = (number: any) => {
        number = floor(Number(number), 2);
        return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    static roundNumberToTwo = (number: any) => {
        number = round(Number(number), 2);
        return number
    }
    static getAgeRangeName(ageRange, packageReducer) {
        let obj = packageReducer?.ageRange?.find((item) => item.id === ageRange);
        return this.getAgeRangeFormat(obj)
    }

    static getAgeRangeFormat(obj) {
        return obj && obj.min.year + "y" + " " + obj.min.month + "m" + " - " + obj.max.year + "y" + " " + obj.max.month + "m"
    }

    static calculateIntervals(startDate, noOfWeeks) {
        const start = dayjs.tz(startDate);
        const end = start.add(noOfWeeks, 'weeks');

        const days = end.diff(start, 'day') || 1;
        const weeks = end.diff(start, 'week') || 1;
        const months = end.diff(start, 'month') || 1;
        const years = end.diff(start, 'year') || 1;

        let weekends = 0;
        let current = start;

        while (current.isSameOrBefore(end)) {
            if (current.day() === 0 || current.day() === 6) {
                weekends++;
            }
            current = current.add(1, 'day');
        }

        return { DAILY: days, WEEK: weeks, WEEKEND: weekends, MONTH: months, YEARLY: years };
    }

    static shortLinkUrl(copyText) {

        let endPointUrl =
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB775InMuZ5ylr7usQKlDeRFMVDFKnG8UE";

        let obj = {
            dynamicLinkInfo: {
                domainUriPrefix: "https://illumine.page.link",
                link: copyText,
            },
            suffix: {
                option: "SHORT",
            },
        };

        var p1 = new Promise(function (resolve, reject) {
            superagent
                .post(endPointUrl)
                .send(obj)
                .set("accept", "json")
                .end((err, res) => {
                    if (res) {
                        resolve(res);
                    } else if (err) {
                        reject(err);
                    }
                });
        });

        return p1;
    }

    static handleCopyToClipboardError(err, text) {
        switch (err?.name) {
            case 'PermissionDeniedError':
                console.error('Clipboard access denied. Please grant permission.');
                notification("error", formatMsg("copy.permissionDenied"))
                break;
            case 'SecurityError':
                console.error('Clipboard access is only available in secure contexts (HTTPS).');
                break;
            case 'ClipboardWriteFailed':
                console.error('Failed to write text to clipboard.');
                break;
            case 'DataTransferError':
                console.error('Data transfer to clipboard failed.');
                break;
            case 'UnsupportedClipboardOperation':
                console.error('Clipboard operation is not supported.');
                break;
            default:
                console.error('An unknown error occurred:', err);
                break;
        }
        let message = formatMsg("copy.failedToCopy") + " " + formatMsg("copy.manually") + "\n\n" + text
        alert(message)
    }

    static copyToClipboard = async (text) => {
        await navigator.clipboard.writeText(text).then(() => {
            notification("success", formatMsg("notification.copyToClip"));
        }).catch((err) => {
            this.handleCopyToClipboardError(err, text)
        })
    };

    static getObjById(id, list) {
        if (!id || !list) {
            return;
        }
        return list?.find((item) => item?.id === id);
    }

    static searchByKey(list, key, value) {
        let text = value
            .trim()
            .toLowerCase()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        if (!text || text === "") {
            return list;
        } else {
            return list.filter((item) => {
                if (item[key] && item[key].toLowerCase().match(text)) {
                    return true;
                }
            });
        }
    };

    static dateRangeFilter(start1, end1, start2, end2, granularity) {
        const latestStart = start1.isAfter(start2) ? start1 : start2;
        const earliestEnd = end1.isBefore(end2) ? end1 : end2;

        if (latestStart.isBefore(earliestEnd, granularity) || latestStart.isSame(earliestEnd, granularity)) {
            return true;
        } else {
            return false;
        }
    };

    static customWeekStartEndFormat: DatePickerProps['format'] = (value) =>
        `${dayjs.tz(value).startOf('week').format("DD MMMM YYYY")} ~ ${dayjs.tz(value).endOf('week').format("DD MMMM YYYY")}`;

    static getAge = (dob) => {
        let birthDate = dob ? dayjs.tz(dob) : undefined;

        if (birthDate) {
            let currentDate = dayjs.tz().add(1, 'days');
            let studentAge = dayjs.duration(currentDate.diff(birthDate));
            let studentAgeInYear = studentAge.years();

            let studentAgeInMonth = studentAge.months();

            if (studentAgeInYear > 0 || studentAgeInMonth > 0) {
                return studentAgeInYear + " year " + studentAgeInMonth + " month";
            } else if (studentAgeInYear === 0 && studentAgeInMonth === 0) {
                return studentAgeInYear + " year " + studentAgeInMonth + " month";
            } else {
                return "-";
            }
        }
        return "-"
    };

    static assignPackageColors = (packageIndex) => {
        let colors = ["#59C069", "#FA91AA", "#00B6DA", "#7413BD"];
        packageIndex = packageIndex % colors.length;
        return colors[packageIndex];
    }

    static checkIfDuplicateSlots = (slots) => {
        let dayTimeActivityMap = new Map();
        for (const element of slots) {
            let key = element.day || element.date + element.startTime.toString() + element.endTime.toString() + element.activityId;

            if (dayTimeActivityMap.has(key)) {
                notification("error", formatMsg("error.duplicateSlots") + " " + (element.day || dayjs.tz(element.date).format("DD MMM,YYYY")));
                return false;
            } else {
                dayTimeActivityMap.set(key, 1);
            }
        }
        return true;
    }


    static getLanguage = () => {
        return [
            { label: "English", value: "en" }, { label: "Arabic", value: "ar" }
        ]
    }

    static isParent = (firebase) => {
        return firebase?.user?.userType?.toLowerCase() === "parent"
    }


    static isAccount = (firebase) => {
        return firebase?.user?.userType?.toLowerCase() === "account"
    }

    static getBookingStatusNane = (bookingStatus) => {
        let name = "booked";
        switch (bookingStatus) {
            case "ENROLLED":
                name = "enrolled";
                break;
            case "WAITLISTED":
                name = "waitlisted"
                break;
            case "CANCELLED":
                name = "cancelled";
                break;
            case "APPROVAL_REQUESTED":
                name = "approvalRequested";
                break;
            case "ENROLLMENT_PENDING":
                name = "enrolledPending";
                break;
            case "COMPLETED":
                name = "completed";
                break;
        }
        return name;
    }

    static getCountryCode() {
        const zone = dayjs.tz.guess();
        if (timeZones && zone && timeZones.countries) {
            const countryCode = Object.keys(timeZones.countries).find(key =>
                timeZones.countries[key].zones.includes(zone)
            );
            return countryCode?.toLowerCase() || 'us';
        }
        return 'us';
    }

    static getStaffRole() {
        return ["Teacher", "Super Admin", "Helper", "Administrator", "Center Head", "Principal", "Senior Teacher", "Finance Admin"]
    }

    static getStringValueForForm(string) {
        return string && string.trim() ? string.trim() : null
    }

    static notifyBugsnag(err, showNotification?: boolean) {
        if (showNotification)
            notification("error", err?.response?.body?.message ? err?.response?.body?.message : formatMsg("error.occured"))
        bugsnagClient.notify(err);
    }
    static getCurrency = (firebase) => {
        const currency = firebase?.schoolConfig?.currencyCode ? firebase.schoolConfig.currencyCode : "INR";
        const currencyMap = {
            AED: 'AED',
            AFN: '؋',
            ALL: 'L',
            AMD: '֏',
            ANG: 'ƒ',
            AOA: 'Kz',
            ARS: '$',
            AUD: '$',
            AWG: 'ƒ',
            AZN: '₼',
            BAM: 'KM',
            BBD: '$',
            BDT: '৳',
            BGN: 'лв',
            BHD: 'BHD',
            BIF: 'FBu',
            BMD: '$',
            BND: '$',
            BOB: '$b',
            BOV: 'BOV',
            BRL: 'R$',
            BSD: '$',
            BTC: '₿',
            BTN: 'Nu.',
            BWP: 'P',
            BYN: 'Br',
            BYR: 'Br',
            BZD: 'BZ$',
            CAD: '$',
            CDF: 'FC',
            CHE: 'CHE',
            CHF: 'CHF',
            CHW: 'CHW',
            CLF: 'CLF',
            CLP: '$',
            CNH: '¥',
            CNY: '¥',
            COP: '$',
            COU: 'COU',
            CRC: '₡',
            CUC: '$',
            CUP: '₱',
            CVE: '$',
            CZK: 'Kč',
            DJF: 'Fdj',
            DKK: 'kr',
            DOP: 'RD$',
            DZD: 'دج',
            EEK: 'kr',
            EGP: 'E£',
            ERN: 'Nfk',
            ETB: 'Br',
            ETH: 'Ξ',
            EUR: '€',
            FJD: '$',
            FKP: '£',
            GBP: '£',
            GEL: '₾',
            GGP: '£',
            GHC: '₵',
            GHS: 'GH₵',
            GIP: '£',
            GMD: 'D',
            GNF: 'FG',
            GTQ: 'Q',
            GYD: 'GYD',
            HKD: 'HKD',
            HNL: 'L',
            HRK: 'kn',
            HTG: 'G',
            HUF: 'Ft',
            IDR: 'Rp',
            ILS: '₪',
            IMP: '£',
            INR: '₹',
            IQD: 'ع.د',
            IRR: '﷼',
            ISK: 'kr',
            JEP: '£',
            JMD: 'J$',
            JOD: 'JD',
            JPY: '¥',
            KES: 'KSh',
            KGS: 'лв',
            KHR: '៛',
            KMF: 'CF',
            KPW: '₩',
            KRW: '₩',
            KWD: 'KWD',
            KYD: '$',
            KZT: '₸',
            LAK: '₭',
            LBP: '£',
            LKR: '₨',
            LRD: '$',
            LSL: 'M',
            LTC: 'Ł',
            LTL: 'Lt',
            LVL: 'Ls',
            LYD: 'LD',
            MAD: 'MAD',
            MDL: 'lei',
            MGA: 'Ar',
            MKD: 'ден',
            MMK: 'K',
            MNT: '₮',
            MOP: 'MOP$',
            MRO: 'UM',
            MRU: 'UM',
            MUR: '₨',
            MVR: 'Rf',
            MWK: 'MK',
            MXN: '$',
            MXV: 'MXV',
            MYR: 'RM',
            MZN: 'MT',
            NAD: '$',
            NGN: '₦',
            NIO: 'C$',
            NOK: 'kr',
            NPR: '₨',
            NZD: '$',
            OMR: '﷼',
            PAB: 'B/.',
            PEN: 'S/.',
            PGK: 'K',
            PHP: '₱',
            PKR: '₨',
            PLN: 'zł',
            PYG: 'Gs',
            QAR: '﷼',
            RMB: '￥',
            RON: 'lei',
            RSD: 'Дин.',
            RUB: '₽',
            RWF: 'R₣',
            SAR: '﷼',
            SBD: '$',
            SCR: '₨',
            SDG: 'ج.س.',
            SEK: 'kr',
            SGD: 'S$',
            SHP: '£',
            SLL: 'Le',
            SOS: 'S',
            SRD: '$',
            SSP: '£',
            STD: 'Db',
            STN: 'Db',
            SVC: '$',
            SYP: '£',
            SZL: 'E',
            THB: '฿',
            TJS: 'SM',
            TMT: 'T',
            TND: 'د.ت',
            TOP: 'T$',
            TRL: '₤',
            TRY: '₺',
            TTD: 'TT$',
            TVD: '$',
            TWD: 'NT$',
            TZS: 'TSh',
            UAH: '₴',
            UGX: 'USh',
            USD: '$',
            UYI: 'UYI',
            UYU: '$U',
            UYW: 'UYW',
            UZS: 'лв',
            VEF: 'Bs',
            VES: 'Bs.S',
            VND: '₫',
            VUV: 'VT',
            WST: 'WS$',
            XAF: 'FCFA',
            XBT: 'Ƀ',
            XCD: '$',
            XOF: 'CFA',
            XPF: '₣',
            XSU: 'Sucre',
            XUA: 'XUA',
            YER: '﷼',
            ZAR: 'R',
            ZMW: 'ZK',
            ZWD: 'Z$',
            ZWL: '$'
        }
        return currencyMap[currency] ? currencyMap[currency] : currency;
    }

    static renderBreadCrumb = (currPageText) => {
        const router = useRouter();
        return <Row typeof="flex" align="middle" style={{ marginBottom: "12px" }}>
            <span
                style={{
                    color: "#8D8D8D",
                    cursor: "pointer"
                }}
                onClick={() => router.back()}>{formatMsg("goBack")}
                <RightOutlined style={{ margin: "0px 8px 0px 8px" }} />
            </span>
            <span>{formatMsg(currPageText)}</span>
        </Row>
    }

    static checkDiscountActive(record: Record<string, unknown>): boolean {

        const discountActive: boolean = (typeof record.active === 'boolean') ? record.active : true;
        const today = dayjs.tz().startOf('day').valueOf();

        if (record.startDate === null) delete record.startDate;
        if (record.endDate === null) delete record.endDate;
        if (record.redemptions === null) delete record.redemptions;

        if (
            (typeof record.startDate === 'number' || typeof record.startDate === 'undefined') &&
            (typeof record.endDate === 'number' || typeof record.endDate === 'undefined') &&
            (typeof record.redemptions === 'number' || typeof record.redemptions === 'undefined')
        ) {
            const discountInDateRange: boolean =
                record.startDate && record.endDate
                    ? today >= dayjs.tz(record.startDate).startOf('day').valueOf() && today <= dayjs.tz(record.endDate).endOf('day').valueOf()
                    : record.startDate
                        ? today >= dayjs.tz(record.startDate).startOf('day').valueOf()
                        : record.endDate
                            ? today <= dayjs.tz(record.endDate).endOf('day').valueOf()
                            : true;

            const discountUsageLimitNotCompletelyUsed: boolean = record.redemptions === undefined || record.redemptions > 0;

            return discountActive && discountInDateRange && discountUsageLimitNotCompletelyUsed;
        }
        else {
            notification('error', 'At least one of the discount has key with wrong datatypes, Internal Server Error');
        }
    }

    static getFilteredDiscount(list) {
        return list?.filter((item) => {
            return Helper.checkDiscountActive(item);
        })
    }

    static getPasswordRegex() {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
    }

    static calulateDuration(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "MONTH":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "month") + 1;
                return duration > 1 ? `Booked for : ${duration} months` : `Booked for : ${duration} month`
            case "WEEK":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "week") + 1;
                return duration > 1 ? `Booked for : ${duration} weeks` : `Booked for : ${duration} week`;
            case "DAILY":
            case "CUSTOM":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "day") + 1;
                return duration > 1 ? `Booked for : ${duration} days` : `Booked for : ${duration} day`;
            case "YEARLY":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "year") + 1;
                return duration > 1 ? `Booked for : ${duration} years` : `Booked for : ${duration} year`;
        }
    }
    static calulateDurationForTable(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "MONTH":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "month") + 1;
                return duration > 1 ? `Booked for : ${duration} months` : `Booked for : ${duration} month`
            case "WEEK":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "week") + 1;
                return duration > 1 ? `Booked for : ${duration} weeks` : `Booked for : ${duration} week`;
            case "DAILY":
            case "CUSTOM":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "day") + 1;
                return duration > 1 ? `Booked for : ${duration} days` : `Booked for : ${duration} day`;
            case "YEARLY":
                duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "year") + 1;
                return duration > 1 ? `Booked for : ${duration} years` : `Booked for : ${duration} year`;
        }
    }

    static calulateDurationCount(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "ENTIRE_PACKAGE_DURATION":
                return 1;
            case "MONTH":
                return duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "month") + 1;
            case "WEEK":
                return duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "week") + 1;
            case "DAILY":
            case "CUSTOM":
                return duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "day") + 1;
            case "YEARLY":
                return duration = dayjs.tz(endDate).diff(dayjs.tz(startDate), "year") + 1;
        }
    }

    static calculateDueDate(packageObj, intervalEndDate, frequency) {
        console.log("packageObj", packageObj)
        if (frequency === "DAILY" || frequency === "CUSTOM") {
            return intervalEndDate;
        }
        switch (packageObj.dueOnCycle.toLowerCase()) {
            case "before":
                return dayjs.tz(intervalEndDate).subtract(packageObj.dueOnDays, "day");
            case "after":
                return dayjs.tz(intervalEndDate).add(packageObj.dueOnDays, "day");
        }
    }

    static syncDateRangeFilterOptionsHelper = (
        paramsKey: string,
        startDateParamKey: string,
        endDateParamKey: string,
        stateSetterFunc: SetStateFunc<any>,
        defaultValue: string[]
    ) => {
        const validDateRangeParamsKeyValues = ['dateRange'];

        if (validDateRangeParamsKeyValues.includes(paramsKey)) {

            const startDateFinalParamValue = Helper.getUrlParam(startDateParamKey);
            const endDateFinalParamValue = Helper.getUrlParam(endDateParamKey);

            if (startDateFinalParamValue && endDateFinalParamValue) {
                const startDateEpoch = Number(startDateFinalParamValue);
                const endDateEpoch = Number(endDateFinalParamValue);
                stateSetterFunc([dayjs.tz(startDateEpoch), dayjs.tz(endDateEpoch)]);
            }
            else {
                Helper.setUrlParam(startDateParamKey, defaultValue[0]);
                Helper.setUrlParam(endDateParamKey, defaultValue[1]);
            }
        }
    }

    static syncFilterOptionsHelper = <T extends string | number | undefined>(
        paramsKey: string,
        stateSetterFunc: SetStateFunc<T>,
        validOptionsArr: T[] | null,
        defaultValue: T,
        typeCastValueToNumber?: boolean
    ): void => {

        const strDefaultValue: string = (defaultValue || defaultValue === 0) ? String(defaultValue) : '';
        const validOptionsSet: Set<T> | null = validOptionsArr ? new Set(validOptionsArr) : null;

        const paramValue: string | null = Helper.getUrlParam(paramsKey);

        let finalParamValue: T | null;
        if (typeCastValueToNumber) finalParamValue = Number(paramValue) as T;
        else finalParamValue = paramValue as T;

        if (finalParamValue) {
            const isFilterValueValid = validOptionsSet ? validOptionsSet.has(finalParamValue) : true;
            if (!isFilterValueValid) {
                Helper.setUrlParam(paramsKey, strDefaultValue);
            }
            else stateSetterFunc(finalParamValue);
        }
        else Helper.setUrlParam(paramsKey, strDefaultValue);
    }

    static getDiscountStatusStrAndColorObj = (record: Discount): DiscountStatusStrAndColorObj => {

        let discountStatusStr: string = 'oopsSomthingWentWrong';
        let discountColor: string = 'black';

        const today = dayjs.tz().startOf('day').valueOf();

        if (record.active) {
            if (record.redemptions > 0 || record.redemptions === undefined) {
                if (!record.startDate && !record.endDate) {
                    discountStatusStr = 'active';
                    discountColor = colors.green;
                }
                else if (record.startDate && today < dayjs.tz(record.startDate).startOf('day').valueOf()) {
                    discountStatusStr = 'upcoming';
                    discountColor = colors.yellow;
                }
                else if (record.endDate && today > dayjs.tz(record.endDate).endOf('day').valueOf()) {
                    discountStatusStr = 'expired';
                    discountColor = colors.red;
                }
                else {
                    discountStatusStr = 'active';
                    discountColor = colors.green;
                }
            }
            else if (record.redemptions === 0) {
                discountStatusStr = 'expired';
                discountColor = colors.red;
            }
            else {
                discountStatusStr = 'oopsSomthingWentWrong';
            }
        }
        else {
            discountStatusStr = 'deactivated';
            discountColor = colors.lightBlue;
        }

        return { discountStatusStr, discountColor };
    }

    static getDiscountValidityPeriodStr = (record: Discount, firebase: any): string => {

        let discountValidtyPeriodStr = '';

        if (record.startDate && record.endDate) {
            discountValidtyPeriodStr = dayjs.tz(record.startDate).format(Helper.dateFormat(firebase)) + ' - ' + dayjs.tz(record.endDate).format(Helper.dateFormat(firebase));
        }
        else if (record.startDate) {
            discountValidtyPeriodStr = formatMsg('Valid') + ' ' + formatMsg('from') + ' ' + dayjs.tz(record.startDate).format(Helper.dateFormat(firebase));
        }
        else if (record.endDate) {
            discountValidtyPeriodStr = formatMsg('Valid') + ' ' + formatMsg('until') + ' ' + dayjs.tz(record.endDate).format(Helper.dateFormat(firebase));
        }
        else {
            discountValidtyPeriodStr = formatMsg('noExpiration');
        }

        return discountValidtyPeriodStr;
    }

    static trimFieldsInObj = (obj: Record<string, any>, keysToIgnore?: string[]) => {

        const keysToIgnoreSet = new Set(keysToIgnore ? keysToIgnore : []);

        for (let key in obj) {
            if (keysToIgnoreSet.has(key)) continue;
            else {
                obj[key] = obj[key] && obj[key].trim() ? obj[key].trim() : null;
            }
        }

        return obj;
    }

    static getClampCss = (higher) => {
        if (typeof higher === 'string' && higher.endsWith('px')) {
            higher = parseInt(higher.slice(0, -2), 10);
        }
        let lower = Math.floor((higher / 3) * 2);
        if (higher >= 24) {
            return `clamp(${lower}px, 2vw + 1rem, ${higher}px)`
        }
        return `clamp(${14}px, 1vw , ${higher}px)`
    }

    static validateCenterMaxCapacity = (
        maxCapacity: number,
        centerIdToCenterObjMap: Map<string, Record<string, any>>,
        centerId: string
    ): boolean => {

        if (maxCapacity === 0) {
            notification(
                'error',
                formatMsg('maxCapacityForCenter') + ' "' + centerIdToCenterObjMap.get(centerId).name + '" ' + formatMsg('cannotBeEmptyZero')
            )
            return false;
        }
        else if (maxCapacity < 0) {
            notification(
                'error',
                formatMsg('maxCapacityForCenter') + ' "' + centerIdToCenterObjMap.get(centerId).name + '" ' + formatMsg('cannotBeNegativeValue')
            )
            return false;
        }
        else if ((maxCapacity - Math.floor(maxCapacity)) > 0) {
            notification(
                'error',
                formatMsg('maxCapacityForCenter') + ' "' + centerIdToCenterObjMap.get(centerId).name + '" ' + formatMsg('cannotBeDecimalValue')
            )
            return false;
        }

        return true;
    }

    static buildReportPayload = (firebase: any, endpointSuffix: string, values: Record<string, any>) => {

        let payload = {};

        switch (endpointSuffix) {
            case ReportEndpointsSuffix.TRANSACTION_REPORT:
                payload = {
                    createdOn: values.createdOn,
                    createdBy: values.createdBy,
                    includeManuallyCreatedInvoices: values.includeManuallyCreatedInvoices,
                    reportParams: {
                        centerIds: values.centerIds,
                        startDate: values.startDate,
                        endDate: values.endDate
                    },
                    teacherId: firebase.teacher.id
                }
                break;
        }

        return payload;
    }

    static processIntervalsArrOfPackageBatch = (intervalArr: Record<string, any>[], startTime: number | undefined, endTime: number | undefined) => {

        let startTimeSame = true, endTimeSame = true;

        if (intervalArr) {

            const intervalArrLen = intervalArr.length;

            for (let j = 0; j < intervalArrLen; j++) {

                const currSlotObj = intervalArr[j];

                if (!startTime) startTime = currSlotObj.startTime;
                else {
                    const startTimeMinute = dayjs.tz(startTime).startOf('minute');
                    const currSlotStartMinute = dayjs.tz(currSlotObj.startTime).startOf('minute');
                    if (!startTimeMinute.isSame(currSlotStartMinute)) {
                        startTimeSame = false;
                        break;
                    }
                }

                if (!endTime) endTime = currSlotObj.endTime;
                else {
                    const endTimeMinute = dayjs.tz(endTime).startOf('minute');
                    const currSlotEndMinute = dayjs.tz(currSlotObj.endTime).startOf('minute');
                    if (!endTimeMinute.isSame(currSlotEndMinute)) {
                        endTimeSame = false;
                        break;
                    }
                }
            }
        }

        return { startTime, endTime, startTimeSame, endTimeSame };
    }

    static doesAllSlotsOfThisPackageHaveSameTimeRange = (packageSchedules: Record<string, any>[]) => {

        let startTime = undefined, endTime = undefined;
        let startTimeSame = true, endTimeSame = true;

        if (packageSchedules) {

            const packageSchedulesLen = packageSchedules.length;

            for (let i = 0; i < packageSchedulesLen; i++) {

                const batchObj = packageSchedules[i];
                const intervalArr = batchObj.intervals;
                const recurringIntervalsArr = batchObj.recurringIntervals;

                const processedDataObj1 = Helper.processIntervalsArrOfPackageBatch(intervalArr, startTime, endTime);
                ({ startTimeSame, endTimeSame, startTime, endTime } = processedDataObj1);

                if (!startTimeSame || !endTimeSame) break;

                const processedDataObj2 = Helper.processIntervalsArrOfPackageBatch(recurringIntervalsArr, startTime, endTime);
                ({ startTimeSame, endTimeSame, startTime, endTime } = processedDataObj2);

                if (!startTimeSame || !endTimeSame) break;
            };
        }

        let allSlotsOfCurrPackageHaveSameTimeRange = startTimeSame && endTimeSame;

        return { allSlotsOfCurrPackageHaveSameTimeRange, startTime, endTime };
    }
    static editUserPermissionForBranch = (firebase, centers) => {
        let canEdit = true;
        centers = centers?.map((item) => item.id);
        centers?.forEach(center => {
            if (!firebase.teacher.superUser && !firebase.teacher.centerIds.includes(center))
                canEdit = false
        })
        return canEdit;
    }

    static hasAccessToTeacherIds = (firebase, teacherIds) => {
        let hasAccess = true;
        const allAccessibleTeacherIds = Helper.getTeacherList().map(teacher => teacher.id);

        teacherIds?.forEach(teacherId => {
            if (!allAccessibleTeacherIds.includes(teacherId)) hasAccess = false;
        });
        return hasAccess;
    }

    static getFieldDataType = (dataType) => {
        switch (dataType) {
            case "dropdown":
            case "yes/no checkbox":
                return "select";
            case "text":
            case "number":
                return "input";
        }
        return dataType;
    }

    static getDropdownValues = (item) => {
        if (item.dataType === "dropdown") {
            return [...item.dropdownValues];
        }
        else if (item.dataType === "yes/no checkbox") {
            return ["Yes", "No"]
        }
    }

    static getAdditionalFormFields = (customFields: any[], showAdditionalDetailsHeader?: boolean) => {

        let fields: any = [];

        customFields?.map((item, index) => {
            fields.push([{
                header: index === 0 && showAdditionalDetailsHeader ? formatMsg("additionalDetails") : null,
                label: formatMsg(item.name),
                type: Helper.getFieldDataType(item.dataType),
                name: item.name,
                placeHolder: formatMsg(item.name),
                required: item.mandatory,
                warningMessage: "Please fill " + item.name,
                inputType: item.dataType,
                options: Helper.getDropdownValues(item),
            }])
        });

        return fields;
    }

    static getAdditionalFieldValue(dataType: string, formValue: any): any {

        const lowercasedDataType = dataType.toLowerCase();

        let value: any = '';

        switch (lowercasedDataType) {
            case 'date':
                value = formValue.valueOf();
                break;
            // case 'number': 
            //     value = Number(formValue); 
            //     break; 
            default:
                value = String(formValue);
                break;
        }

        return value;
    }

    static arrayMove(array: any[], fromIndex: number, toIndex: number) {

        const newArray = [...array];

        const [movedItem] = newArray.splice(fromIndex, 1);
        newArray.splice(toIndex, 0, movedItem);

        return newArray;
    }

    static deleteUserTypeFromList(
        userTypesList: Record<string, any>[],
        toBeDeleteUserTypeName: string,
    ) {
        const newUserTypesList = userTypesList ? [...userTypesList] : [];

        const index = newUserTypesList.findIndex((tempUserTypeObj) => tempUserTypeObj.name === toBeDeleteUserTypeName);
        newUserTypesList.splice(index, 1);

        return newUserTypesList;
    }

    static addNewUserTypeObjToList(
        userTypesList: Record<string, any>[],
        createNewUserTypePayload: Record<string, any>,
    ) {
        const newUserTypesList = userTypesList ? [...userTypesList] : [];

        newUserTypesList.push(createNewUserTypePayload);

        return newUserTypesList;
    }


    static getThemeColors(backgroundColor: string = "#333333"): { buttonColor: string; textColor: string, filter: string } {
        // Helper function to convert hex to RGB
        const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            if (!result?.[1] || !result?.[2] || !result?.[3]) hex = "#333333"
            result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            };
        };

        // Helper function to calculate relative luminance for contrast
        const getLuminance = ({ r, g, b }: { r: number; g: number; b: number }): number => {
            const normalize = (value: number) =>
                value / 255 <= 0.03928 ? value / 255 / 12.92 : Math.pow((value / 255 + 0.055) / 1.055, 2.4);
            return 0.2126 * normalize(r) + 0.7152 * normalize(g) + 0.0722 * normalize(b);
        };

        // Convert the background color to RGB
        const backgroundRgb = hexToRgb(backgroundColor);
        const backgroundLuminance = getLuminance(backgroundRgb);

        // Determine text and button colors based on contrast ratio
        const textColor = backgroundLuminance > 0.5 ? "#000000" : "#FFFFFF"; // High contrast text
        // const buttonColor =
        //     backgroundLuminance > 0.5
        //         ? "#666666" // Medium-dark grey for bright backgrounds
        //         : "#575454"; // Light grey for dark backgrounds
        const filter = backgroundLuminance > 0.5
            ? "invert(1)"
            : "none"

        return { buttonColor: Helper.adjustButtonColor(backgroundColor), textColor, filter };
    }

    static adjustButtonColor(backgroundColor: string = "#333333"): string {
        // Helper function to convert hex to RGB
        const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            if (!result?.[1] || !result?.[2] || !result?.[3]) hex = "#333333"
            result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            };
        };

        // Helper function to convert RGB to hex
        const rgbToHex = (r: number, g: number, b: number): string =>
            `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;

        // Helper function to adjust brightness
        const adjustBrightness = ({ r, g, b }: { r: number; g: number; b: number }, factor: number) => ({
            r: Math.min(255, Math.max(0, Math.round(r * factor))),
            g: Math.min(255, Math.max(0, Math.round(g * factor))),
            b: Math.min(255, Math.max(0, Math.round(b * factor))),
        });

        // Convert background color to RGB
        const backgroundRgb = hexToRgb(backgroundColor);

        // Calculate luminance to determine light or dark
        const luminance = (0.2126 * backgroundRgb.r + 0.7152 * backgroundRgb.g + 0.0722 * backgroundRgb.b) / 255;

        // If the background is dark, make the button brighter (factor > 1)
        // If the background is light, make the button darker (factor < 1)
        const factor = luminance < 0.5 ? 1.7 : 0.5;
        const adjustedRgb = adjustBrightness(backgroundRgb, factor);

        // Convert the adjusted RGB back to hex
        return rgbToHex(adjustedRgb.r, adjustedRgb.g, adjustedRgb.b);
    }

    static getSelectedUserTypeObj(userTypesList: Record<string, any>[]) {

        let selectedUserTypeObj = undefined;

        if (userTypesList && userTypesList.length > 0) {
            for (let i = 0; i < userTypesList.length; i++) {
                let userTypeObj = userTypesList[i];
                if (userTypeObj && userTypeObj.selected) {
                    selectedUserTypeObj = userTypeObj;
                    break;
                }
            };
        }

        return selectedUserTypeObj;
    }

    static getDefaultConfirmAlertObject = (buttonsVibe?: string | null | void) => {

        let primaryButton = {};
        let secondaryButton = {};

        switch (buttonsVibe) {
            case 'purple':
                primaryButton = {
                    backgroundColor: colors.color_purple,
                    color: colors.white,
                    border: `1px solid ${colors.color_purple}`
                };
                secondaryButton = {
                    backgroundColor: colors.white,
                    color: colors.black,
                    border: `1px solid ${colors.color_lightGrey}`
                };
                break;
            default:
                primaryButton = {
                    backgroundColor: colors.slack_red,
                    color: colors.white,
                    border: `1px solid ${colors.slack_red}`
                };
                secondaryButton = {
                    backgroundColor: colors.white,
                    color: colors.black,
                    border: `1px solid ${colors.color_lightGrey}`
                };
        }

        const confirmAlertObject = {
            yes: formatMsg("yes"),
            no: formatMsg("cancel"),
            noFormatMsg: true,
            title: formatMsg('Demo Text'),
            message: formatMsg('Fill your message here'),
            yesOnClick: () => null,
            styles: {
                primaryButton: {
                    ...primaryButton,
                    height: '34px',
                    width: '90px',
                    fontSize: 'var(--font-size-14)'
                },
                secondaryButton: {
                    ...secondaryButton,
                    height: '34px',
                    width: '90px',
                    fontSize: 'var(--font-size-14)'
                }
            }
        };

        return confirmAlertObject;
    }

    static getSelectedUserTypeName = (firebase: any, defaultText: string, prefix?: string, suffix?: string): string => {
        if (
            firebase?.schoolConfig?.useUserTypeAsDisplayName
            &&
            firebase?.schoolConfig?.selectedUserTypeName
        ) {
            let displayStr = firebase.schoolConfig.selectedUserTypeName;
            if (prefix) displayStr = prefix + ' ' + displayStr;
            if (suffix) displayStr = displayStr + ' ' + suffix;
            return displayStr;
        }
        else return defaultText;
    }

    static getChildConfigTransformedForForm = (entityObj: Record<string, any>): Record<string, any> => {

        const editFormObj = { ...entityObj };

        if (entityObj.childConfig) {
            entityObj.childConfig.forEach((additionalFieldObj) => {
                editFormObj[additionalFieldObj.name] = (additionalFieldObj.value) ?
                    (additionalFieldObj.dataType === 'date' || additionalFieldObj.dataType === 'time') ?
                        dayjs.tz(additionalFieldObj.value)
                        :
                        additionalFieldObj.value
                    :
                    null;
            });
        }

        return editFormObj;
    }


    static changeUserStatusConfirmAlert = (newStatusVal: string, userObj: Record<string, any>, yesOnClick: () => void) => {


        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');
        confirmAlertObject.yes = formatMsg("confirm");
        confirmAlertObject.no = formatMsg("cancel");
        let title = '';
        let message: any = '';
        switch (newStatusVal.toLowerCase()) {
            case 'active':
                title = 'activateUser';
                message = (
                    <div className="flex-wrap">
                        {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('activate') + ' '}<span className='font-weight-600'>{userObj.name}</span><span>?</span>
                    </div>
                );
                break;
            case 'inactive':
                title = 'deactivateUser';
                message = (
                    <div className="flex-wrap">
                        {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('deactivate') + ' '}<span className='font-weight-600'>{userObj.name}</span><span>?</span>{formatMsg('noFurtherBookingAllowedForUser')}.
                    </div>
                );
                break;
        }
        confirmAlertObject.title = formatMsg(title);
        confirmAlertObject.message = message;
        confirmAlertObject.yesOnClick = yesOnClick;
        Helper.confirmAlert(confirmAlertObject);
    }


    static changeAccountStatusConfirmAlert = (firebase: any, newStatusVal: string, accountObj: Record<string, any>, yesOnClick: () => void) => {

        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');
        confirmAlertObject.yes = formatMsg("confirm");
        confirmAlertObject.no = formatMsg("cancel");
        let title = '';
        let message: any = '';
        switch (newStatusVal.toLowerCase()) {
            case 'active':
                title = 'activateAccount';
                message = (
                    <div className="flex-wrap">
                        {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('activateThe') + ' ' + formatMsg('small.account') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{'?'}
                    </div>
                );
                break;
            case 'inactive':
                title = 'deactivateAccount';
                message = (
                    <div className="flex-wrap">
                        {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('deactivateThe') + ' ' + formatMsg('small.account') + ' '}<span className='font-weight-600'>{accountObj.name}</span><span>?</span>{'\n' + formatMsg('noFurtherBookingAllowedForAccount')}.
                    </div>
                );
                break;
        }
        confirmAlertObject.title = formatMsg(title);
        confirmAlertObject.message = message;
        confirmAlertObject.yesOnClick = yesOnClick;
        Helper.confirmAlert(confirmAlertObject);
    }

    static getLoggedInUserType = (firebase: any): string => {
        return firebase?.user?.userType || '';
    }

    static isUserLoggedIn = (firebase: any): boolean => {
        return Boolean(firebase?.user?.id);
    }

    static getPricingPackName = (pricingPack, firebase) => {
        const currencyAmt = ""
        switch (pricingPack.frequency) {
            case "WEEK":
                if (pricingPack.all != false) break;
                return currencyAmt + pricingPack.frequencyValue + " days/week";
            case "MONTH":
                if (pricingPack.all != false) break;
                return currencyAmt + " " + pricingPack.frequencyValue + (pricingPack.frequencyType == "DAYS" ? " days" : " weeks") + "/month";
            case "CUSTOM":
                let msg = currencyAmt;
                if (!pricingPack.unlimitedClasses) msg = msg + pricingPack.noOfClasses + " classes ";
                msg =
                    (msg || "Custom ") + (pricingPack.startDate ? "from " : "") +
                    (pricingPack.startDate ? dayjs.tz(pricingPack.startDate).format(Helper.dateFormat(firebase)) : "") +
                    (pricingPack.startDate && pricingPack.endDate ? " to " : "") +
                    (pricingPack.endDate ? dayjs.tz(pricingPack.endDate).format(Helper.dateFormat(firebase)) : "");
                return msg;
        }
        return currencyAmt
    }

    static getPricingPackName2 = (pricingPack, firebase) => {
        const currencyAmt = Helper.getCurrency(firebase) + pricingPack.feeAmount;
        switch (pricingPack.frequency) {
            case "WEEK":
                if (pricingPack.all != false) break;
                return currencyAmt + " for " + pricingPack.frequencyValue + " days/week";
            case "MONTH":
                if (pricingPack.all != false) break;
                return currencyAmt + " for " + pricingPack.frequencyValue + (pricingPack.frequencyType == "DAYS" ? " days" : " weeks") + "/month";
            case "CUSTOM":
                let msg = currencyAmt;
                if (!pricingPack.unlimitedClasses) msg = msg + " for " + pricingPack.noOfClasses + " classes";
                msg =
                    (msg) + (pricingPack.startDate ? " from " : "") +
                    (pricingPack.startDate ? dayjs.tz(pricingPack.startDate).format(Helper.dateFormat(firebase)) : "") +
                    (pricingPack.startDate && pricingPack.endDate ? " to " : "") +
                    (pricingPack.endDate ? dayjs.tz(pricingPack.endDate).format(Helper.dateFormat(firebase)) : "");
                return msg;
        }
        return currencyAmt + " " + formatMsg(pricingPack.frequency);
    }

    static getDefaultDateTimeFormat = (type: string) => {

        let format = '';

        switch (type) {
            case 'date':
                format = 'DD MMM YYYY';
                break;
            case 'time':
                format = 'hh:mm a';
                break;
            case 'dateTime':
                format = 'DD MMM YYYY, h:mm A';
                break;
        }

        return format;
    }

    static getBookingStatusColor = (status: string): string => {

        switch (status.toLowerCase()) {
            case 'confirmed':
                return colors.bright_blue;
            case 'expired':
                return colors.new_gray;
            case 'active':
                return colors.bright_green;
            case 'cancelled':
                return colors.bright_red;
            case 'confirmation_pending':
                return colors.bright_yellow;
        }

        return colors.new_gray;
    }

    static getTransactionId = (paymentRecords: any[]): string => {
        if (paymentRecords && paymentRecords.length > 0) {
            const lastTransactionId = paymentRecords[paymentRecords.length - 1].transactionId;
            return lastTransactionId;
        } else {
            return "";
        }
    }

    static getDisplayNameOfCenter = (centersObj: Record<string, any>): string => {

        let displayName = '';

        if (centersObj) {
            if (centersObj.state) {
                displayName += (displayName) ? ', ' : '';
                displayName += centersObj.state;
            }
            displayName += (displayName) ? ', ' : '';
            displayName += centersObj?.country;
        }

        return displayName;
    }

    static hexToLightColor(hex) {
        if (!hex) {
            return
        }
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${0.12})`;
    }



    static getPricingPackFrequencyKey(frequency: string): string {

        let formatMsgFrequencyKey: string = 'WEEK_NEW';

        switch (frequency) {
            case 'ENTIRE_PACKAGE_DURATION':
                formatMsgFrequencyKey = "ENTIRE_PACKAGE_DURATION"
                break;
            case 'MONTH':
                formatMsgFrequencyKey = "months"
                break;
            case 'WEEK':
                formatMsgFrequencyKey = "WEEK_NEW"
                break;
            case 'DAILY':
                formatMsgFrequencyKey = "days"
                break;
            case 'YEARLY':
                formatMsgFrequencyKey = "years"
                break;
        }

        return formatMsgFrequencyKey;
    }

    static getCurrentBookingStatues = () => {
        return ([
            'ENROLLED',
            'APPROVAL_REQUESTED',
            'ACTIVE',
            'WAITLISTED'
        ]);
    }

    static getValidUpcomingBookingStatues = (): Set<string> => {
        const validUpcomingBookingStatues: Set<string> = new Set([
            NewBookingStatuses.CONFIRMED,
            NewBookingStatuses.CONFIRMATION_PENDING,
            NewBookingStatuses.ACTIVE,
            NewBookingStatuses.WAITLISTED
        ]);

        return validUpcomingBookingStatues;
    }

    static getValidPastBookingStatues = (): Set<string> => {
        const validPastBookingStatues: Set<string> = new Set([
            NewBookingStatuses.CANCELLED,
            NewBookingStatuses.EXPIRED
        ]);

        return validPastBookingStatues;
    }

    static getValidUpcomingMembershipStatues = (): Set<string> => {
        const validUpcomingMembershipStatues: Set<string> = new Set([
            NewMembershipStatuses.CONFIRMED,
            NewMembershipStatuses.CONFIRMATION_PENDING,
            NewMembershipStatuses.ACTIVE,
            NewMembershipStatuses.WAITLISTED
        ]);

        return validUpcomingMembershipStatues;
    }

    static getValidPastMembershipStatues = (): Set<string> => {
        const validPastMembershipStatues: Set<string> = new Set([
            NewMembershipStatuses.CANCELLED,
            NewMembershipStatuses.EXPIRED,
            NewMembershipStatuses.ENDED
        ]);

        return validPastMembershipStatues;
    }

    static entityHasValidStatus(validStatusSet: Set<string>, entityStatus: string) {
        return validStatusSet.has(entityStatus);
    }

    static getBookingStatusName = (bookingStatus) => {
        let name = "booked";
        switch (bookingStatus) {
            case "ENROLLED":
                name = "enrolled";
                break;
            case "WAITLISTED":
                name = "waitlisted"
                break;
            case "CANCELLED":
                name = "cancelled";
                break;
            case "APPROVAL_REQUESTED":
                name = "approvalRequested";
                break;
            case "ENROLLMENT_PENDING":
                name = "enrolledPending";
                break;
            case "COMPLETED":
                name = "completed";
                break;
        }
        return name;
    }

    static getMoreBatchesText = (obj) => {
        return (obj?.batchDetails?.zerothBatch?.uniqueTimeSlotsObjList?.length - 1 === 1) ? formatMsg('option') : formatMsg('options');
    }

    static formatDateRanges = (dates, firebase) => {
        if (!dates.length) return "";

        const sortedDates = dates
            .map((date) => dayjs.tz(date))
            .sort((a, b) => a.valueOf() - b.valueOf());

        let result: string[] = [];
        let start = sortedDates[0];
        let end = start;

        for (let i = 1; i < sortedDates.length; i++) {
            if (sortedDates[i].diff(end, "day") === 1) {
                end = sortedDates[i];
            } else {
                result.push(start.isSame(end, "day") ? start.format(Helper.dateFormat(firebase)) : `${start.format(Helper.dateFormat(firebase))} - ${end.format(Helper.dateFormat(firebase))}`);
                start = sortedDates[i];
                end = start;
            }
        }

        result.push(start.isSame(end, "day") ? start.format(Helper.dateFormat(firebase)) : `${start.format(Helper.dateFormat(firebase))} - ${end.format(Helper.dateFormat(firebase))}`);
        return result.join(", ");
    };

    static mergeCommonSlots(slots: Record<string, { key: string; label: string }[]>): Record<string, any> {
        const dateKeys = Object.keys(slots);
        if (dateKeys.length === 0) return {}; // If no slots exist, return an empty object

        // Extract all unique labels per date
        const labelSets = dateKeys.map(date => new Set(slots[date].map(slot => slot.label)));

        // Check if all dates have exactly the same labels
        const isCommon = labelSets.every(set =>
            set.size === labelSets[0].size && Array.from(set).every(label => labelSets[0].has(label))
        );

        if (!isCommon) return slots; // Return original input if labels don't match across all dates

        // Merge into "Common slots" using the first encountered key for each label
        const labelMap = new Map<string, string>();
        for (const date of dateKeys) {
            for (const slot of slots[date]) {
                if (!labelMap.has(slot.label)) {
                    labelMap.set(slot.label, slot.key);
                }
            }
        }
        return {
            "Common slots": Array.from(labelMap.entries()).map(([label, key]) => ({ key, label }))
        };
    }

    static getSubFrequency(frequency) {
        switch (frequency) {
            case "DAILY":
                return formatMsg("days").toLowerCase()
            case "MONTH":
                return formatMsg("months").toLowerCase()
            case "WEEK":
                return formatMsg("weeks")
            default:
                return formatMsg(frequency || "days")
        }
    }

    static getMembershipMainDatesText = (payload?: Record<string, any>) => {

        const labelDateObj = { label: '', date: 0 };

        if (!payload) return labelDateObj;

        const type = payload?.bookingBasicDetails?.status?.toLowerCase();

        switch (type) {
            case 'cancelled':
                labelDateObj.label = 'cancelledOn';
                labelDateObj.date = payload?.bookingBasicDetails?.cancelledOn;
                break;
            case 'expired':
                labelDateObj.label = 'expiredOn';
                labelDateObj.date = payload?.bookedBatch?.endDate;
                break;
            case 'ended':
                labelDateObj.label = 'endedOn';
                labelDateObj.date = payload?.bookedSlotsStartEndDateAndTime?.endDate;
                break;
            default:
                labelDateObj.label = 'expiresOn';
                if (payload?.remainingCredits === 0) {
                    labelDateObj.date = payload?.bookedSlotsStartEndDateAndTime?.endDate;
                }
                else {
                    labelDateObj.date = payload?.bookedBatch?.endDate;
                }
                break;
        }

        return labelDateObj;
    }

    static getColorArr = () => {
        return ([{
            fontColor: '#C23F00',
            backgroundColor: '#FFF1EA'
        },
        {
            fontColor: '#00855A',
            backgroundColor: '#EEFFF9'
        },
        {
            fontColor: '#AB5B00',
            backgroundColor: '#FFFAEF'
        },
        {
            fontColor: '#FD5F85',
            backgroundColor: '#FFF5F7'
        },
        {
            fontColor: '#00ADD1',
            backgroundColor: '#EDFCFF'
        }]);
    }


    static getWeekDaysDisplayDiv = (weekDays: string[], containerCss?: string, divCss?: string) => {

        const colorArr = Helper.getColorArr();

        return (
            <div className={(containerCss) ? containerCss : 'week-days-container'}>
                {weekDays.map((weekDay: string, index) => {
                    const { fontColor, backgroundColor } = colorArr[index % 5];
                    return (
                        <div
                            style={{ color: fontColor, backgroundColor, border: `0.5px solid ${fontColor}` }}
                            className={(divCss) ? divCss : 'week-day-label'}
                        >
                            {formatMsg(`small.${weekDay}`)}
                        </div>
                    )
                })}
            </div>
        )
    }

    static getInvStatusColorMap = () => {
        return {
            paid: '#28A745',
            cancelled: '#8B4513',
            due: '#CE9C03',
            partiallypaid: '#CE9C03',
            overdue: '#D6001C',
            partiallyoverdue: '#D6001C'
        };
    }

    static getTranscatStatusColorMap = () => {
        return { successful: '#28A745', failed: '#D6001C' };
    }

    static getDiscountVal = (record: any, firebase) => {
        if (!record) return ""
        const getCurrencySymbolAsPerDiscount = (record: any) => {
            return (record.type.toLowerCase() === 'fixed_amount') ? Helper.getCurrency(firebase) : '';
        }

        const getPercentageSymbolAsPerDiscount = (record: any) => {
            return (record.type.toLowerCase() === "percentage" ? "%" : "");
        }
        if (record.value || record.value == 0) {
            return (getCurrencySymbolAsPerDiscount(record)) + String(record.value) + getPercentageSymbolAsPerDiscount(record);
        }
        else {
            let value = '';
            record.applicableOnSiblings.forEach((discountNum, index) => {
                value += (
                    getCurrencySymbolAsPerDiscount(record)
                    +
                    String(discountNum)
                    +
                    getPercentageSymbolAsPerDiscount(record)
                );
                if (index !== record.applicableOnSiblings.length - 1) value += ', ';
            });
            return value;
        }
    }

    static stripHtmlTags = (html: string): string => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return (tempDiv.textContent || tempDiv.innerText || '').trim();
    };

    static getGreetingTime = () => {

        const hour = dayjs.tz().hour(); // Get current hour (0-23)

        if (hour >= 0 && hour < 12) return 'goodMorning';
        if (hour >= 12 && hour < 17) return 'goodAfternoon';
        if (hour >= 17 && hour <= 23) return 'goodEvening';
    };

    static removeKeyFromFormFieldsArr = (
        formFieldsArr: any[],
        toBeRemovedObjsNameArr: string[],
        formObjNameKeyToNewPayloadKeysMap?: Map<string, Record<string, any>>
    ) => {

        const toBeRemovedObjNamesSet = new Set(toBeRemovedObjsNameArr);

        const newFields = formFieldsArr.map((inArr) => {
            const newInArr = inArr
                .map((formObj) => {
                    if (toBeRemovedObjNamesSet.has(formObj.name)) return null;
                    else if (formObjNameKeyToNewPayloadKeysMap && formObjNameKeyToNewPayloadKeysMap.has(formObj.name)) {
                        return {
                            ...formObj,
                            ...formObjNameKeyToNewPayloadKeysMap.get(formObj.name)
                        };
                    }
                    else return formObj;
                })
                .filter(Boolean);
            return newInArr?.length ? newInArr : null;
        }).filter(Boolean);

        return newFields;
    }

    static resetRemovedFieldsOfFormWrapper = (removedFormFields: Record<string, any>[][], form: FormInstance<any>) => {

        const resetFieldsArr: string[] = [];

        removedFormFields.forEach((rowArr) => {
            if (rowArr[0].name) {
                resetFieldsArr.push(rowArr[0].name);
            }
        });

        form.resetFields(resetFieldsArr);
    }

    static currencyOptions = [
        { key: "usd", value: "USD", label: "US dollar" },
        { key: "eur", value: "EUR", label: "Euro" },
        { key: "jpy", value: "JPY", label: "Japanese yen" },
        { key: "gbp", value: "GBP", label: "Pound sterling" },
        { key: "aed", value: "AED", label: "United Arab Emirates dirham" },
        { key: "afn", value: "AFN", label: "Afghan afghani" },
        { key: "all", value: "ALL", label: "Albanian lek" },
        { key: "amd", value: "AMD", label: "Armenian dram" },
        { key: "ang", value: "ANG", label: "Netherlands Antillean guilder" },
        { key: "aoa", value: "AOA", label: "Angolan kwanza" },
        { key: "ars", value: "ARS", label: "Argentine peso" },
        { key: "aud", value: "AUD", label: "Australian dollar" },
        { key: "awg", value: "AWG", label: "Aruban florin" },
        { key: "azn", value: "AZN", label: "Azerbaijani manat" },
        { key: "bam", value: "BAM", label: "Bosnia and Herzegovina convertible mark" },
        { key: "bbd", value: "BBD", label: "Barbadian dollar" },
        { key: "bdt", value: "BDT", label: "Bangladeshi taka" },
        { key: "bgn", value: "BGN", label: "Bulgarian lev" },
        { key: "bhd", value: "BHD", label: "Bahraini dinar" },
        { key: "bif", value: "BIF", label: "Burundian franc" },
        { key: "bmd", value: "BMD", label: "Bermudian dollar" },
        { key: "bnd", value: "BND", label: "Brunei dollar" },
        { key: "bob", value: "BOB", label: "Bolivian boliviano" },
        { key: "brl", value: "BRL", label: "Brazilian real" },
        { key: "bsd", value: "BSD", label: "Bahamian dollar" },
        { key: "btn", value: "BTN", label: "Bhutanese ngultrum" },
        { key: "bwp", value: "BWP", label: "Botswana pula" },
        { key: "byn", value: "BYN", label: "Belarusian ruble" },
        { key: "bzd", value: "BZD", label: "Belize dollar" },
        { key: "cad", value: "CAD", label: "Canadian dollar" },
        { key: "cdf", value: "CDF", label: "Congolese franc" },
        { key: "chf", value: "CHF", label: "Swiss franc" },
        { key: "clp", value: "CLP", label: "Chilean peso" },
        { key: "cny", value: "CNY", label: "Chinese yuan" },
        { key: "cop", value: "COP", label: "Colombian peso" },
        { key: "crc", value: "CRC", label: "Costa Rican colón" },
        { key: "cuc", value: "CUC", label: "Cuban convertible peso" },
        { key: "cup", value: "CUP", label: "Cuban peso" },
        { key: "cve", value: "CVE", label: "Cape Verdean escudo" },
        { key: "czk", value: "CZK", label: "Czech koruna" },
        { key: "djf", value: "DJF", label: "Djiboutian franc" },
        { key: "dkk", value: "DKK", label: "Danish krone" },
        { key: "dop", value: "DOP", label: "Dominican peso" },
        { key: "dzd", value: "DZD", label: "Algerian dinar" },
        { key: "egp", value: "EGP", label: "Egyptian pound" },
        { key: "ern", value: "ERN", label: "Eritrean nakfa" },
        { key: "etb", value: "ETB", label: "Ethiopian birr" },
        { key: "fjd", value: "FJD", label: "Fijian dollar" },
        { key: "fkp", value: "FKP", label: "Falkland Islands pound" },
        { key: "gel", value: "GEL", label: "Georgian lari" },
        { key: "ggp", value: "GGP", label: "Guernsey pound" },
        { key: "ghs", value: "GHS", label: "Ghanaian cedi" },
        { key: "gip", value: "GIP", label: "Gibraltar pound" },
        { key: "gmd", value: "GMD", label: "Gambian dalasi" },
        { key: "gnf", value: "GNF", label: "Guinean franc" },
        { key: "gtq", value: "GTQ", label: "Guatemalan quetzal" },
        { key: "gyd", value: "GYD", label: "Guyanese dollar" },
        { key: "hkd", value: "HKD", label: "Hong Kong dollar" },
        { key: "hnl", value: "HNL", label: "Honduran lempira" },
        { key: "hrk", value: "HRK", label: "Croatian kuna" },
        { key: "htg", value: "HTG", label: "Haitian gourde" },
        { key: "huf", value: "HUF", label: "Hungarian forint" },
        { key: "idr", value: "IDR", label: "Indonesian rupiah" },
        { key: "ils", value: "ILS", label: "Israeli new shekel" },
        { key: "imp", value: "IMP", label: "Manx pound" },
        { key: "inr", value: "INR", label: "Indian rupee" },
        { key: "iqd", value: "IQD", label: "Iraqi dinar" },
        { key: "irr", value: "IRR", label: "Iranian rial" },
        { key: "isk", value: "ISK", label: "Icelandic króna" },
        { key: "jep", value: "JEP", label: "Jersey pound" },
        { key: "jmd", value: "JMD", label: "Jamaican dollar" },
        { key: "jod", value: "JOD", label: "Jordanian dinar" },
        { key: "kes", value: "KES", label: "Kenyan shilling" },
        { key: "kgs", value: "KGS", label: "Kyrgyzstani som" },
        { key: "khr", value: "KHR", label: "Cambodian riel" },
        { key: "kid", value: "KID", label: "Kiribati dollar" },
        { key: "kmf", value: "KMF", label: "Comorian franc" },
        { key: "kpw", value: "KPW", label: "North Korean won" },
        { key: "krw", value: "KRW", label: "South Korean won" },
        { key: "kwd", value: "KWD", label: "Kuwaiti dinar" },
        { key: "kyd", value: "KYD", label: "Cayman Islands dollar" },
        { key: "kzt", value: "KZT", label: "Kazakhstani tenge" },
        { key: "lak", value: "LAK", label: "Lao kip" },
        { key: "lbp", value: "LBP", label: "Lebanese pound" },
        { key: "lkr", value: "LKR", label: "Sri Lankan rupee" },
        { key: "lrd", value: "LRD", label: "Liberian dollar" },
        { key: "lsl", value: "LSL", label: "Lesotho loti" },
        { key: "lyd", value: "LYD", label: "Libyan dinar" },
        { key: "mad", value: "MAD", label: "Moroccan dirham" },
        { key: "mdl", value: "MDL", label: "Moldovan leu" },
        { key: "mga", value: "MGA", label: "Malagasy ariary" },
        { key: "mkd", value: "MKD", label: "Macedonian denar" },
        { key: "mmk", value: "MMK", label: "Burmese kyat" },
        { key: "mnt", value: "MNT", label: "Mongolian tögrög" },
        { key: "mop", value: "MOP", label: "Macanese pataca" },
        { key: "mru", value: "MRU", label: "Mauritanian ouguiya" },
        { key: "mur", value: "MUR", label: "Mauritian rupee" },
        { key: "mvr", value: "MVR", label: "Maldivian rufiyaa" },
        { key: "mwk", value: "MWK", label: "Malawian kwacha" },
        { key: "mxn", value: "MXN", label: "Mexican peso" },
        { key: "myr", value: "MYR", label: "Malaysian ringgit" },
        { key: "mzn", value: "MZN", label: "Mozambican metical" },
        { key: "nad", value: "NAD", label: "Namibian dollar" },
        { key: "ngn", value: "NGN", label: "Nigerian naira" },
        { key: "nio", value: "NIO", label: "Nicaraguan córdoba" },
        { key: "nok", value: "NOK", label: "Norwegian krone" },
        { key: "npr", value: "NPR", label: "Nepalese rupee" },
        { key: "nzd", value: "NZD", label: "New Zealand dollar" },
        { key: "omr", value: "OMR", label: "Omani rial" },
        { key: "pab", value: "PAB", label: "Panamanian balboa" },
        { key: "pen", value: "PEN", label: "Peruvian sol" },
        { key: "pgk", value: "PGK", label: "Papua New Guinean kina" },
        { key: "php", value: "PHP", label: "Philippine peso" },
        { key: "pkr", value: "PKR", label: "Pakistani rupee" },
        { key: "pln", value: "PLN", label: "Polish złoty" },
        { key: "prb", value: "PRB", label: "Transnistrian ruble" },
        { key: "pyg", value: "PYG", label: "Paraguayan guaraní" },
        { key: "qar", value: "QAR", label: "Qatari riyal" },
        { key: "ron", value: "RON", label: "Romanian leu" },
        { key: "rsd", value: "RSD", label: "Serbian dinar" },
        { key: "rub", value: "RUB", label: "Russian ruble" },
        { key: "rwf", value: "RWF", label: "Rwandan franc" },
        { key: "sar", value: "SAR", label: "Saudi riyal" },
        { key: "sek", value: "SEK", label: "Swedish krona" },
        { key: "sgd", value: "SGD", label: "Singapore dollar" },
        { key: "shp", value: "SHP", label: "Saint Helena pound" },
        { key: "sll", value: "SLL", label: "Sierra Leonean leone" },
        { key: "sls", value: "SLS", label: "Somaliland shilling" },
        { key: "sos", value: "SOS", label: "Somali shilling" },
        { key: "srd", value: "SRD", label: "Surinamese dollar" },
        { key: "ssp", value: "SSP", label: "South Sudanese pound" },
        { key: "stn", value: "STN", label: "São Tomé and Príncipe dobra" },
        { key: "syp", value: "SYP", label: "Syrian pound" },
        { key: "szl", value: "SZL", label: "Swazi lilangeni" },
        { key: "thb", value: "THB", label: "Thai baht" },
        { key: "tjs", value: "TJS", label: "Tajikistani somoni" },
        { key: "tmt", value: "TMT", label: "Turkmenistan manat" },
        { key: "tnd", value: "TND", label: "Tunisian dinar" },
        { key: "top", value: "TOP", label: "Tongan paʻanga" },
        { key: "try", value: "TRY", label: "Turkish lira" },
        { key: "ttd", value: "TTD", label: "Trinidad and Tobago dollar" },
        { key: "tvd", value: "TVD", label: "Tuvaluan dollar" },
        { key: "twd", value: "TWD", label: "New Taiwan dollar" },
        { key: "tzs", value: "TZS", label: "Tanzanian shilling" },
        { key: "uah", value: "UAH", label: "Ukrainian hryvnia" },
        { key: "ugx", value: "UGX", label: "Ugandan shilling" },
        { key: "uyu", value: "UYU", label: "Uruguayan peso" },
        { key: "uzs", value: "UZS", label: "Uzbekistani soʻm" },
        { key: "ves", value: "VES", label: "Venezuelan bolívar soberano" },
        { key: "vnd", value: "VND", label: "Vietnamese đồng" },
        { key: "vuv", value: "VUV", label: "Vanuatu vatu" },
        { key: "wst", value: "WST", label: "Samoan tālā" },
        { key: "xaf", value: "XAF", label: "Central African CFA franc" },
        { key: "xcd", value: "XCD", label: "Eastern Caribbean dollar" },
        { key: "xof", value: "XOF", label: "West African CFA franc" },
        { key: "xpf", value: "XPF", label: "CFP franc" },
        { key: "zar", value: "ZAR", label: "South African rand" },
        { key: "zmw", value: "ZMW", label: "Zambian kwacha" },
        { key: "zwb", value: "ZWB", label: "Zimbabwean bonds" }
    ];

    static isLinked(
        linkObj: Record<string, any>,
        linkStatusKey: string
    ): boolean {
        return (linkObj[linkStatusKey]?.toLowerCase() === 'linked')
    }

    static getFilterMaxTagPlaceholder = (
        entityName: string,
        selectedIdsArr: string[],
        entityIdToObjMap?: Map<string, Record<string, any>>
    ) => {

        let value = '';

        switch (entityName) {
            case 'package':
            case 'center':
            case 'instructor':
            case 'participant':
            case 'account':
                if (selectedIdsArr) {
                    if (selectedIdsArr.length === 1) {
                        value = `${entityIdToObjMap
                            ? entityIdToObjMap.get(selectedIdsArr[0])?.name
                            : ''}`;
                    }
                    else {
                        value = `${selectedIdsArr.length} ${formatMsg('selected')}`;
                    }
                }
                break;

            case 'ageRange':
                if (selectedIdsArr) {
                    if (selectedIdsArr.length === 1) {
                        value = `${entityIdToObjMap
                            ? Helper.getAgeRangeFormat(entityIdToObjMap.get(selectedIdsArr[0]))
                            : ''}`;
                    }
                    else {
                        value = `${selectedIdsArr.length} ${formatMsg('selected')}`;
                    }
                }
                break;

            case 'status':
            case 'discountType':
            case 'staffRole':
                if (selectedIdsArr) {
                    if (selectedIdsArr.length === 1) {
                        value = `${formatMsg(selectedIdsArr[0])}`;
                    }
                    else {
                        value = `${selectedIdsArr.length} ${formatMsg('selected')}`;
                    }
                }
                break;

            case 'paymentFrequencies':
                if (selectedIdsArr) {
                    if (selectedIdsArr.length === 1) {
                        value = `${formatMsg('label.' + selectedIdsArr[0])}`;
                    }
                    else {
                        value = `${selectedIdsArr.length} ${formatMsg('selected')}`;
                    }
                }
                break;
        }

        return value;
    }

    static updateSelectedOptionInArr(toggleOptionsArr: ToggleOption[], key: string) {

        let newSelectedOptionObj: ToggleOption | null = null;

        const newToggleOptionsArr = toggleOptionsArr.map((toggleOptionObj: ToggleOption) => {
            const newToggleOptionObj: ToggleOption = {
                ...toggleOptionObj,
                selected: (toggleOptionObj.key === key) ? true : false
            }
            if (newToggleOptionObj.selected) {
                newSelectedOptionObj = newToggleOptionObj;
            }
            return newToggleOptionObj;
        });

        return { newSelectedOptionObj, newToggleOptionsArr };
    }

    static getEntityObjForSetFieldsWithChildConfigPopulated = (entityObj: Record<string, any>): Record<string, any> => {

        const editFormObj = { ...entityObj };

        if (entityObj.childConfig) {
            entityObj.childConfig.forEach((additionalFieldObj) => {
                editFormObj[additionalFieldObj.name] = (additionalFieldObj.value) ?
                    (additionalFieldObj.dataType === 'date' || additionalFieldObj.dataType === 'time') ?
                        dayjs.tz(additionalFieldObj.value)
                        :
                        additionalFieldObj.value
                    :
                    null;
            });
        }

        return editFormObj;
    }

    static getDropdownOptionsFormWrapper = (
        dropdownList: any[],
        allOptionLabel: string,
        allOptionValue: string
    ) => {

        return (dropdownList?.length > 1) ?
            [{ name: allOptionLabel, id: allOptionValue }, ...dropdownList]
            :
            (dropdownList?.length === 1) ?
                [...dropdownList]
                :
                []
    }

    static convertTZ(date, firebase) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: firebase.schoolConfig.timezone }));
    }

    static convertToDate(date, firebase) {
        return new TZDate(date.valueOf(), firebase.schoolConfig.timezone)
    }

    static filterOptionByChildren(input, option) {
        return option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase().trim()) >= 0
    }

    static modifyTimezone(val, firebase) {
        let systemTz = firebase.schoolConfig.timezone || dayjs.tz.guess();
        return val.tz(systemTz, true);
    }
}