import dayjs from 'dayjs';
import React from 'react'
import Helper from '../../util/helper';
import formatMsg from '../../util/formatMsg';

const invStatusColorMap = Helper.getInvStatusColorMap();

const InvoiceMobileCard = (props: any) => {

    const {
        invoiceObj,
        firebase
    } = props;

    return (
        <div className='invoice-list-card'>
            <div className='invoice-card-1st-row'>
                <div className='inv-basic-detail-container'>
                    <div className='inv-pkg-name'>
                        {invoiceObj.packageName}
                    </div>
                    <div className='inv-sub-details-container'>
                        <div className='inv-user-img-container'>
                            <img
                                src='/images/user.png'
                                className='inv-user-img'
                            />
                        </div>
                        <div className='inv-user-name'>
                            {invoiceObj.profileName}
                        </div>
                    </div>
                </div>
                <div className='inv-data'>
                    <div className='mobile-inv-raise-date'>
                        <div className='inv-calendar-img-container'>
                            <img
                                src='/images/miniCalendar.png'
                                className='inv-calendar-img'
                            />
                        </div>
                        {dayjs.tz(invoiceObj.invoiceRaiseDate).format("DD MMM YYYY")}
                    </div>
                    <div className='mobile-inv-total'>
                        {Helper.getCurrency(firebase)}
                        {" "}
                        {invoiceObj.total}
                    </div>
                    <div
                        className='mobile-inv-status'
                        style={{ color: invStatusColorMap[invoiceObj.invoiceStatus] }}
                    >
                        {formatMsg(invoiceObj.invoiceStatus)}
                    </div>
                </div>
            </div>
            <div className='mobile-inv-horizontal-divider' />
            <div className='invoice-card-2nd-row'>
                <div className='inv-2nd-row-1st-col'>
                    <div className='mobile-inv-due-date-container'>
                        <div className='mobile-inv-due-date-header'>
                            {formatMsg('dueDate')}
                        </div>
                        <div
                            style={{ color: invoiceObj.invoiceStatus !== 'paid' ? invStatusColorMap[invoiceObj.invoiceStatus] : 'black' }}
                        >
                            {dayjs.tz(invoiceObj.dueDate).format("DD MMM YYYY")}
                        </div>
                    </div>
                    <div className='mobile-inv-vertical-divider' />
                    <div className='mobile-inv-num-container' >
                        <div className='mobile-inv-due-date-header'>
                            {formatMsg('invoiceNum')}
                        </div>
                        <div>
                            {invoiceObj.invoiceNumber}
                        </div>
                    </div>
                </div>
                <div className='purple-download-container'>
                    <div className='inv-download-img-container'>
                        <img
                            src='/images/purpleDownload.png'
                            className='inv-download-img'
                        />
                    </div>
                    <div
                        onClick={() => (invoiceObj.pdfUrl) ? window.open(invoiceObj.pdfUrl, "_blank") : null}
                        className='inv-purple-download'
                    >
                        {formatMsg('download')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceMobileCard