import { Checkbox, InputNumber, Row } from 'antd';
import React, { useMemo } from 'react'
import formatMsg from '../../util/formatMsg';
import Helper from '../../util/helper';
import dayjs, { Dayjs } from 'dayjs';
import { DayPicker } from 'react-day-picker';

const BookingCalendar = ({
	selectSlotsLater = false, selectedPricingPack, maxFrequency = {},
	credits = 0, setCredits = null, selectedBatch, selectedMonths,
	currentMonth, setSelectedMonths, setSelectedDays, disabled,
	selectedWeeks, setSelectedWeeks, selectedDays, setCurrentMonth,
	selectedWeekDays, setSelectedWeekDays, setSelectedTimings,
	firebase, handleTimingClick, groupedTimings, selectedTimings
}) => {

	const start = (selectedBatch?.startDate ? dayjs.tz(selectedBatch?.startDate) : dayjs(Math.min(...selectedBatch?.intervals.map(d => dayjs.tz(d.date).valueOf())))).startOf("month")
	const end = (selectedBatch?.endDate ? dayjs.tz(selectedBatch?.endDate) : dayjs(Math.max(...selectedBatch?.intervals.map(d => dayjs.tz(d.date).valueOf())))).endOf("month")

	const allMonths: Dayjs[] = [];
	let current = start;
	while (current.isBefore(end) || current.isSame(end, "month")) {
		allMonths.push(current);
		current = current.add(1, "month");
	}

	const weeksInMonth = useMemo(() => {
		let weeks = [];
		let firstDayOfMonth = currentMonth.startOf("month");
		let lastDayOfMonth = currentMonth.endOf("month");
		let startOfWeek = firstDayOfMonth.startOf("isoWeek");
		while (startOfWeek.isBefore(lastDayOfMonth)) {
			let weekDates: Date[] = [];

			for (let i = 0; i < 7; i++) {
				const currentDate = startOfWeek.add(i, "day");
				if (currentDate.format("MMMM") === firstDayOfMonth.format("MMMM")) {
					weekDates.push(Helper.convertToDate(currentDate, firebase));
				}
			}

			weeks.push({
				label: `Week ${weeks.length + 1}+${currentMonth.startOf("month").valueOf()}`,
				dates: weekDates
			});
			startOfWeek = startOfWeek.add(1, "week");
		}
		return weeks;
	}, [currentMonth]);

	const toggleMonthSelection = (month: Dayjs) => {
		const isSelected = selectedMonths.some((m) => m.isSame(month, "month"));

		setSelectedMonths(
			isSelected
				? selectedMonths.filter((m) => !m.isSame(month, "month"))
				: [...selectedMonths, month.startOf("month")]
		);
		setSelectedWeeks(prev =>
			isSelected
				? prev.filter((m) => !dayjs.tz(Number(m.split("+")[1])).isSame(month, "month"))
				: prev
		);
		setSelectedDays((prev) => {
			if (isSelected)
				return prev.filter((d) => !dayjs.tz(d).isSame(month, "month"));
			else if (selectedPricingPack?.pricingPack.all) {
				const start = month.startOf("month");
				const end = month.endOf("month");
				const allDays = [];
				for (let date = start; date.isBefore(end) || date.isSame(end, "day"); date = date.add(1, "day")) {
					if (!disabled(date)) {
						allDays.push(Helper.convertToDate(date, firebase));
					}
				}
				return [...prev, ...allDays];
			}
			return prev;
		});
	};

	const toggleWeekSelection = (weekLabel: string, weekDates: Date[]) => {
		if (selectedPricingPack?.pricingPack?.frequency === "MONTH" &&
			selectedPricingPack?.pricingPack?.frequencyType === "WEEKS" &&
			selectedPricingPack?.pricingPack?.frequencyValue) {

			const [, timestampStr] = weekLabel.split("+");
			const monthKey = dayjs.tz(Number(timestampStr)).format("YYYY-MM");

			const monthWiseWeekCount = selectedWeeks.reduce((acc, label) => {
				const [, weekTimestampStr] = label.split("+");
				const weekMonth = dayjs.tz(Number(weekTimestampStr)).format("YYYY-MM");
				acc[weekMonth] = (acc[weekMonth] || 0) + 1;
				return acc;
			}, {} as Record<string, number>);
			if (
				!selectedWeeks.includes(weekLabel) &&
				monthWiseWeekCount[monthKey] >= selectedPricingPack?.pricingPack?.frequencyValue
			) {
				return;
			}
		}

		setSelectedDays((prev) => {
			const validWeekDates = weekDates.filter((date) => !disabled(date));

			if (validWeekDates.length) {
				setSelectedWeeks((prevWeeks) => {
					if (prevWeeks.includes(weekLabel)) {
						return prevWeeks.filter((w) => w !== weekLabel);
					} else {
						return [...prevWeeks, weekLabel];
					}
				});
			}

			const allDatesSelected = validWeekDates.every((date) =>
				prev.some((d) => dayjs.tz(d).isSame(date, "day"))
			);

			return allDatesSelected
				? prev.filter((d) => !validWeekDates.some((wd) => dayjs.tz(d).isSame(wd, "day")))
				: [...prev, ...validWeekDates];
		});
	};

	return (
		<div>
			{selectSlotsLater ?
				!selectedPricingPack?.pricingPack || selectedPricingPack?.pricingPack?.frequency === "ENTIRE_PACKAGE_DURATION" || selectedPricingPack?.pricingPack?.frequency === "CUSTOM" ?
					null : <div className='book-p-39 book-main-card'>
						<label className='global-required'>{formatMsg("enterNumberOf") + " " + formatMsg(Helper.getSubFrequency(selectedPricingPack?.pricingPack?.frequency))}</label><br />
						<InputNumber
							min={0}
							max={maxFrequency[selectedPricingPack?.pricingPack?.frequency] ?? undefined}
							value={credits}
							onChange={e => setCredits(e)} style={{ maxWidth: 300, width: "100%", marginTop: 5 }}
							placeholder={formatMsg("enterNumberOf") + " " + formatMsg(Helper.getSubFrequency(selectedPricingPack?.pricingPack?.frequency))}
						/>
					</div> :
				selectedPricingPack?.pricingPack?.frequency !== "ENTIRE_PACKAGE_DURATION" || Object.values(groupedTimings).some((timings: any) => timings.length > 1) ?
					<Row className='book-schedule-container book-p-39 book-main-card'>
						{selectedPricingPack?.pricingPack?.frequency !== "ENTIRE_PACKAGE_DURATION" ?
							<div className='book-calendar-container' style={{ flexGrow: Object.values(groupedTimings).some((timings: any) => timings.length > 1) ? 0 : 1 }}>
								<div className='book-package-name book-header-margin-bottom' style={{ textAlign: "center", marginBottom: 20 }}>{formatMsg('pickYourDates')}</div>
								<div className="b-slots-container">
									{selectedPricingPack?.pricingPack?.frequency === "MONTH" ?
										<div className='slots' style={{ paddingBottom: 0 }}>
											<div className="month-grid">
												<div style={{ marginBottom: 8, fontWeight: 600 }}>Select Months</div>
												<div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
													{allMonths.map((month) => {
														const isSelected = selectedMonths.some((m) => m.isSame(month, "month"));
														return (
															<div
																key={month.format("YYYY-MM")}
																// checked={isSelected}
																onClick={() => toggleMonthSelection(month)}
																style={{
																	padding: "5px 8px",
																	border: "0.5px solid var(--primary-color)",
																	borderRadius: "4px",
																	textAlign: "center",
																	cursor: "pointer",
																	background: isSelected ? "var(--primary-color)" : "#fff",
																	color: isSelected ? "#fff" : "var(--primary-color)",
																	fontWeight: 400,
																	fontSize: "var(--font-size-14)"
																}}
															>
																{month.format("MMM YYYY")}
															</div>
														);
													})}
												</div>
											</div>
										</div> : null}
									<div className="slots">
										{selectedPricingPack?.pricingPack?.frequency === "ENTIRE_PACKAGE_DURATION" ||
											selectedPricingPack?.pricingPack?.frequency === "DAILY" ||
											(selectedPricingPack?.pricingPack?.frequency === "MONTH" && (selectedPricingPack?.pricingPack?.all || selectedPricingPack?.pricingPack?.frequencyType !== "WEEKS")) ||
											selectedPricingPack?.pricingPack?.frequency === "CUSTOM" ?
											null :
											<div className="calendar-weeks">
												{weeksInMonth.map((week) => (
													<div
														key={week.label}
														className={`week-box ${selectedWeeks.includes(week.label) ? "selected" : ""}`}
														onClick={() => toggleWeekSelection(week.label, week.dates)}
													>
														<p style={{ whiteSpace: "nowrap" }}>{week.label.split("+")[0]}</p>
													</div>
												))}
											</div>
										}
										{(selectedPricingPack?.pricingPack?.frequency === "MONTH" && selectedPricingPack?.pricingPack?.all) ? null :
											<DayPicker
												timeZone={firebase.schoolConfig.timezone}
												mode={selectedPricingPack?.pricingPack?.frequency === "DAILY" || selectedPricingPack?.pricingPack?.frequencyType === "DAYS" || selectedPricingPack?.pricingPack?.frequency === "CUSTOM" ? "multiple" : undefined}
												onSelect={(days) => {
													let exceedsLimit = false;
													if (selectedPricingPack?.pricingPack?.frequency === "MONTH" && selectedPricingPack?.pricingPack?.frequencyType === "DAYS" && selectedPricingPack?.pricingPack?.frequencyValue) {
														const monthWiseCount = days.reduce((acc, day) => {
															const monthKey = dayjs.tz(day).format("YYYY-MM");
															acc[monthKey] = (acc[monthKey] || 0) + 1;
															return acc;
														}, {} as Record<string, number>);
														exceedsLimit = Object.values(monthWiseCount).some(count => count > selectedPricingPack?.pricingPack?.frequencyValue);
													}
													if (selectedPricingPack?.pricingPack?.frequency === "CUSTOM" && selectedPricingPack?.pricingPack?.noOfClasses)
														exceedsLimit = days.length > selectedPricingPack?.pricingPack?.noOfClasses
													if (!exceedsLimit) {
														setSelectedDays(days);
													}
												}}
												numberOfMonths={1}
												pagedNavigation
												showOutsideDays
												selected={selectedDays}
												month={Helper.convertToDate(currentMonth, firebase)}
												onMonthChange={(month) => setCurrentMonth(dayjs.tz(month))} // Updates the month when changed
												modifiers={{
													selected: selectedDays,
												}}
												weekStartsOn={1}
												disabled={disabled}
												startMonth={selectedBatch?.startDate ? Helper.convertToDate(dayjs.tz(selectedBatch?.startDate), firebase) : Helper.convertToDate(dayjs.tz(Math.min(...selectedBatch?.intervals.map(d => dayjs.tz(d.date).valueOf()))), firebase)}
												endMonth={selectedBatch?.endDate ? Helper.convertToDate(dayjs.tz(selectedBatch?.endDate), firebase) : Helper.convertToDate(dayjs.tz(Math.max(...selectedBatch?.intervals.map(d => dayjs.tz(d.date).valueOf()))), firebase)}
											/>
										}
									</div>
									{selectedPricingPack?.pricingPack?.frequency !== "ENTIRE_PACKAGE_DURATION" &&
										selectedPricingPack?.pricingPack?.frequency !== "DAILY" &&
										selectedPricingPack?.pricingPack?.frequency !== "MONTH" &&
										selectedPricingPack?.pricingPack?.frequency !== "CUSTOM" &&
										(selectedPricingPack?.pricingPack?.frequency === "WEEK" && selectedPricingPack?.pricingPack?.frequencyValue < 7) ?
										<div className='day-picker-div'>
											<div className='global-flex slots-weekdays'>
												{["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].filter(w =>
													selectedBatch.type === "recurring" ? selectedBatch.intervals.some(interval => interval.day === w) : true
												).map(w => (
													<div
														key={w}
														className={`book-weekdays ${selectedWeekDays.includes(w) ? "selected" : ""}`}
														onClick={() =>
															setSelectedWeekDays(prev => {
																if (prev.includes(w))
																	return prev.filter(week => week !== w)
																else {
																	if (!selectedPricingPack?.pricingPack?.all && selectedPricingPack?.pricingPack?.frequencyValue < 7 && selectedWeekDays?.length === selectedPricingPack?.pricingPack?.frequencyValue) return [...prev]
																	return [...prev, w]
																}
															})
														}
													>
														{w.toLowerCase().slice(0, 3)}
													</div>
												))}
											</div>
										</div>
										: null}
									{selectedPricingPack?.pricingPack?.frequency !== "ENTIRE_PACKAGE_DURATION" && (selectedDays.length || selectedWeekDays.length || selectedMonths.length || selectedWeeks.length) ?
										<div
											className='clear-dates'
											onClick={() => {
												setSelectedDays([])
												setSelectedWeekDays([])
												setSelectedMonths([])
												setSelectedWeeks([])
											}}
										>
											{formatMsg("clearDates")}
										</div> :
										null
									}
								</div>
								{/* {selectedDays?.length ?
									<div style={{ whiteSpace: "pre-wrap", maxWidth: 430, width: "100%", paddingLeft: 20 }}>
										<br />
										<div style={{ fontWeight: 600 }}>Selected Dates :&nbsp;</div>
										{Helper.formatDateRanges(selectedDays, firebase)}
									</div> : null
								} */}
							</div>
							: null
						}
						{Object.values(groupedTimings).some((timings: any) => timings.length > 1) ?
							<div className='book-timing-container'>
								<div className='book-package-name book-header-margin-bottom' style={{ textAlign: "center", marginBottom: 20 }}>{formatMsg('timings')}</div>
								<div style={{ flexGrow: 1, justifyContent: "center", alignItems: "flex-start", width: "-webkit-fill-available" }} className='global-flex'>
									<div className="timing-container">
										{Object.entries(groupedTimings)?.map(([dayOrDate, timings]: any) => {
											return timings?.length === 1 || (dayOrDate != "Common slots" && selectedBatch?.type != "recurring" && selectedDays?.findIndex(date => Helper.formatDate(date, firebase) === dayOrDate) == -1) ? null : (
												<div key={dayOrDate} className="day-group">
													<div>{dayOrDate === "Common slots" ? null : dayOrDate}</div>
													<div className="global-flex slots-timings">
														{timings?.map((timing) => (
															<div
																key={timing.key}
																className={`timing-slot ${selectedTimings[dayOrDate] === timing.key || selectedTimings[dayOrDate]?.includes(timing.key) ? "selected" : ""}`}
																onClick={() => handleTimingClick(dayOrDate, timing.key)}
															>
																<p>{timing.label}</p>
															</div>
														))}
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
							: null}
					</Row> :
					null
			}
		</div>
	)
}

export default BookingCalendar