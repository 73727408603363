'use client'

import dayjs from 'dayjs'
import { useRouter } from 'next/navigation'
import React from 'react'
import { setItem } from '../../../../libs/encryptedStorage'
import styles from './style.module.css'


const MobileCalendarSlotViewCard = (props: any) => {

    const router = useRouter();
    const { slotsObj, setSelectedSchedule, setShowMobileSingleScheduleView } = props;

    function openSingleScheduleView() {
        setSelectedSchedule(slotsObj);
        setItem('selectedSchedule', slotsObj);
        setShowMobileSingleScheduleView(true);
        router.push('#schedule-detail-view-open');
    }

    return (
        <div
            className={styles.cardContainer}
            style={{
                borderColor: slotsObj?.color?.darkColor || '',
                color: slotsObj?.color?.darkColor || ''
            }}
            onClick={openSingleScheduleView}
        >
            <div
                className={styles.basicData}
            >
                <div
                    className={styles.packageName}
                >
                    {slotsObj.packageName || ''}
                </div>
                <div
                    className={styles.batchName}
                >
                    {slotsObj.batchName || ''}
                </div>
            </div>
            <div
                className={styles.timeRange}
            >
                {`${dayjs.tz(slotsObj.startTime).format("hh:mm A")} - ${dayjs.tz(slotsObj.endTime).format("hh:mm A")}`}
            </div>
        </div>
    )
}

export default MobileCalendarSlotViewCard