import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/accountSuperAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import bugsnagClient from "@bugsnag/js";
import { getItem } from "../../libs/encryptedStorage";
import Helper from "../../util/helper";

function* getGuestSettings(request) {
  try {
    let { loggedInData } = request.payload;
    loggedInData = getLoggedInData(loggedInData);
    let endpoint = "bookingoApi/settings/guest";
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let guestSettings = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { guestSettings, isLoading: false }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { guestSettings: {} } });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { guestSettings: {} } });
    console.log("failed to get settings", err);
    bugsnagClient.notify(err);
  }
}

function* getSellingFast(request) {
  try {
    let { loggedInData } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = "bookingoApi/guest/packages/sellingFast?guestStudentId=" + (loggedInData.user?.student?.id || null)
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, true, false, true);
    if (response.status == 200) {
      let sellingFast = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { sellingFast, }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { sellingFast: {} } });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { sellingFast: {} } });
    console.log("failed to get selling fast", err);
    bugsnagClient.notify(err);
  }
}

function* getPackageById(request) {
  try {
    let { firebase, packageId } = request.payload;
    firebase = getLoggedInData(firebase);
    let endpoint
    if (firebase?.user?.id) {
      // means user exist
      endpoint = "bookingoApi/package/" + packageId
    }
    else {
      endpoint = "bookingoApi/guest/package/" + packageId + "?loggedOut=true";
    }
    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true, null, !firebase?.user?.id ? true : false);

    if (response.status == 200) {

      let packageObj = response.body.data.package;
      const { studentAdditionalFields } = response.body.data;

      if (response.body.data?.schoolConfig) {
        yield put({
          type: actions.SET_FIREBASE_OBJ_SCHOOL_CONFIG,
          payload: {
            schoolConfig: response.body.data?.schoolConfig
          }
        });
      }

      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          packageObj,
          packageLoading: false,
          studentAdditionalFields: studentAdditionalFields ? studentAdditionalFields : []
        }
      });
    }
    else {
      notification("error", formatMsg("packageToGetPackage"))
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { packageObj: {}, packageLoading: false } });
    }
  } catch (err) {
    notification("error", formatMsg("packageToGetPackage"))
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { packageObj: {}, packageLoading: false } });
    console.log("failed to get package", err);
    bugsnagClient.notify(err);
  }
}

function* getBookingOrMembershipById(request) {
  let { firebase, id, entityType } = request.payload;
  try {
    let endpoint = "bookingoApi/booking/" + id;

    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true);

    if (response.status == 200) {

      let bookingOrMembersipDetailViewObj = response.body.data;

      switch (entityType) {
        case 'booking':
          yield put({
            type: actions.PACKAGE_SLICE_OPERATION,
            payload: {
              bookingDetailViewObj: bookingOrMembersipDetailViewObj,
              packageLoading: false
            }
          });
          break;
        case 'membership':
          yield put({
            type: actions.PACKAGE_SLICE_OPERATION,
            payload: {
              membershipDetailViewObj: bookingOrMembersipDetailViewObj,
              packageLoading: false
            }
          });
          break;
      }
    }
    else {
      switch (entityType) {
        case 'booking':
          notification("error", formatMsg("error.fetchingBookingDetails"))
          yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { bookingDetailViewObj: {}, packageLoading: false } });
          break;
        case 'membership':
          notification("error", formatMsg("error.fetchingMembershipDetails"));
          yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { membershipDetailViewObj: {}, packageLoading: false } });
          break;
      }
    }
  }
  catch (err) {
    switch (entityType) {
      case 'booking':
        notification("error", formatMsg("error.fetchingBookingDetails"))
        yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { bookingDetailViewObj: {}, packageLoading: false } });
        break;
      case 'membership':
        notification("error", formatMsg("error.fetchingMembershipDetails"));
        yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { membershipDetailViewObj: {}, packageLoading: false } });
        break;
    }
    console.log("failed to get package", err);
    bugsnagClient.notify(err);
  }
}


function getLoggedInData(loggedInData) {
  // not logged in case
  if (!loggedInData || !loggedInData.user || !loggedInData.user.id) {
    let centerId = getItem("centerId") || Helper.getUrlParam("centerId");
    return { centerId: centerId, user: { name: "" } };
  }
  return loggedInData;
}

function* getPackageList(request) {
  try {
    let { loggedInData } = request.payload;
    loggedInData = getLoggedInData(loggedInData);
    let endpoint;
    if (loggedInData?.user?.id) {
      // means user exit
      endpoint = "bookingoApi/package/details";
    }
    else {
      endpoint = "bookingoApi/guest/packages/details?loggedOut=true";
    }
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true, null, !loggedInData?.user?.id ? true : false);
    if (response.status == 200) {
      let packageList = response.body.data?.packageList || [];
      if (response.body.data?.schoolConfig) {
        yield put({
          type: actions.SET_FIREBASE_OBJ_SCHOOL_CONFIG,
          payload: {
            schoolConfig: response.body.data?.schoolConfig
          }
        });
      }
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { packageList, isLoading: false, operationType: "PACKAGE_LIST" }
      });
    }
    else {
      notification("error", formatMsg("failedToGetPackageList"))
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    notification("error", formatMsg("failedToGetPackageList"))
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to get package list ", err);
    bugsnagClient.notify(err);
  }
}

function* getPackageClassPacks(request) {
  try {
    let { firebase, packageId } = request.payload;
    firebase = getLoggedInData(firebase)
    let endpoint = firebase?.user?.id ? `bookingoApi/package/${packageId}/classPack/` : `bookingoApi/guest/package/${packageId}/classPack/`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true, null);
    if (response.status == 200) {
      let classPacks = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { classPacks, classPackLoading: false, }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to get package list ", err);
    bugsnagClient.notify(err);
  }
}

function* getAgeRange(request) {
  try {
    let { loggedInData } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = loggedInData?.user?.id ? "bookingoApi/agerange" : "bookingoApi/guest/agerange";
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let ageRange = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { ageRange, ageRangeLoading: false, }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to get package list ", err);
    bugsnagClient.notify(err);
  }
}

function* getActivitiesByPackageId(request) {
  try {
    let { loggedInData, packageId } = request.payload;
    loggedInData = getLoggedInData(loggedInData);
    let endpoint = "bookingoApi/guest/package/activities/" + packageId;
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let activities = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { activities, activityLoading: false, operationType: "ACTIVITY_LIST" }
      });
    }
    else {
      notification("error", formatMsg("failedToGetPackageActivities"))
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { activities: [], activityLoading: false } });
    }
  } catch (err) {
    notification("error", formatMsg("failedToGetPackageActivities"))
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { activities: [], activityLoading: false } });
    console.log("failed to get package list ", err);
    bugsnagClient.notify(err);
  }
}

function* getUpcomingClassesByPackageId(request) {
  try {
    let { loggedInData, packageId } = request.payload;
    loggedInData = getLoggedInData(loggedInData);
    let endpoint = "bookingoApi/guest/package/upcomingClasses/" + packageId;
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let upcomingClasses = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { upcomingClasses, upcomingClassesLoading: false, operationType: "UPCOMING_CLASSES" }
      });
    }
    else {
      notification("error", formatMsg("failedToGetUpcomingClasses"))
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { upcomingClasses: [], upcomingClassesLoading: false, } });
    }
  } catch (err) {
    notification("error", formatMsg("failedToGetUpcomingClasses"))
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { upcomingClasses: [], upcomingClassesLoading: false, } });
    console.log("failed to get package list ", err);
    bugsnagClient.notify(err);
  }
}

function* getPackagePricingByPackageId(request) {
  try {
    let { loggedInData, packageId } = request.payload;
    loggedInData = getLoggedInData(loggedInData);

    let endpoint = "bookingoApi/guest/package/classPack/" + packageId;
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let packagePricing = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { packagePricing, pricingPackLoading: false, operationType: "PACKAGE_PRICING" }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { packagePricing: [], pricingPackLoading: false, } });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED, payload: { packagePricing: [], pricingPackLoading: false, } });
    console.log("failed to get pricing packs by package id  ", err);
    bugsnagClient.notify(err);
  }
}

function* updateWishlist(request) {
  try {
    const { loggedInData, packageId, addOrRemove, packageObj } = request.payload;
    let endpoint = "bookingoApi/guest/" + loggedInData.user.id + "/wishlist/" + packageId;
    let response = yield call(callApi, loggedInData, addOrRemove ? "post" : "delete", endpoint, {}
    );
    if (response.status == 200) {
      notification("success", formatMsg(addOrRemove ? "packageAddedInWishlist" : "packedRemovedFromWithlist"))
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          isLoading: false,
          operationType: "UPDATE_WISHLIST",
          packageObj: packageObj ? { ...packageObj, wishListed: addOrRemove } : null
        }
      });
    }
    else {
      notification("error", formatMsg("failedToUpdateWishlist"))
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    notification("error", formatMsg("failedToUpdateWishlist"))
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to update wishlist ", err);
    bugsnagClient.notify(err);
  }
}

function* requestApproval(request) {
  try {
    const { payload } = request;
    let { firebase, requestPayload, payBeforeSettings, teacherApprovalSettings } = payload;
    firebase = getLoggedInData(firebase);
    let endpoint = firebase?.user?.id ? "bookingoApi/booking" : "bookingoApi/guest/booking";
    let response = yield call(callApi, firebase, "post", endpoint, requestPayload, firebase?.user?.id ? false : true);
    if (response?.status == 200) {
      const notifMessage = teacherApprovalSettings ? formatMsg("packageRequestedSuccessfully") : formatMsg("bookingConfirmedSuccessfully");
      if (!payBeforeSettings) notification("success", notifMessage)
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,

        payload: {
          operationType: "REQUESTED_FOR_APPROVAL",
          redirectLink: response?.body?.data?.redirectLink && typeof response.body.data.redirectLink == "string" ? response?.body?.data?.redirectLink : undefined,
          isLoading: false,
          bookingDetails: response?.body?.data?.responseObj
        }
      });
    }
    else if (response?.status == 206) {
      notification("error", response.body.message);
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          isLoading: false,
        }
      });
    }
    else {
      // show error message
      yield put({
        type: actions.PACKAGE_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({
      type: actions.PACKAGE_SAGA_FAILED,
      payload: { isLoading: false, }
    });
  }
}

function* rescheduleBooking(request) {
  try {
    let { firebase, payload } = request.payload;
    let { startDate, endDate } = payload;
    firebase = getLoggedInData(firebase);
    let endpoint = "bookingoApi/booking/reschedule"
    let response = yield call(callApi, firebase, "post", endpoint, payload);
    if (response?.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          operationType: "BOOKING_RESCHEDULED",
          reschedulingDetails: { startDate, endDate },
          isLoading: false
        }
      });
    }
    else if (response?.status == 206) {
      notification("error", response.body.message);
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          isLoading: false
        }
      });
    }
    else {
      yield put({
        type: actions.PACKAGE_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    console.log("error in rescheduleBooking", err);
    yield put({
      type: actions.PACKAGE_SAGA_FAILED,
      payload: { isLoading: false, }
    });
  }
}

function* getClientSecretId(request) {
  try {
    const { payload } = request;
    let { loggedInData } = payload;
    let endpoint = "bookingoApi/stripe/createStripeClientSecret";
    let response = yield call(callApi, loggedInData, "post", endpoint, {});
    if (response?.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          operationType: "CLIENT_SECRET_ID_SUCCESS",
          clientSecretId: response.body.data.clientSecretId,
          isLoading: false,
        }
      });
    }
    else {
      yield put({
        type: actions.PACKAGE_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    console.log("error in getClientSecretId  ", err);
    yield put({
      type: actions.PACKAGE_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function* doPayment(request) {
  try {
    const { payload } = request;
    let { loggedInData } = payload;
    let endpoint = "bookingoApi/stripe/payment";
    let response = yield call(callApi, loggedInData, "post", endpoint, {});
    if (response?.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          operationType: "PAYMENT_DONE",
          isLoading: false,
        }
      });
    }
    else {
      // show error message
      yield put({
        type: actions.PACKAGE_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    console.log("error in dopayment  ", err);
    yield put({
      type: actions.PACKAGE_SAGA_FAILED,
      payload: { isLoading: false, }
    });
    bugsnagClient.notify(err);
  }
}

function* getAllPackageType(request) {
  try {
    let { loggedInData } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = loggedInData?.user?.id ? "bookingoApi/packageType" : "bookingoApi/guest/packageType";
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      let packagesTypes = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { packagesTypes, packageTypesLoading: false, }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to get getAllPackageType ", err);
    bugsnagClient.notify(err);
  }
}


function* fetchPackageSchedule(request) {
  try {

    let { loggedInData, id } = request.payload;
    loggedInData = getLoggedInData(loggedInData)

    let endpoint = `bookingoApi/package/${id}/schedules`;
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, loggedInData?.user?.id ? false : true);

    if (response.status == 200) {

      const packageSchedule = response.body.data;
      const currPackageSlotsStatus = Helper.doesAllSlotsOfThisPackageHaveSameTimeRange(packageSchedule);

      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          isLoading: false,
          packageSchedule,
          currPackageSlotsStatus
        }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to fetch package schedule", err);
  }
}

function* getAvailabilityMapForSlots(request) {
  try {
    let { loggedInData, reqObj } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = `bookingoApi/booking/availabilityMapForSlots`
    let response = yield call(callApi, loggedInData, "post", endpoint, reqObj, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { isLoading: false, operationType: "PACKAGE_AVAILABILITY_MAP", batchSlotMap: response.body.data }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PACKAGE_SAGA_FAILED
    });
    console.log("failed getAvailabilityMapForSlots", err);
  }
}

function* getAvailableBatchSlots(request) {
  try {
    let { loggedInData, reqObj } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = `bookingoApi/multicenter/centerBatchAvailabilityMap`
    let response = yield call(callApi, loggedInData, "post", endpoint, reqObj, loggedInData?.user?.id ? false : true);
    if (response.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { isLoading: false, batchSlotMap: response.body.data?.batchSlotMap }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PACKAGE_SAGA_FAILED
    });
    console.log("failed to availability map for slots", err);
  }
}

function* fetchCentersOfPackage(request) {
  try {
    let { firebase, packageId } = request.payload;
    firebase = getLoggedInData(firebase)
    let endpoint = firebase?.user?.id ? `bookingoApi/multicenter/centersListOfSinglePackage/${packageId}` : `bookingoApi/guest/multicenter/centersListOfSinglePackage/${packageId}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true, null);
    if (response.status == 200) {
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { isLoading: false, packageCenters: response.body?.data?.centersListOfSinglePackage || [] }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PACKAGE_SAGA_FAILED
    });
    console.log("failed to get centers of package", err);
  }
}

function* applyDiscount(request) {
  try {
    let { firebase, reqObj } = request.payload;
    firebase = getLoggedInData(firebase);

    const { method } = reqObj;

    let endpoint = `bookingoApi/guest/applyDiscount`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj, firebase?.user?.id ? false : true);
    if (response.status == 200) {
      let payload = {}; 
      if (method === 'CODE') {
        payload = { 
          operationType: "PROMO_DISCOUNT_APPLIED", 
          appliedPromoDiscount: response.body.data, 
          promoDiscountLoading: false 
        }
      }
      else if (method === 'NO_CODE') {
        payload = { 
          operationType: "AUTO_DISCOUNT_APPLIED", 
          appliedAutoDiscounts: response.body.data, 
          autoDiscountLoading: false 
        }
      }
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION, 
        payload 
      });
    }
    else {
      const message = response.body?.message || formatMsg("failedToApplyDiscount")
      let payload = {}; 
      if (method === 'CODE') {
        payload = { 
          operationType: "DISCOUNT_FAILED", 
          promoDiscountLoading: false, 
          discountMessage: message 
        }
      }
      else if (method === 'NO_CODE') {
        payload = { 
          operationType: undefined, 
          autoDiscountLoading: false 
        }
      }
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload 
      });
    }
  } catch (err) {
    yield put({
      type: actions.PACKAGE_SAGA_FAILED
    });
    console.log("failed to apply discount", err);
  }
}

function* getBatchesByPackageId(request) {
  try {
    let { loggedInData, packageId } = request.payload;
    loggedInData = getLoggedInData(loggedInData)
    let endpoint = "bookingoApi/guest/package/batches/" + packageId;
    let response = yield call(callApi, loggedInData, "get", endpoint, {}, true);
    if (response.status == 200) {
      let batches = response.body.data
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: { batches, batchLoading: false, }
      });
    }
    else {
      yield put({ type: actions.PACKAGE_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PACKAGE_SAGA_FAILED });
    console.log("failed to get package batches ", err);
    bugsnagClient.notify(err);
  }
}

function* getBookingDetailsByBookingPaymentMetadataId(request) {
  try {
    let { firebase, bookingPaymentMetadataId } = request.payload;
    firebase = getLoggedInData(firebase);
    let endpoint
    if (firebase?.user?.id) {
      endpoint = "bookingoApi/booking/" + bookingPaymentMetadataId + "/bookingDetails"
    }
    else {
      endpoint = "bookingoApi/booking/" + bookingPaymentMetadataId + "/bookingDetails?loggedOut=" + true;
    }
    let response = yield call(callApi, firebase, "get", endpoint, {}, !firebase?.user?.id, null, !firebase?.user?.id);

    if (response.status == 200) {

      let bookingDetails = response.body.data?.responseObj;
      yield put({
        type: actions.PACKAGE_SLICE_OPERATION,
        payload: {
          bookingDetails: bookingDetails,
          isLoading: false,
          operationType: "BOOKING_DETAILS_FETCHED"
        }
      });
    }
    else {
      yield put({
        type: actions.PACKAGE_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({
      type: actions.PACKAGE_SAGA_FAILED,
      payload: { isLoading: false, }
    });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.ACCOUNT_PACKAGE_GET_PACKAGE_BY_ID, getPackageById);
  yield takeLatest(actions.GET_BOOKING_OR_MEMBERSHIP_BY_ID, getBookingOrMembershipById);
  yield takeLatest(actions.GET_ACTIVITIES_BY_PACKAGE_ID, getActivitiesByPackageId);
  yield takeLatest(actions.GET_PACKAGE_LIST, getPackageList);
  yield takeLatest(actions.GET_PACKAGE_CLASS_PACKS, getPackageClassPacks);
  yield takeLatest(actions.ACCOUNT_PACKAGE_GET_AGE_RANGE, getAgeRange);
  yield takeLatest(actions.GET_UPCOMING_CLASSES_BY_PACKAGE_ID, getUpcomingClassesByPackageId);
  yield takeLatest(actions.GET_PACKAGE_PRICING_BY_PACKAGE_ID, getPackagePricingByPackageId);
  yield takeLatest(actions.ACCOUNT_PACKAGE_UPDATE_WISHLIST, updateWishlist);
  yield takeLatest(actions.REQUEST_PAYMENT_APPROVAL, requestApproval);
  yield takeLatest(actions.RESCHEDULE_BOOKING, rescheduleBooking);
  yield takeLatest(actions.GET_GUEST_SETTINGS, getGuestSettings);
  yield takeLatest(actions.GET_CLIENT_SECRED_ID, getClientSecretId)
  yield takeLatest(actions.DO_PAYMENT, doPayment)
  yield takeLatest(actions.ACCOUNT_PACKAGE_GET_SELLING_FAST, getSellingFast);
  yield takeLatest(actions.GET_ALL_PACKAGE_TYPE, getAllPackageType);
  yield takeLatest(actions.ACCOUNT_PACKAGE_FETCH_PACKAGE_SCHEDULE, fetchPackageSchedule);
  yield takeLatest(actions.PACKAGE_AVAILABILITY_MAP, getAvailabilityMapForSlots);
  yield takeLatest(actions.GET_PACKAGE_CENTERS, fetchCentersOfPackage);
  yield takeLatest(actions.ACCOUNT_PACKAGE_GET_AVAILABLE_BATCH_SLOTS, getAvailableBatchSlots);
  yield takeLatest(actions.ACCOUNT_PACKAGE_APPLY_DISCOUNT, applyDiscount);
  yield takeLatest(actions.ACCOUNT_PACKAGE_GET_BATCHES, getBatchesByPackageId);
  yield takeLatest(actions.GET_BOOKING_DETAILS_BY_BOOKING_PAYMENT_METADATA_ID, getBookingDetailsByBookingPaymentMetadataId);
}
