'use client'

import { Popover } from 'antd';
import styles from './style.module.css';
import React, { useState } from 'react';

interface FieldObj {
    key: string;
    heading: string;
    value: string;
    subText?: any;
    prefixImg?: string;
}

interface Props {
    fieldsList: FieldObj[];
    showThreeDots?: Boolean; 
    getThreeDotsPopContent?: any;
    showBorderBottom?: boolean;
}

const MobileAccountUserMetaDataCard = (props: Props) => {

    const {
        fieldsList, 
        getThreeDotsPopContent, 
        showThreeDots, 
        showBorderBottom
    } = props; 

    const [open, setOpen] = useState(false);

    return (
        <div 
            className={`${showBorderBottom ? styles.containerBorderBottom : ''} ${styles.accountUserMetaDataContainer}`} 
        > 
            {
                showThreeDots && getThreeDotsPopContent &&
                <div className={styles.threeDotsContainer}>
                    <Popover
                        content={getThreeDotsPopContent()}
                        open={open}
                        onOpenChange={(boolVal) => setOpen(boolVal)}
                        trigger="click"
                        placement='bottomRight'
                        id='mobile-responsive-popover' 
                    >
                        <div
                            className={styles.threeDots}
                            onClick={(e) => e.preventDefault()}
                        >
                            <img
                                style={{ height: 16, width: 16 }}
                                src='/images/Menu Vertical.png'
                                alt="Options"
                            />
                        </div>
                    </Popover>
                </div>
            }
            {
                (fieldsList || []).map((fieldObj: FieldObj) => {
                    return (
                        <div 
                            key={fieldObj.key}
                            className={styles.metaData}
                        >
                            <div className={styles.heading}>
                                {fieldObj.heading}
                            </div>
                            <div className={fieldObj.key === 'accountName' ? styles.imgValue : styles.value}>
                                {
                                    fieldObj.prefixImg &&
                                    <div className={styles.imgContainer}>
                                        <img
                                            src={fieldObj.prefixImg}
                                            className={styles.imgIcon}
                                        />
                                    </div>
                                }
                                <div className={`${fieldObj.key === 'accountName' ? styles.bold : ''} ${styles.valueText}`}>
                                    <div>{fieldObj.value}</div>
                                    {fieldObj.subText ? <div>{fieldObj.subText}</div> : null}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default MobileAccountUserMetaDataCard