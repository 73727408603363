import React from 'react'
import { ModalWrapper } from '../wrapperComponents/page'
import formatMsg from '../../util/formatMsg'

const TncModal = ({ termsVisible, showHideTermsAndConditions, termsAndConditions }) => {
    return (
        <ModalWrapper
            open={termsVisible}
            title={formatMsg("label.termsAndConditions")}
            onOk={showHideTermsAndConditions}
            onCancel={showHideTermsAndConditions}
            okText={formatMsg("close")}
            maskClosable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
        </ModalWrapper>
    )
}

export default TncModal