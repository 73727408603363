import React from 'react';
import "./style.css";

const ImageWrapper: React.FC<any> = (props) => {
    const { className, src, alt, onClick, theme, collapsed } = props
    const style = props.style || null

    let currentTheme = theme ? theme : "light";
    const imageStyle = currentTheme === "dark"
        ? { filter: "brightness(0) saturate(100%) invert(100%) sepia(100%) hue-rotate(300deg)" }
        : {};
    return (
        <img className={className ? className : "sideIconStyle"} src={src} alt={alt} style={{ ...style, ...imageStyle, marginRight: !collapsed ? 10 : 0 }} onClick={onClick ? onClick : null} />
    );
};

export default ImageWrapper;