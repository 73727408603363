"use client";
import "./style.css";
import { Row, Form, Select } from "antd";
import Helper from "../../util/helper";
import formatMsg from "../../util/formatMsg";
import dayjs from "dayjs";
import { ModalWrapper } from "../wrapperComponents/page";
import { ReactElement, useEffect, useState } from "react";
import { notification } from "../../util/notification";
import { createAccount, getAccountDetails, resetOperationType, linkAccount, delinkAccount, deleteLinkedAccount } from "../../redux/accounts/reducer";
import { useDispatch } from "react-redux";
import { createUser, delinkAccountFromUser, getUserDetails, linkAccountToUser } from "../../redux/users/reducer";
import { resetOperationType as userResetOperationType } from "../../redux/users/reducer";
import FormWrapper from "../formWrapper/page";
import AccountProfileLaptopView from "./AccountProfileLaptopView/page";
import AccountProfileMobileView from "./AccountProfileMobileView/page";
import colors from "../../libs/colorFactory";

const accountStatusOptions = ['ACTIVE', 'INACTIVE'];


const AccountProfile: React.FC<any> = (props) => {

    const { accountDetails, isLoading, firebase } = props;
    const { accountReducer, userReducer, settingReducer } = Helper.getSelectorState(["accountReducer", "userReducer", "settingReducer"]);
    const { operationType } = accountReducer;
    const { customFields } = settingReducer;
    const dispatch = useDispatch();
    const [addNewVisible, setAddnewVisible] = useState<any>(false);
    const [editableRecords, setEditableRecords] = useState<any>();
    const [phone, setPhone] = useState({ phoneNumber: undefined, countryCode: undefined });
    const [linkModalVisible, setLinkModalVisible] = useState(false);
    const [selectedLinkAccount, setSelectedLinkAccount] = useState<any>();
    const [formType, setFormType] = useState<any>();
    const [form] = Form.useForm();
    const { Option } = Select;
    const entityType = Helper.getUrlParam("entityType");

    function editBasicDetails(accountDetails) {
        setAddnewVisible(true);
        setFormType(entityType === "account" ? "accountBasicDetails" : "userBasicDetails");
        accountDetails && accountDetails.phoneNumber && setPhone({ ...phone, phoneNumber: accountDetails.phoneNumber });
        form.setFieldsValue(accountDetails);
    }

    function getPopContent(accountObj: Record<string, any>): ReactElement {

        const options: any[] = [];

        /*
        options.push({
            optionIconSrc: '/images/newDeleteIcon.png',
            iconClassName: 'delete-icon-style',
            iconContainerClassName: 'icon-container-style',
            optionName: 'delete',
            optionAction: () => {
                const id = Helper.getUrlParam('id');
                if (id) {
                    dispatch(deleteLinkedAccount({
                        firebase,
                        reqObj: {
                            accountId1: id,
                            accountId2: accountObj.id
                        }
                    }));
                }
            }
        });
        */

        let lowercasedLinkStatus = '';
        switch (entityType) {
            case 'account':
                lowercasedLinkStatus = accountObj?.linkStatus?.toLowerCase();
                break;
            case 'user':
                lowercasedLinkStatus = accountObj?.accountUserLinkStatusObj?.linkStatus?.toLowerCase();
                break;
        }

        if (lowercasedLinkStatus === 'linked') {
            options.push({
                optionIconSrc: '/images/unlink.png',
                iconClassName: 'unlink-icon-style',
                iconContainerClassName: 'icon-container-style',
                optionName: 'delink',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {
                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        if (entityType === 'user') {

                            confirmAlertObject.yes = formatMsg("confirm");
                            confirmAlertObject.no = formatMsg("cancel");

                            confirmAlertObject.title = formatMsg('delinkAccountFromUser');
                            confirmAlertObject.message = (
                                <div className="flex-wrap">
                                    {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delink') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('from') + ' '}<span className='font-weight-600'>{accountDetails.name}</span><span>?</span>
                                </div>
                            )
                                ;

                            confirmAlertObject.yesOnClick = () => {
                                dispatch(delinkAccountFromUser({
                                    firebase,
                                    reqObj: {
                                        userId: accountDetails.id,
                                        accountId: accountObj.id
                                    }
                                }));
                            };
                        }
                        else if (entityType === 'account') {
                            confirmAlertObject.yes = formatMsg("confirm");
                            confirmAlertObject.no = formatMsg("cancel");

                            confirmAlertObject.title = formatMsg('delinkAccounts');

                            confirmAlertObject.message = (
                                <div className="flex-wrap">
                                    {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delink') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('from') + ' '}<span className='font-weight-600'>{accountDetails.name}</span>{' ' + formatMsg('small.account')}
                                </div>
                            );


                            confirmAlertObject.yesOnClick = () => {
                                dispatch(delinkAccount({
                                    firebase,
                                    reqObj: {
                                        accountId1: id,
                                        accountId2: accountObj.id
                                    }
                                }));
                            };
                        }

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }
        else if (lowercasedLinkStatus === 'delinked') {
            options.push({
                optionIconSrc: '/images/link.png',
                iconClassName: 'link-icon-style',
                iconContainerClassName: 'icon-container-style',
                optionName: 'link',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {
                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        if (entityType === 'user') {
                            confirmAlertObject.yes = formatMsg("confirm");
                            confirmAlertObject.no = formatMsg("cancel");

                            confirmAlertObject.title = formatMsg('linkAccountWithUser');
                            confirmAlertObject.message = (
                                <div className="flex-wrapp">
                                    {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.link') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('small.with') + ' '}<span className='font-weight-600'>{accountDetails.name}</span><span>?</span>
                                </div>
                            );

                            confirmAlertObject.yesOnClick = () => {
                                dispatch(linkAccountToUser({
                                    firebase,
                                    reqObj: {
                                        userId: accountDetails.id,
                                        accountId: accountObj.id
                                    }
                                }));
                            };
                        }
                        else if (entityType === 'account') {
                            confirmAlertObject.yes = formatMsg("confirm");
                            confirmAlertObject.no = formatMsg("cancel");

                            confirmAlertObject.title = formatMsg('linkAccounts');
                            confirmAlertObject.message = (
                                <div className='flex-wrap'>
                                    {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.link') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('small.with') + ' '}<span className='font-weight-600'>{accountDetails.name}</span>{' ' + formatMsg('small.account')}
                                </div>
                            );

                            confirmAlertObject.yesOnClick = () => {
                                dispatch(linkAccount({
                                    firebase,
                                    reqObj: {
                                        accountId1: id,
                                        accountId2: accountObj.id
                                    }
                                }));
                            };
                        }

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }

        return (
            <div>
                {
                    options.map((optionObj, index) => {
                        return (
                            <div
                                onClick={optionObj.optionAction}
                                className={`options-container ${(index !== (options.length - 1)) ? 'border-bottom-divider' : ''}`}
                            >
                                <div
                                    className={optionObj.iconContainerClassName}
                                >
                                    <img
                                        className={optionObj.iconClassName}
                                        src={optionObj.optionIconSrc}
                                    />
                                </div>
                                <div className='custom-regular-font'>
                                    {formatMsg(optionObj.optionName)}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    function buildChildConfig(values: Record<string, any>) {

        const childConfig = settingReducer.customFields ?
            settingReducer.customFields.map((additionalFieldObj) => {

                const tempAdditionalFieldObj = { ...additionalFieldObj };

                tempAdditionalFieldObj.value = values[additionalFieldObj.name] ?
                    Helper.getAdditionalFieldValue(additionalFieldObj.dataType, values[additionalFieldObj.name])
                    :
                    null;

                delete values[additionalFieldObj.name];

                return tempAdditionalFieldObj;
            })
            :
            null;

        return childConfig;
    }

    function getBasicDetailsObj(type: string): Record<string, any> {

        let newObj = {};

        switch (type) {
            case 'account':
                newObj = {
                    email: editableRecords.email ? editableRecords.email : null,
                    phoneNumber: editableRecords.phoneNumber ? editableRecords.phoneNumber : null,
                    profileImageUrl: editableRecords.profileImageUrl ? editableRecords.profileImageUrl : null,
                    name: editableRecords.name ? editableRecords.name : null
                }
                break;
            case 'user':
                newObj = {
                    profileImageUrl: editableRecords.profileImageUrl ? editableRecords.profileImageUrl : null,
                    name: editableRecords.name ? editableRecords.name : null,
                    gender: editableRecords.gender ? editableRecords.gender : null
                };
                break;
        }

        return newObj;
    }

    function getEntityType(formType: string): string {

        let entityType = '';

        switch (formType) {
            case 'accountAdditionalFields':
            case 'accountBasicDetails':
                entityType = 'account';
                break;
            case 'userAdditionalFields':
            case 'userBasicDetails':
                entityType = 'user';
                break;
        }

        return entityType;
    }


    function handleOk() {

        form.validateFields()
            .then((values) => {
                values.status = editableRecords.status || "ACTIVE";
                values.id = editableRecords && editableRecords.id;
                if (!values.profileImageUrl && editableRecords) {
                    values.profileImageUrl = editableRecords.profileImageUrl
                }

                const additionalFieldsFormType = ['accountAdditionalFields', 'userAdditionalFields'];

                if (editableRecords && additionalFieldsFormType.includes(formType)) {
                    values.childConfig = buildChildConfig(values);
                    let entityType = getEntityType(formType);
                    const newObj = getBasicDetailsObj(entityType);
                    values = { ...newObj, ...values };
                }

                const basicFieldsFormType = ['accountBasicDetails', 'userBasicDetails'];

                if (editableRecords.childConfig && basicFieldsFormType.includes(formType)) {
                    values = { ...values, childConfig: editableRecords.childConfig }
                }

                if (Helper.getUrlParam("entityType") === "account") {
                    if (formType === 'accountBasicDetails') {
                        const { defaultFieldsNameToObjMap } = settingReducer;

                        if (defaultFieldsNameToObjMap) {
                            if (defaultFieldsNameToObjMap.get('Phone Number')?.mandatory && !phone.phoneNumber) {
                                notification("error", formatMsg("error.phone"));
                                return;
                            }
                            else if (defaultFieldsNameToObjMap.get('Email')?.mandatory && !form.getFieldValue("email")) {
                                notification("error", formatMsg("error.email"));
                                return;
                            }
                        }
                        else {
                            notification("error", formatMsg("error.oopsSomthingWentWrong"));
                            return;
                        }
                    }
                    values.phoneNumber = phone.phoneNumber;
                    dispatch(createAccount({ reqBody: values, firebase: firebase }));
                }
                else {
                    let acountId = Helper.getUrlParam("id");
                    values.accountId = acountId;
                    dispatch(createUser({ reqBody: values, firebase: firebase }));
                }
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }

    useEffect(() => {
        if (operationType && (operationType === "ACCOUNT_CREATED")) {
            handleCancel();
            const id = Helper.getUrlParam("id");
            id && dispatch(getAccountDetails({ firebase, id }));
            dispatch(resetOperationType(null));
        }
        if (operationType && (operationType === "ACCOUNT_UPDATED")) {
            handleCancel();
            const id = Helper.getUrlParam("id");
            id && dispatch(getAccountDetails({ firebase, id }));
            dispatch(resetOperationType(null));
        }
        if (operationType && (operationType === "ACCOUNTS_LINKED" || operationType === "LINKED_ACCOUNT_DELETED" || operationType === "ACCOUNTS_DELINKED")) {
            setLinkModalVisible(false);
            setSelectedLinkAccount(undefined);
            const id = Helper.getUrlParam("id");
            id && dispatch(getAccountDetails({ firebase, id }));
            dispatch(resetOperationType(null));
        }
        if (userReducer.operationType && (userReducer.operationType === "USER_CREATED" || userReducer.operationType === "USER_UPDATED" || userReducer.operationType === 'DELINKED_ACCOUNT_AND_USER' || userReducer.operationType === 'LINKED_ACCOUNT_TO_USER')) {
            handleCancel();
            const id = Helper.getUrlParam("id");
            const entityType = Helper.getUrlParam("entityType");
            entityType === "user" && dispatch(getUserDetails({ firebase, id }));
            dispatch(userResetOperationType(null));
        }

    }, [operationType, userReducer.operationType])

    useEffect(() => {
        accountDetails && setEditableRecords({ ...accountDetails });
        accountDetails && accountDetails.phoneNumber && setPhone({ ...phone, phoneNumber: accountDetails.phoneNumber });
    }, [accountDetails])


    function handleCancel() {
        form.resetFields();
        setAddnewVisible(undefined);
        setFormType(undefined);
        setPhone({ phoneNumber: undefined, countryCode: undefined });
    }

    const AccountFields = [
        [{
            label: formatMsg("addProfilePhoto"),
            type: "media",
            name: "profileImageUrl",
            placeHolder: formatMsg("addProfilePhoto"),
            warningMessage: "Please select profile photo!",
            inputType: "picture",
            values: form.getFieldValue("profileImageUrl")
        }],
        [{
            label: formatMsg("name"),
            type: "input",
            name: "name",
            placeHolder: formatMsg("name"),
            required: true,
            warningMessage: "Please input name!",
            inputType: "string",
        }],
        [{
            label: formatMsg("phoneNumber"),
            type: "phoneInput",
            name: "phoneNumber",
            placeHolder: formatMsg("phoneNumber"),
            warningMessage: "Please input phone number!",
            required: settingReducer && settingReducer.defaultFieldsNameToObjMap ? settingReducer.defaultFieldsNameToObjMap.get('Phone Number')?.mandatory : true,
            inputType: "phoneInput",
            onPhoneChange: (value, data) => {
                setPhone({
                    phoneNumber: value ? "+" + value : null,
                    countryCode: "+" + data.dialCode,
                })
            },
            phoneValue: phone.phoneNumber ?
                phone.phoneNumber.toString().slice(1) :
                undefined
        }],
        [{
            label: formatMsg("placeholder.email"),
            type: "input",
            name: "email",
            placeHolder: formatMsg("placeholder.email"),
            required: settingReducer && settingReducer.defaultFieldsNameToObjMap ? settingReducer.defaultFieldsNameToObjMap.get('Email')?.mandatory : true,
            warningMessage: "Please input email!",
            inputType: "email",
        }],
    ]

    const UserFields = [
        [{
            label: formatMsg("addProfilePhoto"),
            type: "media",
            name: "profileImageUrl",
            placeHolder: formatMsg("addProfilePhoto"),
            warningMessage: "Please select profile photo!",
            inputType: "picture",
            values: form.getFieldValue("profileImageUrl")
        }],
        [{
            label: formatMsg("name"),
            type: "input",
            name: "name",
            placeHolder: formatMsg("name"),
            required: true,
            warningMessage: "Please input name!",
            inputType: "string",
        }]
    ]

    const handlelinkAccount = () => {
        if (!selectedLinkAccount) {
            notification("error", formatMsg("pleaseSelectAccount"))
            return;
        }
        let reqObj = {
            accountId1: Helper.getUrlParam("id"),
            accountId2: selectedLinkAccount
        }
        dispatch(linkAccount({ firebase, reqObj }));
    }

    function getAdditionalFieldDetails() {
        let fieldsArr = [];
        customFields?.map((item) => {
            let fieldObj = accountDetails.childConfig?.find((i) => i.id === item.id);
            if (item.dataType === "date") {
                fieldsArr.push({
                    key: item.name,
                    value: fieldObj?.value ? dayjs.tz(Number(fieldObj?.value)).format("DD MMM,YYYY") : null
                })
            } else {
                fieldsArr.push({
                    key: item.name,
                    value: fieldObj?.value
                })
            }
        })
        return fieldsArr;
    }

    function handleAddFieldForm(formType) {
        setAddnewVisible(true);
        setFormType(formType);
        let newObj = {};
        customFields?.map((item) => {
            let fieldObj = accountDetails.childConfig?.find((i) => i.id === item.id);
            if (item.dataType === 'date') {
                newObj[item.name] = fieldObj?.value ? dayjs.tz(Number(fieldObj.value)) : null;
            } else {
                newObj[item.name] = fieldObj?.value;
            }
        })
        form.setFieldsValue({ ...newObj });
    }

    function getFormHeader() {
        switch (formType) {
            case "accountBasicDetails":
                return "accountDetails";
            case "userBasicDetails":
                return "userDetails";
            case "accountAdditionalFields":
                return "accountAdditionalDetails";
            case "userAdditionalFields":
                return "userAdditionalDetails";
            default:
                return "accountDetails";
        }
    }

    function getFormFields() {
        switch (formType) {
            case "accountBasicDetails":
                return AccountFields;
            case "userBasicDetails":
                return UserFields;
            case "accountAdditionalFields":
            case "userAdditionalFields":
                return [...Helper.getAdditionalFormFields(customFields)];
        }
    }

    function getStatusShowAndChangeDropdown(accountDetails) {

        if (Helper.getLoggedInUserType(firebase) === 'ACCOUNT') {
            return (
                <div className='account-status-container'>
                    {formatMsg(accountDetails.status)}
                </div>
            );
        }
        else if (Helper.getLoggedInUserType(firebase) === 'TEACHER') {
            return (
                <Select
                    className='select-green'
                    value={accountDetails.status}
                    onSelect={(val) => {
                        if (accountDetails.status.toLowerCase() !== val.toLowerCase()) {
                            if (entityType === 'account') {
                                const updateAccountPayload = { ...accountDetails };
                                delete updateAccountPayload.linkedAccounts;
                                delete updateAccountPayload.users;

                                const yesOnClick = () => {
                                    dispatch(createAccount({ reqBody: { ...updateAccountPayload, status: val }, firebase: firebase }));
                                }

                                Helper.changeAccountStatusConfirmAlert(firebase, val, updateAccountPayload, yesOnClick);
                            }
                            else if (entityType === 'user') {
                                const updateUserPayload = { ...accountDetails };
                                delete updateUserPayload.accounts;

                                const yesOnClick = () => {
                                    dispatch(createUser({ reqBody: { ...updateUserPayload, status: val }, firebase }));
                                }

                                Helper.changeUserStatusConfirmAlert(val, updateUserPayload, yesOnClick);
                            }
                        }
                    }}
                >
                    {
                        accountStatusOptions.map((statusName) => {
                            return (
                                <Select.Option
                                    key={statusName}
                                    value={statusName}
                                >
                                    {formatMsg(statusName)}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            );
        }
    }

    function getAccountName(record) {
        return record.name;
    }

    function getAccountEmail(record) {
        return record.email;
    }

    function getAccountPhoneNumber(record) {
        return record.phoneNumber;
    }

    function getLinkStatus(record) {
        return (
            entityType === 'account' ?
                record.linkStatus ? formatMsg(record.linkStatus) : ''
                :
                entityType === 'user'
                    ? record?.accountUserLinkStatusObj?.linkStatus && formatMsg(record.accountUserLinkStatusObj.linkStatus)
                    : null
        )
    }

    function isPrimaryAccount(record) {
        return (
            <div 
                className="global-subheader-regular" 
                style={{ color: colors.color_purple, fontSize: 'var(--font-size-14)' }}
            >
                {record?.accountUserLinkStatusObj?.accountType && formatMsg(record.accountUserLinkStatusObj.accountType)}
            </div>
        )
    }

    function showPopoverActions(record) {
        return (
            (entityType === 'account')
            ||
            (entityType === 'user' && record?.accountUserLinkStatusObj.accountType?.toLowerCase() !== 'primary_account')
        );
    }

    const dataSource = accountDetails?.linkedAccounts || accountDetails?.accounts;

    return (
        <>
            <div className='laptop-view'>
                <AccountProfileLaptopView
                    firebase={firebase}
                    entityType={entityType}
                    accountDetails={accountDetails}
                    customFields={customFields}
                    handleAddFieldForm={handleAddFieldForm}
                    editBasicDetails={editBasicDetails}
                    setLinkModalVisible={setLinkModalVisible}
                    getStatusShowAndChangeDropdown={getStatusShowAndChangeDropdown}
                    getAdditionalFieldDetails={getAdditionalFieldDetails}
                    getAccountName={getAccountName}
                    getAccountEmail={getAccountEmail}
                    isPrimaryAccount={isPrimaryAccount}
                    getAccountPhoneNumber={getAccountPhoneNumber}
                    getLinkStatus={getLinkStatus}
                    showPopoverActions={showPopoverActions}
                    getPopContent={getPopContent}
                    dataSource={dataSource}
                />
            </div>

            <div className='mobile-view'>
                <AccountProfileMobileView
                    firebase={firebase}
                    entityType={entityType}
                    accountDetails={accountDetails}
                    customFields={customFields}
                    handleAddFieldForm={handleAddFieldForm}
                    editBasicDetails={editBasicDetails}
                    setLinkModalVisible={setLinkModalVisible}
                    getStatusShowAndChangeDropdown={getStatusShowAndChangeDropdown}
                    getAdditionalFieldDetails={getAdditionalFieldDetails}
                    getAccountName={getAccountName}
                    getAccountEmail={getAccountEmail}
                    isPrimaryAccount={isPrimaryAccount}
                    getAccountPhoneNumber={getAccountPhoneNumber}
                    getLinkStatus={getLinkStatus}
                    showPopoverActions={showPopoverActions}
                    getPopContent={getPopContent}
                    dataSource={dataSource}
                />
            </div>

            <ModalWrapper
                open={addNewVisible}
                title={formatMsg(getFormHeader())}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={formatMsg("submit")}
                maskClosable={false}
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                >
                    <FormWrapper
                        form={form}
                        formFields={getFormFields()}
                        editableRecords={editableRecords}
                        setEditableRecords={setEditableRecords}
                    />
                </Form>
            </ModalWrapper>

            {
                entityType && entityType !== "user" &&

                <ModalWrapper
                    open={linkModalVisible}
                    title={formatMsg("linkAccount")}
                    maskClosable={false}
                    confirmLoading={isLoading}
                    onCancel={() => {
                        setSelectedLinkAccount(undefined);
                        setLinkModalVisible(false)
                    }}
                    onOk={() => {
                        handlelinkAccount()
                    }}
                >
                    <Row>
                        <div className="account-margin-bottom">{formatMsg("chooseAccount")}</div>
                        <Select
                            value={selectedLinkAccount}
                            style={{ width: "100%" }}
                            placeholder={formatMsg("chooseAccount")}
                            allowClear
                            onSelect={setSelectedLinkAccount}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => Helper.filterOptionByChildren(input, option)}
                        >
                            {
                                accountDetails && Helper.getAccountList()
                                    ?.filter((account) => {
                                        return (
                                            !accountDetails?.linkedAccounts?.some((item) => item.id === account.id)
                                            &&
                                            account.id !== Helper.getUrlParam("id")
                                        )
                                    })
                                    .map((account) => (
                                        <Option value={account.id}>
                                            {account.name}
                                        </Option>
                                    ))
                            }
                        </Select>
                    </Row>
                    <Row className="account-link-confirm">
                        <div>{formatMsg("confirm.linkAccount")}</div>
                    </Row>
                    <Row className="account-link-ul">
                        <ul>
                            <li>{formatMsg("linkAccount.li1")}</li>
                            <li>{formatMsg("linkAccount.li2")}</li>
                        </ul>
                    </Row>
                </ModalWrapper>
            }
        </>
    )
}
export default AccountProfile