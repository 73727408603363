"use client"
import { Drawer, Spin } from 'antd'
import React from 'react'
import "./style.css"
import dayjs from '../../libs/day';
import formatMsg from "../../util/formatMsg";
import Helper from '../../util/helper';
import LinkWrapper from '../../components/linkWrapper/page';
import colors from '../../libs/colorFactory';
import { listingEndPointUrl } from '../../libs/fbConfig';

const ViewScheduleDrawer = (props) => {
  const replaceSpacesWithEncoded = (value: string): string => {
    return value.replace(/ /g, '%20');
  };

  return (
    <Drawer
      className='laptop-view-single-schedule-drawer'
      open={props.selectedSchedule ? true : false}
      title={
        <div className='displayFlexSpaceBetween'>
          {Helper.isAccount(props.firebase) ?
            <LinkWrapper href={`${listingEndPointUrl}/dashboard/packageDetailView?id=${props.selectedSchedule?.packageId}&centerId=${replaceSpacesWithEncoded(props.firebase.sbDbName)}`} target="_blank">
              <div className='displayFlex'>
                <p className='global-title-subheader'>{props.selectedSchedule?.packageName}</p>
                <img src='/images/redirect_img.png' style={{ height: 12, width: 12, marginLeft: 8 }} />
              </div>
            </LinkWrapper>
            : <LinkWrapper href={"/dashboard/package/packageDetailedView?id=" + props.selectedSchedule?.packageId} target="_blank">
              <div className='displayFlex'>
                <p className='global-title-subheader'>{props.selectedSchedule?.packageName}</p>
                <img src='/images/redirect_img.png' style={{ height: 12, width: 12, marginLeft: 8 }} />
              </div>
            </LinkWrapper>
          }
          <div className='displayFlex' style={{ cursor: "pointer" }} onClick={props.drawerOnClose}>
            <img src='/images/black-cross.png' style={{ height: 22, width: 22 }} />
          </div>
        </div>
      }
      onClose={props.drawerOnClose}
      width={window?.innerWidth > 700 ? 650 : "80%"}
      closable={false}
    >
      <Spin spinning={props.isLoading}>
        <div className='drawer-activity-name'>{props.selectedSchedule?.activity?.name}</div>
        <div className='displayFlex mt-16'>
          <img src='/images/schedule.png' style={{ height: 14, width: 14, marginRight: 10 }} />
          <p className='drawer-schedule-detail'>{dayjs.tz(props.selectedSchedule?.date).format('dddd, DD MMMM YYYY')}</p>
        </div>
        <div className='displayFlex mt-16'>
          <img src='/images/clock.png' style={{ height: 14, width: 14, marginRight: 10 }} />
          <p className='drawer-schedule-detail'>
            {dayjs.tz(props.selectedSchedule?.startTime).format('hh:mma') + " - " + dayjs.tz(props.selectedSchedule?.endTime).format('hh:mma')}
          </p>
        </div>
        <div className='displayFlex mt-16'>
          <img src='/images/clock.png' style={{ height: 14, width: 14, marginRight: 10 }} />
          <p className='drawer-schedule-detail'>
            {props.selectedSchedule?.batchName}
          </p>
        </div>
        <div className='displayFlex mt-16'>
          <img src='/images/board.png' style={{ height: 14, width: 15, marginRight: 9 }} />
          <p className='drawer-schedule-detail'>{props.selectedSchedule?.instructors?.map(s => s.name).join(", ")}</p>
        </div>
        <div className='drawer-heading'>{formatMsg("bookingDetails")}</div>
        <div className='displayFlex gap-25'>
          <div>
            <div className='displayFlex mt-16'>
              <img src='/images/confirm.png' style={{ height: 14, width: 14, marginRight: 10 }} />
              <p className='drawer-schedule-detail'>{"Total Bookings"}</p>
            </div>
            <div className='displayFlex mt-16'>
              <img src='/images/waitlisted.png' style={{ height: 14, width: 14, marginRight: 10 }} />
              <p className='drawer-schedule-detail'>{"Waitlists"}</p>
            </div>
          </div>
          <div>
            <div className='displayFlex mt-16'>
              <span className='drawer-schedule-detail'>:</span>&nbsp;&nbsp;{(props.scheduleDetail?.totalBookings?.length || 0) + " " + "seats"}
            </div>
            <div className='displayFlex mt-16'>
              <span className='drawer-schedule-detail'>:</span>&nbsp;&nbsp;{props.scheduleDetail?.waitlists?.length || "-"}
            </div>
          </div>
        </div>
        <div className='drawer-heading displayFlexSpaceBetweenNoWrap'>
          <p>{formatMsg("participants")}</p>
          {/* <p style={{ color: colors.color_mediumGrey }}>{formatMsg("viewProfile")}</p> */}
        </div>
        {props.scheduleDetail?.students?.filter(s => s.status.toLowerCase() === "active").map(student =>
          // <LinkWrapper href={`/dashboard/users/userDetailView?id=${student.id}&name=s${student.name}`} target="_blank">
          <div className='displayFlexSpaceBetweenNoWrap mb-10 std-sch-bb'>
            <div className='displayFlex'>
              <div>
                <img src={student.profileImageUrl || "/images/NoPhoto.png"} className='drawer-student-profile' />
              </div>
              <div>
                <div className='global-body-text'>{student.name}</div>
                {/* <div className='drawer-schedule-detail global-button-text-regular'>{formatMsg("age") + ": " + Helper.getAge(student.birthDate)}</div> */}
              </div>
            </div>
            {/* <div>
                <img src='/images/arrowRight.png' style={{ height: 13, width: 14 }} />
              </div> */}
          </div>
          // </LinkWrapper>
        )}
      </Spin>
    </Drawer>
  )
}

export default ViewScheduleDrawer