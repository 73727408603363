
import React, { useState, useEffect } from "react";
import colors from "../../libs/colorFactory";
import webFont from "../../libs/fonts";
import Helper from "../../util/helper";

const Toggle: React.FC<any> = (props) => {
    const {
        buttonOneTitle,
        buttonTwoTitle,
        buttonThreeTitle,
        activeBackground,
        selectedTextColor,
        textColor,
        onToggle,
        toggle,
        width,
        height,
        selectedFontWeight,
        fontWeight
    } = props;
    const [selectedNode, setSelectedNode] = useState(1);

    useEffect(() => {
        setSelectedNode(toggle);
    }, [toggle])

    const onChange = (node) => {
        setSelectedNode(node);
        onToggle(node);
    };

    const getTextColor = (node) => {
        if (node === selectedNode) return selectedTextColor ? selectedTextColor : colors.color_darkGrey;
        else return textColor ? textColor : colors.color_darkerGrey;
    };

    const getFontWeight = (node) => {
        if (node === selectedNode) return selectedFontWeight ? selectedFontWeight : 800;
        else return fontWeight ? fontWeight : 400;
    };

    const selectedSwitchStyle = {
        width: "50%",
        height: "100%",
        backgroundColor: activeBackground ? activeBackground : colors.color_white,
        borderRadius: 6,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: colors.color_white
    };

    const nonSelectedStyle = {
        width: "50%",
        cursor: "pointer"
    };

    return (
        <div style={{ width: '100%', minWidth: width ? width : 180, height: height ? height : '36px' }}>
            <div style={styles.mainDIv}>
                <div
                    style={selectedNode === 1 ? selectedSwitchStyle : nonSelectedStyle}
                    onClick={() => onChange(1)}
                >
                    <p
                        style={{
                            ...styles.text,
                            color: getTextColor(1),
                            fontWeight: getFontWeight(1)
                        }}
                    >
                        {buttonOneTitle}
                    </p>
                </div>
                <div
                    style={selectedNode === 2 ? selectedSwitchStyle : nonSelectedStyle}
                    onClick={() => onChange(2)}
                >
                    <p
                        style={{
                            ...styles.text,
                            color: getTextColor(2),
                            fontWeight: getFontWeight(2)
                        }}
                    >
                        {buttonTwoTitle}
                    </p>
                </div>
                {buttonThreeTitle &&
                    <div
                        style={selectedNode === 3 ? selectedSwitchStyle : nonSelectedStyle}
                        onClick={() => onChange(3)}
                    >
                        <p
                            style={{
                                ...styles.text,
                                color: getTextColor(3),
                                fontWeight: getFontWeight(3)
                            }}
                        >
                            {buttonThreeTitle}
                        </p>
                    </div>}
            </div>
        </div>
    );
};

const styles: any = {
    mainDIv: {
        height: '100%',
        border: "0.5px solid #D0D0D0",
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.color_lighterGrey
    },
    text: {
        textAlign: "center",
        margin: 0,
        fontSize: Helper.getClampCss(webFont.medium)
    }
};

export default Toggle;