import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import Helper from "../../util/helper";
import formatMsg from "../../util/formatMsg";
import dayjs from "dayjs";
import { setItem } from "../../libs/encryptedStorage";

function* addClassroom(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingoApi/classroom`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "FETCH_CLASSROOMS" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* deleteClassroom(request) {
  try {
    const { firebase, classId } = request.payload;
    let endpoint = `bookingoApi/classroom/${classId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "FETCH_CLASSROOMS" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* addSettings(request) {
  try {
    const { firebase, reqObj, settingType } = request.payload;
    let endpoint = `bookingoApi/${settingType}`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* deleteSettings(request) {
  try {
    const { firebase, itemId, settingType } = request.payload;
    let endpoint = `bookingoApi/${settingType}/${itemId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* confirmSettings(request) {
  try {
    const { firebase, reqObj, settingType } = request.payload;
    let endpoint = `bookingoApi/settings`
    if (reqObj.id) {
      endpoint = endpoint + "/" + reqObj.id
    }
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to set settings", err);
  }
}

function* getUserSettings(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingoApi/settings/user`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, userSetting: response.body.data }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch settings", err);
  }
}
function* getGuestSettings(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingoApi/settings/guest`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, guestSetting: response.body.data }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch settings", err);
  }
}

function* createStudentCustomField(request) {
  try {
    const { firebase, reqObj, dontShowNotification } = request.payload;
    let endpoint = `bookingoApi/settings/studentCustomField`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      if (!dontShowNotification) {
        notification("success", reqObj.id ? "Custom field edited successfully" : "Custom field added successfully")
      }
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { operationType: "fieldadded" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to add student custom fields", err);
  }
}

function* getCustomFields(request) {
  try {
    const { firebase, fieldType, selectedUserTypeId } = request.payload;

    let endpoint = firebase?.user?.id ? `bookingoApi/settings/studentCustomField/${fieldType}` : `bookingoApi/guest/settings/studentCustomField/${fieldType}`
    let response = yield call(callApi, firebase, "get", endpoint, {}, firebase?.user?.id ? false : true, null);

    if (response.status == 200) {

      const { defaultFields, customFields } = response.body.data;

      const customFieldsIdToObjMap = new Map();
      const defaultFieldsIdToObjMap = new Map();
      const defaultFieldsNameToObjMap = new Map();

      const newDefaultFields: any[] = [];
      const newCustomFields: any[] = [];

      defaultFields.forEach((additionalFieldObj: Record<string, any>) => {
        if (selectedUserTypeId) {
          if (additionalFieldObj.userTypeId === selectedUserTypeId) {
            newDefaultFields.push(additionalFieldObj);
            defaultFieldsIdToObjMap.set(additionalFieldObj.id, additionalFieldObj);
            defaultFieldsNameToObjMap.set(additionalFieldObj.name, additionalFieldObj);
          }
        }
        else {
          newDefaultFields.push(additionalFieldObj);
          defaultFieldsIdToObjMap.set(additionalFieldObj.id, additionalFieldObj);
          defaultFieldsNameToObjMap.set(additionalFieldObj.name, additionalFieldObj);
        }
      });

      customFields.forEach((additionalFieldObj: Record<string, any>) => {
        if (selectedUserTypeId) {
          if (additionalFieldObj.userTypeId === selectedUserTypeId) {
            newCustomFields.push(additionalFieldObj);
            customFieldsIdToObjMap.set(additionalFieldObj.id, additionalFieldObj);
          }
        }
        else {
          newCustomFields.push(additionalFieldObj);
          customFieldsIdToObjMap.set(additionalFieldObj.id, additionalFieldObj);
        }
      });

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          customFields: newCustomFields,
          customFieldsIdToObjMap,
          defaultFields: newDefaultFields,
          defaultFieldsIdToObjMap,
          defaultFieldsNameToObjMap
        }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch custom fields", err);
  }
}

function* deleteCustomField(request) {
  try {
    const { firebase, fieldId } = request.payload;
    let endpoint = `bookingoApi/settings/studentCustomField/${fieldId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "fielddeleted" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to delete field", err);
  }
}


function* saveCustomFieldOrderChanges(request) {
  try {

    const { firebase, customFieldsArray } = request.payload;

    const newAdditionalFieldIdOrderList = customFieldsArray.map((additionalFieldObj: Record<string, any>) => ({
      id: additionalFieldObj.id,
      order: additionalFieldObj.order
    }));

    const payload = {
      newAdditionalFieldIdOrderList,
      createdBy: firebase.teacher.name,
      createdOn: dayjs.tz().valueOf()
    }

    let endpoint = `bookingoApi/settings/saveCustomFieldOrder`;
    let response = yield call(callApi, firebase, "post", endpoint, payload);

    if (response.status == 200) {

      const customFieldsIdToObjMap = new Map();
      customFieldsArray.forEach((additionalFieldObj: Record<string, any>) => {
        customFieldsIdToObjMap.set(additionalFieldObj.id, additionalFieldObj);
      });

      notification("success", formatMsg("orderUpdatedSuccessfully"));

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          operationType: undefined,
          customFields: customFieldsArray,
          customFieldsIdToObjMap
        }
      });
    }
    else {
      notification("error", formatMsg('oopsSomthingWentWrong'));
      yield put({
        type: actions.SETTING_SAGA_FAILED,
        operationType: 'ORDER_UPDATE_FAILED'
      });
    }
  }
  catch (err) {

    Helper.notifyBugsnag(err);
    notification("error", formatMsg('oopsSomthingWentWrong'));

    console.log("failed to update order of custom fields", err);

    yield put({
      type: actions.SETTING_SAGA_FAILED,
      operationType: 'ORDER_UPDATE_FAILED'
    });
  }
}


function* getUserTypesList(request) {
  try {
    const { firebase } = request.payload;

    let endpoint = `bookingoApi/settings/userTypesList`;
    let response = yield call(callApi, firebase, "get", endpoint, {});

    if (response.status == 200) {

      const userTypesList = response.body.data;
      const selectedUserTypeObj = Helper.getSelectedUserTypeObj(userTypesList);

      const userTypeIdToObjMap = new Map();

      userTypesList.forEach((userTypeObj: Record<string, any>) => {
        userTypeIdToObjMap.set(userTypeObj.id, userTypeObj);
      });

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          userTypesList,
          selectedUserTypeObj,
          userTypeIdToObjMap
        }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch user types list", err);
  }
}


function* createNewUserType(request) {

  const { firebase, name } = request.payload;

  try {
    const createNewUserTypePayload = {
      createdOn: dayjs.tz().valueOf(),
      createdBy: firebase.teacher.name,
      name,
      selectedUserTypeNameByUser: true,
      selected: true
    };

    let endpoint = `bookingoApi/settings/userType`;
    let response = yield call(callApi, firebase, "post", endpoint, createNewUserTypePayload);

    if (response.status == 200) {

      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          selectedUserTypeName: name,
          selectedUserTypeId: response.body.data.id,
          selectedUserTypeNameByUser: true,
        }
      };

      setItem("schoolConfig", newFirebase.schoolConfig);

      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          operationType: 'NEW_USER_TYPE_CREATED'
        }
      });
    }
    else {
      notification('error', formatMsg('oopsSomthingWentWrong'));
      yield put({
        type: actions.SETTING_SAGA_FAILED
      });
    }
  }
  catch (err) {
    notification('error', formatMsg('oopsSomthingWentWrong'));
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to create new user type", err);
  }
}


function* updateSelectedUserType(request) {

  const { firebase, newSelectedUserTypeId, selectedUserTypeName } = request.payload;

  try {
    const payload = {
      newSelectedUserTypeId,
      selectedUserTypeName,
      selectedUserTypeNameByUser: true
    };

    let endpoint = `bookingoApi/settings/selectedUserType`;
    let response = yield call(callApi, firebase, "post", endpoint, payload);

    if (response.status == 200) {

      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          selectedUserTypeName,
          selectedUserTypeId: newSelectedUserTypeId,
          selectedUserTypeNameByUser: true
        }
      };

      setItem("schoolConfig", newFirebase.schoolConfig);

      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          operationType: 'UPDATED_SELECTED_USER_TYPE'
        }
      });
    }
    else {
      notification('error', formatMsg('oopsSomthingWentWrong'));
      yield put({
        type: actions.SETTING_SAGA_FAILED
      });
    }
  }
  catch (err) {
    notification('error', formatMsg('oopsSomthingWentWrong'));
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to update selected user type", err);
  }
}


function* updateSchoolConfigInMasterDb(request) {
  try {
    const { firebase, config, skipNotification } = request.payload;

    let endpoint = `bookingoApi/settings/updateSchoolConfigInMasterDB`;
    let response = yield call(callApi, firebase, "post", endpoint, { config });

    if (response.status == 200) {
      if (!skipNotification) {
        notification("success", formatMsg("settings.saved"))
      }
      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          ...config
        }
      };

      setItem("schoolConfig", newFirebase.schoolConfig);

      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          operationType: 'UPDATED_SCHOOL_CONFIG'
        }
      });
    }
    else {
      notification('error', formatMsg('oopsSomthingWentWrong'));
      yield put({
        type: actions.SETTING_SAGA_FAILED
      });
    }
  }
  catch (err) {
    notification('error', formatMsg('oopsSomthingWentWrong'));
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to update selected user type", err);
  }
}

function* saveGeneralSettings(request) {
  try {
    const { firebase, reqObj } = request.payload;
    if (reqObj.logoUrl && typeof reqObj.logoUrl !== 'string') {
      let storagePath = firebase.sbp + "/media/images/";
      let photourl = yield call(Helper.getAttachedMediaPath, storagePath, { fileList: [reqObj.logoUrl] }, firebase);
      if (photourl) {
        reqObj.logoUrl = photourl[0].path
      }
    }
    reqObj.logoUrl = reqObj.logoUrl || null;
    reqObj.logoByUser = true;
    let endpoint = `bookingoApi/settings/generalSettingsField/${firebase.schoolConfig.schoolId}`

    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {

      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          ...reqObj,
          currencyCode: reqObj.currency
        }
      };

      if (newFirebase.schoolConfig?.timezone) {
        dayjs.tz.setDefault(newFirebase.schoolConfig.timezone);
      }
      setItem("schoolConfig", newFirebase.schoolConfig);

      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "SAVE_GENERAL_SETTINGS" }
      });
      notification("success", `${response.body.message}`);
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    notification('error', formatMsg('oopsSomthingWentWrong'));
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to save general settings", err);
  }
}

function* sendStripeCodeToBackend(request) {
  try {
    const { firebase, code } = request.payload;

    let endpoint = `bookingoApi/stripe/setup`

    let response = yield call(callApi, firebase, "post", endpoint, { code });
    if (response.status == 200) {
      const newFirebase = {
        ...firebase,
        schoolConfig: {
          ...firebase.schoolConfig,
          ...response.body.data
        }
      };
      setItem("schoolConfig", newFirebase.schoolConfig);
      yield put({
        type: actions.LOGIN_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          firebase: newFirebase
        }
      });
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "UPDATED_SCHOOL_CONFIG" }
      });
      notification("success", `${response.body.message}`);
    }
    else {
      notification("error", `${response.body.message}`);
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to sendStripeCodeToBackend", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.SETTING_ADD_CLASSROOM, addClassroom)
  yield takeLatest(actions.SETTING_DELETE_CLASSROOM, deleteClassroom)
  yield takeLatest(actions.SETTING_ADD_SETTINGS, addSettings)
  yield takeLatest(actions.SETTING_DELETE_SETTINGS, deleteSettings)
  yield takeLatest(actions.SETTING_CONFIRM_SETTINGS, confirmSettings)
  yield takeLatest(actions.SETTING_GET_USER_SETTINGS, getUserSettings)
  yield takeLatest(actions.SETTING_GET_GUEST_SETTINGS, getGuestSettings)
  yield takeLatest(actions.SETTINGS_CREATE_STUDENT_CUSTOMFIELDS, createStudentCustomField)
  yield takeLatest(actions.SETTINGS_GET_CUSTOMFIELDS, getCustomFields)
  yield takeLatest(actions.SETTINGS_DELETE_CUSTOMFIELD, deleteCustomField)
  yield takeLatest(actions.SETTINGS_SAVE_CUSTOMFIELD_ORDER_CHANGES, saveCustomFieldOrderChanges)
  yield takeLatest(actions.SETTINGS_GET_USER_TYPES_LIST, getUserTypesList)
  yield takeLatest(actions.SETTINGS_CREATE_NEW_USER_TYPE, createNewUserType)
  yield takeLatest(actions.SETTINGS_UPDATE_SELECTED_USER_TYPE, updateSelectedUserType)
  yield takeLatest(actions.SETTINGS_UPDATE_SCHOOL_CONFIG_IN_MASTER_DB, updateSchoolConfigInMasterDb)
  yield takeLatest(actions.SETTING_SAVE_GENERAL_SETTINGS, saveGeneralSettings)
  yield takeLatest(actions.SETTING_SETUP_STRIPE, sendStripeCodeToBackend)
}