'use client'

import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Divider, Affix, Image, Button } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import colors from '../../libs/colorFactory';
import formatMsg from '../../util/formatMsg';
import dayjs from 'dayjs';

import Helper from '../../util/helper';
import "./style.css";
import LinkWrapper from '../linkWrapper/page';
import PrefixIconButton from '../buttons/prefixIconButton';
import TncModal from './TncModal';
import { useRouter } from 'next/navigation'
import { Box } from '../wrapperComponents/page';
import { BookingConfirmationPopup, NewBookingStatuses, packageActions, SpinWrapper } from '../..';
import RescheduleBookingModal from '../Booking/RescheduleBookingModal';
import CustomPopoverOptions from '../customPopoverOptions/page';
import { useDispatch } from 'react-redux';
import CustomPopOver from '../customPopOver/page';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";

const { changeBookingStatus, resetOperationType } = packageActions;

const PackageBookingOrMembershipDetailView = (props) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const { firebase, entityType, payload } = props;
    const { accountPackageReducer, packageReducer } = Helper.getSelectorState(["accountPackageReducer", "packageReducer"]);
    const { bookingDetails } = accountPackageReducer;

    const [mediaPreview, setMediaPreview] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [header, setHeader] = useState(false)
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [showBookingConfirmation, setShowBookingConfirmation] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState<any>();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [afterCancel, setAfterCancel] = useState(false);
    const [beforeCancel, setBeforeCancel] = useState(false);
    function showHideTermsAndConditions() {
        setTermsVisible(!termsVisible);
    }

    const validUpcomingBookingStatues = useRef(Helper.getValidUpcomingBookingStatues());
    const validPastBookingStatues = useRef(Helper.getValidPastBookingStatues());
    const validUpcomingMembershipStatues = useRef(Helper.getValidUpcomingMembershipStatues());
    const validPastMembershipStatues = useRef(Helper.getValidPastMembershipStatues());
    const renderCancellation = payload?.cancellationRules && payload?.cancellationRules?.allow
    const renderReschedule = payload?.reschedulingRules && payload?.reschedulingRules?.allow

    useEffect(() => {
        let confirmBookingPopUp: any = Helper.getUrlParam("confirmBookingPopUp");
        confirmBookingPopUp = Helper.checkUrlParam(confirmBookingPopUp, undefined);
        if (confirmBookingPopUp === "true") {
            setShowBookingConfirmation(true);
            Helper.setUrlParam('confirmBookingPopUp', "");
        }
    }, []);

    useEffect(() => {
        if (packageReducer.operationType === "BOOKING_UPDATED") {
            dispatch(resetOperationType(null));
            setAfterCancel(true);
        }
    }, [packageReducer.operationType]);

    function isEntity(entityOptionsArr: string[]) {
        return entityOptionsArr.some((entityName) => entityName.toLowerCase() === entityType.toLowerCase());
    }

    function membershipHasCreditsLeft() {
        return !(payload.remainingCredits === 0);
    }

    function membershipHasBookedSlots() {
        return (payload.remainingCredits < payload.credits);
    }

    function hasInvoicePaidAmount() {
        return (payload?.paid || payload?.paid === 0);
    }

    function entityHasStatus() {
        return payload?.bookingBasicDetails?.status;
    }

    function entityHasAgeRange() {
        return payload?.ageRange;
    }

    function membershipHasPricePackFrequencyAndCredits() {
        return (payload?.pricePackFrequency && payload?.credits);
    }

    function payloadHasUpcomingSlot() {
        switch (entityType) {
            case 'package':
                return payload?.batchDetails?.zerothBatch?.upcomingSlot;
            case 'booking':
            case 'membership':
                return payload?.bookedSlots?.upcomingSlot;
        }
    }

    function getButtonGroup() {
        return (
            <div>
                {
                    afterCancel &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <PrefixIconButton
                            title={formatMsg("close")}
                            onClick={() => {
                                setAfterCancel(false)
                            }}
                            borderRadius={"4px"}
                            type={"primary"}
                        />
                    </div>}

                {
                    beforeCancel &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <PrefixIconButton
                            title={formatMsg("goBack")}
                            border={true}
                            onClick={() => setBeforeCancel(false)}
                            borderRadius={"4px"}
                        />
                        <PrefixIconButton
                            title={formatMsg("yesCancel")}
                            onClick={() => {
                                dispatch(changeBookingStatus({ firebase, record: payload, confirmOrNot: false }));
                                setBeforeCancel(false);
                            }}
                            borderRadius={"4px"}
                            type={"primary"}
                        />
                    </div>

                }
            </div>
        )
    }

    const images = payload.gallery && payload.gallery.length > 0
        ? [{ original: payload.coverPhoto, type: "image", thumbnail: payload.coverPhoto }, ...payload.gallery.map(img => ({ original: img.path, type: img.type, thumbnail: img.type.includes("video") ? "/images/video_player_placeholder.png" : img.path }))]
        : [{ original: payload.coverPhoto, type: "image", thumbnail: payload.coverPhoto }];


    function getBookedForUser() {
        return (
            <div className='booked-for-user-color-div'>
                {payload?.bookingBasicDetails?.bookedForUser}
            </div>
        )
    }

    function getStartEndTime() {

        let startTime: any = null;
        let endTime: any = null;

        const uniqueTimeSlotsObjList = payload?.bookedSlots?.uniqueTimeSlotsObjList;

        const { bookedBatch, batchDetails } = payload;

        switch (entityType) {
            case 'package':
                if (batchDetails?.zerothBatch?.uniqueTimeSlotsObjList) {
                    startTime = batchDetails.zerothBatch.uniqueTimeSlotsObjList[0].startTime;
                    endTime = batchDetails.zerothBatch.uniqueTimeSlotsObjList[0].endTime;
                }
                break;

            case 'booking':
                if (uniqueTimeSlotsObjList && uniqueTimeSlotsObjList[0]) {
                    startTime = uniqueTimeSlotsObjList[0].startTime;
                    endTime = uniqueTimeSlotsObjList[0].endTime;
                }
                break;

            case 'membership':
                if (bookedBatch) {
                    startTime = bookedBatch.startTime;
                    endTime = bookedBatch.endTime;
                }
                break;
        }

        return (
            isEntity(['booking', 'package']) && (
                <>
                    {
                        (startTime && endTime) ?
                            <span className='font-size-16'>
                                <span>
                                    {`${dayjs.tz(startTime).format(Helper.getDefaultDateTimeFormat('time'))} - ${dayjs.tz(endTime).format(Helper.getDefaultDateTimeFormat('time'))}`}
                                </span>
                            </span>
                            :
                            null
                    }
                    {
                        (isEntity(['package', 'booking']) && batchDetails?.zerothBatch?.uniqueTimeSlotsObjList?.length > 1) &&
                        <span className='plus-more-option'>
                            {
                                `+${batchDetails?.zerothBatch?.uniqueTimeSlotsObjList?.length - 1} ${Helper.getMoreBatchesText(payload)}`
                            }
                        </span>
                    }
                </>
            )
        )
    }

    function getStartEndDate() {

        let startDate: any = null;
        let endDate: any = null;

        const { bookedBatch, batchDetails } = payload;
        let totalRunningDays = null;

        switch (entityType) {
            case 'package':
                if (batchDetails?.zerothBatch) {
                    startDate = batchDetails.zerothBatch.startDate;
                    endDate = batchDetails.zerothBatch.endDate;
                    totalRunningDays = payload?.batchDetails?.zerothBatch?.totalRunningDays
                }
                break;

            case 'booking':
                const bookingStartDate = payload?.bookedSlots?.startEndDateAndTime?.startDate;
                const bookingEndDate = payload?.bookedSlots?.startEndDateAndTime?.endDate;
                if (bookingStartDate && bookingEndDate) {
                    startDate = bookingStartDate;
                    endDate = bookingEndDate;
                    totalRunningDays = payload?.bookedSlots?.totalRunningDays;
                }
                break;

            case 'membership':
                if (bookedBatch) {
                    startDate = bookedBatch.startDate;
                    endDate = bookedBatch.endDate;
                }
                break;
        }

        return (
            isEntity(['booking', 'package'])
                ? (startDate && endDate) &&
                (<div className='entity-date-time-container'>
                    <div>
                        {dayjs.tz(startDate).format(Helper.dateFormat(firebase))}
                        {" - "}
                        {dayjs.tz(endDate).format(Helper.dateFormat(firebase))}
                    </div>
                    {
                        totalRunningDays &&
                        <div style={{ fontWeight: '400', paddingLeft: '5px' }}>
                            {`(${totalRunningDays} ${(totalRunningDays === 1) ? formatMsg('small.day') : formatMsg('days')})`}
                        </div>
                    }
                    {
                        (isEntity(['package']) && ((payload?.batchDetails?.numberOfBatchesInPackage - 1) > 0)) &&
                        <div className='plus-more-option mt-3'>
                            {
                                `+${payload.batchDetails.numberOfBatchesInPackage - 1} ${(payload.batchDetails.numberOfBatchesInPackage - 1 === 1) ? formatMsg('option') : formatMsg('options')}`
                            }
                        </div>
                    }
                </div>)
                : isEntity(['membership']) &&
                    payloadHasUpcomingSlot() ?
                    (getUpcomingSlotDiv())
                    :
                    <div>
                        <div className='no-upcoming-slot-container'>
                            <span className=''>{formatMsg('noUpcomingSlot')}</span>
                        </div>
                    </div>
        )
    }

    function renderCancellationPolicy() {
        let content = [];

        if (payload) {
            const { cancellationRules, reschedulingRules } = payload;
            if (renderCancellation) {
                if (cancellationRules.refundDetails) {
                    cancellationRules.refundDetails.map((item) => {
                        content.push(
                            <li className='book-fontWeightlight'><span className='book-fontWeight600'>{item.percentage}{"% "}{formatMsg("refund")}{" : "}</span> {formatMsg("refundPolicy", { frequency: item.type, duration: item.value })}</li>)
                    })
                }
            }
            if (renderReschedule) {
                content.push(
                    <li className='book-fontWeightlight'><span className='book-fontWeight600'>{formatMsg("rescheduling")}{" : "}</span> {formatMsg("reschedulingPolicy", { frequency: reschedulingRules.durationType, duration: reschedulingRules.duration })}</li>)
            }
        }
        return <ul className='book-participant-container'> {content} </ul>;
    }

    function getMembershipMainDates() {

        const { label, date } = Helper.getMembershipMainDatesText(payload);

        return (
            <div className='data-container'>
                <div className='data-label'>
                    {formatMsg(label)}:
                </div>
                <div className='data-value'>
                    {dayjs.tz(date).format(Helper.dateFormat(firebase))}
                </div>
            </div>
        )
    }

    function getActionButtons() {

        switch (entityType) {
            case 'package':
                return (
                    <div>
                        <LinkWrapper
                            href={`/dashboard/book?id=${Helper.getUrlParam('id')}`}
                        >
                            <PrefixIconButton
                                title={formatMsg("bookNow")}
                                borderRadius="8px"
                                onClick={() => null}
                                height="40px"
                                className='tab-lap-book-now-btn'
                                type={"primary"}
                            />
                        </LinkWrapper>
                    </div>
                )
            case 'booking':
                return (
                    <div>
                        <PrefixIconButton
                            type={"primary"}
                            title={formatMsg("viewSchedule")}
                            borderRadius="8px"
                            height="40px"
                            onClick={() => router.push(`/dashboard/calendar?entityType=booking&id=${payload?.bookingBasicDetails?.id}&centerId=` + Helper.getUrlParam("centerId"))}
                        />
                    </div>
                )
            case 'membership':
                if (membershipHasCreditsLeft()) {
                    if (
                        Helper.entityHasValidStatus(
                            validUpcomingMembershipStatues.current,
                            payload?.bookingBasicDetails?.status
                        )
                    ) {
                        return (
                            <div>
                                <PrefixIconButton
                                    type={"primary"}
                                    title={formatMsg("bookSlots")}
                                    borderRadius="8px"
                                    onClick={() => {
                                        setRescheduleModal(payload?.bookingBasicDetails?.id)
                                    }}
                                    height="40px"
                                />
                            </div>
                        )
                    }
                    else return null;
                }
                else {
                    return (
                        <div>
                            <PrefixIconButton
                                type={"primary"}
                                title={formatMsg("viewSchedule")}
                                borderRadius="8px"
                                height="40px"
                                onClick={() => router.push(`/dashboard/calendar?entityType=booking&id=${payload?.bookingBasicDetails?.id}&centerId=` + Helper.getUrlParam("centerId"))}
                            />
                        </div>
                    )
                }
        }
    }

    function getAgeRangeDiv() {
        return (
            <div className='age-range-text'>
                {formatMsg("age")}{": "}
                {
                    payload.ageRange.min ?
                        ((Math.round(payload.ageRange.min.year) +
                            (Math.round((payload.ageRange.min.month / 12) * 10) / 10))
                            + " - " +
                            (Math.round(payload.ageRange.max.year) +
                                (Math.round((payload.ageRange.max.month / 12) * 10) / 10)))
                        + " years "
                        :
                        null
                }
            </div>
        )
    }

    function getUpcomingSlotDiv() {

        let upcomingSlotObj = null;

        switch (entityType) {
            case 'package':
                upcomingSlotObj = payload?.batchDetails?.zerothBatch?.upcomingSlot;
                break;
            case 'booking':
            case 'membership':
                upcomingSlotObj = payload?.bookedSlots?.upcomingSlot;
                break;
        }

        if (upcomingSlotObj) {
            return (
                <div>
                    <div className={`upcoming-slot-container ${isEntity(['package', 'booking']) ? 'mb-8' : ''}`}>
                        <span className='upcoming-slot-heading'>{formatMsg('upcomingSlot')}:</span>
                        <span className='upcoming-slot-date-time'>
                            {dayjs.tz(upcomingSlotObj.date).format(Helper.dateFormat(firebase))}
                            {
                                (upcomingSlotObj.startTime && upcomingSlotObj.endTime) ?
                                    `, ${dayjs.tz(upcomingSlotObj.startTime).format(Helper.getDefaultDateTimeFormat('time'))} - ${dayjs.tz(upcomingSlotObj.endTime).format(Helper.getDefaultDateTimeFormat('time'))}`
                                    :
                                    null
                            }
                        </span>
                    </div>
                </div>
            )
        }
    }

    function getMobileViewBottomStickyDiv() {

        switch (entityType) {
            case 'package':
                return (
                    <div className='mobile-book-now'>
                        <div className='package-pricing'>
                            {
                                payload?.cheapestPrice &&
                                <div className='cheapest-price-container mb-25 font-size-16'>
                                    <span className='cheapest-price-div'>
                                        {`${Helper.getCurrency(firebase)}${payload.cheapestPrice} `}
                                    </span>
                                    <span className='onwards-text'>
                                        {formatMsg('onwards')}
                                    </span>
                                </div>
                            }
                        </div>
                        <LinkWrapper
                            href={`/dashboard/book?id=${payload?.id}`}
                            aTagStyle={{ width: '50%' }}
                        >
                            <PrefixIconButton
                                title={formatMsg("bookNow")}
                                width="100%"
                                maxWidth="350px"
                                height="38px"
                                outerDivWidth="100%"
                                marginTop="5px"
                                prefixRight={"9px"}
                                onClick={() => null}
                                borderRadius={'8px'}
                                type={"primary"}
                            />
                        </LinkWrapper>
                    </div>
                )
        };
    }

    function getEntityBasicDetails1stDivForMobileScreen() {

        return (
            <div className='mobile-sticky-bar'>
                <div className='package-name-label'>
                    {payload.name}
                    {payload?.bookedInCenter?.name ? (" - " + payload.bookedInCenter.name) : null}
                </div>
                <div className='entity-start-end-date'>
                    <div className='calendar-icon-container'>
                        <img src="/images/calender.png" className="calendar-icon" alt="calenderIcon" />
                    </div>
                    {
                        getStartEndDate()
                    }
                </div>
                {isEntity(['package']) && entityHasAgeRange() && (
                    <div className='age-range-container'>
                        <img src="/images/ageIcon.png" alt="Age Icon" className='age-range-icon' />
                        {getAgeRangeDiv()}
                    </div>
                )}
                <div className='location-container'>
                    {
                        isEntity(['package']) && payload?.centersList?.map((item) => item.name)?.join(", ")
                    }
                    {
                        isEntity(['booking', 'membership']) &&
                        <div className='location-display-name'>
                            <img src="/images/location.png" alt="Location Icon" className='location-icon' />
                            {payload?.bookedInCenter?.name}
                        </div>
                    }
                </div>
                {/* <div>
                    {
                        (isEntity(['package', 'booking']) && payloadHasUpcomingSlot()) &&
                        getUpcomingSlotDiv()
                    }
                </div> */}
            </div>
        );
    }

    function entityHasWeekDays() {

        switch (entityType) {
            case 'package':
                return payload?.batchDetails?.zerothBatch?.weekDays;
            case 'booking':
            case 'membership':
                return payload?.bookedSlots?.weekDays;
        }
    }

    function getWeekDaysDiv() {

        let weekDays = null;

        switch (entityType) {
            case 'package':
                weekDays = payload?.batchDetails?.zerothBatch?.weekDays;
                break;
            case 'booking':
            case 'membership':
                weekDays = payload?.bookedSlots?.weekDays;
                break;
        }

        return (
            (weekDays?.length > 0) &&
            <div className=''>
                {Helper.getWeekDaysDisplayDiv(weekDays, 'week-days-line', 'week-day-cell')}
            </div>
        )
    }


    const getApproveReject = (payload) => {
        let options: any = [];

        if (payload?.bookingBasicDetails?.status && (payload.bookingBasicDetails.status !== "CANCELLED")) {
            const currentTime = dayjs.tz();
            const { startDate } = payload.bookingBasicDetails;

            const getDeadlineTime = (startDate, duration, durationType) => {
                let deadline = dayjs.tz(startDate);

                if (durationType === "hours") {
                    deadline = deadline.subtract(duration, "hour");
                } else if (durationType === "days") {
                    deadline = deadline.subtract(duration, "day");
                }

                return deadline;
            };


            if (payload.reschedulingRules.allow) {
                const rescheduleDeadline = getDeadlineTime(startDate, payload.reschedulingRules.duration, payload.reschedulingRules.durationType);
                if (currentTime.isBefore(rescheduleDeadline)) {
                    if (payload.bookingBasicDetails.status != NewBookingStatuses.CONFIRMATION_PENDING && payload.bookingBasicDetails.status != NewBookingStatuses.WAITLISTED) options.push({
                        image: "/images/RescheduleIcon.png",
                        title: formatMsg("reschedule"),
                        action: () => {
                            setIsPopupOpen(undefined);
                            setOpenRescheduleModal(payload.bookingBasicDetails.id);
                        }
                    });
                }
            }

            if (payload.cancellationRules.allow) {
                const cancelDeadline = getDeadlineTime(startDate, payload.cancellationRules.duration, payload.cancellationRules.durationType);
                if (currentTime.isBefore(cancelDeadline)) {
                    options.push({
                        image: "/images/Reject.png",
                        title: formatMsg("cancel"),
                        action: () => {
                            setIsPopupOpen(undefined);
                            setBeforeCancel(true);
                        }
                    });
                }
            }
        }

        return options;
    };


    function getCancellationReschedulingOptions() {

        return getApproveReject(payload)?.length ? (
            <div className="displayFlex">
                <CustomPopoverOptions
                    imgSrc={"/images/dot.png"}
                    imgWidth={"5px"}
                    imgHeight={"25px"}
                    open={isPopupOpen}
                    setOpen={setIsPopupOpen}
                    recordId={payload.id}
                    options={getApproveReject(payload)}
                />
            </div>
        ) : null;
    }

    const getRefundPolicy = (cancellationRules, bookingStartDate) => {
        const currentTime = dayjs.tz();
        const timeDifference = dayjs.tz(bookingStartDate).diff(currentTime, "hours");

        let refundPercentage = 0;
        cancellationRules.refundDetails.forEach((rule) => {
            let ruleTime = rule.type === "days" ? rule.value * 24 : rule.value;
            if (timeDifference >= ruleTime) {
                refundPercentage = Math.max(refundPercentage, rule.percentage);
            }
        });

        return `You will receive a ${refundPercentage}% refund.`;
    };

    const getCancellationNotice = (cancellationRules) => {
        return `Cancel at least ${cancellationRules.duration} ${cancellationRules.durationType} in advance to avoid charges.`;
    };



    function getEntityBasicDetails1stDivForLapTabScreen() {
        return (
            <div
                className='entity-basic-details'
                style={{ justifyContent: header ? "space-around" : "space-between" }}
            >
                <div className='lap-tab-sticky-bar-content'>
                    <div className='first-row-detail-view'>
                        <div className='package-name-container'>
                            <div className='package-name-label'>
                                {payload?.name}
                                {payload?.bookedInCenter?.name ? (" - " + payload.bookedInCenter.name) : null}
                            </div>
                            {
                                ((isEntity(['booking', 'membership'])) && entityHasStatus()) &&
                                <div
                                    className='status-name-container'
                                    style={{
                                        backgroundColor: Helper.getBookingStatusColor(payload.bookingBasicDetails.status)
                                    }}
                                >
                                    {formatMsg(payload.bookingBasicDetails.status)}
                                </div>
                            }
                            {
                                (isEntity(['membership']) &&
                                    membershipHasBookedSlots() &&
                                    membershipHasCreditsLeft() &&
                                    Helper.entityHasValidStatus(
                                        validUpcomingMembershipStatues.current,
                                        payload?.bookingBasicDetails?.status
                                    )) &&
                                <div className='view-schedule-text'>
                                    {"("}{formatMsg('viewSchedule')}{")"}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='second-row-detail-view'>

                        <div className='second-row-col-1'>
                            <div className='entity-start-end-date'>
                                <div className='calendar-icon-container'>
                                    <img src="/images/calender.png" className="calendar-icon" alt="calenderIcon" />
                                </div>
                                <div>
                                    {
                                        getStartEndDate()
                                    }
                                </div>
                            </div>

                            {
                                isEntity(['package', 'booking']) &&
                                <div className='entity-start-end-date'>
                                    <div className='calendar-icon-container'>
                                        <img src="/images/clock.png" className="calendar-icon" alt="calenderIcon" />
                                    </div>
                                    <div className='entity-time-range'>
                                        {
                                            getStartEndTime()
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {isEntity(['package']) && entityHasAgeRange() && (
                            <div className='age-range-container'>
                                <img src="/images/ageIcon.png" alt="Age Icon" className='age-range-icon' />
                                {getAgeRangeDiv()}
                            </div>
                        )}

                        {
                            isEntity(['package']) && Boolean(payload?.cheapestPrice) &&
                            <div className='package-price-container'>
                                <div className='dollar-icon-container'>
                                    <img src="/images/dollarCoinIcon.png" className="dollar-icon" alt="dollarIcon" />
                                </div>
                                <div className='onwards-text-detail-view'>
                                    {`${Helper.getCurrency(firebase)}${payload.cheapestPrice} ` + formatMsg('onwards')}
                                </div>
                            </div>
                        }

                        {
                            isEntity(['booking', 'membership']) && (
                                <div className='user-container'>
                                    <div className='user-color-div'>
                                        <img src="/images/userIcon.png" className="user-icon" alt="userIcon" />
                                        {
                                            getBookedForUser()
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {/* {
                            isEntity(['booking', 'membership']) &&
                            hasInvoicePaidAmount() &&
                            <div className='invoice-paid-amount-container'>
                                <div className='dollar-icon-container'>
                                    <img src="/images/dollarCoinIcon.png" className="dollar-icon" alt="dollarIcon" />
                                </div>
                                <div>
                                    {Helper.getCurrency(firebase)}
                                    {payload.paid || 0}
                                    {" "}{formatMsg("label.paid")}
                                </div>
                                <div className='view-invoice'>
                                    {"("}{formatMsg('viewInvoice')}{")"}
                                </div>
                            </div>
                        } */}
                        {
                            entityHasWeekDays() && getWeekDaysDiv()
                        }

                        {
                            (isEntity(['booking']) &&
                                payload?.bookingBasicDetails?.status?.toLowerCase() === 'cancelled' &&
                                payload?.bookingBasicDetails?.cancelledOn) &&
                            <div className='second-row-detail-view'>
                                <div className='data-container'>
                                    <div className='data-label'>
                                        {formatMsg('cancelledOn')}:
                                    </div>
                                    <div className='data-value'>
                                        {dayjs.tz(payload.bookingBasicDetails.cancelledOn).format(Helper.dateFormat(firebase))}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        (isEntity(['membership'])) ?
                            <div className='third-row-detail-view'>
                                {
                                    (membershipHasPricePackFrequencyAndCredits()) && (
                                        <div className='data-container'>
                                            <div className='data-label'>
                                                {formatMsg('membershipType')}:
                                            </div>
                                            <div className='data-value'>
                                                {`${payload.credits || 0} ${formatMsg(payload.pricePackFrequency)} ${formatMsg('plan')}`}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    Boolean(payload.remainingCredits || payload.remainingCredits >= 0) &&
                                    <div className='data-container'>
                                        <div className='data-label'>
                                            {formatMsg('remainingWeeksToBeBooked')}:
                                        </div>
                                        <div className='data-value'>
                                            {payload.remainingCredits}
                                        </div>
                                    </div>
                                }
                                {
                                    getMembershipMainDates()
                                }
                            </div>
                            :
                            null
                    }
                    <div>
                        {
                            (isEntity(['booking', 'package']) && payloadHasUpcomingSlot()) &&
                            getUpcomingSlotDiv()
                        }
                    </div>
                </div>

                <div className='buttons-container'>
                    {
                        getActionButtons()
                    }
                    {
                        isEntity(['booking']) &&
                        getCancellationReschedulingOptions()
                    }
                </div>
            </div>
        )
    }

    function getEntityBasicDetails2ndDiv() {

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "24px", marginLeft: "10px", marginTop: "5px" }}>
                <div className='location-div'>
                    <img src="/images/location.png" alt="Location Icon" className='location-icon' />
                    <div className='location-container'>
                        {
                            isEntity(['package']) && payload?.centersList?.length >= 1 &&
                            <div className='location-display-name'>
                                {
                                    isEntity(['package']) && payload?.centersList?.map((item) => item.name)?.join(", ")
                                }
                                {
                                    // (payload?.centersList?.length - 1 > 0) &&
                                    // <span className='more-text'>
                                    //     {` +${payload?.centersList?.length - 1} ${formatMsg('more')}`}
                                    // </span>
                                }
                            </div>
                        }
                        {
                            isEntity(['booking', 'membership']) &&
                            <div className='location-display-name'>
                                {payload.bookedInCenter?.name}
                            </div>
                        }

                    </div>
                </div>
                {
                    isEntity(['booking', 'membership']) && payload?.bookedBatch?.name &&
                    <div className='booked-batch-name'>
                        <div className='batch-icon-container'>
                            <img src="/images/batchIcon.png" className="batch-icon" alt="batchIcon" />
                        </div>
                        <div>
                            {payload?.bookedBatch?.name}
                        </div>
                    </div>
                }
            </div>
        )
    }

    function getDescriptionOfPackageDiv() {

        return (
            <Col
                xxl={15} xl={17} lg={19} md={21} sm={22} xs={22}
                style={styles.descriptionContainer}
            >
                <div className='description-box-content'>
                    <div className='about-heading'>{formatMsg("about")}</div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: payload.description
                        }}
                    />
                </div>
            </Col>
        )
    }

    function getCancellationAndReschedulingRules() {

        return (
            <Col
                xxl={15} xl={17} lg={19} md={21} sm={22} xs={22}
                style={styles.cancellationReschedulingPolicy}>
                <div className='description-box-content'>
                    <div className='cancellation-policy-heading'>{formatMsg("cancellationReschedulingPolicy")}</div>
                    <div>
                        {renderCancellationPolicy()}
                    </div>
                </div>
            </Col>
        )
    }

    function getTermsAndConditionsLabel() {

        return (
            <Col xxl={15} xl={17} lg={19} md={21} sm={22} xs={22} className="terms-and-conditions-container">
                <span
                    className='view-text'
                >
                    <span>
                        {formatMsg("view")}
                    </span>

                    <span
                        onClick={showHideTermsAndConditions}
                        className='terms-conditions-text'
                    >
                        {formatMsg("label.termsAndConditions")}
                    </span>
                </span>
            </Col>
        )
    }

    function getSectionDivider() {
        return (
            <div className="divider adjust-divider">
                <Divider />
            </div>
        )
    }

    return (
        <Box
            className='custom-detail-box'
        >
            <SpinWrapper
                spinning={
                    accountPackageReducer.isLoading
                }
            >
                <Row align={"middle"} justify={"center"}>
                    <Col xs={24} className='margin-top-fill'>
                        <Affix
                            offsetTop={49}
                        >
                            {
                                getEntityBasicDetails1stDivForMobileScreen()
                            }
                        </Affix>
                    </Col>

                    <Col xxl={15} xl={17} lg={19} md={21} sm={22} xs={22} className="gallery-container" >
                        <div className='image-previewer-container' >
                            <Row gutter={10}>
                                <Col
                                    xxl={images.length == 1 ? 24 : 18}
                                    xl={images.length == 1 ? 24 : 18}
                                    lg={images.length == 1 ? 24 : 18}
                                    md={images.length == 1 ? 24 : 18}
                                    sm={images.length == 1 ? 24 : 18}
                                    xs={images.length == 1 ? 24 : 18}
                                    className='package-cover-image'
                                >
                                    <Image
                                        src={images[0].original}
                                        alt="Main view"
                                        style={styles.mainImage}
                                        onClick={() => {
                                            setCurrentIndex(0);
                                            setIsPreviewOpen(true);
                                        }}
                                        preview={false}
                                    />
                                </Col>
                                {images.length > 1 && (
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={styles.thumbnailContainer}>
                                        {images.slice(1, 4).map((img, index) => {
                                            return (
                                                <div key={index} style={styles.thumbnailWrapper}>
                                                    <Image
                                                        src={img.thumbnail}
                                                        style={{ ...styles.thumbnail, height: "100%" }}
                                                        onClick={() => {
                                                            setCurrentIndex(index + 1);
                                                            setIsPreviewOpen(true);
                                                        }}
                                                        preview={false}
                                                    />
                                                    {index === 2 && images.length > 4 && (
                                                        <button
                                                            onClick={() => {
                                                                setCurrentIndex(index + 1)
                                                                setIsPreviewOpen(true);
                                                            }}
                                                            style={styles.seeMoreButton}
                                                        >
                                                            +{images.length - 4} more
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </Col>
                                )}
                            </Row>
                            <Lightbox
                                open={isPreviewOpen}
                                index={currentIndex}
                                close={() => setIsPreviewOpen(false)}
                                slides={images.map((img) =>
                                    img.type.includes("video") ?
                                        ({ type: "video", sources: [{ src: img.original, type: "video/mp4" }] }) :
                                        ({ src: img.original, type: img.type })
                                )}
                                plugins={[Download, Counter, Fullscreen, Thumbnails, Zoom, Video]}
                                video={{ autoPlay: true, playsInline: true, preload: "auto" }}
                                counter={{ container: { style: { top: "unset", bottom: 0, left: "50%" } } }}
                                controller={{ closeOnBackdropClick: true, closeOnPullUp: true }}
                            />
                        </div>
                    </Col>

                    <Col xxl={header ? 24 : 15} xl={header ? 24 : 17} lg={header ? 24 : 19} md={header ? 24 : 21} sm={header ? 24 : 22} xs={header ? 24 : 22} >
                        <Affix
                            offsetTop={53}
                            onChange={e => setHeader(e)}
                        >
                            <div className="lap-tab-sticky-bar">
                                {
                                    getEntityBasicDetails1stDivForLapTabScreen()
                                }
                                {
                                    !header &&
                                    getSectionDivider()
                                }
                                {
                                    !header &&
                                    getEntityBasicDetails2ndDiv()
                                }
                            </div>
                        </Affix>
                    </Col>

                    {
                        payload?.description &&
                        getDescriptionOfPackageDiv()
                    }

                    {
                        (renderCancellation || renderReschedule) &&
                        getCancellationAndReschedulingRules()
                    }

                    {
                        payload?.termsAndConditions && Helper.stripHtmlTags(payload?.termsAndConditions) &&
                        getTermsAndConditionsLabel()
                    }

                    {
                        getMobileViewBottomStickyDiv()
                    }

                    <div className='bottom-filler'></div>

                    <TncModal
                        termsVisible={termsVisible}
                        termsAndConditions={payload?.termsAndConditions}
                        showHideTermsAndConditions={showHideTermsAndConditions}
                    />

                    {showBookingConfirmation &&
                        <BookingConfirmationPopup
                            showBookingConfirmation={showBookingConfirmation}
                            setShowBookingConfirmation={setShowBookingConfirmation}
                            bookingDetails={bookingDetails}
                            firebase={firebase}
                        />
                    }

                    {open ? <RescheduleBookingModal
                        open={openRescheduleModal}
                        setOpen={setOpenRescheduleModal}
                    /> : null}

                    {afterCancel &&
                        <div >
                            <div className='pbm-cpo-overlay'>
                                <div className='pbm-cpo-popup'>
                                    <p className='pbm-cpo-textAlignCenter fw-700' style={{ whiteSpace: "pre-wrap" }}>{formatMsg("afterCancel")}</p>
                                    <p className='pbm-cpo-textAlignLeft'>{formatMsg("desc.afterCancel")}</p>
                                    <div>{getButtonGroup()}</div>
                                </div>
                            </div>
                        </div>}

                    {beforeCancel &&
                        <div >
                            <div className='pbm-cpo-overlay'>
                                <div className='pbm-cpo-popup'>
                                    <p className='pbm-cpo-textAlignCenter fw-700' style={{ whiteSpace: "pre-wrap" }}>{formatMsg("beforeCancel")}</p>
                                    <div className='pbm-cpo-textAlignLeft mt-16'>
                                        {payload?.cancellationRules && <div className='popup-refund'>{formatMsg("popup.refundPolicy") + ": " + getRefundPolicy(payload.cancellationRules, payload.bookingBasicDetails.startDate)}</div>}
                                        {payload?.cancellationRules && <div>{formatMsg("cancellationNotice") + ": " + getCancellationNotice(payload?.cancellationRules)}</div>}
                                    </div>
                                    <div>{getButtonGroup()}</div>
                                </div>
                            </div>
                        </div>}





                    {rescheduleModal ? <RescheduleBookingModal open={rescheduleModal} setOpen={setRescheduleModal} remainingCredits={payload.remainingCredits} /> : null}

                </Row>
            </SpinWrapper>
        </Box>
    )
}

export default PackageBookingOrMembershipDetailView;


const styles: any = {
    wrap: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 15
    },
    cardHeader: {
        fontSize: 16
    },
    mainImageContainer: {
        width: '100%',
        position: 'relative',
        borderRadius: '2px',
        overflow: 'hidden',
    },
    mainImage: {
        width: '100%',
        aspectRatio: '16/9',
        objectFit: 'cover',
        cursor: 'pointer',
        height: "100%",
        borderRadius: "8px"
    },
    thumbnailContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: "10px",
        height: "auto"
    },
    thumbnailWrapper: {
        position: 'relative',
        flex: '1 1 0',
        overflow: 'hidden',
        borderRadius: 8
    },
    thumbnail: {
        width: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        aspectRatio: "16 / 9"
    },
    seeMoreButton: {
        position: 'absolute',
        inset: 0,
        background: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        borderRadius: '8px'
    },
    closeButton: {
        position: 'fixed',
        top: '32px',
        right: '32px',
        background: 'rgba(0, 0, 0, 0.1)',
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        transition: "0.3s"
    },
    downloadButton: {
        position: 'fixed',
        top: '32px',
        left: '32px',
        background: 'rgba(0, 0, 0, 0.1)',
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        transition: "0.3s"
    },
    descriptionContainer: {
        marginTop: 28,
        marginBottom: 28,
        borderRadius: "8px 0px 0px 0px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 25px 0px #0000000F",
        height: "auto",
        overflowY: "auto",
    },

    cancellationReschedulingPolicy: {
        borderRadius: "8px 0px 0px 0px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 25px 0px #0000000F",
        height: "auto",
        overflowY: "auto",
    },
    downloadIcon: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "45px",
        height: "45px"
    }
}